import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "./AuthHeader.css";

type Props = {
  showModal: () => void;
};

const AuthMenu = ({ showModal }: Props): ReactElement => {
  return (
    <nav className="auth-menu-container">
      <div className="arrow-up"></div>
      <ul>
        <li>
          <Link to="/">Inicio</Link>
        </li>
        <li>
          <Link to="/profile">Perfil</Link>
        </li>
        <li>
          <Link to="/remittance">Nuevo envío</Link>
        </li>
        <li onClick={showModal}>
          <div className="auth-menu-div">Cerrar sesión</div>
        </li>
      </ul>
    </nav>
  );
};

export default AuthMenu;
