import { combineReducers } from "redux";
// import of reducers
import SimulatorReducer, { simulatorStateType } from "./simulatorReducer";
import UserReducer, { userStateType } from "./userReducer";
import RegisterReducer, { registerStateType } from "./registerReducer";
import LoginReducer, { loginStateType } from "./loginReducer";
import forgotPasswordReducer, {
  forgotPasswordStateType,
} from "./forgotPasswordReducer";
import newPasswordReducer, { newPasswordStateType } from "./newPasswordReducer";
import beneficiaryFormReducer, {
  beneficiaryFormStateType,
} from "./beneficiaryFormReducer";
import paymentReducer, { paymentStateType } from "./paymentReducer";
import transactionSummaryReducer, {
  transactionSummaryStateType,
} from "./transactionSummaryReducer";

import homeReducer, { homeReducerStateType } from "./homeReducer";
import loginCashierReducer, {
  loginCashierStateType,
} from "./cashier/loginCashierReducer";
import cashierReducer, { cashierStateType } from "./cashier/cashierReducer";

export type globalStateType = {
  simulatorBox: simulatorStateType;
  register: registerStateType;
  login: loginStateType;
  forgotPassword: forgotPasswordStateType;
  newPassword: newPasswordStateType;
  beneficiaryForm: beneficiaryFormStateType;
  payment: paymentStateType;
  user: userStateType;
  home: homeReducerStateType;
  transactionSummary: transactionSummaryStateType;
  loginCashier: loginCashierStateType;
  cashier: cashierStateType;
};

export default combineReducers<globalStateType>({
  simulatorBox: SimulatorReducer,
  register: RegisterReducer,
  login: LoginReducer,
  forgotPassword: forgotPasswordReducer,
  newPassword: newPasswordReducer,
  beneficiaryForm: beneficiaryFormReducer,
  payment: paymentReducer,
  user: UserReducer,
  home: homeReducer,
  transactionSummary: transactionSummaryReducer,
  loginCashier: loginCashierReducer,
  cashier: cashierReducer,
});
