import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { globalStateType } from "../../../ducks/reducers";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Loader from "../../common/Loader";
import {
  onEmailChange,
  onPasswordChange,
  onCheckRememberMeChange,
  onEmailBlur,
  onPasswordBlur,
  signIn,
  cleanLoginState,
} from "../../../ducks/actions/cashier/LoginCashierActions";
import Logo from "../../../assets/img/logo/logo.png";
import CheckCircle from "../../../assets/icons/check-circle-yellow.png";
import UnCheckCircle from "../../../assets/icons/unCheck-circle.png";
import "../user/Login.css";

type Props = {
  email: string;
  emailValid?: boolean;
  emailError: string;
  onEmailChange: (text: string) => void;
  onEmailBlur: () => void;
  password: string;
  passwordValid?: boolean;
  passwordError: string;
  onPasswordChange: (text: string) => void;
  onPasswordBlur: () => void;
  checkRememberCashier: string;
  onCheckRememberMeChange: (value: string) => void;
  error: string;
  signIn: () => void;
  loading: boolean;
  cleanLoginState: () => void;
  successfullyLogin: boolean;
};

type State = typeof initialState;

const initialState = {
  visiblePassword: false,
  visibleHelpPassword: false,
};

class LoginCashier extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    const { onCheckRememberMeChange, onEmailChange } = this.props;

    const rememberMeStorage = localStorage.getItem("checkRememberCashier");
    const emailStorage = localStorage.getItem("emailCashier");

    if (rememberMeStorage && emailStorage) {
      onCheckRememberMeChange(rememberMeStorage);
      onEmailChange(emailStorage);
    }
  }

  componentWillUnmount() {
    const { cleanLoginState } = this.props;
    cleanLoginState();
  }

  handleOnVisibleClick = (value: string) => () => {
    if (value === "password") {
      this.setState({ visiblePassword: !this.state.visiblePassword });
    }
  };

  handleOnVisibleFocus = () => {
    this.setState({ visibleHelpPassword: !this.state.visibleHelpPassword });
  };

  onCheckMarkChange = () => {
    const {
      onCheckRememberMeChange,
      checkRememberCashier,
      email,
      emailValid,
    } = this.props;

    if (checkRememberCashier === "true") {
      onCheckRememberMeChange("false");
      localStorage.setItem("checkRememberCashier", "false");
      localStorage.setItem("emailCashier", "");
    } else {
      if (emailValid) {
        onCheckRememberMeChange("true");
        localStorage.setItem("checkRememberCashier", "true");
        localStorage.setItem("emailCashier", email);
      }
    }
  };

  handleOnEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onEmailChange, onEmailBlur } = this.props;
    onEmailChange(event.target.value);
    onEmailBlur();
  };

  handleOnPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onPasswordChange, onPasswordBlur } = this.props;
    onPasswordChange(event.target.value);
    onPasswordBlur();
  };

  handleOnButtonClick = () => {
    const { signIn } = this.props;
    signIn();
  };
  render() {
    const {
      email,
      emailValid,
      emailError,
      password,
      passwordValid,
      passwordError,
      checkRememberCashier,
      onEmailBlur,
      onPasswordBlur,
      error,
      loading,
      successfullyLogin,
    } = this.props;
    const { visiblePassword } = this.state;

    const redirect = successfullyLogin ? (
      <Redirect to="/home-cashier" />
    ) : (
      <React.Fragment />
    );

    return (
      <React.Fragment>
        {redirect}
        <div className="login-container">
          {loading ? <Loader /> : null}
          <div className="login-header">
            <a href="/">
              <img src={Logo} alt="celupago" className="login-logo" />
            </a>
            <h2 className="subTitle-login">Iniciar sesión </h2>
          </div>

          <div className="inputs-login-container">
            <Input
              styleName={
                emailValid !== undefined
                  ? emailValid
                    ? "common-input-valid"
                    : "common-input-invalid"
                  : ""
              }
              type="text"
              maxLength={40}
              minLength={6}
              label="Correo electrónico"
              onChangeValue={this.handleOnEmailChange}
              onBlur={onEmailBlur}
              value={email}
            />
            <div className="error-login-container">
              <p className="error-text-login">{emailError}</p>
            </div>

            <Input
              styleName={
                passwordValid !== undefined
                  ? passwordValid
                    ? "common-input-valid"
                    : "common-input-invalid"
                  : ""
              }
              type="password"
              maxLength={8}
              minLength={8}
              label="Contraseña"
              visible={visiblePassword}
              onChangeValue={this.handleOnPasswordChange}
              onBlur={onPasswordBlur}
              value={password}
              onClick={this.handleOnVisibleClick("password")}
            />
            <div className="error-login-container">
              <p className="error-text-login">{passwordError}</p>
            </div>
          </div>

          <div className="help-login-container">
            <div className="text-login ">
              <div className="login-check-container ">
                {checkRememberCashier === "true" ? (
                  <img
                    src={CheckCircle}
                    alt="check"
                    className="login-check-circle"
                    onClick={this.onCheckMarkChange}
                  />
                ) : (
                  <img
                    src={UnCheckCircle}
                    alt="unCheck"
                    className="login-check-circle"
                    onClick={this.onCheckMarkChange}
                  />
                )}
              </div>
              <p className="text-login">Recuérdame</p>
            </div>
          </div>
          <div className="error-register">
            <p className="error-text-register">{error}</p>
          </div>
          <div className="button-login">
            <Button
              buttonText={"INGRESAR"}
              onClick={this.handleOnButtonClick}
              disabled={emailValid && passwordValid ? false : true}
            />
          </div>

          <div className="text-login">
            ¿Aún no eres cajero? Haz tu solicitud{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeZRKi6v61FewnTgf9qAPAYwZMpzuVYO95TLK49xH0Wf3rIjw/viewform"
              className="text-blue-checkEmail"
            >
              aquí
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    email: state.loginCashier.email,
    emailValid: state.loginCashier.emailValid,
    emailError: state.loginCashier.emailError,
    password: state.loginCashier.password,
    passwordValid: state.loginCashier.passwordValid,
    passwordError: state.loginCashier.passwordError,
    checkRememberCashier: state.loginCashier.checkRememberCashier,
    error: state.loginCashier.error,
    loading: state.loginCashier.loading,
    successfullyLogin: state.loginCashier.successfullyLogin,
  };
};

export default connect(mapStateTopProps, {
  onEmailChange,
  onPasswordChange,
  onCheckRememberMeChange,
  onEmailBlur,
  onPasswordBlur,
  signIn,
  cleanLoginState,
})(LoginCashier);
