import { HOME_ACTIONS } from "../actions/HomeActions";
import { TransactionHistory } from "../../utils/ConstHelper";

export type homeReducerStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  transactions: [] as TransactionHistory[],
  error: "",
  loading: false,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case HOME_ACTIONS.GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload,
        error: "",
      };

    case HOME_ACTIONS.GET_TRANSACTION_HISTORY_START:
      return { ...state, loading: true };

    case HOME_ACTIONS.GET_TRANSACTION_HISTORY_FAIL:
      return {
        ...state,
        transactions: [],
        error: action.payload,
        loading: false,
      };
    case HOME_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case HOME_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
