import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Menu = () => {
  return (
    <nav className="menu">
      <ul>
        <li>
          <a href="#aboutUs">Nosotros</a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.ekiipago.com/blog/"
          >
            Blog
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeZRKi6v61FewnTgf9qAPAYwZMpzuVYO95TLK49xH0Wf3rIjw/viewform"
          >
            Cajero
          </a>
        </li>
        <li>
          <span className="sub-menu">Iniciar sesión</span>
          <ul className="sub-menu">
            <li>
              <Link to="/login">como usuario</Link>
            </li>
            <li>
              <Link to="/login-cashier">como cajero</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/register">
            <button className="register">Regístrate</button>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
