import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../common/Input";
import { globalStateType } from "../../../ducks/reducers";
import {
  Country,
  DocType,
  AccountType,
  Bank,
  callingCodeVe,
  CallingCodeVeType,
} from "../../../utils/ConstHelper";
import Button from "../../common/Button";
import Dropdown from "../../common/Dropdown";
import BeneficiaryDropdown from "./BeneficiaryDropdown";
import RadioButton from "../../../assets/icons/radio-button.png";
import RadioButtonOn from "../../../assets/icons/radio-button-on.png";
import {
  onNameChange,
  onNameBlur,
  onLastNameChange,
  onLastNameBlur,
  onNumberDocumentChange,
  onNumberDocumentBlur,
  onEmailChange,
  onEmailBlur,
  onAccountNumberChange,
  onAccountNumberBlur,
  onPhoneNumberChange,
  onPhoneNumberBlur,
  onRadioButtonChange,
  getCountryData,
  onBankChange,
  onDocTypeChange,
  onAccountTypeChange,
  onCallingCodeChange,
  affiliateAccount,
  getBeneficiaries,
  setSelectedBeneficiary,
} from "../../../ducks/actions/BeneficiaryFormActions";
import "./BeneficiaryForm.css";
import Loader from "../../common/Loader";
import Modal from "../../common/Modal";
import BeneficiarySummary from "./BeneficiarySummary";
import ExclamationCircle from "./../../../assets/icons/exclamation-circle.png";

const defaultState = { logOut: false };

type Props = {
  checkOwn: boolean;
  checkThirdParty: boolean;
  checkBeneficiary: boolean;
  onRadioButtonChange: (
    checkOwn: boolean,
    checkThirdParty: boolean,
    checkBeneficiary: boolean
  ) => void;
  name: string;
  nameValid?: boolean;
  nameError: string;
  onNameChange: (text: string) => void;
  onNameBlur: () => void;
  lastName: string;
  lastNameValid?: boolean;
  lastNameError: string;
  onLastNameChange: (text: string) => void;
  onLastNameBlur: () => void;
  numberDocument: string;
  numberDocumentValid?: boolean;
  numberDocumentError: string;
  onNumberDocumentChange: (text: string) => void;
  onNumberDocumentBlur: () => void;
  accountNumber: string;
  accountNumberValid?: boolean;
  accountNumberError: string;
  onAccountNumberChange: (text: string) => void;
  onAccountNumberBlur: () => void;
  phoneNumber: string;
  phoneNumberValid?: boolean;
  phoneNumberError: string;
  onPhoneNumberChange: (text: string) => void;
  onPhoneNumberBlur: () => void;
  email: string;
  emailValid?: boolean;
  emailError: string;
  onEmailChange: (text: string) => void;
  onEmailBlur: () => void;
  error: string;
  stepsNavigation: () => void;
  getCountryData: () => void;
  activeCountries: Country[];
  activeDocTypes: DocType[];
  accountType: AccountType[];
  availableBanks: Bank[];
  onBankChange: (value: Bank) => void;
  onDocTypeChange: (value: DocType) => void;
  onAccountTypeChange: (value: AccountType) => void;
  onCallingCodeChange: (value: CallingCodeVeType) => void;
  selectedBank?: Bank;
  selectedDocType?: DocType;
  selectedAccountType?: AccountType;
  selectedCallingCodeVe?: CallingCodeVeType;
  affiliateAccount: (navigate: () => void) => void;
  loading: boolean;
  getBeneficiaries: () => void;
  affiliatedAccounts: any[];
  selectedBeneficiary: any;
  setSelectedBeneficiary: (value: any) => void;
  showModal: boolean;
};

type State = typeof defaultState;

class BeneficiaryForm extends Component<Props, State> {
  state = defaultState;

  componentDidMount() {
    const { getCountryData, getBeneficiaries } = this.props;
    getCountryData();
    getBeneficiaries();
  }

  onRadioButtonChange = (
    checkOwn: boolean,
    checkThirdParty: boolean,
    checkBeneficiary: boolean
  ) => {
    const { onRadioButtonChange } = this.props;

    onRadioButtonChange(checkOwn, checkThirdParty, checkBeneficiary);
  };

  handleOnNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onNameChange, onNameBlur } = this.props;
    onNameChange(event.target.value);
    onNameBlur();
  };

  handleOnLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onLastNameChange, onLastNameBlur } = this.props;
    onLastNameChange(event.target.value);
    onLastNameBlur();
  };

  handleOnNumberDocumentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onNumberDocumentChange, onNumberDocumentBlur } = this.props;
    onNumberDocumentChange(event.target.value);
    onNumberDocumentBlur();
  };

  handleOnAccountNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onAccountNumberChange, onAccountNumberBlur } = this.props;
    onAccountNumberChange(event.target.value);
    onAccountNumberBlur();
  };

  handleOnPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onPhoneNumberChange, onPhoneNumberBlur } = this.props;
    onPhoneNumberChange(event.target.value);
    onPhoneNumberBlur();
  };

  handleOnEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onEmailChange, onEmailBlur } = this.props;
    onEmailChange(event.target.value);
    onEmailBlur();
  };

  handleOnButtonClick = () => {
    const { affiliateAccount, stepsNavigation } = this.props;
    affiliateAccount(stepsNavigation);
  };

  handleAffiliatedOnButtonClick = () => {
    const { stepsNavigation } = this.props;
    stepsNavigation();
  };
  handleBankChange = (value: Bank) => {
    const { onBankChange } = this.props;

    onBankChange(value);
  };

  handleDocTypeChange = (value: DocType) => {
    const { onDocTypeChange } = this.props;
    onDocTypeChange(value);
  };

  handleAccountTypeChange = (value: AccountType) => {
    const { onAccountTypeChange } = this.props;
    onAccountTypeChange(value);
  };

  handleCallingCodeChange = (value: AccountType) => {
    const { onCallingCodeChange } = this.props;
    onCallingCodeChange(value);
  };
  handleSelectedBeneficiaryChange = (value: any) => {
    const { setSelectedBeneficiary } = this.props;
    setSelectedBeneficiary(value);
  };

  renderBeneficiaryResume = () => {
    const { selectedBeneficiary } = this.props;
    return (
      <div className="beneficiary-resume-container">
        <BeneficiarySummary
          label={"Nombres y apellidos"}
          value={selectedBeneficiary.account_holder}
        />
        <BeneficiarySummary
          label={"Correo electrónico"}
          value={selectedBeneficiary.email}
        />
        <BeneficiarySummary
          label={"Banco"}
          value={selectedBeneficiary.bank.entity_name}
        />
        <BeneficiarySummary
          label={"Documento de identidad"}
          value={`${selectedBeneficiary.doc_type} - ${selectedBeneficiary.document}`}
        />
        <BeneficiarySummary
          label={"Nº de teléfono"}
          value={`${selectedBeneficiary.phone_number}`}
        />
        <BeneficiarySummary
          label={"Tipo y Nº de cuenta"}
          value={`
            ${
              selectedBeneficiary.account_type === "CHECKING" ? "CC" : "CA"
            } - ${selectedBeneficiary.account_number}`}
        />
      </div>
    );
  };

  renderBeneficiaryList = () => {
    const { affiliatedAccounts, selectedBeneficiary } = this.props;
    return (
      <div className="beneficiary-list-container">
        <BeneficiaryDropdown
          placeholder={"Beneficiarios frecuentes"}
          large
          options={affiliatedAccounts}
          onValueChange={this.handleSelectedBeneficiaryChange}
          selectedOption={selectedBeneficiary}
          valid={selectedBeneficiary ? true : false}
        />
        {selectedBeneficiary && this.renderBeneficiaryResume()}
        <div
          className={
            "beneficiaryForm-button-container dropdown-button-container"
          }
        >
          <Button
            id={"beneficiary-list-button"}
            buttonText={"CONTINUAR"}
            onClick={this.handleAffiliatedOnButtonClick}
            disabled={selectedBeneficiary ? false : true}
          />
        </div>
      </div>
    );
  };

  renderForm() {
    const {
      name,
      nameValid,
      nameError,
      lastName,
      lastNameValid,
      lastNameError,
      numberDocument,
      numberDocumentValid,
      numberDocumentError,
      accountNumber,
      accountNumberValid,
      accountNumberError,
      phoneNumber,
      phoneNumberValid,
      phoneNumberError,
      email,
      emailValid,
      emailError,
      error,
      activeDocTypes,
      accountType,
      availableBanks,
      selectedBank,
      selectedDocType,
      selectedAccountType,
      selectedCallingCodeVe,
    } = this.props;
    return (
      <React.Fragment>
        <div className="inputs-container-beneficiaryForm">
          <div>
            <div className="input-beneficiaryForm">
              <Input
                styleName={
                  nameValid !== undefined
                    ? nameValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={20}
                minLength={3}
                label="Nombres"
                onChangeValue={this.handleOnNameChange}
                onBlur={onNameBlur}
                value={name}
              />
              <div className="error-beneficiaryForm-container">
                <p className="error-text-beneficiaryForm-container">
                  {nameError}
                </p>
              </div>
            </div>
            <div className="input-beneficiaryForm">
              <Input
                styleName={
                  lastNameValid !== undefined
                    ? lastNameValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={20}
                minLength={3}
                label="Apellidos"
                onChangeValue={this.handleOnLastNameChange}
                onBlur={onLastNameBlur}
                value={lastName}
              />
              <div className="error-beneficiaryForm-container">
                <p className="error-text-beneficiaryForm-container">
                  {lastNameError}
                </p>
              </div>
            </div>
            <div className="input-beneficiaryForm">
              <Input
                styleName={
                  emailValid !== undefined
                    ? emailValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={40}
                minLength={6}
                label="Correo electrónico"
                onChangeValue={this.handleOnEmailChange}
                onBlur={onEmailBlur}
                value={email}
              />
              <div className="error-beneficiaryForm-container">
                <p className="error-text-beneficiaryForm-container">
                  {emailError}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="subtitle-container-beneficiaryForm">
              <p className="text-beneficiaryForm">Pago móvil:</p>
            </div>
            <div className="dropdown-container-beneficiaryForm">
              <Dropdown
                placeholder={"Banco país beneficiario"}
                large
                options={availableBanks}
                onValueChange={this.handleBankChange}
                selectedOption={selectedBank}
                valid={selectedBank ? true : false}
              />
            </div>
            <div className="dropdown-short-container-beneficiaryForm">
              <Dropdown
                placeholder={"Codigo"}
                options={callingCodeVe}
                onValueChange={this.handleCallingCodeChange}
                selectedOption={selectedCallingCodeVe}
                valid={selectedCallingCodeVe ? true : false}
              />
              <Input
                disable={selectedCallingCodeVe ? false : true}
                styleName={
                  phoneNumberValid !== undefined
                    ? phoneNumberValid
                      ? "common-input-dropdown common-input-valid"
                      : "common-input-dropdown common-input-invalid"
                    : "common-input-dropdown"
                }
                type="text"
                maxLength={7}
                minLength={7}
                label="Nº de teléfono"
                onChangeValue={this.handleOnPhoneNumberChange}
                onBlur={onPhoneNumberBlur}
                value={phoneNumber}
              />
            </div>
            <div className="error-beneficiaryForm-container">
              <p className="error-text-beneficiaryForm-container">
                {phoneNumberError}
              </p>
            </div>
            <div className="dropdown-short-container-beneficiaryForm">
              <Dropdown
                placeholder={"Tipo"}
                options={activeDocTypes}
                onValueChange={this.handleDocTypeChange}
                selectedOption={selectedDocType}
                valid={selectedDocType ? true : false}
              />
              <Input
                styleName={
                  numberDocumentValid !== undefined
                    ? numberDocumentValid
                      ? "common-input-dropdown common-input-valid"
                      : "common-input-dropdown common-input-invalid"
                    : "common-input-dropdown"
                }
                type="text"
                maxLength={15}
                minLength={8}
                label="Nº de documento"
                onChangeValue={this.handleOnNumberDocumentChange}
                onBlur={onNumberDocumentBlur}
                value={numberDocument}
              />
            </div>
            <div className="error-beneficiaryForm-container">
              <p className="error-text-beneficiaryForm-container">
                {numberDocumentError}
              </p>
            </div>
            <div className="account-subtitle-container-beneficiaryForm">
              <p className="text-beneficiaryForm">Cuenta bancaria:</p>
            </div>
            <div className="dropdown-short-container-beneficiaryForm ">
              <Dropdown
                placeholder={"Tipo"}
                options={accountType}
                onValueChange={this.handleAccountTypeChange}
                selectedOption={selectedAccountType}
                valid={selectedAccountType ? true : false}
              />

              <Input
                disable={selectedBank ? false : true}
                styleName={
                  accountNumberValid !== undefined
                    ? accountNumberValid
                      ? "common-input-dropdown common-input-valid"
                      : "common-input-dropdown common-input-invalid"
                    : "common-input-dropdown"
                }
                type="text"
                maxLength={selectedBank ? selectedBank.digits : 20}
                minLength={selectedBank ? selectedBank.digits : 13}
                label="Nº de cuenta"
                onChangeValue={this.handleOnAccountNumberChange}
                onBlur={onAccountNumberBlur}
                value={accountNumber}
              />
            </div>
            <div className="error-beneficiaryForm-container">
              <p className="error-text-beneficiaryForm-container">
                {accountNumberError}
              </p>
            </div>
          </div>
        </div>
        <div className="error-beneficiaryForm">
          <p className="error-text-beneficiaryForm">{error}</p>
        </div>
        <div className="button-register">
          <div className={"beneficiaryForm-button-container"}>
            <Button
              id={"beneficiary-button"}
              buttonText={"CONTINUAR"}
              onClick={this.handleOnButtonClick}
              disabled={
                emailValid &&
                nameValid &&
                lastNameValid &&
                numberDocumentValid &&
                accountNumberValid &&
                phoneNumberValid &&
                selectedCallingCodeVe &&
                selectedAccountType &&
                selectedBank &&
                selectedDocType
                  ? false
                  : true
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  hideModal = () => {
    this.setState({ logOut: true });
  };

  render() {
    const { logOut } = this.state;
    const {
      checkOwn,
      checkThirdParty,
      checkBeneficiary,
      showModal,
      loading,
    } = this.props;

    const redirect = logOut ? <Redirect to="/" /> : <React.Fragment />;
    return (
      <div className="beneficiaryForm-container">
        {loading ? <Loader /> : <React.Fragment />}
        <Modal
          show={showModal}
          title={"Sesión expirada"}
          img={ExclamationCircle}
          body={"Su sesión ha expirado"}
          handleClose={this.hideModal}
        />
        {redirect}
        <div>
          <h2 className="title-beneficiaryForm">Datos del beneficiario</h2>
        </div>
        <div className="radio-buttons-container-beneficiaryForm">
          <div className="radio-button-container-beneficiaryForm">
            <p className="text-beneficiaryForm"> Dirigido a una cuenta</p>
          </div>
          <div className="radio-button-container-beneficiaryForm">
            <div className="radio-button-beneficiaryForm">
              {checkThirdParty ? (
                <img
                  src={RadioButtonOn}
                  alt="check"
                  className="beneficiaryForm-check-circle"
                />
              ) : (
                <img
                  src={RadioButton}
                  alt="unCheck"
                  className="beneficiaryForm-check-circle"
                  onClick={() => this.onRadioButtonChange(true, false, false)}
                />
              )}
              <p className="text-beneficiaryForm">Tercero</p>
            </div>
            <div className="radio-button-beneficiaryForm">
              {checkOwn ? (
                <img
                  src={RadioButtonOn}
                  alt="check"
                  className="beneficiaryForm-check-circle"
                />
              ) : (
                <img
                  src={RadioButton}
                  alt="unCheck"
                  className="beneficiaryForm-check-circle"
                  onClick={() => this.onRadioButtonChange(false, true, false)}
                />
              )}
              <p className="text-beneficiaryForm">Propia</p>
            </div>
            <div className="radio-button-beneficiaryForm">
              {checkBeneficiary ? (
                <img
                  src={RadioButtonOn}
                  alt="check"
                  className="beneficiaryForm-check-circle"
                />
              ) : (
                <img
                  src={RadioButton}
                  alt="unCheck"
                  className="beneficiaryForm-check-circle"
                  onClick={() => this.onRadioButtonChange(false, false, true)}
                />
              )}
              <p className="text-beneficiaryForm">Frecuente</p>
            </div>
          </div>
        </div>
        {checkBeneficiary ? this.renderBeneficiaryList() : this.renderForm()}
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    checkOwn: state.beneficiaryForm.checkOwn,
    checkThirdParty: state.beneficiaryForm.checkThirdParty,
    checkBeneficiary: state.beneficiaryForm.checkBeneficiary,
    name: state.beneficiaryForm.name,
    nameValid: state.beneficiaryForm.nameValid,
    nameError: state.beneficiaryForm.nameError,
    lastName: state.beneficiaryForm.lastName,
    lastNameValid: state.beneficiaryForm.lastNameValid,
    lastNameError: state.beneficiaryForm.lastNameError,
    numberDocument: state.beneficiaryForm.numberDocument,
    numberDocumentValid: state.beneficiaryForm.numberDocumentValid,
    numberDocumentError: state.beneficiaryForm.numberDocumentError,

    accountNumber: state.beneficiaryForm.accountNumber,
    accountNumberValid: state.beneficiaryForm.accountNumberValid,
    accountNumberError: state.beneficiaryForm.accountNumberError,
    phoneNumber: state.beneficiaryForm.phoneNumber,
    phoneNumberValid: state.beneficiaryForm.phoneNumberValid,
    phoneNumberError: state.beneficiaryForm.phoneNumberError,
    email: state.beneficiaryForm.email,
    emailValid: state.beneficiaryForm.emailValid,
    emailError: state.beneficiaryForm.emailError,
    error: state.beneficiaryForm.error,
    activeCountries: state.beneficiaryForm.activeCountries,
    activeDocTypes: state.beneficiaryForm.activeDocTypes,
    accountType: state.beneficiaryForm.accountType,
    availableBanks: state.beneficiaryForm.availableBanks,

    selectedBank: state.beneficiaryForm.selectedBank,
    selectedDocType: state.beneficiaryForm.selectedDocType,
    selectedAccountType: state.beneficiaryForm.selectedAccountType,
    selectedCallingCodeVe: state.beneficiaryForm.selectedCallingCodeVe,
    loading: state.beneficiaryForm.loading,
    affiliatedAccounts: state.beneficiaryForm.affiliatedAccounts,
    selectedBeneficiary: state.beneficiaryForm.selectedBeneficiary,

    showModal: state.beneficiaryForm.showModal,
  };
};

export default connect(mapStateTopProps, {
  onNameChange,
  onNameBlur,
  onLastNameChange,
  onLastNameBlur,
  onNumberDocumentChange,
  onNumberDocumentBlur,
  onAccountNumberChange,
  onAccountNumberBlur,
  onPhoneNumberChange,
  onPhoneNumberBlur,
  onEmailChange,
  onEmailBlur,
  onRadioButtonChange,
  getCountryData,
  onBankChange,
  onDocTypeChange,
  onAccountTypeChange,
  onCallingCodeChange,
  affiliateAccount,
  getBeneficiaries,
  setSelectedBeneficiary,
})(BeneficiaryForm);
