import React, { ReactElement } from "react";
import Button from "./Button";
import "./Modal.css";

type Props = {
  handleClose?: () => void;
  handleGoBack?: () => void;
  show: boolean;
  title?: string;
  body?: string;
  img?: string;
  buttonTextGoBack?: string;
  buttonTextClose?: string;
};

const Modal = ({
  handleClose,
  handleGoBack,
  show,
  title,
  body,
  img,
  buttonTextGoBack,
  buttonTextClose,
}: Props): ReactElement => {
  const showHideClassName = show
    ? "modal display-block-modal"
    : "modal display-none-modal";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="title-modal-container">
          <span className="title-modal">{title}</span>
        </div>
        <div className="body-modal">
          {img ? <img src={img} className="img-modal" alt="img-modal" /> : null}
          <div className="text-modal-container">
            <span className="text-modal">{body}</span>
          </div>
          {handleGoBack ? (
            <div className="modal-buttons-container">
              <div className="modal-button">
                <Button
                  buttonText={buttonTextGoBack ? buttonTextGoBack : "REGRESAR"}
                  onClick={handleGoBack}
                  disabled={false}
                  secondaryButton={true}
                />
              </div>
              <div className="modal-button">
                <Button
                  buttonText={buttonTextClose ? buttonTextClose : "CONTINUAR"}
                  onClick={handleClose}
                  disabled={false}
                />
              </div>
            </div>
          ) : (
            <div className="modal-button-container">
              <Button
                buttonText={"OK"}
                onClick={handleClose}
                disabled={false}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Modal;
