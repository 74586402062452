import { LOGIN_CASHIER_ACTIONS } from "../../actions/cashier/LoginCashierActions";

export type loginCashierStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  email: "",
  emailValid: undefined as undefined | boolean,
  emailError: "",
  password: "",
  passwordValid: undefined as undefined | boolean,
  passwordError: "",
  checkRememberCashier: "false",
  error: "",
  loading: false,
  successfullyLogin: false,
  logOut: false,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN_CASHIER_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };
    case LOGIN_CASHIER_ACTIONS.ON_EMAIL_CHANGE:
      return {
        ...state,
        email: action.payload.email,
        emailValid: action.payload.valid,
        error: "",
      };

    case LOGIN_CASHIER_ACTIONS.ON_PASSWORD_CHANGE:
      return {
        ...state,
        password: action.payload.password,
        passwordValid: action.payload.valid,
        error: "",
      };

    case LOGIN_CASHIER_ACTIONS.ON_CHECK_REMEMBER_ME_CHANGE:
      return { ...state, checkRememberCashier: action.payload };

    case LOGIN_CASHIER_ACTIONS.ON_EMAIL_BLUR:
      return {
        ...state,
        emailValid: action.payload.valid,
        emailError: action.payload.errorMessage,
      };

    case LOGIN_CASHIER_ACTIONS.ON_PASSWORD_BLUR:
      return {
        ...state,
        passwordValid: action.payload.valid,
        passwordError: action.payload.errorMessage,
      };

    case LOGIN_CASHIER_ACTIONS.LOGIN_START:
      return { ...state, loading: true };

    case LOGIN_CASHIER_ACTIONS.LOGIN_FAIL:
      return {
        ...state,
        error: action.payload.errorMessage,
        loading: false,
      };

    case LOGIN_CASHIER_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload.errorMessage,
        successfullyLogin: true,
      };

    case LOGIN_CASHIER_ACTIONS.LOGOUT_SUCCESS:
      return {
        ...state,
        logOut: true,
        loading: false,
      };
    case LOGIN_CASHIER_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
