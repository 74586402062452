import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const HamburgerMenu = () => {
  return (
    <nav className="hamburger-menu-container">
      <div className="arrow-up"></div>
      <ul>
        <li>
          <Link to="/register">Regístrate</Link>
        </li>
        <li>
          <Link to="/login">Iniciar sesión</Link>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeZRKi6v61FewnTgf9qAPAYwZMpzuVYO95TLK49xH0Wf3rIjw/viewform"
          >
            Cajero
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.ekiipago.com/blog/"
          >
            Blog
          </a>
        </li>
        <li>
          <a href="#aboutUs">Nosotros</a>
        </li>
      </ul>
    </nav>
  );
};

export default HamburgerMenu;
