import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/img/logo/logoWhite.png";
import Yadio from "../../../assets/img/logo/yadio.png";
import Afx from "../../../assets/img/logo/afx.png";
import Facebook from "../../../assets/icons/facebook.png";
import Instagram from "../../../assets/icons/instagram.png";
import Twitter from "../../../assets/icons/twitter.png";
import Youtube from "../../../assets/icons/youtube.png";
import "./Footer.css";

const FooterContainer = () => {
  return (
    <div>
      <div className="footer">
        <div className="logo-footer-container">
          <div className="logo-footer-wrap">
            <img src={Logo} alt="celupago" className="logo-footer" />
          </div>
          <div className="logo-footer-wrap">
            <img src={Afx} alt="afx" className="afx" />
          </div>
        </div>

        <div className="footer-wrap">
          <div className="footer-container">
            <h5 className="title-footer"> Aviso Legal</h5>
            <ul>
              <li>
                <Link to="/terms-of-use" target="_blank">
                  Términos y Condiciones
                </Link>
              </li>
              <li>
                <Link to="/legal-permission" target="_blank">
                  Política de Privacidad
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-container">
            <h5 className="title-footer">Soporte</h5>
            <a href="mailto:soporte@ekiipago.com"> soporte@ekiipago.com</a>
          </div>
          <div className="footer-container">
            <h5 className="title-footer">Horario</h5>
            <p className="week"> Lunes a viernes</p>
            <span className="schedule"> 8:00 a. m. - 5:00 p. m. </span>
            {/*   <p className="week"> Sábado</p>
          <span className="schedule"> 10:00AM - 3:00PM</span> */}
          </div>

          <div className="footer-container">
            <h5 className="title-footer">Equipo</h5>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeZRKi6v61FewnTgf9qAPAYwZMpzuVYO95TLK49xH0Wf3rIjw/viewform"
            >
              Únete a nosotros
            </a>
          </div>
          <div className="footer-container">
            <h5 className="title-footer">Síguenos</h5>
            <a
              rel="noopener noreferrer"
              href="https://www.facebook.com/Ekiipago-106111987813302"
              target="_blank"
            >
              <img src={Facebook} alt="facebook" className="brands facebook" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://twitter.com/ekiipago"
              target="_blank"
            >
              <img src={Twitter} alt="twitter" className="brands twitter" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/ekiipago/"
              target="_blank"
            >
              <img
                src={Instagram}
                alt="instagram"
                className="brands instagram"
              />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCs3FH6psJTIjTBxvyMLLU9A?view_as=subscriber"
              target="_blank"
            >
              <img src={Youtube} alt="youtube" className="brands youtube" />
            </a>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="allies-footer-container">
        <a rel="noopener noreferrer" href="https://yadio.io" target="_blank">
          <img src={Yadio} alt="yadio" className="yadio" />
        </a>
      </div>
    </div>
  );
};

export default FooterContainer;
