import React, { ReactElement, useState } from "react";
import ReactGA from "react-ga";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import moment from "moment";
import Landing from "../components/public-views/landing/Landing";
import TermsAndConditions from "../components/public-views/landing/TermsAndConditions";
import Register from "../components/public-views/user/Register";
import CheckEmail from "../components/public-views/user/CheckEmail";
import Login from "../components/public-views/user/Login";
import ForgotPassword from "../components/public-views/user/ForgotPassword";
import NewPassword from "../components/public-views/user/NewPassword";
import RemittancesContainer from "../components/auth-views/remittances/RemittancesContainer";
import HomeContainer from "../components/auth-views/home/HomeContainer";
import LegalPermission from "../components/public-views/landing/LegalPermission";
import LoginCashier from "../components/public-views/cashier/LoginCashier";
import HomeCashierContainer from "../components/auth-views/cashier/HomeCashierContainer";
import Profile from "../components/auth-views/profile/Profile";
import Modal from "../components/common/Modal";
import ExclamationCircle from "../assets/icons/exclamation-circle.png";
import "./Main.css";

type Props = {};

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const AppRouter = (): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [flagHelper, setFlagHelper] = useState(0);
  const handleToggleModal = (value: boolean) => {
    setFlagHelper(1);
    setShowModal(value);
  };

  const getAuthView = (Component: any) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      const currentMoment = moment().unix();
      const sessionExpiresIn = localStorage.getItem("sessionExpiresIn");
      const expiresMinus10 = moment
        .unix(Number(sessionExpiresIn))
        .subtract(10, "minutes")
        .unix();

      if (
        currentMoment >= expiresMinus10 &&
        currentMoment < Number(sessionExpiresIn)
      ) {
        if (flagHelper === 0) {
          handleToggleModal(true);
        }
      }

      return Component;
    } else {
      setFlagHelper(0);
      return <Redirect to="/" />;
    }
  };

  const getUnAuthView = (Component: any) => {
    const token = localStorage.getItem("accessToken");
    const tokenCashier = localStorage.getItem("accessTokenCashier");
    if (token) {
      return <Redirect to="/home" />;
    } else if (tokenCashier) {
      return <Redirect to="/home-cashier" />;
    } else {
      setFlagHelper(0);
      return Component;
    }
  };

  const getCashierViewNotification = (Component: any) => {
    const tokenCashier = localStorage.getItem("accessTokenCashier");
    if (tokenCashier) {
      return <Redirect to="/home-cashier" />;
    } else {
      return Component;
    }
  };

  const getAuthViewCashier = (Component: any) => {
    const token = localStorage.getItem("accessTokenCashier");

    if (token) {
      return Component;
    } else {
      setFlagHelper(0);
      return <Redirect to="/" />;
    }
  };

  return (
    <Router history={history}>
      <React.Fragment>
        <Modal
          show={showModal}
          title={"Su sesión expira en 10 minutos"}
          img={ExclamationCircle}
          handleClose={() => handleToggleModal(false)}
        />
        <div className="container">
          <Switch>
            <Route exact path="/" render={() => getUnAuthView(<Landing />)} />
            <Route
              exact
              path="/register"
              render={() => getUnAuthView(<Register />)}
            />
            <Route exact path="/check-email" render={() => <CheckEmail />} />
            <Route
              exact
              path="/login/forgot-password"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/login-cashier"
              render={() => getUnAuthView(<LoginCashier />)}
            />
            <Route
              exact
              path="/login/:id"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/login"
              render={() => getUnAuthView(<Login />)}
            />
            <Route
              exact
              path="/forgot-password"
              render={() => getUnAuthView(<ForgotPassword />)}
            />
            <Route
              exact
              path="/new-password/:id"
              render={(props) => <NewPassword {...props} />}
            />
            <Route
              exact
              path="/terms-of-use"
              render={() => <TermsAndConditions />}
            />
            <Route
              exact
              path="/legal-permission"
              render={() => <LegalPermission />}
            />
            <Route
              exact
              path="/home"
              render={() => getAuthView(<HomeContainer />)}
            />

            <Route
              exact
              path="/profile"
              render={() => getAuthView(<Profile />)}
            />
            <Route
              exact
              path="/remittance"
              render={() => getAuthView(<RemittancesContainer />)}
            />
            <Route
              exact
              path="/home-cashier"
              render={() => getAuthViewCashier(<HomeCashierContainer />)}
            />
            <Route
              exact
              path="/cashier-notification"
              render={() => getCashierViewNotification(<LoginCashier />)}
            />
          </Switch>
        </div>
      </React.Fragment>
    </Router>
  );
};

export default AppRouter;
