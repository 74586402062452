import React, { Component } from "react";
import Operations from "./transaction-section/Operations";
import ProfileCashier from "./ProfileCashier";
import "./MainHomeCashier.css";
import { CashierTransactions } from "../../../utils/ConstHelper";
import { globalStateType } from "../../../ducks/reducers";
import { connect } from "react-redux";
import {
  updateStatusOperation,
  updateCryptoOperation,
  getRates,
} from "../../../ducks/actions/cashier/CashierActions";
import { Redirect } from "react-router-dom";
import EditOperation from "./edit-operation/EditOperation";

const defaultProps = {
  selectedOptions: "ProfileCashier",
};
const defaultState = {
  editShow: false,
  editOperationSuccess: false,
  currentTransaction: undefined as any | CashierTransactions,
};

type Props = {
  selectedOptions: String;
  getRates: () => void;
  selectOption: (option: string) => void;
  updateStatusOperation: (status: string, id: number) => void;
  updateCryptoOperation: (
    cryptoCurrency: string,
    cryptoAmount: number,
    id: number,
    status: string
  ) => void;
  btcToUsd: number;
  btcToVes: number;
  amountComplete: number;
  guarantee: number;
  rates: any;
} & typeof defaultProps;
type State = typeof defaultState;

class MainHomeCashier extends Component<Props, State> {
  state = defaultState;

  componentDidMount() {
    const { getRates } = this.props;
    getRates();
  }

  handleVisibleEdit = (transaction: CashierTransactions) => {
    const { selectOption } = this.props;
    selectOption("EditOperations");
    this.setState({ currentTransaction: transaction });
  };

  handleCryptoChange = (
    cryptoCurrency: string,
    cryptoAmount: number,
    id: number,
    status: string
  ) => {
    const { updateCryptoOperation, selectOption } = this.props;
    updateCryptoOperation(cryptoCurrency, cryptoAmount, id, status);
    this.setState({ editOperationSuccess: true });
    selectOption("Operations");
  };
  handleStatusChange = (status: string, id: number) => {
    const { updateStatusOperation, selectOption } = this.props;
    updateStatusOperation(status, id);
    this.setState({ editOperationSuccess: true });
    selectOption("Operations");
  };

  render() {
    const {
      selectedOptions,
      btcToVes,
      btcToUsd,
      amountComplete,
      guarantee,
      rates,
    } = this.props;
    const { currentTransaction, editOperationSuccess } = this.state;

    const redirect = editOperationSuccess ? (
      <Redirect to="/" />
    ) : (
      <React.Fragment />
    );
    return (
      <div className="main-home-cashier-container ">
        {redirect}
        {selectedOptions === "ProfileCashier" ? (
          <ProfileCashier />
        ) : selectedOptions === "EditOperations" ? (
          <EditOperation
            handleCryptoChange={this.handleCryptoChange}
            handleStatusChange={this.handleStatusChange}
            transaction={currentTransaction}
            btcToUsd={btcToUsd}
            btcToVes={btcToVes}
            amountComplete={amountComplete}
            guarantee={guarantee}
            rates={rates}
          />
        ) : (
          <Operations handleVisibleEdit={this.handleVisibleEdit} />
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    btcToUsd: state.cashier.btcToUsd,
    btcToVes: state.cashier.btcToVes,
    amountComplete: state.cashier.amountComplete,
    guarantee: state.cashier.guarantee,
    rates: state.cashier.rates,
  };
};
export default connect(mapStateTopProps, {
  updateStatusOperation,
  updateCryptoOperation,
  getRates,
})(MainHomeCashier);
