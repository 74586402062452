import React, { useState } from "react";
import moment from "moment";
import DownArrow from "../../../../assets/icons/chevron-down-blue.png";
import UpArrow from "../../../../assets/icons/chevron-up-blue.png";
import { TransactionHistory } from "../../../../utils/ConstHelper";
import "./HistoryList.css";

type Props = {
  transactions: TransactionHistory[];
  currentTransaction: number;
  onTransactionPress: (key: number) => void;
};

const HistoryList = ({
  onTransactionPress,
  currentTransaction,
  transactions,
}: Props) => {
  const [more, setMore] = useState(false);

  return (
    <React.Fragment>
      <div className="history-list-main-container">
        {transactions.slice(0 * 4, 0 * 4 + 4).map((transaction, index) => (
          <div
            key={transaction.key}
            className="history-list-item"
            onClick={() => onTransactionPress(transaction.key)}
          >
            <div className="history-list-left-section">
              <img
                src={
                  currentTransaction === transaction.key ? UpArrow : DownArrow
                }
                alt="radio"
                className="blue-chevron-style"
              />
            </div>
            <div className="history-list-right-section">
              <div className="transaction-item-row-item">
                <span className="transaction-history-label">
                  Nº de Transacción
                </span>
                <span className="transaction-history-value">
                  {transaction.celupagoReference}
                </span>
              </div>
              {currentTransaction === transaction.key ? (
                <React.Fragment>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label">
                      Banco país origen
                    </span>
                    <span className="transaction-history-value">
                      {transaction.cashierBank[0].commercial_name}
                    </span>
                  </div>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label">
                      Referencia Bancaria
                    </span>
                    <span className="transaction-history-value">
                      {transaction.bankReference}
                    </span>
                  </div>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label">ID cajero</span>
                    <span className="transaction-history-value">
                      {transaction.cashierId}
                    </span>
                  </div>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label">
                      Nombre cajero
                    </span>
                    <span className="transaction-history-value">
                      {transaction.cashierName}
                    </span>
                  </div>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label">
                      Beneficiario
                    </span>
                    <span className="transaction-history-value">
                      {transaction.beneficiaryName}
                    </span>
                  </div>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label">
                      Fecha de emisión
                    </span>
                    <span className="transaction-history-value">
                      {moment(transaction.emissionDate).format(
                        "DD/MM/YYYY HH:MM A"
                      )}
                    </span>
                  </div>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label">
                      Monto orígen
                    </span>
                    <span className="transaction-history-value">
                      {transaction.originAmount} {transaction.originCurrency}
                    </span>
                  </div>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label">
                      Monto destino
                    </span>
                    <span className="transaction-history-value">
                      {transaction.destinationAmount}{" "}
                      {transaction.destinationCurrency}
                    </span>
                  </div>
                  <div className="transaction-item-row-item">
                    <span className="transaction-history-label status-blue-color">
                      Estatus
                    </span>
                    <span className="transaction-history-status status-blue-color">
                      {transaction.status}
                    </span>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
            </div>
          </div>
        ))}
        {more &&
          transactions.slice(1 * 4, 1 * 4 + 4).map((transaction, index) => (
            <div
              key={transaction.key}
              className="history-list-item"
              onClick={() => onTransactionPress(transaction.key)}
            >
              <div className="history-list-left-section">
                <img
                  src={
                    currentTransaction === transaction.key ? UpArrow : DownArrow
                  }
                  alt="radio"
                  className="blue-chevron-style"
                />
              </div>
              <div className="history-list-right-section">
                <div className="transaction-item-row-item">
                  <span className="transaction-history-label">
                    Nº de Transacción
                  </span>
                  <span className="transaction-history-value">
                    {transaction.celupagoReference}
                  </span>
                </div>
                {currentTransaction === transaction.key ? (
                  <React.Fragment>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label">
                        Banco país origen
                      </span>
                      <span className="transaction-history-value">
                        {transaction.cashierBank[0].commercial_name}
                      </span>
                    </div>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label">
                        Referencia Bancaria
                      </span>
                      <span className="transaction-history-value">
                        {transaction.bankReference}
                      </span>
                    </div>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label">
                        ID cajero
                      </span>
                      <span className="transaction-history-value">
                        {transaction.cashierId}
                      </span>
                    </div>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label">
                        Nombre cajero
                      </span>
                      <span className="transaction-history-value">
                        {transaction.cashierName}
                      </span>
                    </div>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label">
                        Beneficiario
                      </span>
                      <span className="transaction-history-value">
                        {transaction.beneficiaryName}
                      </span>
                    </div>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label">
                        Fecha de emisión
                      </span>
                      <span className="transaction-history-value">
                        {moment(transaction.emissionDate).format(
                          "DD/MM/YYYY HH:MM A"
                        )}
                      </span>
                    </div>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label">
                        Monto orígen
                      </span>
                      <span className="transaction-history-value">
                        {transaction.originAmount} {transaction.originCurrency}
                      </span>
                    </div>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label">
                        Monto destino
                      </span>
                      <span className="transaction-history-value">
                        {transaction.destinationAmount}{" "}
                        {transaction.destinationCurrency}
                      </span>
                    </div>
                    <div className="transaction-item-row-item">
                      <span className="transaction-history-label status-blue-color">
                        Estatus
                      </span>
                      <span className="transaction-history-status status-blue-color">
                        {transaction.status}
                      </span>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
          ))}
      </div>
      <div
        className="transaction-history-see-more-container"
        onClick={() => setMore(!more)}
      >
        <span className="transaction-history-see-more">
          {more ? "Ver menos" : "Ver más"}
        </span>
      </div>
    </React.Fragment>
  );
};

export default HistoryList;
