import React, { ReactElement } from "react";
import "./Common.css";

type Props = {
  id?: string;
  disabled?: boolean;
  buttonText: string;
  secondaryButton?: boolean;
  onClick?: () => void;
};

const Button = ({
  disabled,
  buttonText,
  onClick,
  id,
  secondaryButton,
}: Props): ReactElement => {
  return (
    <div className="button-container">
      <button
        id={id}
        type="button"
        className={
          disabled
            ? "common-button-disabled"
            : secondaryButton
            ? "common-button common-secondary-button"
            : "common-button"
        }
        onClick={onClick}
        disabled={disabled}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default Button;
