import {
  getErrorMessage,
  COUNTRY_OPTIONS,
  calculateAge,
  Country,
  GENDER_TYPES,
} from "../../utils/ConstHelper";
import { cleanSimulatorState } from "./SimulatorActions";
import RequestManager from "../../services/RequestManager";
import {
  validateBlanks,
  validatePassword,
  validateAlphanumeric,
  validateNumeric,
  validateCharacterSpecial,
  validateNumber,
} from "../../utils/Validators";

const base = "USER_ACTIONS_";
const requestManager = new RequestManager();

export const USER_ACTIONS = {
  FETCH_COUNTRY_DATA: base + "FETCH_COUNTRY_DATA",
  FETCH_USER_PROFILE_START: base + "FETCH_USER_PROFILE_START",
  FETCH_USER_PROFILE_FAIL: base + "FETCH_USER_PROFILE_FAIL",
  FETCH_USER_PROFILE_SUCCESS: base + "FETCH_USER_PROFILE_SUCCESS",
  DISPATCH_UNAUTHORIZED: base + "DISPATCH_UNAUTHORIZED",
  EDIT_PROFILE_SUCCESS: base + "EDIT_PROFILE_SUCCESS",

  ON_PASSWORD_CHANGE: base + "ON_PASSWORD_CHANGE",
  ON_NEW_PASSWORD_CHANGE: base + "ON_NEW_PASSWORD_CHANGE",
  ON_PASSWORD_CONFIRM_CHANGE: base + "ON_PASSWORD_CONFIRM_CHANGE",
  ON_BIRTHDAY_CHANGE: base + "ON_BIRTHDAY_CHANGE",
  ON_GENDER_CHANGE: base + "ON_GENDER_CHANGE",
  ON_COUNTRY_CHANGE: base + "ON_COUNTRY_CHANGE",
  ON_CALLING_CODE_CHANGE: base + "ON_CALLING_CODE_CHANGE",
  ON_NUMBER_PHONE_CHANGE: base + "ON_NUMBER_PHONE_CHANGE",

  //ON BLUR ACTIONS
  ON_PASSWORD_BLUR: base + "ON_PASSWORD_BLUR",
  ON_NEW_PASSWORD_BLUR: base + "ON_NEW_PASSWORD_BLUR",
  ON_PASSWORD_CONFIRM_BLUR: base + "ON_PASSWORD_CONFIRM_BLUR",
  ON_NUMBER_PHONE_BLUR: base + "ON_NUMBER_PHONE_BLUR",

  CLEAN_STATE: base + "CLEAN_STATE",

  DO_NOTHING: base + "DO_NOTHING",
};

export const cleanUserState = () => {
  return {
    type: USER_ACTIONS.CLEAN_STATE,
  };
};

export const getUserProfile = () => {
  return (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: USER_ACTIONS.FETCH_USER_PROFILE_START });
    requestManager
      .get("/users/profile", {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        const {
          id,
          name,
          gender,
          document,
          email,
          country,
          docType,
          birthday,
          accounts,
          last_name,
          phone_number,
          phone_prefix,
          client_type,
        } = response.data;

        const flag = COUNTRY_OPTIONS.filter(
          (option) => country.iso === option.iso
        );

        const genderType = GENDER_TYPES.filter(
          (option) => gender === option.value
        );

        const user = {
          id,
          name,
          lastName: last_name,
          email,
          document,
          phoneNumber: phone_number,
          phonePrefix: phone_prefix,
          birthday,
          gender,
          clientType: client_type,
          country,
          docType,
          accounts,
          selectedGender: genderType,
          selectedCountry: { ...country, flag: flag[0].flag },
          selectedCallingCode: {
            value: phone_prefix,
            label: phone_prefix,
          },
        };

        dispatch({
          type: USER_ACTIONS.FETCH_USER_PROFILE_SUCCESS,
          payload: user,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: USER_ACTIONS.FETCH_USER_PROFILE_FAIL,
              payload: { error: errorMessage.error, showModal: true },
            });
          }, 500);
        } else {
          dispatch({
            type: USER_ACTIONS.FETCH_USER_PROFILE_FAIL,
            payload: { error: errorMessage.error, showModal: false },
          });
        }
      });
  };
};

export const handleUnauthorizedError = () => {
  return (dispatch: any, getState: any) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginDate");
    localStorage.removeItem("sessionExpiresIn");
    localStorage.removeItem("sessionTime");

    dispatch(cleanSimulatorState());
    setTimeout(() => {
      dispatch({
        type: USER_ACTIONS.DISPATCH_UNAUTHORIZED,
      });
    }, 500);
  };
};

export const getCountryData = () => {
  return (dispatch: any, getState: any) => {
    requestManager
      .get("/country?status=ACTIVE", {})
      .then((response: any) => {
        const activeCountries = response.data.map((country: any) => {
          const flag = COUNTRY_OPTIONS.filter(
            (option) => country.iso === option.iso
          );

          const docTypes = country.docTypes.map((doc: any) => {
            return { ...doc, value: doc.id, label: doc.code };
          });
          return {
            ...country,
            flag: flag[0].flag,
            value: country.currency,
            label: country.currency,
            docTypes,
          };
        });

        dispatch({
          type: USER_ACTIONS.FETCH_COUNTRY_DATA,
          payload: activeCountries,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: USER_ACTIONS.FETCH_COUNTRY_DATA,
          payload: [],
        });
      });
  };
};

export const onBirthdayChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: USER_ACTIONS.DO_NOTHING,
    };
  } else {
    if (calculateAge(text) >= 18 && calculateAge(text) <= 70) {
      return {
        type: USER_ACTIONS.ON_BIRTHDAY_CHANGE,
        payload: {
          birthday: text,
          valid: false,
        },
      };
    } else {
      return {
        type: USER_ACTIONS.ON_BIRTHDAY_CHANGE,
        payload: {
          birthday: text,
          valid: true,
          errorMessage: "Fecha inválida",
        },
      };
    }
  }
};

export const onPasswordChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: USER_ACTIONS.DO_NOTHING,
    };
  } else {
    if (validatePassword(text)) {
      return {
        type: USER_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: true,
        },
      };
    } else {
      return {
        type: USER_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: false,
        },
      };
    }
  }
};

export const onPasswordBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { password } = state.user;

    if (password !== "") {
      if (validatePassword(password)) {
        dispatch({
          type: USER_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: USER_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: false, errorMessage: "Contraseña inválida" },
        });
      }
    } else {
      dispatch({
        type: USER_ACTIONS.ON_PASSWORD_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onNewPasswordChange = (text: string) => {
  const validAlphanumeric = validateAlphanumeric(text);
  const validNumeric = validateNumeric(text);
  const validCharacterSpecial = validateCharacterSpecial(text);

  let validateLength = false;

  if (text.length >= 8 && text.length <= 15) {
    validateLength = true;
  }

  if (validateBlanks(text)) {
    return {
      type: USER_ACTIONS.DO_NOTHING,
    };
  } else {
    if (validatePassword(text)) {
      return {
        type: USER_ACTIONS.ON_NEW_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: true,
          checkAlphanumeric: true,
          checkSpecialCharacter: true,
          checkLength: true,
        },
      };
    } else {
      return {
        type: USER_ACTIONS.ON_NEW_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: false,
          checkAlphanumeric: validAlphanumeric && validNumeric,
          checkSpecialCharacter: validCharacterSpecial,
          checkLength: validateLength,
        },
      };
    }
  }
};

export const onNewPasswordBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { newPassword } = state.user;

    if (newPassword !== "") {
      if (validatePassword(newPassword)) {
        dispatch({
          type: USER_ACTIONS.ON_NEW_PASSWORD_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: USER_ACTIONS.ON_NEW_PASSWORD_BLUR,
          payload: { valid: false, errorMessage: "Contraseña inválida" },
        });
      }
    } else {
      dispatch({
        type: USER_ACTIONS.ON_NEW_PASSWORD_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onPasswordConfirmChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: USER_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: USER_ACTIONS.ON_PASSWORD_CONFIRM_CHANGE,
      payload: text,
    };
  }
};

export const onPasswordConfirmBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { passwordConfirmation, newPassword } = state.user;

    if (passwordConfirmation !== "") {
      if (passwordConfirmation === newPassword) {
        dispatch({
          type: USER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: USER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
          payload: {
            valid: false,
            errorMessage: "Confirmar contraseña no coincide",
          },
        });
      }
    } else {
      dispatch({
        type: USER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onCountryChange = (value: Country) => {
  return {
    type: USER_ACTIONS.ON_COUNTRY_CHANGE,
    payload: value,
  };
};

export const onCallingCodeChange = (value: any) => {
  return {
    type: USER_ACTIONS.ON_CALLING_CODE_CHANGE,
    payload: value,
  };
};

export const onGenderTypeChange = (value: any) => {
  return {
    type: USER_ACTIONS.ON_GENDER_CHANGE,
    payload: value,
  };
};

export const onNumberPhoneChange = (text: string) => {
  if (validateNumber(text)) {
    return {
      type: USER_ACTIONS.ON_NUMBER_PHONE_CHANGE,
      payload: text,
    };
  } else {
    if (text === "") {
      return {
        type: USER_ACTIONS.ON_NUMBER_PHONE_CHANGE,
        payload: text,
      };
    } else {
      return {
        type: USER_ACTIONS.DO_NOTHING,
      };
    }
  }
};

export const onNumberPhoneBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { phoneNumber } = state.user;

    if (phoneNumber !== "") {
      if (phoneNumber.length >= 8) {
        dispatch({
          type: USER_ACTIONS.ON_NUMBER_PHONE_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: USER_ACTIONS.ON_NUMBER_PHONE_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener mínimo 8 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: USER_ACTIONS.ON_NUMBER_PHONE_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const updateUser = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const {
      selectedGender,
      newBirthday,
      password,
      newPassword,
      phoneNumber,
      selectedCallingCode,
      selectedCountry,
    } = state.user;

    const data = {
      gender: selectedGender && selectedGender.value,
      birthday: newBirthday && newBirthday,
      password: password !== "" ? password : null,
      newPassword: newPassword !== "" ? newPassword : null,
      phone_number: phoneNumber,
      phone_prefix: selectedCallingCode.value,
      country: selectedCountry.id,
    };
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: USER_ACTIONS.FETCH_USER_PROFILE_START });
    requestManager
      .put(`/users/updateinfo`, data, {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        dispatch({
          type: USER_ACTIONS.EDIT_PROFILE_SUCCESS,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessTokenCashier");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: USER_ACTIONS.FETCH_USER_PROFILE_FAIL,
              payload: { error: errorMessage.error, showModal: true },
            });
          }, 500);
        } else {
          dispatch({
            type: USER_ACTIONS.FETCH_USER_PROFILE_FAIL,
            payload: { error: errorMessage.message },
          });
        }
      });
  };
};
