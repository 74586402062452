import React, { useState } from "react";
import Clear from "../../../../assets/icons/close-x.png";
import UnCheck from "../../../../assets/icons/rectangle.png";
import Check from "../../../../assets/icons/checked-rectangle.png";

import { CASHIER_FILTERS, CashierFilter } from "../../../../utils/ConstHelper";

import "./Filters.css";

type Props = {
  value?: string;
  handleFilterChange: (value: CashierFilter) => void;
  clearFilter: () => void;
};

const StatusFilter = ({ value, handleFilterChange, clearFilter }: Props) => {
  const [showStatuses, displayStatuses] = useState(false);
  return (
    <div className="full-filter-component-container">
      <div className="filter-label-container status-filter-container-margin">
        <div
          className="label-div-container"
          onClick={() => displayStatuses(!showStatuses)}
        >
          <span className="filter-label-style">{value || "Estatus"}</span>
        </div>
        <div className="clear-image-container">
          {value && (
            <img
              src={Clear}
              alt="x"
              className="clear-filter-button"
              onClick={() => clearFilter()}
            />
          )}
        </div>
      </div>
      {showStatuses && (
        <div className="statuses-list">
          {CASHIER_FILTERS.map((filter: CashierFilter) => (
            <div
              key={filter.id}
              className="status-list-row"
              onClick={() => {
                handleFilterChange(filter);
                displayStatuses(!showStatuses);
              }}
            >
              <div className="list-label-container">
                <span className="status-list-label">{filter.label}</span>
              </div>
              <div className="list-check-container">
                <img
                  src={value === filter.label ? Check : UnCheck}
                  alt="check"
                  className="check-filter-button"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StatusFilter;
