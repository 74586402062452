import React, { Component } from "react";
import Logo from "../../../assets/img/logo/logo.png";
import Hamburger from "../../../assets/icons/bars.png";
import "./Header.css";

import Menu from "./Menu";
import HamburgerMenu from "./HamburgerMenu";

interface Props {}

interface State {
  isVisible: boolean;
}

class HeaderContainer extends Component<Props, State> {
  state: State = {
    isVisible: false
  };

  render() {
    return (
      <div className="header">
        <div className="logo-container">
          <a href="/">
            <img src={Logo} alt="celupago" className="logo" />
          </a>
        </div>
        <div className="menu-container">
          <div className="menu-box-1">
            <div className="menu-box-2">
              <div className="menu-box-3">
                <Menu />
                <div
                  className="hamburger-menu"
                  onClick={() =>
                    this.setState({ isVisible: !this.state.isVisible })
                  }
                >
                  <img src={Hamburger} alt="menu" className="hamburger" />
                  {this.state.isVisible ? <HamburgerMenu /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderContainer;
