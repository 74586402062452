import React, { ReactElement } from "react";
import "./BeneficiaryForm.css";

type Props = {
  label: string;
  value: string;
};
const BeneficiarySummary = ({ label, value }: Props): ReactElement => {
  return (
    <div className="beneficiary-resume-row">
      <div className="beneficiary-resume-row-label-container">
        <p className="beneficiary-resume-label-text">{label}</p>
      </div>

      <div className="beneficiary-resume-row-value-container">
        <p className="beneficiary-resume-text">{value}</p>
      </div>
    </div>
  );
};

export default BeneficiarySummary;
