import { CASHIER_ACTIONS } from "../../actions/cashier/CashierActions";
import { CashierTransactions, CryptoActive } from "../../../utils/ConstHelper";

export type cashierStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  id: undefined as undefined | number,
  cashierType: "",
  celupagos_id: "",
  document: "",
  email: "",
  lastName: "",
  name: "",
  phoneNumber: "",
  phonePrefix: "",
  rate: "",
  docType: {},
  birthday: "",
  newBirthday: "",
  newBirthdayError: "",
  birthdayValid: false,
  loading: false,
  error: "",
  showModal: false,
  password: "",
  passwordError: "",
  newPassword: "",
  newPasswordError: "",
  passwordConfirmation: "",
  passwordConfirmationError: "",
  passwordValid: false,
  newPasswordValid: false,
  passwordConfirmationValid: false,
  newRate: "",
  newRateValid: false,
  newRateError: "",
  checkSpecialCharacter: false,
  checkAlphanumeric: false,
  checkLength: false,
  editSuccess: false,
  transactions: [] as CashierTransactions[],
  btcToUsd: 0,
  btcToVes: 0,
  rates: {},
  amountComplete: 0,
  guarantee: 0,
  cryptoActives: [] as CryptoActive[],
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_SUCCESS:
      return { ...state, ...action.payload, loading: false, error: "" };

    case CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_START:
      return { ...state, loading: true };

    case CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        showModal: action.payload.showModal,
      };

    case CASHIER_ACTIONS.EDIT_PROFILE_SUCCESS:
      return { ...state, editSuccess: true };

    case CASHIER_ACTIONS.ON_PASSWORD_CHANGE:
      return {
        ...state,
        password: action.payload.password,
        passwordValid: action.payload.valid,
      };

    case CASHIER_ACTIONS.ON_PASSWORD_BLUR:
      return {
        ...state,
        passwordValid: action.payload.valid,
        passwordError: action.payload.errorMessage,
      };

    case CASHIER_ACTIONS.ON_NEW_PASSWORD_CHANGE:
      return {
        ...state,
        newPassword: action.payload.password,
        newPasswordValid: action.payload.valid,
        checkAlphanumeric: action.payload.checkAlphanumeric,
        checkSpecialCharacter: action.payload.checkSpecialCharacter,
        checkLength: action.payload.checkLength,
      };

    case CASHIER_ACTIONS.ON_NEW_PASSWORD_BLUR:
      return {
        ...state,
        newPasswordValid: action.payload.valid,
        newPasswordError: action.payload.errorMessage,
      };

    case CASHIER_ACTIONS.ON_PASSWORD_CONFIRM_CHANGE:
      return { ...state, passwordConfirmation: action.payload };

    case CASHIER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR:
      return {
        ...state,
        passwordConfirmationValid: action.payload.valid,
        passwordConfirmationError: action.payload.errorMessage,
      };

    case CASHIER_ACTIONS.ON_NEW_RATE_CHANGE:
      return {
        ...state,
        newRate: action.payload.rate,
        newRateValid: action.payload.valid,
      };

    case CASHIER_ACTIONS.ON_NEW_RATE_BLUR:
      return {
        ...state,
        newRateValid: action.payload.valid,
        newRateError: action.payload.errorMessage,
      };

    case CASHIER_ACTIONS.ON_BIRTHDAY_CHANGE:
      return {
        ...state,
        newBirthday: action.payload.birthday,
        birthdayValid: action.payload.valid,
        newBirthdayError: action.payload.errorMessage,
      };

    case CASHIER_ACTIONS.FETCH_CASHIER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload,
        error: "",
      };

    case CASHIER_ACTIONS.FETCH_CASHIER_TRANSACTIONS_START:
      return { ...state, loading: true };

    case CASHIER_ACTIONS.FETCH_CASHIER_TRANSACTIONS_FAIL:
      return {
        ...state,
        transactions: [],
        error: action.payload,
        loading: false,
      };

    case CASHIER_ACTIONS.GET_RATES:
      return {
        ...state,
        btcToUsd: action.payload.btcToUsd,
        btcToVes: action.payload.btcToVes,
        rates: action.payload.rates,
      };

    case CASHIER_ACTIONS.GET_CRYPTO_ACTIVES:
      return {
        ...state,
        cryptoActives: action.payload,
      };

    case CASHIER_ACTIONS.GET_CASHIER_AMOUNT_COMPLETE:
      return {
        ...state,
        amountComplete: action.payload,
      };

    case CASHIER_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case CASHIER_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
