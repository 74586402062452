import React, { ReactElement } from "react";
import Select from "react-select";
import { CountryOption, COUNTRY_OPTIONS } from "../../../utils/ConstHelper";

type Props = {
  onClick?: () => void;
  onCurrencyChange?: (value: any) => void;
  options: any;
  beneficiary?: boolean;
  selectedCountry?: CountryOption;
};

const getIndex = (data: any, options: any) => {
  return options.indexOf(data) + 1;
};

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    border: "none",
    color: "white",
  }),
  container: (styles: any) => ({
    ...styles,
    width: "100%",
    height: "100%",
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    marginLeft: window.innerWidth > 480 ? -75 : 0,
    color: "white",
    width: window.innerWidth > 480 ? 200 : 90,
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
    marginLeft: window.innerWidth < 400 ? 10 : 0,
  }),
};

const Option = (props: any): ReactElement => {
  const { innerRef, innerProps, selectProps, data, options } = props;
  return (
    <div
      className="country-select-container-option"
      {...innerProps}
      ref={innerRef}
    >
      <div
        className={
          selectProps.options.length === getIndex(data, options)
            ? "country-info-container-option last-children-fix"
            : "country-info-container-option"
        }
      >
        <img src={data.flag} className="flag" alt="" />
        <p>{data.currency}</p>
        {window.innerWidth <= 480 ? (
          <React.Fragment />
        ) : (
          <p className="country-name-text">{data.name}</p>
        )}
      </div>
    </div>
  );
};
const SingleValue = (props: any): ReactElement => {
  const { innerRef, innerProps } = props;
  return (
    <div className="single-value-container" {...innerProps} ref={innerRef}>
      {window.innerWidth < 400 ? (
        <React.Fragment />
      ) : (
        <p className="single-value-currency">{props.data.currency}</p>
      )}

      <img
        src={props.data.flag}
        className={
          window.innerWidth < 400
            ? "single-value-flag single-value-flag-fix-small"
            : "single-value-flag"
        }
        alt=""
      />
    </div>
  );
};

const CountrySelect = ({
  onClick,
  options,
  beneficiary,
  onCurrencyChange,
  selectedCountry,
}: Props): ReactElement => {
  return (
    <React.Fragment>
      {beneficiary ? (
        <div className="country-select-container" onClick={onClick}>
          <div className="country-info-container">
            <p>{COUNTRY_OPTIONS[0].countryCurrency}</p>
            <img src={COUNTRY_OPTIONS[0].flag} className="flag" alt="" />
          </div>
        </div>
      ) : (
        <div className="country-select-container">
          <Select
            options={options}
            styles={selectStyles}
            placeholder={""}
            value={selectedCountry}
            components={{ Option, SingleValue }}
            onChange={(value) =>
              onCurrencyChange ? onCurrencyChange(value) : console.log("")
            }
            noOptionsMessage={({ inputValue }) => "Sin resultados"}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default CountrySelect;
