import React from "react";
import SimulatorContainer from "../simulator-box/SimulatorContainer";
import { withRouter } from "react-router-dom";
import Clock from "../../../assets/icons/clock-light.png";
import Money from "../../../assets/icons/money-light.png";
import "./Main.css";

const MobileMain = ({ history }: any) => (
  <div className="main-container">
    <div className="text-main-container">
      <p className="title-main">
        Envía dinero a Venezuela <br />
        seguro y confiable
      </p>
      <p className="text-main">
        Con <span className="text-white-main">ekii</span>
        <span className="text-blue-main">envíos</span> podrás enviar{" "}
        <span className="text-blue-main">dinero</span> de forma{" "}
        <span className="text-blue-main">fácil</span> y{" "}
        <span className="text-blue-main">segura</span> a bancos de <br />{" "}
        Venezuela.
      </p>
    </div>

    <div className="simulator-main-container">
      <SimulatorContainer history={history} />
    </div>

    <div className="cards-main-container">
      <div className="clock-main-container">
        <img src={Clock} alt="clock" className="clock-main" />
        <p className="text-main text-note">
          Envía dinero en menos de
          <span className="text-yellow-main"> 60 minutos</span>
        </p>
      </div>
      <div className="money-main-container">
        <img src={Money} alt="money" className="money-main" />
        <p className="text-main text-note">
          <span className="text-yellow-main ">Olvídate</span> de las{" "}
          <span className="text-yellow-main">comisiones </span>
          al enviar dinero
        </p>
      </div>
    </div>
  </div>
);

const DesktopMain = ({ history }: any) => (
  <div className="main-container">
    <div className="text-main-container">
      <p className="title-main">
        Envía dinero a Venezuela <br />
        seguro y confiable
      </p>

      <p className="text-main">
        Con <span className="text-white-main">ekii</span>
        <span className="text-blue-main">envíos</span> podrás enviar{" "}
        <span className="text-blue-main">dinero</span> <br />
        de forma <span className="text-blue-main">fácil</span> y{" "}
        <span className="text-blue-main">segura</span> a bancos de <br />{" "}
        Venezuela.
      </p>
      <div className="cards-main-container">
        <div className="clock-main-container">
          <img src={Clock} alt="clock" className="clock-main" />
          <p className="text-main text-note">
            Envía dinero en menos de
            <span className="text-yellow-main"> 60 minutos</span>
          </p>
        </div>
        <div className="money-main-container">
          <img src={Money} alt="money" className="money-main" />
          <p className="text-main text-note">
            <span className="text-yellow-main ">Olvídate</span> de las{" "}
            <span className="text-yellow-main">comisiones</span>
            <br /> al enviar dinero
          </p>
        </div>
      </div>
    </div>
    <div className="simulator-main-container">
      <SimulatorContainer history={history} />
    </div>
  </div>
);

const Main = ({ history }: any) => {
  return window.innerWidth < 580 ? (
    <MobileMain history={history} />
  ) : (
    <DesktopMain history={history} />
  );
};

export default withRouter(Main);
