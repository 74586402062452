import { USER_ACTIONS } from "../actions/UserActions";
import {
  DocType,
  Country,
  GENDER_TYPES,
  GenderType,
} from "../../utils/ConstHelper";

export type userStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  id: undefined as undefined | number,
  name: "",
  lastName: "",
  email: "",
  document: "",
  phoneNumber: "",
  phonePrefix: "",
  birthday: "",
  selectedBirthday: "",
  gender: "",
  clientType: "",
  password: "",
  newPassword: "",
  passwordConfirmation: "",
  passwordValid: undefined as undefined | boolean,
  newPasswordValid: undefined as undefined | boolean,
  passwordConfirmationValid: undefined as undefined | boolean,
  newBirthday: "",
  newBirthdayError: "",
  birthdayValid: undefined as undefined | boolean,
  numberPhoneError: "",
  numberPhoneValid: true as boolean | boolean,
  passwordError: "",
  newPasswordError: "",
  passwordConfirmationError: "",
  checkSpecialCharacter: false,
  checkAlphanumeric: false,
  checkLength: false,
  country: undefined as Country | undefined,
  selectedCountry: undefined as Country | undefined,
  docType: undefined as DocType | undefined,
  selectedCallingCode: undefined as any,
  genderType: GENDER_TYPES as GenderType[],
  selectedGender: undefined as GenderType | undefined,
  accounts: [],
  loading: false,
  error: "",
  showModal: false,
  editSuccess: false,
  activeCountries: [] as Country[],
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case USER_ACTIONS.FETCH_USER_PROFILE_SUCCESS:
      return { ...state, ...action.payload, loading: false, error: "" };

    case USER_ACTIONS.FETCH_COUNTRY_DATA:
      return { ...state, activeCountries: action.payload };

    case USER_ACTIONS.FETCH_USER_PROFILE_START:
      return { ...state, loading: true };

    case USER_ACTIONS.FETCH_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        showModal: action.payload.showModal,
      };

    case USER_ACTIONS.ON_PASSWORD_CHANGE:
      return {
        ...state,
        password: action.payload.password,
        passwordValid: action.payload.valid,
      };

    case USER_ACTIONS.ON_PASSWORD_BLUR:
      return {
        ...state,
        passwordValid: action.payload.valid,
        passwordError: action.payload.errorMessage,
      };

    case USER_ACTIONS.ON_NEW_PASSWORD_CHANGE:
      return {
        ...state,
        newPassword: action.payload.password,
        newPasswordValid: action.payload.valid,
        checkAlphanumeric: action.payload.checkAlphanumeric,
        checkSpecialCharacter: action.payload.checkSpecialCharacter,
        checkLength: action.payload.checkLength,
      };

    case USER_ACTIONS.ON_NEW_PASSWORD_BLUR:
      return {
        ...state,
        newPasswordValid: action.payload.valid,
        newPasswordError: action.payload.errorMessage,
      };

    case USER_ACTIONS.ON_PASSWORD_CONFIRM_CHANGE:
      return { ...state, passwordConfirmation: action.payload };

    case USER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR:
      return {
        ...state,
        passwordConfirmationValid: action.payload.valid,
        passwordConfirmationError: action.payload.errorMessage,
      };

    case USER_ACTIONS.ON_BIRTHDAY_CHANGE:
      return {
        ...state,
        newBirthday: action.payload.birthday,
        birthdayValid: action.payload.valid,
        newBirthdayError: action.payload.errorMessage,
      };

    case USER_ACTIONS.ON_COUNTRY_CHANGE:
      return { ...state, selectedCountry: action.payload };

    case USER_ACTIONS.ON_CALLING_CODE_CHANGE:
      return { ...state, selectedCallingCode: action.payload };

    case USER_ACTIONS.ON_GENDER_CHANGE:
      return { ...state, selectedGender: action.payload };

    case USER_ACTIONS.ON_NUMBER_PHONE_CHANGE:
      return { ...state, phoneNumber: action.payload };

    case USER_ACTIONS.ON_NUMBER_PHONE_BLUR:
      return {
        ...state,
        numberPhoneValid: action.payload.valid,
        numberPhoneError: action.payload.errorMessage,
      };

    case USER_ACTIONS.EDIT_PROFILE_SUCCESS:
      return { ...state, editSuccess: true };

    case USER_ACTIONS.DISPATCH_UNAUTHORIZED:
      return { ...state, showModal: true };

    case USER_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case USER_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
