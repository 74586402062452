import moment from "moment";
import {
  validatePassword,
  validateEmail,
  validateBlanks,
} from "../../utils/Validators";
import { getErrorMessage } from "../../utils/ConstHelper";
import RequestManager from "../../services/RequestManager";

const base = "LOGIN_ACTIONS_";
const requestManager = new RequestManager();

export const LOGIN_ACTIONS = {
  //ON CHANGES ACTIONS
  ON_EMAIL_CHANGE: base + "ON_EMAIL_CHANGE",
  ON_PASSWORD_CHANGE: base + "ON_PASSWORD_CHANGE",
  ON_CHECK_REMEMBER_ME_CHANGE: base + "ON_CHECK_REMEMBER_ME_CHANGE",

  //ON BLUR ACTIONS
  ON_EMAIL_BLUR: base + "ON_EMAIL_BLUR",
  ON_PASSWORD_BLUR: base + "ON_PASSWORD_BLUR",
  ON_CHECK_REMEMBER_ME_BLUR: base + "ON_CHECK_REMEMBER_ME_BLUR",

  LOGIN_START: base + "LOGIN_START",
  LOGIN_FAIL: base + "LOGIN_FAIL",
  LOGIN_SUCCESS: base + "LOGIN_SUCCESS",

  LOGIN_CONFIRM: base + "LOGIN_CONFIRM",

  CLEAN_STATE: base + "CLEAN_STATE",

  DO_NOTHING: base + "DO_NOTHING",
};

export const cleanLoginState = () => {
  return {
    type: LOGIN_ACTIONS.CLEAN_STATE,
  };
};

export const onEmailChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: LOGIN_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: LOGIN_ACTIONS.ON_EMAIL_CHANGE,
      payload: {
        email: text,
        valid: true,
      },
    };
  }
};

export const onPasswordChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: LOGIN_ACTIONS.DO_NOTHING,
    };
  } else {
    if (validatePassword(text)) {
      return {
        type: LOGIN_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: true,
        },
      };
    } else {
      return {
        type: LOGIN_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: false,
        },
      };
    }
  }
};

export const onCheckRememberMeChange = (value: string) => {
  return {
    type: LOGIN_ACTIONS.ON_CHECK_REMEMBER_ME_CHANGE,
    payload: value,
  };
};

export const onEmailBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { email } = state.login;

    if (email !== "") {
      if (validateEmail(email)) {
        dispatch({
          type: LOGIN_ACTIONS.ON_EMAIL_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: LOGIN_ACTIONS.ON_EMAIL_BLUR,
          payload: {
            valid: false,
            errorMessage: "La dirección de correo no es válida",
          },
        });
      }
    } else {
      dispatch({
        type: LOGIN_ACTIONS.ON_EMAIL_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onPasswordBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { password } = state.login;
    if (password !== "") {
      if (validatePassword(password)) {
        dispatch({
          type: LOGIN_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: LOGIN_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: false, errorMessage: "Contraseña inválida" },
        });
      }
    } else {
      dispatch({
        type: LOGIN_ACTIONS.ON_PASSWORD_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const signIn = () => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: LOGIN_ACTIONS.LOGIN_START });

    const state = getState();
    const { email, password } = state.login;

    const data = {
      email,
      password,
    };

    requestManager
      .post("/users/signIn", data, {})
      .then((response: any) => {
        const loginDate = moment().unix();
        const sessionExpiresIn = moment()
          .add(response.data.expiresIn, "seconds")
          .unix();

        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("loginDate", loginDate.toString());
        localStorage.setItem("sessionExpiresIn", sessionExpiresIn.toString());
        localStorage.setItem("sessionTime", response.data.expiresIn.toString());
        setTimeout(() => {
          dispatch({
            type: LOGIN_ACTIONS.LOGIN_SUCCESS,
            payload: { errorMessage: "" },
          });
        }, 500);
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({
          type: LOGIN_ACTIONS.LOGIN_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      });
  };
};

export const confirmRegister = (id: string) => {
  return (dispatch: any) => {
    dispatch({ type: LOGIN_ACTIONS.LOGIN_START });
    requestManager
      .get(`/users/confirm/${id}`, {})
      .then((response: any) => {
        dispatch({
          type: LOGIN_ACTIONS.LOGIN_CONFIRM,
          payload: { email: response.data, loginConfirm: true },
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({
          type: LOGIN_ACTIONS.LOGIN_CONFIRM,
          payload: { errorMessage: errorMessage.message, LoginConfirm: false },
        });
      });
  };
};
