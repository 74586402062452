import React, { Component } from "react";
import { connect } from "react-redux";
import AuthHeader from "../../common/auth-header/AuthHeader";
import Breadcrumbs from "./Breadcrumbs";
import RemittanceCalculation from "./RemittanceCalculation";
import BeneficiaryForm from "./BeneficiaryForm";
import Payment from "./Payment";
import TransactionSummary from "./TransactionSummary";
import Loader from "../../common/Loader";
import {
  getUserProfile,
  cleanUserState,
} from "../../../ducks/actions/UserActions";
import "./RemittancesContainer.css";
import { globalStateType } from "../../../ducks/reducers";
import { Redirect } from "react-router-dom";

const defaultProps = {};
const defaultState = { currentBreadcrumb: 0, isHeaderMenuVisible: false };

type Props = {
  //props | state
  getUserProfile: () => void;
  cleanUserState: () => void;
  loading: boolean;
  userError: any;
} & typeof defaultProps;

type State = typeof defaultState;

class RemittancesContainer extends Component<Props, State> {
  state = defaultState;

  componentDidMount() {
    this.props.getUserProfile();
  }

  handleNextStepPress = () => {
    const { currentBreadcrumb } = this.state;
    if (currentBreadcrumb >= 0 && currentBreadcrumb < 3) {
      this.setState({ currentBreadcrumb: currentBreadcrumb + 1 });
    }
  };

  toggleHeaderMenu = () => {
    const { isHeaderMenuVisible } = this.state;
    const visible = isHeaderMenuVisible;
    this.setState({ isHeaderMenuVisible: !visible });
  };

  hideHeaderMenu = () => {
    const { isHeaderMenuVisible } = this.state;
    if (isHeaderMenuVisible) {
      this.setState({ isHeaderMenuVisible: false });
    }
  };

  handleBackStepPress = () => {
    const { currentBreadcrumb } = this.state;
    if (currentBreadcrumb > 0 && currentBreadcrumb <= 3) {
      this.setState({ currentBreadcrumb: currentBreadcrumb - 1 });
    }
  };

  handleStep1OnClick = () => {
    this.setState({ currentBreadcrumb: 0 });
  };
  handleStep2OnClick = () => {
    this.setState({ currentBreadcrumb: 1 });
  };

  handleStep3OnClick = () => {
    this.setState({ currentBreadcrumb: 2 });
  };

  handleStep4OnClick = () => {
    this.setState({ currentBreadcrumb: 3 });
  };

  renderCurrentComponent = () => {
    const { currentBreadcrumb } = this.state;
    const components = [
      <RemittanceCalculation stepsNavigation={this.handleNextStepPress} />,
      <BeneficiaryForm stepsNavigation={this.handleNextStepPress} />,
      <Payment
        stepsNavigation={this.handleNextStepPress}
        navigationHome={this.handleStep1OnClick}
      />,
      <TransactionSummary stepsNavigation={this.handleStep1OnClick} />,
    ];
    return components[currentBreadcrumb];
  };

  getUserSession = () => {
    const { userError, cleanUserState } = this.props;
    if (userError === "UNAUTHORIZED") {
      cleanUserState();
      return <Redirect to="/" />;
    } else {
      return <React.Fragment />;
    }
  };

  render() {
    const { currentBreadcrumb, isHeaderMenuVisible } = this.state;
    const redirect = this.getUserSession();
    return (
      <div className="remittances-container" onClick={this.hideHeaderMenu}>
        {this.props.loading ? <Loader /> : <React.Fragment />}
        {redirect}
        <AuthHeader
          isVisible={isHeaderMenuVisible}
          toggleHeaderMenu={this.toggleHeaderMenu}
        />
        <Breadcrumbs
          handleBackPress={this.handleBackStepPress}
          currentStep={currentBreadcrumb}
          // navigateToStep1={this.handleStep1OnClick}
          // navigateToStep2={this.handleStep2OnClick}
          // navigateToStep3={this.handleStep3OnClick}
          // navigateToStep4={this.handleStep4OnClick}
        />
        {this.renderCurrentComponent()}
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.user.loading,
    userError: state.user.error,
  };
};
export default connect(mapStateTopProps, { getUserProfile, cleanUserState })(
  RemittancesContainer
);
