import RequestManager from "../../services/RequestManager";
import {
  validateNames,
  validateNumber,
  validateEmail,
  validateBlanks,
} from "../../utils/Validators";
import {
  getErrorMessage,
  COUNTRY_OPTIONS,
  Bank,
  DocType,
  AccountType,
} from "../../utils/ConstHelper";

const base = "BENEFICIARY_FORM_ACTIONS_";
const requestManager = new RequestManager();

export const BENEFICIARY_FORM_ACTIONS = {
  //GET COUNTRY INFO

  FETCH_COUNTRY_DATA: base + "FETCH_COUNTRY_DATA",
  FETCH_BANKS_DATA: base + "FETCH_BANKS_DATA",
  FETCH_USER_BENEFICIARY: base + "FETCH_USER_BENEFICIARY",
  //ON CHANGES ACTIONS
  ON_NAME_CHANGE: base + "ON_NAME_CHANGE",
  ON_LAST_NAME_CHANGE: base + "ON_LAST_NAME_CHANGE",
  ON_NUMBER_DOCUMENT_CHANGE: base + "ON_NUMBER_DOCUMENT_CHANGE",
  ON_BANK_CHANGE: base + "ON_BANK_CHANGE",
  ON_DOC_TYPE_CHANGE: base + "ON_DOC_TYPE_CHANGE",
  ON_ACCOUNT_TYPE_CHANGE: base + "ON_ACCOUNT_TYPE_CHANGE",
  ON_CALLING_CODE_CHANGE: base + "ON_CALLING_CODE_CHANGE",
  ON_ACCOUNT_NUMBER_CHANGE: base + "ON_ACCOUNT_NUMBER_CHANGE",
  ON_PHONE_NUMBER_CHANGE: base + "ON_PHONE_NUMBER_CHANGE",
  ON_EMAIL_CHANGE: base + "ON_EMAIL_CHANGE",
  ON_RADIO_BUTTON_CHANGE: base + "ON_RADIO_BUTTON_CHANGE",
  SET_SELECTED_BENEFICIARY: base + "SET_SELECTED_BENEFICIARY",

  //ON BLUR ACTIONS
  ON_NAME_BLUR: base + "ON_NAME_BLUR",
  ON_LAST_NAME_BLUR: base + "ON_LAST_NAME_BLUR",
  ON_NUMBER_DOCUMENT_BLUR: base + "ON_NUMBER_DOCUMENT_BLUR",
  ON_COUNTRY_BLUR: base + "ON_COUNTRY_BLUR",
  ON_ACCOUNT_TYPE_BLUR: base + "ON_ACCOUNT_TYPE_BLUR",
  ON_ACCOUNT_NUMBER_BLUR: base + "ON_ACCOUNT_NUMBER_BLUR",
  ON_PHONE_NUMBER_BLUR: base + "ON_PHONE_NUMBER_BLUR",
  ON_EMAIL_BLUR: base + "ON_EMAIL_BLUR",

  //CREATE BENEFICIARY ACCOUNT

  BENEFICIARY_FORM_START: base + "BENEFICIARY_FORM_START",
  BENEFICIARY_FORM_FAIL: base + "BENEFICIARY_FORM_FAIL",
  BENEFICIARY_FORM_SUCCESS: base + "BENEFICIARY_FORM_SUCCESS",

  SESSION_EXPIRED: base + "SESSION_EXPIRED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",
};

export const cleanBeneficiaryFormState = () => {
  return {
    type: BENEFICIARY_FORM_ACTIONS.CLEAN_STATE,
  };
};

//ON CHANGES ACTIONS
export const onNameChange = (text: string) => {
  if (validateNames(text)) {
    if (text.charAt(0) === " ") {
      return {
        type: BENEFICIARY_FORM_ACTIONS.DO_NOTHING,
      };
    } else {
      const textSplit = text.split("  ").toString();
      const textReplace = textSplit.replace(",", " ");
      return {
        type: BENEFICIARY_FORM_ACTIONS.ON_NAME_CHANGE,
        payload: textReplace,
      };
    }
  } else {
    return {
      type: BENEFICIARY_FORM_ACTIONS.DO_NOTHING,
    };
  }
};

export const onLastNameChange = (text: string) => {
  if (validateNames(text)) {
    if (text.charAt(0) === " ") {
      return {
        type: BENEFICIARY_FORM_ACTIONS.DO_NOTHING,
      };
    } else {
      const textSplit = text.split("  ").toString();
      const textReplace = textSplit.replace(",", " ");
      return {
        type: BENEFICIARY_FORM_ACTIONS.ON_LAST_NAME_CHANGE,
        payload: textReplace,
      };
    }
  } else {
    return {
      type: BENEFICIARY_FORM_ACTIONS.DO_NOTHING,
    };
  }
};

export const onNumberDocumentChange = (text: string) => {
  if (validateNumber(text)) {
    return {
      type: BENEFICIARY_FORM_ACTIONS.ON_NUMBER_DOCUMENT_CHANGE,
      payload: text,
    };
  } else {
    if (text === "") {
      return {
        type: BENEFICIARY_FORM_ACTIONS.ON_NUMBER_DOCUMENT_CHANGE,
        payload: text,
      };
    } else {
      return {
        type: BENEFICIARY_FORM_ACTIONS.DO_NOTHING,
      };
    }
  }
};

export const onAccountNumberChange = (text: string) => {
  if (validateNumber(text)) {
    return {
      type: BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_NUMBER_CHANGE,
      payload: text,
    };
  } else {
    if (text === "") {
      return {
        type: BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_NUMBER_CHANGE,
        payload: text,
      };
    } else {
      return {
        type: BENEFICIARY_FORM_ACTIONS.DO_NOTHING,
      };
    }
  }
};

export const onPhoneNumberChange = (text: string) => {
  if (validateNumber(text)) {
    return {
      type: BENEFICIARY_FORM_ACTIONS.ON_PHONE_NUMBER_CHANGE,
      payload: text,
    };
  } else {
    if (text === "") {
      return {
        type: BENEFICIARY_FORM_ACTIONS.ON_PHONE_NUMBER_CHANGE,
        payload: text,
      };
    } else {
      return {
        type: BENEFICIARY_FORM_ACTIONS.DO_NOTHING,
      };
    }
  }
};

export const onEmailChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: BENEFICIARY_FORM_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: BENEFICIARY_FORM_ACTIONS.ON_EMAIL_CHANGE,
      payload: text,
    };
  }
};

export const onRadioButtonChange = (
  checkThirdParty: boolean,
  checkOwn: boolean,
  checkBeneficiary: boolean
) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { name, lastName, email } = state.user;
    dispatch({
      type: BENEFICIARY_FORM_ACTIONS.ON_RADIO_BUTTON_CHANGE,
      payload: { checkOwn, checkThirdParty, checkBeneficiary },
    });
    if (checkOwn) {
      dispatch(onEmailChange(email));
      dispatch(onNameChange(name));
      dispatch(onLastNameChange(lastName));
      dispatch(onEmailBlur());
      dispatch(onNameBlur());
      dispatch(onLastNameBlur());
      dispatch(setSelectedBeneficiary("clean"));
    } else {
      dispatch(onEmailChange(""));
      dispatch(onNameChange(""));
      dispatch(onLastNameChange(""));
      dispatch(setSelectedBeneficiary("clean"));
      // dispatch(onEmailBlur());
      // dispatch(onNameBlur());
      // dispatch(onLastNameBlur());
    }
  };
};

//ON BLUR ACTIONS
export const onNameBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { name } = state.beneficiaryForm;

    if (name !== "") {
      if (name.length >= 3) {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_NAME_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_NAME_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener mínimo 3 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: BENEFICIARY_FORM_ACTIONS.ON_NAME_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onLastNameBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { lastName } = state.beneficiaryForm;

    if (lastName !== "") {
      if (lastName.length >= 3) {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_LAST_NAME_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_LAST_NAME_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener mínimo 3 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: BENEFICIARY_FORM_ACTIONS.ON_LAST_NAME_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onNumberDocumentBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { numberDocument } = state.beneficiaryForm;

    if (numberDocument !== "") {
      if (numberDocument.length >= 7) {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_NUMBER_DOCUMENT_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_NUMBER_DOCUMENT_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener mínimo 7 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: BENEFICIARY_FORM_ACTIONS.ON_NUMBER_DOCUMENT_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onPhoneNumberBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { phoneNumber } = state.beneficiaryForm;

    if (phoneNumber !== "") {
      if (phoneNumber.length >= 7) {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_PHONE_NUMBER_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_PHONE_NUMBER_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener 7 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: BENEFICIARY_FORM_ACTIONS.ON_PHONE_NUMBER_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onAccountNumberBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { accountNumber, selectedBank } = state.beneficiaryForm;

    if (accountNumber !== "") {
      if (accountNumber.length >= selectedBank.digits) {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_NUMBER_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_NUMBER_BLUR,
          payload: {
            valid: false,
            errorMessage: `El campo debe contener ${selectedBank.digits} caracteres`,
          },
        });
      }
    } else {
      dispatch({
        type: BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_NUMBER_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onEmailBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { email } = state.beneficiaryForm;

    if (email !== "") {
      if (validateEmail(email)) {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_EMAIL_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.ON_EMAIL_BLUR,
          payload: {
            valid: false,
            errorMessage: "La dirección de correo no es válida",
          },
        });
      }
    } else {
      dispatch({
        type: BENEFICIARY_FORM_ACTIONS.ON_EMAIL_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const getCountryData = () => {
  return (dispatch: any, getState: any) => {
    requestManager
      .get("/country?search=VE", {})
      .then((response: any) => {
        const availableCountries = response.data.map((country: any) => {
          const flag = COUNTRY_OPTIONS.filter(
            (option) => country.iso === option.iso
          );

          const docTypes = country.docTypes.map((doc: any) => {
            return { ...doc, value: doc.id, label: doc.code };
          });
          return {
            ...country,
            flag: flag[0].flag,
            value: country.currency,
            label: country.currency,
            docTypes,
          };
        });

        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.FETCH_COUNTRY_DATA,
          payload: availableCountries,
        });
        dispatch(getBanks());
      })
      .catch((error: any) => {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.FETCH_COUNTRY_DATA,
          payload: [],
        });
      });
  };
};

export const getBanks = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { activeCountries } = state.beneficiaryForm;
    requestManager
      .get(`/bank/${activeCountries[0].id}/byCountry`, {})
      .then((response: any) => {
        const availableBanks = response.data.map((bank: any) => {
          return {
            id: bank.id,
            bankStatus: bank.bank_status,
            code: bank.code,
            commercialName: bank.commercial_name,
            countryId: bank.countryId,
            entityName: bank.entity_name,
            createdAt: bank.created_at,
            value: bank.id,
            label: bank.commercial_name,
            digits: bank.digits,
          };
        });

        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.FETCH_BANKS_DATA,
          payload: availableBanks,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.FETCH_BANKS_DATA,
          payload: [],
        });
      });
  };
};

export const onBankChange = (value: Bank) => {
  return {
    type: BENEFICIARY_FORM_ACTIONS.ON_BANK_CHANGE,
    payload: value,
  };
};

export const onDocTypeChange = (value: DocType) => {
  return {
    type: BENEFICIARY_FORM_ACTIONS.ON_DOC_TYPE_CHANGE,
    payload: value,
  };
};

export const onAccountTypeChange = (value: AccountType) => {
  return {
    type: BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_TYPE_CHANGE,
    payload: value,
  };
};

export const onCallingCodeChange = (value: AccountType) => {
  return {
    type: BENEFICIARY_FORM_ACTIONS.ON_CALLING_CODE_CHANGE,
    payload: value,
  };
};

export const affiliateAccount = (navigate: () => void) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: BENEFICIARY_FORM_ACTIONS.BENEFICIARY_FORM_START,
    });
    const state = getState();
    const {
      name,
      lastName,
      numberDocument,
      selectedBank,
      selectedDocType,
      selectedAccountType,
      accountNumber,
      email,
      selectedCallingCodeVe,
      phoneNumber,
    } = state.beneficiaryForm;

    const clientAccount = {
      account_holder: name + " " + lastName,
      document: numberDocument,
      doc_type: selectedDocType.code,
      email,
      account_type: selectedAccountType.value,
      account_number: accountNumber,
      phone_number: selectedCallingCodeVe.value + phoneNumber,
      bank: selectedBank.id,
    };
    const accessToken = localStorage.getItem("accessToken");
    requestManager
      .post(`/bank-account/account`, clientAccount, {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.BENEFICIARY_FORM_SUCCESS,
          payload: response.data.id,
        });
        navigate();
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);

        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: BENEFICIARY_FORM_ACTIONS.SESSION_EXPIRED,
            });
          }, 500);
        } else {
          dispatch({
            type: BENEFICIARY_FORM_ACTIONS.BENEFICIARY_FORM_FAIL,
            payload: errorMessage.message,
          });
        }
      });
  };
};

export const getBeneficiaries = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { id } = state.user;
    const accessToken = localStorage.getItem("accessToken");
    requestManager
      .get(`bank-account/${id}/byUser?status=ACTIVE`, {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        const beneficiaries = response.data.map((beneficiary: any) => {
          return {
            ...beneficiary,
            value: beneficiary.id,
            label:
              beneficiary.account_holder +
              " " +
              beneficiary.bank.commercial_name,
          };
        });
        dispatch({
          type: BENEFICIARY_FORM_ACTIONS.FETCH_USER_BENEFICIARY,
          payload: beneficiaries,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);

        if (errorMessage.error === "UNAUTHORIZED") {
          setTimeout(() => {
            dispatch({
              type: BENEFICIARY_FORM_ACTIONS.SESSION_EXPIRED,
            });
          }, 500);
        } else {
          dispatch({
            type: BENEFICIARY_FORM_ACTIONS.BENEFICIARY_FORM_FAIL,
            payload: errorMessage.message,
          });
        }
      });
  };
};

export const setSelectedBeneficiary = (value: any) => {
  return {
    type: BENEFICIARY_FORM_ACTIONS.SET_SELECTED_BENEFICIARY,
    payload: {
      selectedBeneficiary: value === "clean" ? undefined : value,
      beneficiaryAccountId: value === "clean" ? "" : value.id,
    },
  };
};
