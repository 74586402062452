import React, { Component } from "react";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import { Link, match, Redirect } from "react-router-dom";
import {
  onEmailChange,
  onPasswordChange,
  onCheckRememberMeChange,
  onEmailBlur,
  onPasswordBlur,
  signIn,
  confirmRegister,
  cleanLoginState,
} from "../../../ducks/actions/LoginActions";
import { globalStateType } from "../../../ducks/reducers";
import Logo from "../../../assets/img/logo/logo.png";
import CheckCircle from "../../../assets/icons/check-circle-yellow.png";
import UnCheckCircle from "../../../assets/icons/unCheck-circle.png";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Loader from "../../common/Loader";
import "./Login.css";

interface DetailParams {
  id: string;
}

type Props = {
  email: string;
  emailValid?: boolean;
  emailError: string;
  onEmailChange: (text: string) => void;
  onEmailBlur: () => void;
  password: string;
  passwordValid?: boolean;
  passwordError: string;
  onPasswordChange: (text: string) => void;
  onPasswordBlur: () => void;
  checkRememberMe: string;
  onCheckRememberMeChange: (value: string) => void;
  error: string;
  signIn: () => void;
  match?: match<DetailParams>;
  confirmRegister: (text: string) => void;
  loginLanding: boolean;
  loginConfirm: boolean;
  loading: boolean;
  cleanLoginState: () => void;
  successfullyLogin: boolean;
};

type State = typeof initialState;

const initialState = {
  visiblePassword: false,
  visibleHelpPassword: false,
  passwordConfirm: false,
};

const args = {
  dataLayer: {
    event: "sign_up",
  },
  dataLayerName: "LoginDataLayer",
};

class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const {
      onCheckRememberMeChange,
      onEmailChange,
      confirmRegister,
      email,
      match,
    } = this.props;

    if (match) {
      if (match.path === "/login/forgot-password") {
        this.setState({ passwordConfirm: true });
      } else {
        confirmRegister(match.params.id);
        onEmailChange(email);
      }
    }
    const rememberMeStorage = localStorage.getItem("checkRememberMe");
    const emailStorage = localStorage.getItem("email");

    if (rememberMeStorage && emailStorage) {
      onCheckRememberMeChange(rememberMeStorage);
      onEmailChange(emailStorage);
    }
  }

  componentWillUnmount() {
    const { cleanLoginState } = this.props;
    cleanLoginState();
  }

  handleOnVisibleClick = (value: string) => () => {
    if (value === "password") {
      this.setState({ visiblePassword: !this.state.visiblePassword });
    }
  };

  handleOnVisibleFocus = () => {
    this.setState({ visibleHelpPassword: !this.state.visibleHelpPassword });
  };

  onCheckMarkChange = () => {
    const {
      onCheckRememberMeChange,
      checkRememberMe,
      email,
      emailValid,
    } = this.props;

    if (checkRememberMe === "true") {
      onCheckRememberMeChange("false");
      localStorage.setItem("checkRememberMe", "false");
      localStorage.setItem("email", "");
    } else {
      if (emailValid) {
        onCheckRememberMeChange("true");
        localStorage.setItem("checkRememberMe", "true");
        localStorage.setItem("email", email);
      }
    }
  };

  handleOnEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onEmailChange, onEmailBlur } = this.props;
    onEmailChange(event.target.value);
    onEmailBlur();
  };

  handleOnPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onPasswordChange, onPasswordBlur } = this.props;
    onPasswordChange(event.target.value);
    onPasswordBlur();
  };

  handleOnButtonClick = () => {
    TagManager.dataLayer(args);
    const { signIn } = this.props;
    signIn();
  };

  render() {
    const {
      email,
      emailValid,
      emailError,
      password,
      passwordValid,
      passwordError,
      checkRememberMe,
      onEmailBlur,
      onPasswordBlur,
      error,
      loginLanding,
      loginConfirm,
      loading,
      successfullyLogin,
    } = this.props;

    const { visiblePassword, passwordConfirm } = this.state;
    const redirect = successfullyLogin ? (
      <Redirect to="/home" />
    ) : (
      <React.Fragment />
    );
    return (
      <React.Fragment>
        {redirect}
        <div className="login-container">
          {loading ? <Loader /> : null}
          <div className="login-header">
            <a href="/">
              <img src={Logo} alt="celupago" className="login-logo" />
            </a>
            <h2 className="subTitle-login">Iniciar sesión </h2>
          </div>

          {loginLanding ? (
            passwordConfirm === true ? (
              <div className="title-shadow-container-login">
                <div className="title-container-login">
                  <span className="title-login">
                    ¡Cambio de contraseña exitoso!
                  </span>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )
          ) : (
            <div className="title-shadow-container-login">
              <div className="title-container-login">
                <span className="title-login">
                  {loginConfirm ? "¡Registro exitoso!" : "¡Registro fallido!"}
                </span>
              </div>
            </div>
          )}

          <div className="inputs-login-container">
            <Input
              styleName={
                emailValid !== undefined
                  ? emailValid
                    ? "common-input-valid"
                    : "common-input-invalid"
                  : ""
              }
              type="text"
              maxLength={40}
              minLength={6}
              label="Correo electrónico"
              onChangeValue={this.handleOnEmailChange}
              onBlur={onEmailBlur}
              value={email}
            />
            <div className="error-login-container">
              <p className="error-text-login">{emailError}</p>
            </div>

            <Input
              styleName={
                passwordValid !== undefined
                  ? passwordValid
                    ? "common-input-valid"
                    : "common-input-invalid"
                  : ""
              }
              type="password"
              maxLength={15}
              minLength={8}
              label="Contraseña"
              visible={visiblePassword}
              onChangeValue={this.handleOnPasswordChange}
              onBlur={onPasswordBlur}
              value={password}
              onClick={this.handleOnVisibleClick("password")}
            />
            <div className="error-login-container">
              <p className="error-text-login">{passwordError}</p>
            </div>
          </div>

          <div className="help-login-container">
            <Link to="/forgot-password" className="text-login text-login-hover">
              ¿Olvidaste tu contraseña?
            </Link>

            <div className="text-login ">
              <div className="login-check-container ">
                {checkRememberMe === "true" ? (
                  <img
                    src={CheckCircle}
                    alt="check"
                    className="login-check-circle"
                    onClick={this.onCheckMarkChange}
                  />
                ) : (
                  <img
                    src={UnCheckCircle}
                    alt="unCheck"
                    className="login-check-circle"
                    onClick={this.onCheckMarkChange}
                  />
                )}
              </div>
              <p className="text-login">Recuérdame</p>
            </div>
          </div>
          <div className="error-register">
            <p className="error-text-register">{error}</p>
          </div>
          <div className="button-login">
            <Button
              buttonText={"INGRESAR"}
              onClick={this.handleOnButtonClick}
              disabled={emailValid && passwordValid ? false : true}
            />
          </div>

          <div className="text-login">
            ¿Aún no tienes cuenta? Regístrate{" "}
            <a href="/register" className="text-blue-checkEmail">
              aquí
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    email: state.login.email,
    emailValid: state.login.emailValid,
    emailError: state.login.emailError,
    password: state.login.password,
    passwordValid: state.login.passwordValid,
    passwordError: state.login.passwordError,
    checkRememberMe: state.login.checkRememberMe,
    error: state.login.error,
    loginLanding: state.login.loginLanding,
    loginConfirm: state.login.loginConfirm,
    loading: state.login.loading,
    successfullyLogin: state.login.successfullyLogin,
  };
};

export default connect(mapStateTopProps, {
  onEmailChange,
  onPasswordChange,
  onCheckRememberMeChange,
  onEmailBlur,
  onPasswordBlur,
  signIn,
  confirmRegister,
  cleanLoginState,
})(Login);
