import React, { Component } from "react";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import CheckCircle from "../../../assets/icons/check-circle.png";
import UnCheckCircle from "../../../assets/icons/unCheck-circle.png";
import EditIcon from "../../../assets/icons/edit-blue.png";
import ExclamationCircle from "./../../../assets/icons/exclamation-circle.png";
import UserCircle from "../../../assets/icons/user-circle.png";
import Loader from "../../common/Loader";
import Input from "../../common/Input";
import CountryDropdown from "../../common/CountryDropdown";
import Dropdown from "../../common/Dropdown";
import AuthHeader from "../../common/auth-header/AuthHeader";
import Button from "../../common/Button";
import Modal from "../../common/Modal";
import { Country, DocType, GenderType } from "../../../utils/ConstHelper";
import { globalStateType } from "../../../ducks/reducers";
import {
  getUserProfile,
  cleanUserState,
  getCountryData,
  onPasswordChange,
  onPasswordBlur,
  onNewPasswordChange,
  onNewPasswordBlur,
  onPasswordConfirmChange,
  onPasswordConfirmBlur,
  updateUser,
  onCountryChange,
  onCallingCodeChange,
  onGenderTypeChange,
  onBirthdayChange,
  onNumberPhoneChange,
  onNumberPhoneBlur,
} from "../../../ducks/actions/UserActions";
import "./Profile.css";

const defaultProps = {};
const defaultState = {
  isHeaderMenuVisible: false,
  visiblePassword: false,
  visibleNewPassword: false,
  visiblePasswordConfirm: false,
  visibleHelpPassword: false,
  visibleEditPassword: false,
  logOut: false,
  editGender: false,
  editBirthday: false,
  editPassword: false,
  editNewPassword: false,
  editPasswordConfirm: false,
  editPhoneNumber: false,
  editCallingCode: false,
  editCountry: false,
};

type Props = {
  name: string;
  lastName: string;
  email: string;
  document: string;
  phoneNumber: string;
  phonePrefix: string;
  birthday: string;
  selectedBirthday: string;
  gender: string;
  country?: Country;
  selectedCountry?: Country;
  docType?: DocType;
  selectedCallingCode?: any;
  activeCountries: Country[];
  password: string;
  newPassword: string;
  passwordConfirmation: string;
  passwordValid?: boolean;
  newPasswordValid?: boolean;
  passwordConfirmationValid?: boolean;
  passwordError: string;
  newPasswordError: string;
  passwordConfirmationError: string;
  checkSpecialCharacter: boolean;
  checkAlphanumeric: boolean;
  checkLength: boolean;
  genderType: GenderType[];
  selectedGender?: GenderType;
  birthdayValid?: boolean;
  newBirthday: string;
  newBirthdayError: string;
  numberPhoneError: string;
  numberPhoneValid?: boolean;
  loading: boolean;
  showModal: boolean;
  error: string;
  editSuccess: boolean;
  getCountryData: () => void;
  getUserProfile: () => void;
  cleanUserState: () => void;
  onPasswordChange: (text: string) => void;
  onPasswordBlur: () => void;
  onNewPasswordChange: (text: string) => void;
  onNewPasswordBlur: () => void;
  onPasswordConfirmChange: (text: string) => void;
  onPasswordConfirmBlur: () => void;
  onCountryChange: (value: Country) => void;
  onCallingCodeChange: (value: any) => void;
  onGenderTypeChange: (value: any) => void;
  onBirthdayChange: (value: string) => void;
  onNumberPhoneChange: (value: string) => void;
  onNumberPhoneBlur: () => void;
  updateUser: () => void;
} & typeof defaultProps;

type State = typeof defaultState;

class Profile extends Component<Props, State> {
  state = defaultState;

  componentDidMount() {
    const { getUserProfile, getCountryData } = this.props;
    getUserProfile();
    getCountryData();
  }
  handleCallingCodeChange = (value: any) => {
    const { onCallingCodeChange } = this.props;
    this.setState({ editCallingCode: true });
    onCallingCodeChange(value);
  };

  handleOnNumberPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onNumberPhoneChange, onNumberPhoneBlur } = this.props;
    onNumberPhoneChange(event.target.value);
    onNumberPhoneBlur();
    this.setState({ editPhoneNumber: true });
  };

  handleCountryChange = (value: Country) => {
    const { onCountryChange } = this.props;
    onCountryChange(value);
    this.setState({ editCountry: true });
  };

  handleOnBirthdayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onBirthdayChange } = this.props;
    onBirthdayChange(event.target.value);
    this.setState({ editBirthday: true });
  };

  handleGenderTypeChange = (value: GenderType) => {
    const { onGenderTypeChange } = this.props;
    onGenderTypeChange(value);
    this.setState({ editGender: true });
  };

  handleOnNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onNewPasswordChange, onNewPasswordBlur } = this.props;
    onNewPasswordChange(event.target.value);
    onNewPasswordBlur();
    this.setState({ editNewPassword: true });
  };

  handleOnPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onPasswordChange, onPasswordBlur } = this.props;
    onPasswordChange(event.target.value);
    onPasswordBlur();
    this.setState({ editPassword: true });
  };

  handleOnPasswordConfirmChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onPasswordConfirmChange, onPasswordConfirmBlur } = this.props;
    onPasswordConfirmChange(event.target.value);
    onPasswordConfirmBlur();
    this.setState({ editPassword: true });
  };

  handleOnVisibleFocus = () => {
    this.setState({ visibleHelpPassword: !this.state.visibleHelpPassword });
  };

  toggleHeaderMenu = () => {
    const { isHeaderMenuVisible } = this.state;
    const visible = isHeaderMenuVisible;
    this.setState({ isHeaderMenuVisible: !visible });
  };

  hideHeaderMenu = () => {
    const { isHeaderMenuVisible } = this.state;
    if (isHeaderMenuVisible) {
      this.setState({ isHeaderMenuVisible: false });
    }
  };

  hideEditPassword = () => {
    const { visibleEditPassword } = this.state;
    const {
      onPasswordConfirmChange,
      onNewPasswordChange,
      onPasswordChange,
    } = this.props;

    if (visibleEditPassword) {
      onPasswordChange("");
      onNewPasswordChange("");
      onPasswordConfirmChange("");
      this.setState({
        editPassword: false,
        editNewPassword: false,
        editPasswordConfirm: false,
      });
    }

    const visible = visibleEditPassword;
    this.setState({ visibleEditPassword: !visible });
  };

  handleOnVisibleClick = (value: string) => () => {
    const {
      visiblePassword,
      visibleNewPassword,
      visiblePasswordConfirm,
    } = this.state;

    if (value === "password") {
      this.setState({ visiblePassword: !visiblePassword });
    } else if (value === "newPassword") {
      this.setState({ visibleNewPassword: !visibleNewPassword });
    } else {
      this.setState({
        visiblePasswordConfirm: !visiblePasswordConfirm,
      });
    }
  };

  handleOnButtonClick = () => {
    const { updateUser } = this.props;
    updateUser();
  };

  hideModal = () => {
    const { cleanUserState } = this.props;
    this.setState({ logOut: true });
    cleanUserState();
  };

  handleDisabledButton = () => {
    const {
      newPasswordValid,
      passwordValid,
      passwordConfirmationValid,
      birthdayValid,
      selectedGender,
      selectedCountry,
      country,
      numberPhoneValid,
      password,
      passwordConfirmation,
      newPassword,
    } = this.props;

    const {
      editGender,
      editBirthday,
      editPassword,
      editNewPassword,
      editPasswordConfirm,
      editPhoneNumber,
      editCallingCode,
      editCountry,
      visibleEditPassword,
    } = this.state;

    const validEdit =
      editGender ||
      editBirthday ||
      editPassword ||
      editNewPassword ||
      editPasswordConfirm ||
      editPhoneNumber ||
      editCallingCode ||
      editCountry;

    const validCountry = country?.iso !== selectedCountry?.iso;

    let validPasswords;

    if (visibleEditPassword) {
      validPasswords =
        passwordConfirmation &&
        password &&
        newPassword &&
        newPasswordValid &&
        passwordValid &&
        passwordConfirmationValid;
    }

    const validBirthday = birthdayValid === false;

    const validation =
      (validPasswords || validBirthday || selectedGender || validCountry) &&
      validEdit &&
      !birthdayValid !== false &&
      numberPhoneValid !== false &&
      (!visibleEditPassword
        ? !visibleEditPassword
        : passwordConfirmation &&
          password &&
          newPassword &&
          newPasswordValid &&
          passwordValid &&
          passwordConfirmationValid);

    return !validation;
  };

  render() {
    const {
      isHeaderMenuVisible,
      visibleEditPassword,
      visiblePassword,
      visibleNewPassword,
      visiblePasswordConfirm,
      logOut,
      editGender,
    } = this.state;
    const {
      name,
      lastName,
      email,
      docType,
      document,
      phoneNumber,
      numberPhoneError,
      numberPhoneValid,
      genderType,
      birthday,
      birthdayValid,
      newBirthdayError,
      selectedGender,
      activeCountries,
      selectedCountry,
      selectedCallingCode,
      password,
      newPassword,
      passwordConfirmation,
      passwordValid,
      newPasswordValid,
      passwordConfirmationValid,
      passwordError,
      newPasswordError,
      passwordConfirmationError,
      checkSpecialCharacter,
      checkAlphanumeric,
      checkLength,
      loading,
      showModal,
      error,
      editSuccess,
    } = this.props;

    const maxDate = moment().format().split("T")[0];
    const redirect = logOut ? <Redirect to="/" /> : <React.Fragment />;

    let callingCodeOptions = [
      {
        value: selectedCountry?.calling_code,
        label: selectedCountry?.calling_code,
      },
    ];
    return (
      <div className="profile-user-container">
        {redirect}
        {loading && <Loader />}
        <Modal
          show={showModal}
          title={"Sesión expirada"}
          img={ExclamationCircle}
          body={"Su sesión ha expirado"}
          handleClose={this.hideModal}
        />
        <Modal
          show={editSuccess}
          title={"Datos actualizados correctamente"}
          img={CheckCircle}
          body={""}
          handleClose={this.hideModal}
        />
        <AuthHeader
          isVisible={isHeaderMenuVisible}
          toggleHeaderMenu={this.toggleHeaderMenu}
        />
        <div className="profile-user-identification-container">
          <h2 className="subTitle-profile-user">Perfil</h2>
          <div className="profile-user-identification">
            <img
              src={UserCircle}
              alt="userCircle"
              className="profile-user-icon"
            />
            <div>
              <div className="profile-user-text-identification ">{name}</div>
            </div>
          </div>
        </div>
        <div className="inputs-container">
          <div>
            <Input
              styleName={"common-input-disabled common-input-valid"}
              type="text"
              maxLength={20}
              minLength={3}
              disable
              label="Nombres y apellidos"
              value={`${name} ${lastName}`}
              onChangeValue={() => {}}
            />
            <div className="error-profile-user-container">
              <p className="error-text-profile-user-container" />
            </div>
            <Input
              styleName={"common-input-disabled common-input-valid"}
              type="text"
              disable
              maxLength={40}
              minLength={6}
              label="Correo electrónico"
              value={email}
              onChangeValue={() => {}}
            />
            <div className="error-profile-user-container">
              <p className="error-text-profile-user-container" />
            </div>
            <div className="profile-user-dropdown-container">
              <Dropdown
                disabled={true}
                type={"calling"}
                valid={true}
                placeholder={"Tipo"}
                selectedOption={docType}
                onValueChange={this.handleCallingCodeChange}
              />
              <Input
                styleName={
                  "common-input-dropdown-calling common-input-disabled common-input-valid"
                }
                type="text"
                maxLength={15}
                minLength={8}
                label="Nº de documento"
                value={document}
                onChangeValue={() => {}}
              />
            </div>
            <div className="error-profile-user-container-aux">
              <p className="error-text-profile-user-container" />
            </div>
            <div className="profile-user-dropdown-container">
              <Dropdown
                type={"calling"}
                valid={selectedCallingCode ? true : false}
                placeholder={"Código"}
                options={callingCodeOptions}
                selectedOption={selectedCallingCode}
                onValueChange={this.handleCallingCodeChange}
              />
              <Input
                styleName={
                  numberPhoneValid !== undefined
                    ? numberPhoneValid
                      ? "common-input-dropdown-calling common-input-valid"
                      : "common-input-dropdown-calling common-input-invalid"
                    : "common-input-dropdown-calling"
                }
                type="text"
                maxLength={15}
                minLength={8}
                label="Nº de teléfono"
                value={phoneNumber}
                onChangeValue={this.handleOnNumberPhoneChange}
                onBlur={onNumberPhoneBlur}
              />
            </div>
            <div className="error-profile-user-container">
              <p className="error-text-profile-user-container">
                {numberPhoneError}
              </p>
            </div>
          </div>
          <div>
            <div
              className={
                birthday
                  ? "profile-date-picker-container profile-date-picker-valid"
                  : birthdayValid !== undefined
                  ? birthdayValid
                    ? "profile-date-picker-container profile-date-picker-invalid "
                    : "profile-date-picker-container profile-date-picker-valid"
                  : "profile-date-picker-container"
              }
            >
              <p className="profile-text-date-picker">Fecha de nacimiento</p>
              <TextField
                id="date"
                type="date"
                size="small"
                onChange={this.handleOnBirthdayChange}
                defaultValue={birthday}
                error={birthdayValid}
                className={"white-icon"}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    color: "#bababa",
                    fontSize: 14,
                    letterSpacing: 0.1,
                  },
                }}
                InputProps={{
                  inputProps: {
                    max: maxDate,
                    position: "start",
                    color: "white",
                  },
                  disableUnderline: true,
                  style: {
                    color: "#bababa",
                    width: 145,
                    height: 20,
                    marginRight: 5,
                    fontSize: 14,
                    marginTop: 3,
                  },
                }}
              />
            </div>
            <div className="error-profile-user-container">
              <p className="error-text-profile-user-container">
                {newBirthdayError}
              </p>
            </div>
            <div className={"profile-gender-container"}>
              <Dropdown
                large
                placeholder={"Genero"}
                options={genderType}
                selectedOption={selectedGender}
                valid={editGender}
                onValueChange={this.handleGenderTypeChange}
              />
            </div>

            <div className="error-profile-user-container">
              <p className="error-text-profile-user-container" />
            </div>
            <CountryDropdown
              type={selectedCountry ? "country-valid" : "country"}
              placeholder={"País"}
              options={activeCountries}
              selectedOption={selectedCountry}
              onCountryChange={this.handleCountryChange}
            />
            <div className="profile-password-container">
              <div className="profile-edit-container">
                <img
                  src={EditIcon}
                  alt="unCheck"
                  className="profile-user-edit"
                  onClick={this.hideEditPassword}
                />
              </div>
              <Input
                disable
                styleName={
                  "passwordValid" !== undefined
                    ? "passwordValid"
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={15}
                minLength={8}
                label="Contraseña"
              />
              <div className="error-profile-user-container">
                <p className="error-text-profile-user-container"></p>
              </div>
            </div>
            {visibleEditPassword && (
              <React.Fragment>
                <Input
                  styleName={
                    passwordValid !== undefined
                      ? passwordValid
                        ? "common-input-valid"
                        : "common-input-invalid"
                      : ""
                  }
                  type="password"
                  maxLength={15}
                  minLength={8}
                  label="Ingrese contraseña actual"
                  visible={visiblePassword}
                  onChangeValue={this.handleOnPasswordChange}
                  onBlur={onPasswordBlur}
                  value={password}
                  onClick={this.handleOnVisibleClick("password")}
                />
                <div className="error-profile-user-container">
                  <p className="error-text-profile-user-container">
                    {passwordError}
                  </p>
                </div>
                <Input
                  styleName={
                    newPasswordValid !== undefined
                      ? newPasswordValid
                        ? "common-input-valid"
                        : "common-input-invalid"
                      : ""
                  }
                  type="password"
                  maxLength={15}
                  minLength={8}
                  label="Nueva contraseña"
                  visible={visibleNewPassword}
                  onChangeValue={this.handleOnNewPasswordChange}
                  onBlur={() => {
                    this.handleOnVisibleFocus();
                    onNewPasswordBlur();
                    onPasswordConfirmBlur();
                  }}
                  value={newPassword}
                  onFocus={() => this.handleOnVisibleFocus()}
                  onClick={this.handleOnVisibleClick("newPassword")}
                />
                <div className="error-profile-user-container">
                  <p className="error-text-profile-user-container">
                    {newPasswordError}
                  </p>
                </div>
                {this.state.visibleHelpPassword && (
                  <div className="help-password-container">
                    <div className="help-password-block">
                      {checkAlphanumeric ? (
                        <img
                          src={CheckCircle}
                          alt="check"
                          className="profile-user-help-circle"
                        />
                      ) : (
                        <img
                          src={UnCheckCircle}
                          alt="unCheck"
                          className="profile-user-help-circle"
                        />
                      )}
                      <p className="help-password-text ">Alfanuméricos</p>
                    </div>

                    <div className="help-password-block">
                      {checkSpecialCharacter ? (
                        <img
                          src={CheckCircle}
                          alt="check"
                          className="profile-user-help-circle"
                        />
                      ) : (
                        <img
                          src={UnCheckCircle}
                          alt="unCheck"
                          className="profile-user-help-circle"
                        />
                      )}
                      <p className="help-password-text help-password-text-aux">
                        Carácter especial . , / * # -
                      </p>
                    </div>

                    <div className="help-password-block">
                      {checkLength ? (
                        <img
                          src={CheckCircle}
                          alt="check"
                          className="profile-user-help-circle"
                        />
                      ) : (
                        <img
                          src={UnCheckCircle}
                          alt="unCheck"
                          className="profile-user-help-circle"
                        />
                      )}
                      <p className="help-password-text">
                        Longitud: 8 caracteres
                      </p>
                    </div>
                  </div>
                )}
                <Input
                  styleName={
                    passwordConfirmationValid !== undefined
                      ? passwordConfirmationValid
                        ? "common-input-valid"
                        : "common-input-invalid"
                      : ""
                  }
                  type="password"
                  maxLength={15}
                  minLength={8}
                  label="Repetir contraseña"
                  visible={visiblePasswordConfirm}
                  onChangeValue={this.handleOnPasswordConfirmChange}
                  onBlur={() => {
                    onNewPasswordBlur();
                    onPasswordConfirmBlur();
                  }}
                  value={passwordConfirmation}
                  onClick={this.handleOnVisibleClick("visiblePasswordConfirm")}
                />
                <div className="error-profile-user-container">
                  <p className="error-text-profile-user-container">
                    {passwordConfirmationError}
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="error-profile-user">
          <p className="error-text-profile-user">{error}</p>
        </div>
        <div className="button-profile-user">
          <Button
            buttonText={"GUARDAR"}
            onClick={this.handleOnButtonClick}
            disabled={this.handleDisabledButton()}
          />
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    name: state.user.name,
    lastName: state.user.lastName,
    email: state.user.email,
    document: state.user.document,
    phoneNumber: state.user.phoneNumber,
    phonePrefix: state.user.phonePrefix,
    birthday: state.user.birthday,
    gender: state.user.gender,
    country: state.user.country,
    docType: state.user.docType,
    loading: state.user.loading,
    error: state.user.error,
    showModal: state.user.showModal,
    activeCountries: state.user.activeCountries,
    selectedCountry: state.user.selectedCountry,
    selectedCallingCode: state.user.selectedCallingCode,
    selectedBirthday: state.user.selectedBirthday,
    selectedGender: state.user.selectedGender,
    password: state.user.password,
    newPassword: state.user.newPassword,
    passwordConfirmation: state.user.passwordConfirmation,
    passwordValid: state.user.passwordValid,
    newPasswordValid: state.user.newPasswordValid,
    passwordConfirmationValid: state.user.passwordConfirmationValid,
    passwordError: state.user.passwordError,
    newPasswordError: state.user.newPasswordError,
    passwordConfirmationError: state.user.passwordConfirmationError,
    checkSpecialCharacter: state.user.checkSpecialCharacter,
    checkAlphanumeric: state.user.checkAlphanumeric,
    checkLength: state.user.checkLength,
    genderType: state.user.genderType,
    birthdayValid: state.user.birthdayValid,
    newBirthday: state.user.newBirthday,
    newBirthdayError: state.user.newBirthdayError,
    numberPhoneError: state.user.numberPhoneError,
    numberPhoneValid: state.user.numberPhoneValid,
    editSuccess: state.user.editSuccess,
  };
};
export default connect(mapStateTopProps, {
  getUserProfile,
  cleanUserState,
  getCountryData,
  onPasswordChange,
  onPasswordBlur,
  onNewPasswordChange,
  onNewPasswordBlur,
  onPasswordConfirmChange,
  onPasswordConfirmBlur,
  updateUser,
  onCountryChange,
  onCallingCodeChange,
  onGenderTypeChange,
  onBirthdayChange,
  onNumberPhoneChange,
  onNumberPhoneBlur,
})(Profile);
