import React, { Component } from "react";
import { connect } from "react-redux";
import SimulatorContainer from "../../common/simulator-box/SimulatorContainer";
import { cleanSimulatorInputs } from "../../../ducks/actions/SimulatorActions";
import { COMING_FROM } from "../../../utils/ConstHelper";
import "./RemittanceCalculation.css";
import { globalStateType } from "../../../ducks/reducers";

const defaultProps = {};
const defaultState = {};

type Props = {
  //props | state
  stepsNavigation?: () => void;
  //hideHeaderMenu: () => void;
  cleanSimulatorInputs: () => void;
} & typeof defaultProps;

type State = typeof defaultState;
class RemittanceCalculation extends Component<Props> {
  componentDidMount() {
    const { cleanSimulatorInputs } = this.props;
    cleanSimulatorInputs();
  }
  render() {
    return (
      <div
        className="calculation-container"
        //onClick={this.props.hideHeaderMenu}
      >
        <div className="calculation-text-container">
          <p className="calculation-text">Cálculo de remesa</p>
        </div>
        <div className="calculator-container">
          <SimulatorContainer
            comingFrom={COMING_FROM.private}
            stepsNavigation={this.props.stepsNavigation}
          />
        </div>
        {/* <div className="calculation-button-container">
          <Button buttonText={"CONTINUAR"} disabled={false} />
        </div> */}
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {};
};

export default connect(mapStateTopProps, {
  cleanSimulatorInputs,
})(RemittanceCalculation);
