import RequestManager from "../../services/RequestManager";
import {
  validateEmail,
  validateNames,
  validatePassword,
  validateNumber,
  validateAlphanumeric,
  validateCharacterSpecial,
  validateNumeric,
  validateBlanks,
} from "../../utils/Validators";
import {
  getErrorMessage,
  COUNTRY_OPTIONS,
  Country,
  DocType,
} from "../../utils/ConstHelper";

const base = "REGISTER_ACTIONS_";
const requestManager = new RequestManager();

export const REGISTER_ACTIONS = {
  //GET COUNTRY INFO

  FETCH_COUNTRY_DATA: base + "FETCH_COUNTRY_DATA",

  //ON CHANGES ACTIONS
  ON_EMAIL_CHANGE: base + "ON_EMAIL_CHANGE",
  ON_EMAIL_CONFIRM_CHANGE: base + "ON_EMAIL_CONFIRM_CHANGE",
  ON_PASSWORD_CHANGE: base + "ON_PASSWORD_CHANGE",
  ON_PASSWORD_CONFIRM_CHANGE: base + "ON_PASSWORD_CONFIRM_CHANGE",
  ON_NAME_CHANGE: base + "ON_NAME_CHANGE",
  ON_LAST_NAME_CHANGE: base + "ON_LAST_NAME_CHANGE",
  ON_NUMBER_DOCUMENT_CHANGE: base + "ON_NUMBER_DOCUMENT_CHANGE",
  ON_CALLING_CODE_CHANGE: base + "ON_CALLING_CODE_CHANGE",
  ON_NUMBER_PHONE_CHANGE: base + "ON_NUMBER_PHONE_CHANGE",
  ON_CHECK_TERMS_CHANGE: base + "ON_CHECK_TERMS_CHANGE",
  ON_COUNTRY_CHANGE: base + "ON_COUNTRY_CHANGE",
  ON_DOC_TYPE_CHANGE: base + "ON_DOC_TYPE_CHANGE",

  //ON BLUR ACTIONS
  ON_EMAIL_BLUR: base + "ON_EMAIL_BLUR",
  ON_EMAIL_CONFIRM_BLUR: base + "ON_EMAIL_CONFIRM_BLUR",
  ON_PASSWORD_BLUR: base + "ON_PASSWORD_BLUR",
  ON_PASSWORD_CONFIRM_BLUR: base + "ON_PASSWORD_CONFIRM_BLUR",
  ON_NAME_BLUR: base + "ON_NAME_BLUR",
  ON_LAST_NAME_BLUR: base + "ON_LAST_NAME_BLUR",
  ON_TYPE_DOCUMENT_BLUR: base + "ON_TYPE_DOCUMENT_BLUR",
  ON_NUMBER_DOCUMENT_BLUR: base + "ON_NUMBER_DOCUMENT_BLUR",
  ON_CALLING_CODE_BLUR: base + "ON_CALLING_CODE_BLUR",
  ON_NUMBER_PHONE_BLUR: base + "ON_NUMBER_PHONE_BLUR",
  ON_CHECK_TERMS_BLUR: base + "ON_CHECK_TERMS_BLUR",

  REGISTER_START: base + "REGISTER_START",
  REGISTER_FAIL: base + "REGISTER_FAIL",
  REGISTER_SUCCESS: base + "REGISTER_SUCCESS",

  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",
};

export const cleanRegisterState = () => {
  return {
    type: REGISTER_ACTIONS.CLEAN_STATE,
  };
};

//ON CHANGES ACTIONS

export const onEmailChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: REGISTER_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: REGISTER_ACTIONS.ON_EMAIL_CHANGE,
      payload: text,
    };
  }
};

export const onEmailConfirmChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: REGISTER_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: REGISTER_ACTIONS.ON_EMAIL_CONFIRM_CHANGE,
      payload: text,
    };
  }
};

export const onPasswordChange = (text: string) => {
  const validAlphanumeric = validateAlphanumeric(text);

  const validNumeric = validateNumeric(text);

  const validCharacterSpecial = validateCharacterSpecial(text);

  let validateLength = false;

  if (text.length >= 8 && text.length <= 15) {
    validateLength = true;
  }

  if (validateBlanks(text)) {
    return {
      type: REGISTER_ACTIONS.DO_NOTHING,
    };
  } else {
    if (validatePassword(text)) {
      return {
        type: REGISTER_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: true,
          checkAlphanumeric: true,
          checkSpecialCharacter: true,
          checkLength: true,
        },
      };
    } else {
      return {
        type: REGISTER_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: false,
          checkAlphanumeric: validAlphanumeric && validNumeric,
          checkSpecialCharacter: validCharacterSpecial,
          checkLength: validateLength,
        },
      };
    }
  }
};

export const onPasswordConfirmChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: REGISTER_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: REGISTER_ACTIONS.ON_PASSWORD_CONFIRM_CHANGE,
      payload: text,
    };
  }
};

export const onNameChange = (text: string) => {
  if (validateNames(text)) {
    if (text.charAt(0) === " ") {
      return {
        type: REGISTER_ACTIONS.DO_NOTHING,
      };
    } else {
      const textSplit = text.split("  ").toString();
      const textReplace = textSplit.replace(",", " ");
      return {
        type: REGISTER_ACTIONS.ON_NAME_CHANGE,
        payload: textReplace,
      };
    }
  } else {
    return {
      type: REGISTER_ACTIONS.DO_NOTHING,
    };
  }
};

export const onLastNameChange = (text: string) => {
  if (validateNames(text)) {
    if (text.charAt(0) === " ") {
      return {
        type: REGISTER_ACTIONS.DO_NOTHING,
      };
    } else {
      const textSplit = text.split("  ").toString();
      const textReplace = textSplit.replace(",", " ");
      return {
        type: REGISTER_ACTIONS.ON_LAST_NAME_CHANGE,
        payload: textReplace,
      };
    }
  } else {
    return {
      type: REGISTER_ACTIONS.DO_NOTHING,
    };
  }
};

export const onNumberDocumentChange = (text: string) => {
  if (validateNumber(text)) {
    return {
      type: REGISTER_ACTIONS.ON_NUMBER_DOCUMENT_CHANGE,
      payload: text,
    };
  } else {
    if (text === "") {
      return {
        type: REGISTER_ACTIONS.ON_NUMBER_DOCUMENT_CHANGE,
        payload: text,
      };
    } else {
      return {
        type: REGISTER_ACTIONS.DO_NOTHING,
      };
    }
  }
};

export const onNumberPhoneChange = (text: string) => {
  if (validateNumber(text)) {
    return {
      type: REGISTER_ACTIONS.ON_NUMBER_PHONE_CHANGE,
      payload: text,
    };
  } else {
    if (text === "") {
      return {
        type: REGISTER_ACTIONS.ON_NUMBER_PHONE_CHANGE,
        payload: text,
      };
    } else {
      return {
        type: REGISTER_ACTIONS.DO_NOTHING,
      };
    }
  }
};

export const onCheckTermsChange = (value: boolean) => {
  return {
    type: REGISTER_ACTIONS.ON_CHECK_TERMS_CHANGE,
    payload: value,
  };
};

//ON BLUR ACTIONS

export const onEmailBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { email } = state.register;

    if (email !== "") {
      if (validateEmail(email)) {
        dispatch({
          type: REGISTER_ACTIONS.ON_EMAIL_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: REGISTER_ACTIONS.ON_EMAIL_BLUR,
          payload: {
            valid: false,
            errorMessage: "La dirección de correo no es válida",
          },
        });
      }
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_EMAIL_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onEmailConfirmBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { emailConfirmation, email } = state.register;

    if (emailConfirmation !== "") {
      if (emailConfirmation === email) {
        dispatch({
          type: REGISTER_ACTIONS.ON_EMAIL_CONFIRM_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: REGISTER_ACTIONS.ON_EMAIL_CONFIRM_BLUR,
          payload: {
            valid: false,
            errorMessage: "La confirmación del correo no coincide",
          },
        });
      }
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_EMAIL_CONFIRM_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onPasswordBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { password } = state.register;
    if (password !== "") {
      if (validatePassword(password)) {
        dispatch({
          type: REGISTER_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: REGISTER_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: false, errorMessage: "Contraseña inválida" },
        });
      }
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_PASSWORD_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onPasswordConfirmBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { passwordConfirmation, password } = state.register;

    if (passwordConfirmation !== "") {
      if (passwordConfirmation === password) {
        dispatch({
          type: REGISTER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: REGISTER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
          payload: {
            valid: false,
            errorMessage: "La confirmación de contraseña no coincide",
          },
        });
      }
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onNameBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { name } = state.register;

    if (name !== "") {
      if (name.length >= 3) {
        dispatch({
          type: REGISTER_ACTIONS.ON_NAME_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: REGISTER_ACTIONS.ON_NAME_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener mínimo 3 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_NAME_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onLastNameBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { lastName } = state.register;

    if (lastName !== "") {
      if (lastName.length >= 3) {
        dispatch({
          type: REGISTER_ACTIONS.ON_LAST_NAME_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: REGISTER_ACTIONS.ON_LAST_NAME_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener mínimo 3 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_LAST_NAME_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onTypeDocumentBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { typeDocument } = state.register;

    if (typeDocument !== "") {
      dispatch({
        type: REGISTER_ACTIONS.ON_TYPE_DOCUMENT_BLUR,
        payload: { valid: true, errorMessage: "" },
      });
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_TYPE_DOCUMENT_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onNumberDocumentBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { numberDocument } = state.register;

    if (numberDocument !== "") {
      if (numberDocument.length >= 7) {
        dispatch({
          type: REGISTER_ACTIONS.ON_NUMBER_DOCUMENT_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: REGISTER_ACTIONS.ON_NUMBER_DOCUMENT_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener mínimo 7 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_NUMBER_DOCUMENT_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onNumberPhoneBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { numberPhone } = state.register;

    if (numberPhone !== "") {
      if (numberPhone.length >= 8) {
        dispatch({
          type: REGISTER_ACTIONS.ON_NUMBER_PHONE_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: REGISTER_ACTIONS.ON_NUMBER_PHONE_BLUR,
          payload: {
            valid: false,
            errorMessage: "El campo debe contener mínimo 8 caracteres",
          },
        });
      }
    } else {
      dispatch({
        type: REGISTER_ACTIONS.ON_NUMBER_PHONE_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const getCountryData = () => {
  return (dispatch: any, getState: any) => {
    requestManager
      .get("/country?status=ACTIVE", {})
      .then((response: any) => {
        const activeCountries = response.data.map((country: any) => {
          const flag = COUNTRY_OPTIONS.filter(
            (option) => country.iso === option.iso
          );
          const docTypes = country.docTypes
            .filter((document: any) => document.doc_type_status === "ACTIVE")
            .map((doc: any) => {
              return { ...doc, value: doc.id, label: doc.code };
            });
          return {
            ...country,
            flag: flag[0].flag,
            value: country.currency,
            label: country.currency,
            docTypes,
          };
        });
        const availableCountries = activeCountries.filter(
          (country: any) => country.iso !== "VE"
        );
        dispatch({
          type: REGISTER_ACTIONS.FETCH_COUNTRY_DATA,
          payload: availableCountries,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: REGISTER_ACTIONS.FETCH_COUNTRY_DATA,
          payload: [],
        });
      });
  };
};

export const signUp = () => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: REGISTER_ACTIONS.REGISTER_START });
    const state = getState();
    const {
      email,
      password,
      name,
      lastName,
      numberDocument,
      numberPhone,
      selectedCountry,
      selectedDocType,
      selectedCallingCode,
    } = state.register;

    const data = {
      name,
      lastName,
      email,
      password,
      document: numberDocument,
      phoneNumber: numberPhone,
      phonePrefix: selectedCallingCode.label,
      country: selectedCountry.id,
      docType: selectedDocType.id,
    };

    requestManager
      .post("/users/signUp", data, {})
      .then((response: any) => {
        localStorage.setItem("checkEmail", email);
        window.open("/check-email");
        dispatch({
          type: REGISTER_ACTIONS.REGISTER_SUCCESS,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({
          type: REGISTER_ACTIONS.REGISTER_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      });
  };
};

export const onCountryChange = (value: Country) => {
  return {
    type: REGISTER_ACTIONS.ON_COUNTRY_CHANGE,
    payload: value,
  };
};

export const onCallingCodeChange = (value: any) => {
  return {
    type: REGISTER_ACTIONS.ON_CALLING_CODE_CHANGE,
    payload: value,
  };
};

export const onDocTypeChange = (value: DocType) => {
  return {
    type: REGISTER_ACTIONS.ON_DOC_TYPE_CHANGE,
    payload: value,
  };
};

export const resendEmail = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { email } = state.register;

    const data = {
      email,
    };
    requestManager
      .post("/users/resendemail", data, {})
      .then((response: any) => {
        dispatch({ type: REGISTER_ACTIONS.DO_NOTHING });
        window.close();
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({
          type: REGISTER_ACTIONS.REGISTER_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      });
  };
};
