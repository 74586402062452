import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "../../../common/Loader";
import { getCashierTransactions } from "../../../../ducks/actions/cashier/CashierActions";
import { globalStateType } from "../../../../ducks/reducers";
import TransactionTable from "./TransactionsTable";
import {
  CashierTransactions,
  CashierFilter,
} from "../../../../utils/ConstHelper";
import StatusFilter from "./StatusFilter";
import DateFilter from "./DateFilter";
import Search from "../../../../assets/icons/search-regular.png";
import NoTransactionComponent from "../../../common/NoTransaction";
import "./Operations.css";

const defaultProps = {};
const defaultState = {
  statusFilter: {} as CashierFilter,
  fromDate: "",
  toDate: "",
};

type Props = {
  //props | state
  getCashierTransactions: (status?: string, from?: string, to?: string) => void;
  loading: boolean;
  //history: any;
  transactions: CashierTransactions[];
  handleVisibleEdit: (transaction: CashierTransactions) => void;
} & typeof defaultProps;

type State = typeof defaultState;
class Operations extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = defaultState;
  }
  componentDidMount() {
    const { getCashierTransactions } = this.props;
    getCashierTransactions();
  }

  handleFromDateChange = (date: any) => {
    const { toDate } = this.state;
    if (toDate) {
      this.setState({ fromDate: date <= toDate ? date : "" });
    } else {
      this.setState({ fromDate: date });
    }
  };

  handleToDateChange = (date: any) => {
    const { fromDate } = this.state;
    if (fromDate) {
      this.setState({ toDate: date >= fromDate ? date : "" });
    } else {
      this.setState({ toDate: date });
    }
  };

  handleSetFilterStatus = (value: CashierFilter) => {
    this.setState({ statusFilter: value });
  };

  clearFilterStatus = () => {
    this.setState({ statusFilter: {} as CashierFilter });
  };

  clearFilterFromDate = () => {
    this.setState({ fromDate: "" });
  };

  clearFilterToDate = () => {
    this.setState({ toDate: "" });
  };

  filteredSearch = () => {
    const { getCashierTransactions } = this.props;
    const { statusFilter, fromDate, toDate } = this.state;
    if (statusFilter.value || fromDate || toDate) {
      getCashierTransactions(statusFilter.value, fromDate, toDate);
    } else {
      getCashierTransactions();
    }
  };

  onClickRefresh = () => {
    const { getCashierTransactions } = this.props;
    getCashierTransactions();
  };

  render() {
    const { transactions, loading, handleVisibleEdit } = this.props;
    const { statusFilter, fromDate, toDate } = this.state;
    return (
      <div className="operations-container">
        {loading && <Loader />}
        <div className="operations-title-container">
          <span className="operations-title">Operaciones</span>
        </div>
        <div className="cashier-transactions-filter-container">
          <StatusFilter
            value={statusFilter.label}
            handleFilterChange={this.handleSetFilterStatus}
            clearFilter={this.clearFilterStatus}
          />
          <DateFilter
            value={fromDate}
            label="Desde"
            handleDateChange={this.handleFromDateChange}
            clearFilter={this.clearFilterFromDate}
          />
          <DateFilter
            value={toDate}
            label="Hasta"
            handleDateChange={this.handleToDateChange}
            clearFilter={this.clearFilterToDate}
          />
          <div>
            <img
              src={Search}
              alt="search"
              className="search-filter-button"
              onClick={this.filteredSearch}
            />
          </div>
        </div>
        <div className="cashier-table-container">
          {transactions.length > 0 ? (
            <TransactionTable
              transactions={transactions}
              editShow={handleVisibleEdit}
              refresh={this.onClickRefresh}
            />
          ) : (
            <NoTransactionComponent cashier={true} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.cashier.loading,
    transactions: state.cashier.transactions,
  };
};

export default connect(mapStateTopProps, {
  getCashierTransactions,
})(Operations);
