import React, { Component, ReactElement } from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import ReactGA from "react-ga";
import reducers from "./ducks/reducers";
import AppRouter from "./router";

const defaultProps = {};
type Props = {} & typeof defaultProps;

class App extends Component {
  initializeAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS || "");
  };
  render(): ReactElement<Props> {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      this.initializeAnalytics();
    }
    const store = createStore(
      reducers,
      {},
      composeWithDevTools(applyMiddleware(ReduxThunk))
    );
    return (
      <Provider store={store}>
        <AppRouter />
      </Provider>
    );
  }
}

export default App;
