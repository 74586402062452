import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Eye from "../../../../assets/icons/eye-regular-black.png";
import Edit from "../../../../assets/icons/edit-purple.png";
import Refresh from "../../../../assets/icons/refresh.png";
import EditDisabled from "../../../../assets/icons/edit-disabled.png";
import {
  CashierTransactions,
  NON_ALLOWED_TO_EDIT_CASHIER_TRANSACTION_STATUS,
} from "../../../../utils/ConstHelper";
import TransactionCard from "./TransactionDetailCard";
import "./Operations.css";

type Props = {
  transactions: CashierTransactions[];
  editShow: (transaction: CashierTransactions) => void;
  refresh?: () => void;
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    marginTop: 20,
    backgroundColor: "#b2aeae",
    color: "#000000",
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
  },
  body: {
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    width: "100%",
  },
});

const TransactionTable = ({ transactions, editShow, refresh }: Props) => {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const [currentTransaction, setCurrentTransaction] = useState(
    undefined as undefined | CashierTransactions
  );
  const classes = useStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (transaction: CashierTransactions) => {
    setCurrentTransaction(transaction);
    setOpen(true);
  };

  const handleClickEdit = (transaction: CashierTransactions) => {
    editShow(transaction);
  };

  const getEditable = (statusCode: string) => {
    return !NON_ALLOWED_TO_EDIT_CASHIER_TRANSACTION_STATUS.hasOwnProperty(
      statusCode
    );
  };

  return (
    <React.Fragment>
      <TransactionCard
        open={open}
        handleClose={handleClose}
        transaction={currentTransaction}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                Nº de Transacción
              </StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Monto orígen</StyledTableCell>
              <StyledTableCell align="center">Monto destino</StyledTableCell>
              <StyledTableCell align="center">Estatus</StyledTableCell>
              {refresh ? (
                <StyledTableCell align="center">
                  <div onClick={refresh}>
                    <img
                      src={Refresh}
                      alt="refresh"
                      className="refresh-icon-style"
                    />
                  </div>
                </StyledTableCell>
              ) : (
                <StyledTableCell align="center"> </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.slice(page * 5, page * 5 + 5).map((transaction) => (
              <StyledTableRow key={transaction.key}>
                <StyledTableCell align="center">
                  {transaction.celupagoReference}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(transaction.emissionDate).format("DD/MM/YYYY")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {transaction.originAmount} {transaction.originCurrency}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {transaction.destinationAmount}{" "}
                  {transaction.destinationCurrency}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {transaction.status}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <img
                    src={Eye}
                    alt="eye"
                    className="details-icon-style"
                    onClick={() => {
                      handleClickOpen(transaction);
                    }}
                  />

                  <img
                    src={
                      getEditable(transaction.statusCode) ? Edit : EditDisabled
                    }
                    alt="edit"
                    className={
                      getEditable(transaction.statusCode)
                        ? "edit-blue-icon-style"
                        : "edit-blue-icon-style edit-icon-disabled"
                    }
                    onClick={() => {
                      getEditable(transaction.statusCode) &&
                        handleClickEdit(transaction);
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={transactions.length}
          rowsPerPage={5}
          page={page}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : "más de" + to}`
          }
          onChangePage={handleChangePage}
        />
      </TableContainer>
    </React.Fragment>
  );
};

export default TransactionTable;
