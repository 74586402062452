import { validateEmail, validateBlanks } from "../../utils/Validators";
import RequestManager from "../../services/RequestManager";
import { getErrorMessage } from "../../utils/ConstHelper";

const base = "FORGOT_PASSWORD_ACTIONS_";
const requestManager = new RequestManager();

export const FORGOT_PASSWORD_ACTIONS = {
  //ON CHANGES ACTIONS
  ON_EMAIL_CHANGE: base + "ON_EMAIL_CHANGE",

  //ON BLUR ACTIONS
  ON_EMAIL_BLUR: base + "ON_EMAIL_BLUR",

  FORGOT_PASSWORD_START: base + "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_FAIL: base + "FORGOT_PASSWORD_FAIL",
  FORGOT_PASSWORD_SUCCESS: base + "FORGOT_PASSWORD_SUCCESS",

  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",
};

export const cleanForgotPasswordState = () => {
  return {
    type: FORGOT_PASSWORD_ACTIONS.CLEAN_STATE,
  };
};

export const onEmailChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: FORGOT_PASSWORD_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: FORGOT_PASSWORD_ACTIONS.ON_EMAIL_CHANGE,
      payload: {
        email: text,
        valid: true,
      },
    };
  }
};

export const onEmailBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { email } = state.forgotPassword;

    if (email !== "") {
      if (validateEmail(email)) {
        dispatch({
          type: FORGOT_PASSWORD_ACTIONS.ON_EMAIL_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: FORGOT_PASSWORD_ACTIONS.ON_EMAIL_BLUR,
          payload: {
            valid: false,
            errorMessage: "La dirección de correo no es válida",
          },
        });
      }
    } else {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.ON_EMAIL_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const forgotPassword = () => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_START });

    const state = getState();
    const { email } = state.forgotPassword;

    const data = {
      email,
    };

    requestManager
      .post("/users/forgotpassword", data)
      .then((response: any) => {
        dispatch({
          type: FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_SUCCESS,
          payload: { errorMessage: "" },
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({
          type: FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      });
  };
};
