import React, { useState } from "react";
import RadioOn from "../../../../assets/icons/radio-button-on.png";
import RadioOff from "../../../../assets/icons/radio-button.png";
import "./FilterOptions.css";

type Props = {
  handleUserFinishedTransactions: () => void;
  handleUserRejectedTransactions: () => void;
  handleUserInProcessTransactions: () => void;
  handleUserTransactions: () => void;
};

const FilterOptions = ({
  handleUserFinishedTransactions,
  handleUserRejectedTransactions,
  handleUserInProcessTransactions,
  handleUserTransactions,
}: Props) => {
  const [currentOption, setOption] = useState(1);
  return (
    <div className="transaction-filter-option-main-container">
      <div className="title-row">
        <p className="transaction-filter-title">Filtrar:</p>
      </div>
      <div className="filter-row">
        <div className="transaction-filter-option-row-container">
          <div
            className="option-container"
            onClick={() => {
              setOption(1);
              handleUserTransactions();
            }}
          >
            <img
              src={currentOption === 1 ? RadioOn : RadioOff}
              alt="radio"
              className="radio-button-option-style"
            />
            <p className="filter-option-label-style">Todas</p>
          </div>
          <div
            className="option-container"
            onClick={() => {
              setOption(2);
              handleUserInProcessTransactions();
            }}
          >
            <img
              src={currentOption === 2 ? RadioOn : RadioOff}
              alt="radio"
              className="radio-button-option-style"
            />
            <p className="filter-option-label-style">En proceso</p>
          </div>
        </div>
        <div className="transaction-filter-option-row-container">
          <div
            className="option-container"
            onClick={() => {
              setOption(3);
              handleUserFinishedTransactions();
            }}
          >
            <img
              src={currentOption === 3 ? RadioOn : RadioOff}
              alt="radio"
              className="radio-button-option-style"
            />
            <p className="filter-option-label-style">Completadas</p>
          </div>
          <div
            className="option-container"
            onClick={() => {
              setOption(4);
              handleUserRejectedTransactions();
            }}
          >
            <img
              src={currentOption === 4 ? RadioOn : RadioOff}
              alt="radio"
              className="radio-button-option-style"
            />
            <p className="filter-option-label-style">Rechazadas</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
