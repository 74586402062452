import React, { Component } from "react";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import Button from "../../common/Button";
import CheckCircle from "../../../assets/icons/check-circle.png";
import UnCheckCircle from "../../../assets/icons/unCheck-circle.png";
import EditIcon from "../../../assets/icons/edit.png";
import EyeIcon from "../../../assets/icons/eye.png";
import EyeSlashIcon from "../../../assets/icons/eye-slash-gray.png";
import UserCircle from "../../../assets/icons/user-circle.png";
import { globalStateType } from "../../../ducks/reducers";
import {
  onPasswordChange,
  onPasswordBlur,
  onNewPasswordChange,
  onNewPasswordBlur,
  onPasswordConfirmChange,
  onPasswordConfirmBlur,
  changePasswordCashier,
  onNewRateChange,
  onNewRateBlur,
  onBirthdayChange,
  updateBirthdayCashier,
  updateRateCashier,
  cleanCashierState,
} from "../../../ducks/actions/cashier/CashierActions";
import ExclamationCircle from "./../../../assets/icons/exclamation-circle.png";
import Loader from "../../common/Loader";
import Modal from "../../common/Modal";
import "./ProfileCashier.css";
import moment from "moment";

const defaultProps = {};
const defaultState = {
  visibleEdit: false,
  visiblePassword: false,
  typeTextField: "password",
  visibleHelpPassword: false,
  logOut: false,
};
type Props = {
  name: string;
  cashierType: string;
  celupagos_id: string;
  document: string;
  email: string;
  lastName: string;
  phoneNumber: string;
  phonePrefix: string;
  rate: string;
  docType: {};
  loading: boolean;
  password: string;
  newPassword: string;
  passwordConfirmation: string;
  passwordValid: boolean;
  newPasswordValid: boolean;
  passwordConfirmationValid: boolean;
  newRate: string;
  newRateValid: boolean;
  newRateError: string;
  birthday: string;
  newBirthday: string;
  birthdayValid: boolean;
  onPasswordChange: (text: string) => void;
  onPasswordBlur: () => void;
  onNewPasswordChange: (text: string) => void;
  onNewPasswordBlur: () => void;
  onPasswordConfirmChange: (text: string) => void;
  onPasswordConfirmBlur: () => void;
  onNewRateChange: (text: string) => void;
  onNewRateBlur: () => void;
  checkSpecialCharacter: boolean;
  checkAlphanumeric: boolean;
  checkLength: boolean;
  changePasswordCashier: () => void;
  updateRateCashier: () => void;
  updateBirthdayCashier: () => void;
  onBirthdayChange: (text: string) => void;
  showModal: boolean;
  cleanCashierState: () => void;
  editSuccess: boolean;
  passwordError: string;
  newPasswordError: string;
  passwordConfirmationError: string;
  newBirthdayError: string;
} & typeof defaultProps;
type State = typeof defaultState;

class ProfileCashier extends Component<Props, State> {
  state = defaultState;

  handleOnButtonClick = () => {
    const { visibleEdit } = this.state;
    const {
      password,
      newPassword,
      passwordConfirmation,
      passwordValid,
      newPasswordValid,
      passwordConfirmationValid,
      changePasswordCashier,
      rate,
      newRate,
      newRateValid,
      updateRateCashier,
      newBirthday,
      birthdayValid,
      updateBirthdayCashier,
    } = this.props;

    if (visibleEdit) {
      if (password && newPassword && passwordConfirmation) {
        if (!passwordValid && !newPasswordValid && !passwordConfirmationValid) {
          changePasswordCashier();
        }
      }
    }

    if (newBirthday && !birthdayValid) {
      updateBirthdayCashier();
    }

    if (rate !== newRate && !newRateValid && newRate) {
      updateRateCashier();
    }
  };

  handleVisibleEdit = () => {
    const { visibleEdit } = this.state;
    const visible = visibleEdit;
    this.setState({ visibleEdit: !visible });
  };

  handleVisiblePassword = () => {
    const { visiblePassword } = this.state;
    const visible = visiblePassword;
    let type = "";
    if (!visible) {
      type = "text";
    } else {
      type = "password";
    }
    this.setState({ visiblePassword: !visible, typeTextField: type });
  };

  handleOnVisibleFocus = () => {
    this.setState({ visibleHelpPassword: !this.state.visibleHelpPassword });
  };

  handleOnNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onNewPasswordChange, onNewPasswordBlur } = this.props;
    onNewPasswordChange(event.target.value);
    onNewPasswordBlur();
  };

  handleOnPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onPasswordChange, onPasswordBlur } = this.props;
    onPasswordChange(event.target.value);
    onPasswordBlur();
  };

  handleOnPasswordConfirmChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onPasswordConfirmChange, onPasswordConfirmBlur } = this.props;
    onPasswordConfirmChange(event.target.value);
    onPasswordConfirmBlur();
  };

  handleOnNewRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onNewRateChange, onNewRateBlur } = this.props;
    onNewRateChange(event.target.value);
    onNewRateBlur();
  };

  handleOnBirthdayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onBirthdayChange } = this.props;
    onBirthdayChange(event.target.value);
  };

  hideModal = () => {
    const { cleanCashierState } = this.props;
    this.setState({ logOut: true });
    cleanCashierState();
  };

  render() {
    const {
      visibleEdit,
      visiblePassword,
      typeTextField,
      visibleHelpPassword,
      logOut,
    } = this.state;
    const {
      name,
      lastName,
      cashierType,
      celupagos_id,
      email,
      rate,
      newRate,
      passwordValid,
      newPasswordValid,
      passwordConfirmationValid,
      checkSpecialCharacter,
      checkAlphanumeric,
      checkLength,
      onNewPasswordBlur,
      onPasswordBlur,
      newRateValid,
      newRateError,
      loading,
      showModal,
      birthday,
      birthdayValid,
      newBirthday,
      editSuccess,
      password,
      newPassword,
      passwordConfirmation,
      passwordError,
      newPasswordError,
      passwordConfirmationError,
      newBirthdayError,
    } = this.props;

    const redirect = logOut ? <Redirect to="/" /> : <React.Fragment />;
    const maxDate = moment().format().split("T")[0];
    return (
      <div className="profile-cashier-container">
        {loading ? <Loader /> : null}
        {redirect}
        <Modal
          show={showModal}
          title={"Sesión expirada"}
          img={ExclamationCircle}
          body={"Su sesión ha expirado"}
          handleClose={this.hideModal}
        />
        <Modal
          show={editSuccess}
          title={"Datos actualizados correctamente"}
          img={CheckCircle}
          body={""}
          handleClose={this.hideModal}
        />
        <div className="profile-cashier-title-container">
          <img
            src={UserCircle}
            alt="userCircle"
            className="sidebar-user-icon"
          />
          <div>
            <div className="profile-cashier-title">{name}</div>
            <div className="profile-cashier-subtitle profile-cashier-subtitle-light">
              Editar mi perfil
            </div>
          </div>
        </div>
        <div className="profile-cashier-data-container ">
          <div className="profile-cashier-data">
            <div>
              <span className="profile-cashier-subtitle">
                Nombre y Apellido
              </span>
            </div>
            <div>
              <span className="profile-cashier-subtitle">
                {name} {lastName}
              </span>
            </div>
          </div>
          <div className="profile-cashier-data">
            <div>
              <span className="profile-cashier-subtitle">
                Mi correo electrónico
              </span>
            </div>
            <div>
              <span className="profile-cashier-subtitle">{email}</span>
            </div>
          </div>
          <div className="profile-cashier-data">
            <div>
              <span className="profile-cashier-subtitle">Mi ID</span>
            </div>
            <div>
              <span className="profile-cashier-subtitle">{celupagos_id}</span>
            </div>
          </div>
          <div className="profile-cashier-data">
            <div>
              <span className="profile-cashier-subtitle">
                Fecha de nacimiento
              </span>
            </div>
            <div>
              <TextField
                id="date"
                type="date"
                className="date-picker"
                onChange={this.handleOnBirthdayChange}
                defaultValue={birthday}
                InputLabelProps={{
                  shrink: true,
                }}
                error={birthdayValid}
                InputProps={{
                  inputProps: { max: maxDate },
                }}
              />
              <div className="error-profile-container">
                <p className="error-text-register-container">
                  {newBirthdayError}
                </p>
              </div>
            </div>
          </div>
          {cashierType === "MASTER" ? (
            <div className="profile-cashier-data">
              <div>
                <span className="profile-cashier-subtitle">Tasa de cambio</span>
              </div>
              <div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="textField"
                  size="small"
                  defaultValue={rate}
                  error={newRateValid}
                  onChange={this.handleOnNewRateChange}
                  inputProps={{ maxLength: 13, minLength: 3 }}
                />
                <div className="error-profile-container">
                  <p className="error-text-register-container">
                    {newRateError}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}
          <div className="profile-cashier-data">
            <div>
              <span className="profile-cashier-subtitle">Contraseña</span>
            </div>
            <div>
              <TextField
                id="password-basic"
                variant="outlined"
                className="textField"
                size="small"
                type="password"
                value={12345678}
              />
              <img
                src={EditIcon}
                alt="editIcon"
                className="editIcon"
                onClick={this.handleVisibleEdit}
              />
            </div>
          </div>
          {visibleEdit ? (
            <React.Fragment>
              <div className="profile-cashier-data">
                <div>
                  <span className="profile-cashier-subtitle">
                    Ingrese contraseña actual
                  </span>
                </div>
                <div>
                  <TextField
                    id="password"
                    variant="outlined"
                    className="textField"
                    type={typeTextField}
                    size="small"
                    error={passwordValid}
                    onChange={this.handleOnPasswordChange}
                    inputProps={{ maxLength: 8, minLength: 8 }}
                    onBlur={() => {
                      onPasswordBlur();
                    }}
                  />
                  <div className="error-profile-container">
                    <p className="error-text-register-container">
                      {passwordError}
                    </p>
                  </div>
                  {visiblePassword ? (
                    <img
                      src={EyeSlashIcon}
                      alt="editIcon"
                      className="editIcon"
                      onClick={this.handleVisiblePassword}
                    />
                  ) : (
                    <img
                      src={EyeIcon}
                      alt="editIcon"
                      className="editIcon"
                      onClick={this.handleVisiblePassword}
                    />
                  )}
                </div>
              </div>
              <div className="profile-cashier-data">
                <div>
                  <span className="profile-cashier-subtitle">
                    Nueva contraseña
                  </span>
                </div>
                <div>
                  <TextField
                    id="newPassword"
                    variant="outlined"
                    className="textField"
                    type={typeTextField}
                    size="small"
                    error={newPasswordValid}
                    // helperText={newPasswordError}
                    inputProps={{ maxLength: 8, minLength: 8 }}
                    onChange={this.handleOnNewPasswordChange}
                    onBlur={() => {
                      this.handleOnVisibleFocus();
                      onNewPasswordBlur();
                      onPasswordConfirmBlur();
                    }}
                    onFocus={() => this.handleOnVisibleFocus()}
                  />
                  <div className="error-profile-container">
                    <p className="error-text-register-container">
                      {newPasswordError}
                    </p>
                  </div>
                  {visibleHelpPassword ? (
                    <div className="profile-help-password-container">
                      <div className="profile-help-password-block">
                        {checkAlphanumeric ? (
                          <img
                            src={CheckCircle}
                            alt="check"
                            className="profile-help-circle"
                          />
                        ) : (
                          <img
                            src={UnCheckCircle}
                            alt="unCheck"
                            className="profile-help-circle"
                          />
                        )}
                        <p className="profile-help-password-text ">
                          Alfanuméricos
                        </p>
                      </div>

                      <div className="profile-help-password-block">
                        {checkSpecialCharacter ? (
                          <img
                            src={CheckCircle}
                            alt="check"
                            className="profile-help-circle"
                          />
                        ) : (
                          <img
                            src={UnCheckCircle}
                            alt="unCheck"
                            className="profile-help-circle"
                          />
                        )}
                        <p className="profile-help-password-text profile-help-password-text-aux  ">
                          Carácter especial <br />. , / * # -
                        </p>
                      </div>

                      <div className="profile-help-password-block">
                        {checkLength ? (
                          <img
                            src={CheckCircle}
                            alt="check"
                            className="profile-help-circle"
                          />
                        ) : (
                          <img
                            src={UnCheckCircle}
                            alt="unCheck"
                            className="profile-help-circle"
                          />
                        )}
                        <p className="profile-help-password-text">
                          Longitud: 8 caracteres
                        </p>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>

              <div className="profile-cashier-data">
                <div>
                  <span className="profile-cashier-subtitle">
                    Confirmar contraseña
                  </span>
                </div>
                <div>
                  <TextField
                    id="repeatPassword"
                    variant="outlined"
                    className="textField"
                    type={typeTextField}
                    error={passwordConfirmationValid}
                    size="small"
                    inputProps={{ maxLength: 8, minLength: 8 }}
                    onChange={this.handleOnPasswordConfirmChange}
                  />
                  <div className="error-profile-container">
                    <p className="error-text-register-container">
                      {passwordConfirmationError}
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          <div className={"profile-cashier-button"}>
            <Button
              buttonText={"GUARDAR"}
              onClick={this.handleOnButtonClick}
              disabled={
                ((!birthdayValid && newBirthday) ||
                  (!newRateValid && newRate) ||
                  (!passwordConfirmationValid &&
                    passwordConfirmation &&
                    !passwordValid &&
                    password &&
                    !newPasswordValid &&
                    newPassword)) &&
                !birthdayValid &&
                !newRateValid &&
                !passwordConfirmationValid &&
                !passwordValid &&
                !newPasswordValid
                  ? false
                  : true
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    name: state.cashier.name,
    cashierType: state.cashier.cashierType,
    celupagos_id: state.cashier.celupagos_id,
    document: state.cashier.document,
    email: state.cashier.email,
    lastName: state.cashier.lastName,
    phoneNumber: state.cashier.phoneNumber,
    phonePrefix: state.cashier.phonePrefix,
    rate: state.cashier.rate,
    docType: state.cashier.docType,
    loading: state.cashier.loading,
    password: state.cashier.password,
    newPassword: state.cashier.newPassword,
    passwordConfirmation: state.cashier.passwordConfirmation,
    newRate: state.cashier.newRate,
    newRateValid: state.cashier.newRateValid,
    newRateError: state.cashier.newRateError,
    passwordValid: state.cashier.passwordValid,
    newPasswordValid: state.cashier.newPasswordValid,
    passwordConfirmationValid: state.cashier.passwordConfirmationValid,
    birthday: state.cashier.birthday,
    newBirthday: state.cashier.newBirthday,
    birthdayValid: state.cashier.birthdayValid,
    showModal: state.cashier.showModal,
    checkSpecialCharacter: state.cashier.checkSpecialCharacter,
    checkAlphanumeric: state.cashier.checkAlphanumeric,
    checkLength: state.cashier.checkLength,
    editSuccess: state.cashier.editSuccess,
    passwordError: state.cashier.passwordError,
    newPasswordError: state.cashier.newPasswordError,
    passwordConfirmationError: state.cashier.passwordConfirmationError,
    newBirthdayError: state.cashier.newBirthdayError,
  };
};
export default connect(mapStateTopProps, {
  onPasswordChange,
  onPasswordBlur,
  onNewPasswordChange,
  onNewPasswordBlur,
  onPasswordConfirmChange,
  onPasswordConfirmBlur,
  changePasswordCashier,
  onNewRateChange,
  onNewRateBlur,
  updateRateCashier,
  onBirthdayChange,
  updateBirthdayCashier,
  cleanCashierState,
})(ProfileCashier);
