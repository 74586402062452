import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TextField, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";
import Button from "../../../common/Button";
import { validateFloat } from "../../../../utils/Validators";
import { latinFormat } from "../../../../ducks/actions/SimulatorActions";
import {
  CashierTransactions,
  CASHIER_FILTERS,
  CashierStatusType,
  CashierFilter,
  CryptoActive,
} from "../../../../utils/ConstHelper";
import { globalStateType } from "../../../../ducks/reducers";
import { getCryptoActives } from "../../../../ducks/actions/cashier/CashierActions";
import "./EditOperation.css";

type Props = {
  cryptoActives: CryptoActive[];
  transaction: CashierTransactions;
  btcToUsd: number;
  btcToVes: number;
  amountComplete: number;
  guarantee: number;
  rates: any;
  handleCryptoChange: (
    cryptoCurrency: string,
    cryptoAmount: number,
    id: number,
    status: string
  ) => void;
  handleStatusChange: (status: string, id: number) => void;
  getCryptoActives: () => void;
};
const EditOperation = ({
  handleCryptoChange,
  handleStatusChange,
  transaction,
  btcToUsd,
  btcToVes,
  amountComplete,
  guarantee,
  rates,
  getCryptoActives,
  cryptoActives,
}: Props) => {
  moment.locale("es");
  const day = moment().format("D");
  const weekday = moment().format("dddd");
  const month = moment().format("MMMM");
  const year = moment().format("YYYY");
  const hour = moment().format("hh:mm A");
  useEffect(() => {
    getCryptoActives();
  }, [getCryptoActives]);
  const getStatus = (value: string) => {
    const status = CASHIER_FILTERS.filter((type) => type.value === value);
    return status.length > 0 ? status[0].value : "";
  };
  const [status, setStatus] = useState(
    getStatus(transaction ? transaction.statusCode : "")
  );
  const [statusValid, setStatusValid] = useState(false);
  const [cryptoAmount, setCryptoAmount] = useState(
    parseFloat(transaction.cryptoAmount)
  );
  const [cryptoAmountValid, setCryptoAmountValid] = useState(false);
  const [cryptoCurrency, setCryptoCurrency] = useState(
    transaction.cryptoCurrency
  );
  const [cryptoCurrencyValid, setCryptoCurrencyValid] = useState(false);
  const [id] = useState(transaction ? transaction.id : 0);

  const handleOnCryptoAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const amount = event.target.value;
    if (validateFloat(amount, true)) {
      setCryptoAmount(parseFloat(amount));
      setCryptoAmountValid(false);
      if (!cryptoCurrency) {
        setCryptoCurrencyValid(true);
      }
    } else {
      setCryptoAmountValid(true);
    }
  };
  const handleOnCryptoCurrencyChange = (event: any) => {
    const currency = event.target.value;
    setCryptoCurrency(currency);
    setCryptoCurrencyValid(false);
    if (cryptoAmount === 0) {
      setCryptoAmountValid(true);
    }
  };
  const handleOnStatusChange = (event: CashierStatusType | any) => {
    setStatus(event.target.value);
    if (event.target.value === transaction.statusCode) {
      setStatusValid(false);
    } else {
      setStatusValid(true);
    }
    if (transaction.statusCode === "VD" && event.target.value !== "CE") {
      setCryptoAmount(parseFloat("0.000000000"));
      setCryptoCurrency("");
    }
  };

  const handleOnClickButton = () => {
    if (cryptoCurrency && cryptoAmount) {
      handleCryptoChange(cryptoCurrency, cryptoAmount, id, status);
    } else {
      handleStatusChange(status, id);
    }
  };

  // const handleConvertToUSD = (value: number) => {
  //   const dollar = rates["USD"] / rates[transaction.originCurrency];
  //   const amount = value / dollar;

  //   return latinFormat(amount);
  // };

  const renderItems = () => {
    let statusOptions: CashierFilter[] = [];

    if (transaction.statusCode === "EP") {
      statusOptions = [
        {
          label: "En proceso",
          value: "EP",
          id: 0,
        },
        {
          label: "Validada",
          value: "VD",
          id: 6,
        },
        {
          label: "Rechazado",
          value: "RZ",
          id: 2,
        },
        {
          label: "Devuelto",
          value: "DT",
          id: 3,
        },
      ];
    } else if (transaction.statusCode === "VD") {
      statusOptions = [
        {
          label: "Validada",
          value: "VD",
          id: 6,
        },
        {
          label: "Compra exitosa",
          value: "CE",
          id: 4,
        },
        {
          label: "Compra no exitosa",
          value: "CNE",
          id: 5,
        },
      ];
    }

    return statusOptions.map((status) => (
      <MenuItem value={status.value} key={status.id}>
        {status.label}
      </MenuItem>
    ));
  };

  const renderCrypto = () => {
    return cryptoActives.map((crypto) => (
      <MenuItem value={crypto.value} key={crypto.id}>
        {crypto.name}
      </MenuItem>
    ));
  };
  const enableButton = () => {
    if (statusValid && status !== "CE") {
      return false;
    } else if (
      !cryptoAmountValid &&
      cryptoAmount &&
      !cryptoCurrencyValid &&
      cryptoCurrency
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="edit-operation-container">
      <div className="edit-operation-title-container">
        <div className="margin-top-from-title">
          <span className="edit-operation-title ">Editar operación</span>
        </div>

        <div className="edit-operation-info">
          <div>
            <span className="edit-operation-subtitle">
              <span className="text-bold-edit-operation operation-right-margin">
                BTC al día
              </span>
              {` ${weekday} ${day} de ${month}, ${year}; ${hour}.`}
            </span>
          </div>
          <div>
            <span className="edit-operation-subtitle">
              <span className="text-bold-edit-operation">{`BTC= ${btcToVes} VES`}</span>
              <span className="text-bold-edit-operation btc-left-margin">{`BTC= ${btcToUsd} USD`}</span>
            </span>
          </div>
          <div className="margin-top-from-amount">
            <span className="edit-operation-subtitle">
              <span className="text-bold-edit-operation text-color-edit-operation">
                Monto total usado:
              </span>{" "}
              <span className="text-color-edit-operation">
                {latinFormat(amountComplete)} USD
              </span>
            </span>
          </div>
          <div>
            <span className="edit-operation-subtitle">
              <span className="text-bold-edit-operation text-color-edit-operation">
                Garantía disponible:
              </span>{" "}
              <span className="text-color-edit-operation">{guarantee} USD</span>
            </span>
          </div>
        </div>
      </div>
      <div className="edit-operation-data-container">
        <div className="edit-operation-column-container">
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Nombre usuario
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.user.name} {transaction.user.last_name}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                País origen
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.cashierAccount.bank.country.name}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Banco país origen
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.cashierAccount.bank.entity_name}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Monto país origen
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.originAmount} {transaction.originCurrency}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                País destino
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.clientAccount.bank.country.name}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Banco país destino
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.clientAccount.bank.entity_name}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Monto país destino
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.destinationAmount}{" "}
                {transaction.destinationCurrency}
              </span>
            </div>
          </div>
        </div>
        <div className="edit-operation-column-container">
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Transacción ID
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.celupagoReference}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Fecha de transacción
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {moment(transaction.emissionDate).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Tasa de cambio
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.exchangeType}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Referencia bancaria
              </span>
            </div>
            <div>
              <span className="edit-operation-subtitle">
                {transaction.bankReference}
              </span>
            </div>
          </div>
          <div className="edit-operation-data">
            <div>
              <span className="edit-operation-subtitle text-bold-edit-operation">
                Estatus
              </span>
            </div>
            <div>
              <Select
                className="edit-operation-dropdown"
                id="status"
                value={status}
                onChange={(value) => handleOnStatusChange(value)}
              >
                {renderItems()}
              </Select>
            </div>
          </div>
          {status === "VD" && (
            <div className="edit-operation-data vd-help-text-container">
              <span className="vd-help-text">
                Recuerda que tienes 30 minutos para hacer el cambio a COMPRA
                EXITOSA
              </span>
            </div>
          )}

          {transaction.statusCode === "CE" ||
            (status === "CE" && (
              <React.Fragment>
                <div className="edit-operation-data">
                  <div>
                    <span className="edit-operation-subtitle text-bold-edit-operation">
                      Tipo cripto moneda
                    </span>
                  </div>
                  <div>
                    <Select
                      className="edit-operation-dropdown"
                      id="cryptoCurrency"
                      value={cryptoCurrency}
                      onChange={(value) => handleOnCryptoCurrencyChange(value)}
                    >
                      {renderCrypto()}
                    </Select>
                  </div>
                </div>

                <div className="edit-operation-data">
                  <div>
                    <span className="edit-operation-subtitle text-bold-edit-operation">
                      Cantidad cripto moneda
                    </span>
                  </div>
                  <div>
                    <TextField
                      id="cryptoAmount"
                      variant="outlined"
                      className="edit-operation-textField"
                      size="small"
                      defaultValue={transaction.cryptoAmount}
                      error={cryptoAmountValid}
                      onChange={handleOnCryptoAmountChange}
                      inputProps={{ maxLength: 11, minLength: 7 }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
      <div className={"edit-operation-button-container"}>
        <div className={"edit-operation-button"}>
          <Button
            buttonText={"GUARDAR"}
            onClick={handleOnClickButton}
            disabled={enableButton()}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateTopProps = (state: globalStateType) => {
  return {
    cryptoActives: state.cashier.cryptoActives,
  };
};
export default connect(mapStateTopProps, { getCryptoActives })(EditOperation);
