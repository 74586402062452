import { FORGOT_PASSWORD_ACTIONS } from "../actions/ForgotPasswordActions";

export type forgotPasswordStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  email: "",
  emailValid: undefined as undefined | boolean,
  emailError: "",
  loading: false,
  error: "",
  forgotPasswordSuccess: false,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FORGOT_PASSWORD_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case FORGOT_PASSWORD_ACTIONS.ON_EMAIL_CHANGE:
      return {
        ...state,
        email: action.payload.email,
        emailValid: action.payload.valid,
        error: "",
      };

    case FORGOT_PASSWORD_ACTIONS.ON_EMAIL_BLUR:
      return {
        ...state,
        emailValid: action.payload.valid,
        emailError: action.payload.errorMessage,
      };

    case FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_START:
      return { ...state, loading: true };

    case FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload.errorMessage,
        loading: false,
      };

    case FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload.errorMessage,
        forgotPasswordSuccess: true,
      };

    case FORGOT_PASSWORD_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
