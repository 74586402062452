import React, { ReactElement } from "react";
import Select from "react-select";
import "./Common.css";
import { Country } from "../../utils/ConstHelper";

type Props = {
  onClick?: () => void;
  type?: string;
  placeholder: string;
  options?: Country[];
  selectedOption?: Country;
  onCountryChange: (value: Country) => void;
};

const getIndex = (data: any, options: any) => {
  return options.indexOf(data) + 1;
};

const selectValidStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid #ebff00",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginBottom: 30,
    width: 291,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 291,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const selectCountryStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid white",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginBottom: 30,
    width: 291,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 291,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const Option = (props: any): ReactElement => {
  const { innerRef, innerProps, selectProps, data, options } = props;
  return (
    <div
      className="country-dropdown-container-option"
      {...innerProps}
      ref={innerRef}
    >
      <div
        className={
          selectProps.options.length === getIndex(data, options)
            ? "country-dropdown-info-container-option country-dropdown-last-children-fix"
            : "country-dropdown-info-container-option"
        }
      >
        <img src={data.flag} className="country-dropdown-flag" alt="" />
        <p>{data.iso}</p>

        <p className="country-dropdown-country-name-text">{props.data.name}</p>
      </div>
    </div>
  );
};
const SingleValue = (props: any): ReactElement => {
  const { innerRef, innerProps } = props;

  return (
    <div
      className="country-dropdown-single-value-container"
      {...innerProps}
      ref={innerRef}
    >
      <p className="">{props.data.name}</p>

      <img
        src={props.data.flag}
        className={"country-dropdown-single-value-flag"}
        alt=""
      />
    </div>
  );
};

const CountryDropdown = ({
  type,
  placeholder,
  selectedOption,
  options,
  onCountryChange,
}: Props): ReactElement => {
  return (
    <React.Fragment>
      <div>
        <Select
          styles={type === "country" ? selectCountryStyles : selectValidStyles}
          placeholder={placeholder}
          components={{ Option, SingleValue }}
          isSearchable={false}
          options={options}
          value={selectedOption}
          noOptionsMessage={({ inputValue }) => "Sin resultados"}
          onChange={(value: any) => onCountryChange(value)}
        />
      </div>
    </React.Fragment>
  );
};

export default CountryDropdown;
