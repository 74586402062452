import React, { ReactElement } from "react";
import Select from "react-select";
import "./Common.css";
import { DocType } from "../../utils/ConstHelper";

type Props = {
  onClick?: () => void;
  type?: string;
  large?: boolean;
  valid?: boolean;
  placeholder: string;
  options?: DocType[] | any;
  selectedOption?: DocType | any;
  onValueChange: (value: DocType | any) => void;
  isSearchable?: boolean;
  disabled?: boolean;
};

const getIndex = (data: any, options: any) => {
  return options.indexOf(data) + 1;
};

const selectLargeValidStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid #ebff00",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: 290,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 290,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const selectLargeStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid white",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: 290,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 290,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid white",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: 100,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 180,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const selectValidStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid #ebff00",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: 100,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 180,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const selectCallingStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid white",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: 100,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 100,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const selectCallingDisabledStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#383838",
    border: "none",
    borderBottom: "1px solid #6f7711",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: 100,
    height: 44,
    color: "red",
  }),
  container: (styles: any) => ({
    ...styles,
    width: 100,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#7d7d85",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const selectCallingValidStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid #ebff00",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: 100,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 100,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const Option = (props: any): ReactElement => {
  const { innerRef, innerProps, selectProps, data, options } = props;

  const textStyleClass = props.data.custom
    ? "dropdown-label-text custom-dropdown-option"
    : "dropdown-label-text";
  return (
    <div className="dropdown-container-option" {...innerProps} ref={innerRef}>
      <div
        className={
          selectProps.options.length === getIndex(data, options)
            ? "dropdown-info-container-option dropdown-last-children-fix"
            : "dropdown-info-container-option"
        }
      >
        <p>{data.iso}</p>
        <p className={textStyleClass}>
          {props.data.name
            ? `${props.data.label} ${props.data.name}`
            : props.data.label}
        </p>
      </div>
    </div>
  );
};
const SingleValue = (props: any): ReactElement => {
  const { innerRef, innerProps, data } = props;

  return (
    <div
      className="dropdown-single-value-container "
      {...innerProps}
      ref={innerRef}
    >
      <p className="">
        {data.notAvailable
          ? "Ingresa nombre o ID Cajero"
          : data.label || data.code}
      </p>
    </div>
  );
};

const Dropdown = ({
  type,
  large,
  valid,
  placeholder,
  selectedOption,
  options,
  onValueChange,
  isSearchable,
  disabled,
}: Props): ReactElement => {
  return (
    <React.Fragment>
      <div>
        <Select
          styles={
            large
              ? valid
                ? selectLargeValidStyles
                : selectLargeStyles
              : type === "calling"
              ? valid
                ? disabled
                  ? selectCallingDisabledStyles
                  : selectCallingValidStyles
                : selectCallingStyles
              : valid
              ? selectValidStyles
              : selectStyles
          }
          isDisabled={disabled}
          placeholder={placeholder}
          components={{ Option, SingleValue }}
          isSearchable={isSearchable}
          options={options}
          value={selectedOption}
          noOptionsMessage={({ inputValue }) => "Sin resultados"}
          onChange={(value) => onValueChange(value)}
        />
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
