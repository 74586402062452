import React, { ReactElement } from "react";
import Select from "react-select";
import "./BeneficiaryForm.css";

type Props = {
  onClick?: () => void;
  type?: string;
  large?: boolean;
  valid?: boolean;
  placeholder: string;
  options?: any;
  selectedOption?: any;
  onValueChange: (value: any) => void;
  isSearchable?: boolean;
};

const getIndex = (data: any, options: any) => {
  return options.indexOf(data) + 1;
};

const selectValidStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid white",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: window.innerWidth > 990 ? 680 : 321,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: window.innerWidth > 990 ? 680 : 321,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#1d1d1d",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#bababa",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    border: "none",
    borderBottom: "1px solid white",
    borderRadius: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    marginTop: -30,
    width: window.innerWidth > 990 ? 680 : 321,
    height: 44,
  }),
  container: (styles: any) => ({
    ...styles,
    width: window.innerWidth > 990 ? 680 : 321,
    backgroundColor: "transparent",
  }),

  input: (styles: any) => ({
    ...styles,
    width: 0,
    color: "white",
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",
    color: "white",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#626262",

    color: "white",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: "Exo 2",
    fontSize: 14,
    color: "#ffffff",
    marginLeft: window.innerWidth > 480 ? 3 : 8,
  }),
};

const Option = (props: any): ReactElement => {
  const { innerRef, innerProps, selectProps, data, options } = props;
  return (
    <div
      className="beneficiary-container-option"
      {...innerProps}
      ref={innerRef}
    >
      <div
        className={
          selectProps.options.length === getIndex(data, options)
            ? "beneficiary-info-container-option beneficiary-last-children-fix"
            : "beneficiary-info-container-option"
        }
      >
        <p className="beneficiary-holder-text">{props.data.account_holder}</p>
        <p className="beneficiary-bank-text">
          {props.data.bank.commercial_name}
        </p>
      </div>
    </div>
  );
};
const SingleValue = (props: any): ReactElement => {
  const { innerRef, innerProps } = props;
  return (
    <div
      className="beneficiary-single-value-container "
      {...innerProps}
      ref={innerRef}
    >
      <p className="beneficiary-single-value-text">{props.data.label}</p>
    </div>
  );
};

const BeneficiaryDropdown = ({
  valid,
  placeholder,
  selectedOption,
  options,
  onValueChange,
  isSearchable,
}: Props): ReactElement => {
  return (
    <React.Fragment>
      <div>
        <Select
          styles={valid ? selectValidStyles : selectStyles}
          placeholder={placeholder}
          components={{ Option, SingleValue }}
          isSearchable={isSearchable}
          options={options}
          value={selectedOption}
          noOptionsMessage={({ inputValue }) => "Sin resultados"}
          onChange={(value) => onValueChange(value)}
        />
      </div>
    </React.Fragment>
  );
};

export default BeneficiaryDropdown;
