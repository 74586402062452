import { REGISTER_ACTIONS } from "../actions/RegisterActions";
import { Country, DocType } from "../../utils/ConstHelper";

export type registerStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  email: "",
  emailValid: undefined as undefined | boolean,
  emailConfirmation: "",
  emailConfirmationValid: undefined as undefined | boolean,
  password: "",
  passwordValid: undefined as undefined | boolean,
  passwordConfirmation: "",
  passwordConfirmationValid: undefined as undefined | boolean,
  name: "",
  nameValid: undefined as undefined | boolean,
  lastName: "",
  lastNameValid: undefined as undefined | boolean,

  numberDocument: "",
  numberDocumentValid: undefined as undefined | boolean,

  numberPhone: "",
  numberPhoneValid: undefined as undefined | boolean,
  checkTerms: false,
  emailError: "",
  emailConfirmationError: "",
  passwordError: "",
  passwordConfirmationError: "",
  nameError: "",
  lastNameError: "",
  numberDocumentError: "",
  numberPhoneError: "",
  checkSpecialCharacter: false,
  checkAlphanumeric: false,
  checkLength: false,
  loading: false,
  validRegister: false,
  error: "",
  activeCountries: [] as Country[],
  selectedCountry: undefined as Country | undefined,
  selectedDocType: undefined as DocType | undefined,
  selectedCallingCode: undefined as any,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REGISTER_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case REGISTER_ACTIONS.FETCH_COUNTRY_DATA:
      return { ...state, activeCountries: action.payload };

    case REGISTER_ACTIONS.ON_EMAIL_CHANGE:
      return { ...state, email: action.payload };

    case REGISTER_ACTIONS.ON_EMAIL_CONFIRM_CHANGE:
      return { ...state, emailConfirmation: action.payload };

    case REGISTER_ACTIONS.ON_PASSWORD_CHANGE:
      return {
        ...state,
        password: action.payload.password,
        passwordValid: action.payload.valid,
        checkAlphanumeric: action.payload.checkAlphanumeric,
        checkSpecialCharacter: action.payload.checkSpecialCharacter,
        checkLength: action.payload.checkLength,
      };

    case REGISTER_ACTIONS.ON_PASSWORD_CONFIRM_CHANGE:
      return { ...state, passwordConfirmation: action.payload };

    case REGISTER_ACTIONS.ON_NAME_CHANGE:
      return { ...state, name: action.payload };

    case REGISTER_ACTIONS.ON_LAST_NAME_CHANGE:
      return { ...state, lastName: action.payload };

    case REGISTER_ACTIONS.ON_NUMBER_DOCUMENT_CHANGE:
      return { ...state, numberDocument: action.payload };

    case REGISTER_ACTIONS.ON_NUMBER_PHONE_CHANGE:
      return { ...state, numberPhone: action.payload };

    case REGISTER_ACTIONS.ON_CHECK_TERMS_CHANGE:
      return { ...state, checkTerms: action.payload };

    case REGISTER_ACTIONS.ON_EMAIL_BLUR:
      return {
        ...state,
        emailValid: action.payload.valid,
        emailError: action.payload.errorMessage,
      };

    case REGISTER_ACTIONS.ON_EMAIL_CONFIRM_BLUR:
      return {
        ...state,
        emailConfirmationValid: action.payload.valid,
        emailConfirmationError: action.payload.errorMessage,
      };

    case REGISTER_ACTIONS.ON_PASSWORD_BLUR:
      return {
        ...state,
        passwordValid: action.payload.valid,
        passwordError: action.payload.errorMessage,
      };

    case REGISTER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR:
      return {
        ...state,
        passwordConfirmationValid: action.payload.valid,
        passwordConfirmationError: action.payload.errorMessage,
      };

    case REGISTER_ACTIONS.ON_NAME_BLUR:
      return {
        ...state,
        nameValid: action.payload.valid,
        nameError: action.payload.errorMessage,
      };

    case REGISTER_ACTIONS.ON_LAST_NAME_BLUR:
      return {
        ...state,
        lastNameValid: action.payload.valid,
        lastNameError: action.payload.errorMessage,
      };

    case REGISTER_ACTIONS.ON_NUMBER_DOCUMENT_BLUR:
      return {
        ...state,
        numberDocumentValid: action.payload.valid,
        numberDocumentError: action.payload.errorMessage,
      };

    case REGISTER_ACTIONS.ON_NUMBER_PHONE_BLUR:
      return {
        ...state,
        numberPhoneValid: action.payload.valid,
        numberPhoneError: action.payload.errorMessage,
      };

    case REGISTER_ACTIONS.REGISTER_START:
      return { ...state, loading: true };

    case REGISTER_ACTIONS.REGISTER_FAIL:
      return {
        ...state,
        error: action.payload.errorMessage,
        loading: false,
      };

    case REGISTER_ACTIONS.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        validRegister: true,
      };

    case REGISTER_ACTIONS.ON_COUNTRY_CHANGE:
      return { ...state, selectedCountry: action.payload };

    case REGISTER_ACTIONS.ON_DOC_TYPE_CHANGE:
      return { ...state, selectedDocType: action.payload };

    case REGISTER_ACTIONS.ON_CALLING_CODE_CHANGE:
      return { ...state, selectedCallingCode: action.payload };

    case REGISTER_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
