import React, { Component } from "react";
import Dropdown from "../../common/Dropdown";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  onEmailChange,
  onEmailConfirmChange,
  onPasswordChange,
  onPasswordConfirmChange,
  onNameChange,
  onLastNameChange,
  onNumberDocumentChange,
  onCallingCodeChange,
  onNumberPhoneChange,
  onCheckTermsChange,
  onEmailBlur,
  onEmailConfirmBlur,
  onPasswordBlur,
  onPasswordConfirmBlur,
  onNameBlur,
  onLastNameBlur,
  onNumberDocumentBlur,
  onNumberPhoneBlur,
  cleanRegisterState,
  signUp,
  getCountryData,
  onCountryChange,
  onDocTypeChange,
} from "../../../ducks/actions/RegisterActions";
import Logo from "../../../assets/img/logo/logo.png";
import CheckCircle from "../../../assets/icons/check-circle-yellow.png";
import UnCheckCircle from "../../../assets/icons/unCheck-circle.png";
import Button from "../../common/Button";
import Input from "../../common/Input";
import { globalStateType } from "../../../ducks/reducers";
import { Country, DocType } from "../../../utils/ConstHelper";
import CountryDropdown from "../../common/CountryDropdown";
import Loader from "../../common/Loader";
import "./Register.css";

type Props = {
  email: string;
  emailValid?: boolean;
  emailError: string;
  onEmailChange: (text: string) => void;
  onEmailBlur: () => void;
  emailConfirmation: string;
  emailConfirmationValid?: boolean;
  emailConfirmationError: string;
  onEmailConfirmChange: (text: string) => void;
  onEmailConfirmBlur: () => void;
  password: string;
  passwordValid?: boolean;
  passwordError: string;
  onPasswordChange: (text: string) => void;
  onPasswordBlur: () => void;
  passwordConfirmation: string;
  passwordConfirmationValid?: boolean;
  passwordConfirmationError: string;
  onPasswordConfirmChange: (text: string) => void;
  onPasswordConfirmBlur: () => void;
  name: string;
  nameValid?: boolean;
  nameError: string;
  onNameChange: (text: string) => void;
  onNameBlur: () => void;
  lastName: string;
  lastNameValid?: boolean;
  lastNameError: string;
  onLastNameChange: (text: string) => void;
  onLastNameBlur: () => void;
  numberDocument: string;
  numberDocumentValid?: boolean;
  numberDocumentError: string;
  onNumberDocumentChange: (text: string) => void;
  onNumberDocumentBlur: () => void;
  onCallingCodeChange: (value: any) => void;
  onDocTypeChange: (value: any) => void;
  numberPhone: string;
  numberPhoneValid?: boolean;
  numberPhoneError: string;
  onNumberPhoneChange: (text: string) => void;
  onNumberPhoneBlur: () => void;
  checkTerms: boolean;
  checkSpecialCharacter: boolean;
  checkAlphanumeric: boolean;
  checkLength: boolean;
  onCheckTermsChange: (value: boolean) => void;
  cleanRegisterState: () => void;
  signUp: () => void;
  validRegister: boolean;
  error: string;
  loading: boolean;
  getCountryData: () => void;
  activeCountries: Country[];
  selectedCountry?: Country;
  selectedDocType?: DocType;
  selectedCallingCode?: any;
  onCountryChange: (value: Country) => void;
};

type State = typeof initialState;

const initialState = {
  visiblePassword: false,
  visibleHelpPassword: false,
  visiblePasswordConfirm: false,
};

class Register extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
    const { cleanRegisterState } = this.props;
    cleanRegisterState();
  }

  componentDidMount() {
    this.props.getCountryData();
  }

  handleOnVisibleClick = (value: string) => () => {
    if (value === "password") {
      this.setState({ visiblePassword: !this.state.visiblePassword });
    } else {
      this.setState({
        visiblePasswordConfirm: !this.state.visiblePasswordConfirm,
      });
    }
  };

  handleOnVisibleFocus = () => {
    this.setState({ visibleHelpPassword: !this.state.visibleHelpPassword });
  };

  onCheckMarkChange = () => {
    const { onCheckTermsChange, checkTerms } = this.props;
    onCheckTermsChange(!checkTerms);
  };

  handleOnEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onEmailChange, onEmailBlur } = this.props;
    onEmailChange(event.target.value);
    onEmailBlur();
  };

  handleOnEmailConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onEmailConfirmChange, onEmailConfirmBlur } = this.props;
    onEmailConfirmChange(event.target.value);
    onEmailConfirmBlur();
  };

  handleOnPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onPasswordChange, onPasswordBlur } = this.props;
    onPasswordChange(event.target.value);
    onPasswordBlur();
  };

  handleOnPasswordConfirmChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onPasswordConfirmChange, onPasswordConfirmBlur } = this.props;
    onPasswordConfirmChange(event.target.value);
    onPasswordConfirmBlur();
  };

  handleOnNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onNameChange, onNameBlur } = this.props;
    onNameChange(event.target.value);
    onNameBlur();
  };

  handleOnLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onLastNameChange, onLastNameBlur } = this.props;
    onLastNameChange(event.target.value);
    onLastNameBlur();
  };

  handleOnNumberDocumentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onNumberDocumentChange, onNumberDocumentBlur } = this.props;
    onNumberDocumentChange(event.target.value);
    onNumberDocumentBlur();
  };

  handleOnNumberPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onNumberPhoneChange, onNumberPhoneBlur } = this.props;
    onNumberPhoneChange(event.target.value);
    onNumberPhoneBlur();
  };

  handleOnButtonClick = () => {
    const { signUp } = this.props;
    signUp();
  };

  handleCountryChange = (value: Country) => {
    const { onCountryChange } = this.props;

    onCountryChange(value);
  };

  handleDocTypeChange = (value: DocType | any) => {
    const { onDocTypeChange } = this.props;

    onDocTypeChange(value);
  };

  handleCallingCodeChange = (value: any) => {
    const { onCallingCodeChange } = this.props;

    onCallingCodeChange(value);
  };
  render() {
    const {
      email,
      emailValid,
      emailError,
      emailConfirmation,
      emailConfirmationValid,
      emailConfirmationError,
      password,
      passwordValid,
      passwordError,
      passwordConfirmation,
      passwordConfirmationValid,
      passwordConfirmationError,
      name,
      nameValid,
      nameError,
      lastName,
      lastNameValid,
      lastNameError,
      numberDocument,
      numberDocumentValid,
      numberDocumentError,
      numberPhone,
      numberPhoneValid,
      numberPhoneError,
      checkTerms,
      checkSpecialCharacter,
      checkAlphanumeric,
      checkLength,
      onEmailBlur,
      onEmailConfirmBlur,
      onPasswordBlur,
      onPasswordConfirmBlur,
      onNameBlur,
      onLastNameBlur,
      onNumberDocumentBlur,
      onNumberPhoneBlur,
      error,
      loading,
      validRegister,
      activeCountries,
      selectedCallingCode,
      selectedDocType,
      selectedCountry,
    } = this.props;

    const { visiblePasswordConfirm, visiblePassword } = this.state;

    const redirect =
      validRegister === false ? <React.Fragment /> : <Redirect to="/" />;
    let callingCodeOptions = [
      {
        value: selectedCountry?.calling_code,
        label: selectedCountry?.calling_code,
      },
    ];
    return (
      <React.Fragment>
        {redirect}
        <div className="register-container">
          {loading ? <Loader /> : null}
          <div className="register-header">
            <a href="/">
              <img src={Logo} alt="celupago" className="register-logo" />
            </a>
            <h2 className="subTitle-register">Registro</h2>
          </div>
          <div className="inputs-container">
            <div>
              <Input
                styleName={
                  emailValid !== undefined
                    ? emailValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={40}
                minLength={6}
                label="Correo electrónico"
                onChangeValue={this.handleOnEmailChange}
                onBlur={onEmailBlur}
                value={email}
              />
              <div className="error-register-container">
                <p className="error-text-register-container">{emailError}</p>
              </div>
              <Input
                styleName={
                  emailConfirmationValid !== undefined
                    ? emailConfirmationValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={40}
                minLength={6}
                label="Confirmación de correo electrónico"
                onChangeValue={this.handleOnEmailConfirmationChange}
                onBlur={onEmailConfirmBlur}
                value={emailConfirmation}
              />
              <div className="error-register-container">
                <p className="error-text-register-container">
                  {emailConfirmationError}
                </p>
              </div>
              <Input
                styleName={
                  passwordValid !== undefined
                    ? passwordValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="password"
                maxLength={15}
                minLength={8}
                label="Nueva contraseña ekiienvíos"
                visible={visiblePassword}
                onChangeValue={this.handleOnPasswordChange}
                onBlur={() => {
                  this.handleOnVisibleFocus();
                  onPasswordBlur();
                  onPasswordConfirmBlur();
                }}
                value={password}
                onFocus={() => this.handleOnVisibleFocus()}
                onClick={this.handleOnVisibleClick("password")}
              />
              <div className="error-register-container">
                <p className="error-text-register-container">{passwordError}</p>
              </div>
              {this.state.visibleHelpPassword ? (
                <div className="help-password-container">
                  <div className="help-password-block">
                    {checkAlphanumeric ? (
                      <img
                        src={CheckCircle}
                        alt="check"
                        className="register-help-circle"
                      />
                    ) : (
                      <img
                        src={UnCheckCircle}
                        alt="unCheck"
                        className="register-help-circle"
                      />
                    )}
                    <p className="help-password-text ">Alfanuméricos</p>
                  </div>
                  <div className="help-password-block">
                    {checkSpecialCharacter ? (
                      <img
                        src={CheckCircle}
                        alt="check"
                        className="register-help-circle"
                      />
                    ) : (
                      <img
                        src={UnCheckCircle}
                        alt="unCheck"
                        className="register-help-circle"
                      />
                    )}
                    <p className="help-password-text">
                      Carácter especial . , / * # -
                    </p>
                  </div>
                  <div className="help-password-block">
                    {checkLength ? (
                      <img
                        src={CheckCircle}
                        alt="check"
                        className="register-help-circle"
                      />
                    ) : (
                      <img
                        src={UnCheckCircle}
                        alt="unCheck"
                        className="register-help-circle"
                      />
                    )}
                    <p className="help-password-text">Longitud: 8 caracteres</p>
                  </div>
                </div>
              ) : (
                <React.Fragment />
              )}
              <Input
                styleName={
                  passwordConfirmationValid !== undefined
                    ? passwordConfirmationValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="password"
                maxLength={15}
                minLength={8}
                label="Confirmación de contraseña"
                visible={visiblePasswordConfirm}
                onChangeValue={this.handleOnPasswordConfirmChange}
                onBlur={onPasswordConfirmBlur}
                value={passwordConfirmation}
                onClick={this.handleOnVisibleClick("passwordConfirmation")}
              />
              <div className="error-register-container">
                <p className="error-text-register-container">
                  {passwordConfirmationError}
                </p>
              </div>
            </div>
            <div>
              <Input
                styleName={
                  nameValid !== undefined
                    ? nameValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={20}
                minLength={3}
                label="Nombres"
                onChangeValue={this.handleOnNameChange}
                onBlur={onNameBlur}
                value={name}
              />
              <div className="error-register-container">
                <p className="error-text-register-container">{nameError}</p>
              </div>
              <Input
                styleName={
                  lastNameValid !== undefined
                    ? lastNameValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={20}
                minLength={3}
                label="Apellidos"
                onChangeValue={this.handleOnLastNameChange}
                onBlur={onLastNameBlur}
                value={lastName}
              />
              <div className="error-register-container">
                <p className="error-text-register-container">{lastNameError}</p>
              </div>
              <CountryDropdown
                type={selectedCountry ? "country-valid" : "country"}
                placeholder={"País"}
                options={activeCountries}
                selectedOption={selectedCountry}
                onCountryChange={this.handleCountryChange}
              />
              <div className="dropdown-container">
                <Dropdown
                  placeholder={"Tipo"}
                  valid={selectedDocType ? true : false}
                  selectedOption={selectedDocType}
                  options={selectedCountry?.docTypes}
                  onValueChange={this.handleDocTypeChange}
                />
                <Input
                  styleName={
                    numberDocumentValid !== undefined
                      ? numberDocumentValid
                        ? "common-input-dropdown common-input-valid"
                        : "common-input-dropdown common-input-invalid"
                      : "common-input-dropdown"
                  }
                  type="text"
                  maxLength={15}
                  minLength={7}
                  label="Nº de documento"
                  onChangeValue={this.handleOnNumberDocumentChange}
                  onBlur={onNumberDocumentBlur}
                  value={numberDocument}
                />
              </div>
              <div className="error-register-container-aux">
                <p className="error-text-register-container">
                  {numberDocumentError}
                </p>
              </div>
              <div className="dropdown-container">
                <Dropdown
                  type={"calling"}
                  valid={selectedCallingCode ? true : false}
                  placeholder={"Código"}
                  options={callingCodeOptions}
                  selectedOption={selectedCallingCode}
                  onValueChange={this.handleCallingCodeChange}
                />
                <Input
                  styleName={
                    numberPhoneValid !== undefined
                      ? numberPhoneValid
                        ? "common-input-dropdown-calling common-input-valid"
                        : "common-input-dropdown-calling common-input-invalid"
                      : "common-input-dropdown-calling"
                  }
                  type="text"
                  maxLength={15}
                  minLength={8}
                  label="Nº de teléfono"
                  onChangeValue={this.handleOnNumberPhoneChange}
                  onBlur={onNumberPhoneBlur}
                  value={numberPhone}
                />
              </div>
              <div className="error-register-container-aux">
                <p className="error-text-register-container">
                  {numberPhoneError}
                </p>
              </div>
            </div>
          </div>
          <div className="text-terms-register ">
            {checkTerms ? (
              <img
                src={CheckCircle}
                alt="check"
                className="register-check-circle"
                onClick={this.onCheckMarkChange}
              />
            ) : (
              <img
                src={UnCheckCircle}
                alt="unCheck"
                className="register-check-circle"
                onClick={this.onCheckMarkChange}
              />
            )}
            <Link
              to="/terms-of-use"
              target="_blank"
              className="link-text-terms-register "
            >
              <span className="text-light-terms-register">Acepto </span>Términos
              y Condiciones
            </Link>
          </div>
          <div className="error-register">
            <p className="error-text-register">{error}</p>
          </div>
          <div className="button-register">
            <Button
              buttonText={"INGRESAR"}
              onClick={this.handleOnButtonClick}
              disabled={
                emailValid &&
                emailConfirmationValid &&
                passwordValid &&
                passwordConfirmationValid &&
                nameValid &&
                lastNameValid &&
                numberDocumentValid &&
                numberPhoneValid &&
                checkTerms &&
                selectedCallingCode &&
                selectedDocType &&
                selectedCountry
                  ? false
                  : true
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateTopProps = (state: globalStateType) => {
  return {
    email: state.register.email,
    emailValid: state.register.emailValid,
    emailError: state.register.emailError,
    emailConfirmation: state.register.emailConfirmation,
    emailConfirmationValid: state.register.emailConfirmationValid,
    emailConfirmationError: state.register.emailConfirmationError,
    password: state.register.password,
    passwordValid: state.register.passwordValid,
    passwordError: state.register.passwordError,
    passwordConfirmation: state.register.passwordConfirmation,
    passwordConfirmationValid: state.register.passwordConfirmationValid,
    passwordConfirmationError: state.register.passwordConfirmationError,
    name: state.register.name,
    nameValid: state.register.nameValid,
    nameError: state.register.nameError,
    lastName: state.register.lastName,
    lastNameValid: state.register.lastNameValid,
    lastNameError: state.register.lastNameError,
    numberDocument: state.register.numberDocument,
    numberDocumentValid: state.register.numberDocumentValid,
    numberDocumentError: state.register.numberDocumentError,
    numberPhone: state.register.numberPhone,
    numberPhoneValid: state.register.numberPhoneValid,
    numberPhoneError: state.register.numberPhoneError,
    checkTerms: state.register.checkTerms,
    checkSpecialCharacter: state.register.checkSpecialCharacter,
    checkAlphanumeric: state.register.checkAlphanumeric,
    checkLength: state.register.checkLength,
    error: state.register.error,
    loading: state.register.loading,
    validRegister: state.register.validRegister,
    activeCountries: state.register.activeCountries,
    selectedCountry: state.register.selectedCountry,
    selectedDocType: state.register.selectedDocType,
    selectedCallingCode: state.register.selectedCallingCode,
  };
};

export default connect(mapStateTopProps, {
  onEmailChange,
  onEmailConfirmChange,
  onPasswordChange,
  onPasswordConfirmChange,
  onNameChange,
  onLastNameChange,
  onNumberDocumentChange,
  onCallingCodeChange,
  onNumberPhoneChange,
  onCheckTermsChange,
  onEmailBlur,
  onEmailConfirmBlur,
  onPasswordBlur,
  onPasswordConfirmBlur,
  onNameBlur,
  onLastNameBlur,
  onNumberDocumentBlur,
  onNumberPhoneBlur,
  cleanRegisterState,
  signUp,
  onCountryChange,
  getCountryData,
  onDocTypeChange,
})(Register);
