import React, { Component } from "react";
import { globalStateType } from "../../../ducks/reducers";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import {
  onPasswordChange,
  onPasswordConfirmChange,
  onPasswordBlur,
  onPasswordConfirmBlur,
  newPassword,
} from "../../../ducks/actions/NewPasswordActions";
import Logo from "../../../assets/img/logo/logo.png";
import Loader from "../../common/Loader";
import Button from "../../common/Button";
import Input from "../../common/Input";
import CheckCircle from "../../../assets/icons/check-circle.png";
import UnCheckCircle from "../../../assets/icons/unCheck-circle.png";
import "./Login.css";

interface DetailParams {
  id: string;
}

type Props = {
  password: string;
  passwordValid?: boolean;
  passwordError: string;
  onPasswordChange: (text: string) => void;
  onPasswordBlur: () => void;
  passwordConfirmation: string;
  passwordConfirmationValid?: boolean;
  passwordConfirmationError: string;
  onPasswordConfirmChange: (text: string) => void;
  onPasswordConfirmBlur: () => void;
  checkSpecialCharacter: boolean;
  checkAlphanumeric: boolean;
  checkLength: boolean;
  match: match<DetailParams>;
  newPassword: (id: string) => void;
  error: string;
  loading: boolean;
};

type State = typeof initialState;

const initialState = {
  visiblePassword: false,
  visibleHelpPassword: false,
  visiblePasswordConfirm: false,
};

class NewPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  handleOnVisibleClick = (value: string) => () => {
    if (value === "password") {
      this.setState({ visiblePassword: !this.state.visiblePassword });
    } else {
      this.setState({
        visiblePasswordConfirm: !this.state.visiblePasswordConfirm,
      });
    }
  };

  handleOnVisibleFocus = () => {
    this.setState({ visibleHelpPassword: !this.state.visibleHelpPassword });
  };

  handleOnPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onPasswordChange, onPasswordBlur } = this.props;
    onPasswordChange(event.target.value);
    onPasswordBlur();
  };

  handleOnPasswordConfirmChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onPasswordConfirmChange, onPasswordConfirmBlur } = this.props;
    onPasswordConfirmChange(event.target.value);
    onPasswordConfirmBlur();
  };

  handleOnButtonClick = () => {
    const { newPassword, match } = this.props;
    newPassword(match.params.id);
  };

  render() {
    const {
      password,
      passwordValid,
      passwordError,
      passwordConfirmation,
      passwordConfirmationValid,
      passwordConfirmationError,
      onPasswordBlur,
      onPasswordConfirmBlur,
      checkSpecialCharacter,
      checkAlphanumeric,
      checkLength,
      error,
      loading,
    } = this.props;

    const { visiblePasswordConfirm, visiblePassword } = this.state;

    return (
      <React.Fragment>
        <div className="login-container">
          {loading ? <Loader /> : null}
          <div className="login-header">
            <a href="/">
              <img src={Logo} alt="celupago" className="login-logo" />
            </a>
            <h2 className="subTitle-login">Ingrese nueva contraseña</h2>
          </div>

          <div className="inputs-login-container">
            <Input
              styleName={
                passwordValid !== undefined
                  ? passwordValid
                    ? "common-input-valid"
                    : "common-input-invalid"
                  : ""
              }
              type="password"
              maxLength={15}
              minLength={8}
              label="Nueva contraseña"
              visible={visiblePassword}
              onChangeValue={this.handleOnPasswordChange}
              onBlur={() => {
                this.handleOnVisibleFocus();
                onPasswordBlur();
                onPasswordConfirmBlur();
              }}
              value={password}
              onFocus={() => this.handleOnVisibleFocus()}
              onClick={this.handleOnVisibleClick("password")}
            />
            <div className="error-register-container">
              <p className="error-text-register-container">{passwordError}</p>
            </div>
            {this.state.visibleHelpPassword ? (
              <div className="help-password-container">
                <div className="help-password-block">
                  {checkAlphanumeric ? (
                    <img
                      src={CheckCircle}
                      alt="check"
                      className="register-help-circle"
                    />
                  ) : (
                    <img
                      src={UnCheckCircle}
                      alt="unCheck"
                      className="register-help-circle"
                    />
                  )}
                  <p className="help-password-text ">Alfanuméricos</p>
                </div>
                <div className="help-password-block">
                  {checkSpecialCharacter ? (
                    <img
                      src={CheckCircle}
                      alt="check"
                      className="register-help-circle"
                    />
                  ) : (
                    <img
                      src={UnCheckCircle}
                      alt="unCheck"
                      className="register-help-circle"
                    />
                  )}
                  <p className="help-password-text help-password-text-aux  ">
                    Carácter especial . , / * # -
                  </p>
                </div>
                <div className="help-password-block">
                  {checkLength ? (
                    <img
                      src={CheckCircle}
                      alt="check"
                      className="register-help-circle"
                    />
                  ) : (
                    <img
                      src={UnCheckCircle}
                      alt="unCheck"
                      className="register-help-circle"
                    />
                  )}
                  <p className="help-password-text">Longitud: 8 caracteres</p>
                </div>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <Input
              styleName={
                passwordConfirmationValid !== undefined
                  ? passwordConfirmationValid
                    ? "common-input-valid"
                    : "common-input-invalid"
                  : ""
              }
              type="password"
              maxLength={8}
              minLength={8}
              label="Confirmación de nueva contraseña"
              visible={visiblePasswordConfirm}
              onChangeValue={this.handleOnPasswordConfirmChange}
              onBlur={onPasswordConfirmBlur}
              value={passwordConfirmation}
              onClick={this.handleOnVisibleClick("passwordConfirmation")}
            />
            <div className="error-register-container">
              <p className="error-text-register-container">
                {passwordConfirmationError}
              </p>
            </div>
            <div className="error-register">
              <p className="error-text-register">{error}</p>
            </div>
            <div className="button-login">
              <Button
                buttonText={"CAMBIAR CONTRASEÑA"}
                onClick={this.handleOnButtonClick}
                disabled={
                  passwordValid && passwordConfirmationValid ? false : true
                }
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    password: state.newPassword.password,
    passwordValid: state.newPassword.passwordValid,
    passwordError: state.newPassword.passwordError,
    passwordConfirmation: state.newPassword.passwordConfirmation,
    passwordConfirmationValid: state.newPassword.passwordConfirmationValid,
    passwordConfirmationError: state.newPassword.passwordConfirmationError,
    checkSpecialCharacter: state.newPassword.checkSpecialCharacter,
    checkAlphanumeric: state.newPassword.checkAlphanumeric,
    checkLength: state.newPassword.checkLength,
    error: state.newPassword.error,
    loading: state.newPassword.loading,
  };
};

export default connect(mapStateTopProps, {
  onPasswordChange,
  onPasswordConfirmChange,
  onPasswordBlur,
  onPasswordConfirmBlur,
  newPassword,
})(NewPassword);
