import { PAYMENT_ACTIONS } from "../actions/PaymentActions";
import { Bank, AccountCashier } from "../../utils/ConstHelper";

export type paymentStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  availableBanks: [] as Bank[],
  availableCashiersOnline: [] as AccountCashier[],
  selectedBank: undefined as Bank | undefined,
  selectedCashierOnline: undefined as AccountCashier | undefined,
  referenceNumber: "",
  referenceNumberValid: undefined as undefined | boolean,
  referenceNumberError: "",
  loading: false,
  idTransaction: "",
  limitAvailable: true,
  tail: false,
  showModal: false,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PAYMENT_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case PAYMENT_ACTIONS.FETCH_BANKS_DATA:
      return {
        ...state,
        availableBanks: action.payload,
      };

    case PAYMENT_ACTIONS.ON_BANK_CHANGE:
      return {
        ...state,
        selectedBank: action.payload,
        selectedCashierOnline: undefined,
      };

    case PAYMENT_ACTIONS.ON_REFERENCE_NUMBER_CHANGE:
      return { ...state, referenceNumber: action.payload };

    case PAYMENT_ACTIONS.ON_REFERENCE_NUMBER_BLUR:
      return {
        ...state,
        referenceNumberValid: action.payload.valid,
        referenceNumberError: action.payload.errorMessage,
      };

    case PAYMENT_ACTIONS.FETCH_CASHIERS_ONLINE_DATA:
      return {
        ...state,
        availableCashiersOnline: action.payload,
      };

    case PAYMENT_ACTIONS.ON_CASHIER_ONLINE_CHANGE:
      return { ...state, selectedCashierOnline: action.payload };

    case PAYMENT_ACTIONS.PAYMENT_START:
      return { ...state, loading: true };

    case PAYMENT_ACTIONS.PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
      };

    case PAYMENT_ACTIONS.PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        idTransaction: action.payload,
      };

    case PAYMENT_ACTIONS.ON_LIMIT_AVAILABLE_CHANGE:
      return {
        ...state,
        limitAvailable: action.payload,
        selectedCashierOnline: undefined,
      };

    case PAYMENT_ACTIONS.FETCH_CASHIERS_LIMIT_AVAILABLE:
      return {
        ...state,
        limitAvailable: action.payload.limitAvailable,
        tail: action.payload.tail,
      };

    case PAYMENT_ACTIONS.SESSION_EXPIRED:
      return {
        ...state,
        showModal: true,
        loading: false,
      };

    case PAYMENT_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
