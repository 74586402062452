import {
  validatePassword,
  validateAlphanumeric,
  validateCharacterSpecial,
  validateNumeric,
  validateBlanks,
} from "../../utils/Validators";
import RequestManager from "../../services/RequestManager";
import { getErrorMessage } from "../../utils/ConstHelper";

const base = "NEW_PASSWORD_ACTIONS_";
const requestManager = new RequestManager();

export const NEW_PASSWORD_ACTIONS = {
  //ON CHANGES ACTIONS
  ON_PASSWORD_CHANGE: base + "ON_PASSWORD_CHANGE",
  ON_PASSWORD_CONFIRM_CHANGE: base + "ON_PASSWORD_CONFIRM_CHANGE",

  //ON BLUR ACTIONS
  ON_PASSWORD_BLUR: base + "ON_PASSWORD_BLUR",
  ON_PASSWORD_CONFIRM_BLUR: base + "ON_PASSWORD_CONFIRM_BLUR",

  NEW_PASSWORD_START: base + "NEW_PASSWORD_START",
  NEW_PASSWORD_FAIL: base + "NEW_PASSWORD_FAIL",
  NEW_PASSWORD_SUCCESS: base + "NEW_PASSWORD_SUCCESS",

  DO_NOTHING: base + "DO_NOTHING",
};

export const onPasswordChange = (text: string) => {
  const validAlphanumeric = validateAlphanumeric(text);

  const validNumeric = validateNumeric(text);

  const validCharacterSpecial = validateCharacterSpecial(text);

  let validateLength = false;

  if (text.length >= 8 && text.length <= 15) {
    validateLength = true;
  }

  if (validateBlanks(text)) {
    return {
      type: NEW_PASSWORD_ACTIONS.DO_NOTHING,
    };
  } else {
    if (validatePassword(text)) {
      return {
        type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: true,
          checkAlphanumeric: true,
          checkSpecialCharacter: true,
          checkLength: true,
        },
      };
    } else {
      return {
        type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: false,
          checkAlphanumeric: validAlphanumeric && validNumeric,
          checkSpecialCharacter: validCharacterSpecial,
          checkLength: validateLength,
        },
      };
    }
  }
};

export const onPasswordConfirmChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: NEW_PASSWORD_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_CONFIRM_CHANGE,
      payload: text,
    };
  }
};

export const onPasswordBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { password } = state.newPassword;
    if (password !== "") {
      if (validatePassword(password)) {
        dispatch({
          type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: false, errorMessage: "Contraseña inválida" },
        });
      }
    } else {
      dispatch({
        type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onPasswordConfirmBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { passwordConfirmation, password } = state.newPassword;

    if (passwordConfirmation !== "") {
      if (passwordConfirmation === password) {
        dispatch({
          type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
          payload: { valid: true, errorMessage: "" },
        });
      } else {
        dispatch({
          type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
          payload: {
            valid: false,
            errorMessage: "La confirmación de contraseña no coincide",
          },
        });
      }
    } else {
      dispatch({
        type: NEW_PASSWORD_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const newPassword = (id: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { password } = state.newPassword;

    const data = {
      idConfirm: id,
      password,
    };

    requestManager
      .post("/users/newpassword", data)
      .then((response: any) => {
        dispatch({
          type: NEW_PASSWORD_ACTIONS.NEW_PASSWORD_SUCCESS,
          payload: { errorMessage: "" },
        });
        window.open("/login/forgot-password");
        window.close();
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({
          type: NEW_PASSWORD_ACTIONS.NEW_PASSWORD_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      });
  };
};
