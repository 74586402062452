import { BENEFICIARY_FORM_ACTIONS } from "../actions/BeneficiaryFormActions";
import {
  Country,
  AccountType,
  DocType,
  ACCOUNT_TYPES,
  Bank,
  CallingCodeVeType,
} from "../../utils/ConstHelper";

export type beneficiaryFormStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  activeCountries: [] as Country[],
  activeDocTypes: [] as DocType[],
  accountType: ACCOUNT_TYPES as AccountType[],
  availableBanks: [] as Bank[],

  checkOwn: false,
  checkThirdParty: true,
  checkBeneficiary: false,

  name: "",
  nameValid: undefined as undefined | boolean,
  nameError: "",

  lastName: "",
  lastNameValid: undefined as undefined | boolean,
  lastNameError: "",

  numberDocument: "",
  numberDocumentValid: undefined as undefined | boolean,
  numberDocumentError: "",

  selectedCountry: undefined as Country | undefined,
  selectedBank: undefined as Bank | undefined,
  selectedDocType: undefined as DocType | undefined,
  selectedAccountType: undefined as AccountType | undefined,
  selectedCallingCodeVe: undefined as CallingCodeVeType | undefined,

  accountNumber: "",
  accountNumberValid: undefined as undefined | boolean,
  accountNumberError: "",

  phoneNumber: "",
  phoneNumberValid: undefined as undefined | boolean,
  phoneNumberError: "",

  email: "",
  emailValid: undefined as undefined | boolean,
  emailError: "",

  error: "",
  loading: false,

  affiliatedAccounts: [],
  selectedBeneficiary: undefined as any,

  beneficiaryAccountId: "",
  showModal: false,
  recurrentBeneficiary: true,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case BENEFICIARY_FORM_ACTIONS.CLEAN_STATE:
      return {
        ...state,
        ...INITIAL_STATE,
        beneficiaryAccountId: state.beneficiaryAccountId,
      };

    case BENEFICIARY_FORM_ACTIONS.FETCH_COUNTRY_DATA:
      return {
        ...state,
        activeCountries: action.payload,
        activeDocTypes: action.payload[0].docTypes,
      };

    case BENEFICIARY_FORM_ACTIONS.FETCH_BANKS_DATA:
      return {
        ...state,
        availableBanks: action.payload,
      };
    case BENEFICIARY_FORM_ACTIONS.FETCH_USER_BENEFICIARY:
      return {
        ...state,
        affiliatedAccounts: action.payload,
      };
    case BENEFICIARY_FORM_ACTIONS.ON_NAME_CHANGE:
      return { ...state, name: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_LAST_NAME_CHANGE:
      return { ...state, lastName: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_NUMBER_DOCUMENT_CHANGE:
      return { ...state, numberDocument: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_NUMBER_CHANGE:
      return { ...state, accountNumber: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_PHONE_NUMBER_CHANGE:
      return { ...state, phoneNumber: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_EMAIL_CHANGE:
      return { ...state, email: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_NAME_BLUR:
      return {
        ...state,
        nameValid: action.payload.valid,
        nameError: action.payload.errorMessage,
      };

    case BENEFICIARY_FORM_ACTIONS.ON_LAST_NAME_BLUR:
      return {
        ...state,
        lastNameValid: action.payload.valid,
        lastNameError: action.payload.errorMessage,
      };

    case BENEFICIARY_FORM_ACTIONS.ON_NUMBER_DOCUMENT_BLUR:
      return {
        ...state,
        numberDocumentValid: action.payload.valid,
        numberDocumentError: action.payload.errorMessage,
      };
    case BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_NUMBER_BLUR:
      return {
        ...state,
        accountNumberValid: action.payload.valid,
        accountNumberError: action.payload.errorMessage,
      };

    case BENEFICIARY_FORM_ACTIONS.ON_PHONE_NUMBER_BLUR:
      return {
        ...state,
        phoneNumberValid: action.payload.valid,
        phoneNumberError: action.payload.errorMessage,
      };
    case BENEFICIARY_FORM_ACTIONS.ON_EMAIL_BLUR:
      return {
        ...state,
        emailValid: action.payload.valid,
        emailError: action.payload.errorMessage,
      };

    case BENEFICIARY_FORM_ACTIONS.ON_RADIO_BUTTON_CHANGE:
      return {
        ...state,
        checkOwn: action.payload.checkOwn,
        checkThirdParty: action.payload.checkThirdParty,
        checkBeneficiary: action.payload.checkBeneficiary,
      };

    case BENEFICIARY_FORM_ACTIONS.ON_DOC_TYPE_CHANGE:
      return { ...state, selectedDocType: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_ACCOUNT_TYPE_CHANGE:
      return { ...state, selectedAccountType: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_CALLING_CODE_CHANGE:
      return { ...state, selectedCallingCodeVe: action.payload };

    case BENEFICIARY_FORM_ACTIONS.ON_BANK_CHANGE:
      return { ...state, selectedBank: action.payload };

    case BENEFICIARY_FORM_ACTIONS.BENEFICIARY_FORM_START:
      return { ...state, loading: true };

    case BENEFICIARY_FORM_ACTIONS.BENEFICIARY_FORM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case BENEFICIARY_FORM_ACTIONS.BENEFICIARY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        recurrentBeneficiary: true,
        beneficiaryAccountId: action.payload,
      };

    case BENEFICIARY_FORM_ACTIONS.SET_SELECTED_BENEFICIARY:
      return {
        ...state,
        selectedBeneficiary: action.payload.selectedBeneficiary,
        beneficiaryAccountId: action.payload.beneficiaryAccountId,
        recurrentBeneficiary: false,
      };

    case BENEFICIARY_FORM_ACTIONS.SESSION_EXPIRED:
      return {
        ...state,
        loading: false,
        showModal: true,
      };

    case BENEFICIARY_FORM_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
