import axios from "axios";
import RequestManager from "../../services/RequestManager";
import { COUNTRY_OPTIONS, getErrorMessage } from "../../utils/ConstHelper";
const requestManager = new RequestManager();

const base = "SIMULATOR_ACTIONS_";

export const SIMULATOR_ACTIONS = {
  DO_NOTHING: base + "DO_NOTHING",
  CLEAN_STATE: base + "CLEAN_STATE",
  CLEAN_INPUTS: base + "CLEAN_INPUTS",
  GET_RATES: base + "GET_RATES",
  GET_AVAILABLE_COUNTRIES: base + "GET_AVAILABLE_COUNTRIES",
  SET_SAVING: base + "SET_SAVING",
  SET_SAVING_CURRENCY: base + "SET_SAVING_CURRENCY",
  SET_TOTAL: base + "SET_TOTAL",
  SET_TOTAL_CURRENCY: base + "SET_TOTAL_CURRENCY",
  SET_TYPE_OF_CHANGE: base + "SET_TYPE_OF_CHANGE",
  SHOW_DETAILS_BLOCK: base + "SHOW_DETAILS_BLOCK",
  ON_CHANGE_AMOUNT_TO_SEND: base + "ON_CHANGE_AMOUNT_TO_SEND",
  SET_AMOUNT_TO_SEND_CURRENCY: base + "SET_AMOUNT_TO_SEND_CURRENCY",
  ON_CHANGE_AMOUNT_TO_RECEIVE: base + "ON_CHANGE_AMOUNT_TO_RECEIVE",
  SET_AMOUNT_TO_RECEIVE_CURRENCY: base + "SET_AMOUNT_TO_RECEIVE_CURRENCY",
  SET_USER_LANDING_COUNTRY: base + "SET_USER_LANDING_COUNTRY",
  SET_MIN_MAX_AMOUNT: base + "SET_MIN_MAX_AMOUNT",
};

const compareCountryName = (nameA: any, nameB: any) => {
  const countryA = nameA.name.toUpperCase();
  const countryB = nameB.name.toUpperCase();

  let comparison = 0;
  if (countryA > countryB) {
    comparison = 1;
  } else if (countryA < countryB) {
    comparison = -1;
  }
  return comparison;
};

export const cleanSimulatorState = () => {
  return {
    type: SIMULATOR_ACTIONS.CLEAN_STATE,
  };
};

export const cleanSimulatorInputs = () => {
  return {
    type: SIMULATOR_ACTIONS.CLEAN_INPUTS,
  };
};

export const latinFormat = (value: number) => {
  const formatter = new Intl.NumberFormat("es-VE", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(value).substr(4);
};

export const convertToNumber = (value: number | string) => {
  const regex = /([^0-9])+/gi;
  const stringValue = value.toString().split(",");
  const valueToConvert = stringValue[0].replace(regex, "").replace(",", ".");
  const valueConverted = Number(valueToConvert);
  return Number.isNaN(valueConverted) ? 0 : valueConverted;
};

export const getAvailableCountries = () => {
  return (dispatch: any, getState: any) => {
    requestManager
      .get("/country?status=ACTIVE", {})
      .then((response: any) => {
        dispatch(getRates());

        const activeCountries = response.data.map((country: any) => {
          const flag = COUNTRY_OPTIONS.filter(
            (option) => country.iso === option.iso
          );
          return {
            ...country,
            flag: flag[0].flag,
            value: country.currency,
            label: country.currency,
          };
        });
        const availableCountries = activeCountries.filter(
          (country: any) => country.iso !== "VE"
        );
        availableCountries.sort(compareCountryName);
        dispatch({
          type: SIMULATOR_ACTIONS.GET_AVAILABLE_COUNTRIES,
          payload: availableCountries,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({ type: SIMULATOR_ACTIONS.DO_NOTHING, payload: errorMessage });
      });
  };
};

export const setInitialCountry = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { rates, availableCountries } = state.simulatorBox;
    axios
      .get(process.env.REACT_APP_GET_COUNTRY_SERVICE + "")
      .then((response: any) => {
        const country = response.data.pais;
        const originCountry = availableCountries.filter(
          (option: any) => option.iso === country.iso
        );

        let maxVES;
        let minVES;
        if (
          originCountry.length === 0 ||
          (originCountry.length > 0 && originCountry[0].iso === "VE")
        ) {
          const firstCountry = availableCountries.find(
            (cc: any) => cc.iso === "PE"
          );

          let minLocalCurrency;
          let maxLocalCurrency;

          if (firstCountry.exchange_rate_enable === "INACTIVE") {
            maxVES =
              firstCountry.exchange_rate_usd_destiny * firstCountry.max_amount;
            minVES =
              firstCountry.exchange_rate_usd_destiny * firstCountry.min_amount;

            minLocalCurrency = minVES / Number(firstCountry.exchange_rate);
            maxLocalCurrency = maxVES / Number(firstCountry.exchange_rate);
            dispatch({
              type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
              payload: {
                maxAmount: latinFormat(maxLocalCurrency),
                minAmount: latinFormat(minLocalCurrency),
              },
            });
            dispatch({
              type: SIMULATOR_ACTIONS.SET_USER_LANDING_COUNTRY,
              payload: {
                amountToSendCountry: firstCountry,
                amountToSendCurrency: firstCountry.currency,
              },
            });
            dispatch(
              setTypeOfChange(
                firstCountry.currency,
                firstCountry.exchange_rate ? firstCountry.exchange_rate : 1
              )
            );
          } else {
            maxVES = rates["USD"] * firstCountry.max_amount;
            minVES = rates["USD"] * firstCountry.min_amount;

            minLocalCurrency = minVES / rates[firstCountry.currency];
            maxLocalCurrency = maxVES / rates[firstCountry.currency];
            dispatch({
              type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
              payload: {
                maxAmount: latinFormat(
                  maxLocalCurrency * Number(firstCountry.rate)
                ),
                minAmount: latinFormat(
                  minLocalCurrency * Number(firstCountry.rate)
                ),
              },
            });
            dispatch({
              type: SIMULATOR_ACTIONS.SET_USER_LANDING_COUNTRY,
              payload: {
                amountToSendCountry: firstCountry,

                amountToSendCurrency: firstCountry.currency,
              },
            });
            dispatch(
              setTypeOfChange(
                firstCountry.currency,
                rates[firstCountry.currency] ? rates[firstCountry.currency] : 1
              )
            );
          }
        } else {
          let minLocalCurrency;
          let maxLocalCurrency;

          if (originCountry[0].exchange_rate_enable === "INACTIVE") {
            maxVES =
              originCountry[0].exchange_rate_usd_destiny *
              originCountry[0].max_amount;
            minVES =
              originCountry[0].exchange_rate_usd_destiny *
              originCountry[0].min_amount;

            minLocalCurrency = minVES / Number(originCountry[0].exchange_rate);
            maxLocalCurrency = maxVES / Number(originCountry[0].exchange_rate);
            dispatch({
              type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
              payload: {
                maxAmount: latinFormat(maxLocalCurrency),
                minAmount: latinFormat(minLocalCurrency),
              },
            });
            dispatch({
              type: SIMULATOR_ACTIONS.SET_USER_LANDING_COUNTRY,
              payload: {
                amountToSendCountry: originCountry[0],
                amountToSendCurrency: originCountry[0].currency,
              },
            });
            dispatch(
              setTypeOfChange(
                originCountry[0].currency,
                originCountry[0].exchange_rate
                  ? originCountry[0].exchange_rate
                  : 1
              )
            );
          } else {
            maxVES = rates["USD"] * originCountry[0].max_amount;
            minVES = rates["USD"] * originCountry[0].min_amount;
            minLocalCurrency = minVES / rates[originCountry[0].currency];
            maxLocalCurrency = maxVES / rates[originCountry[0].currency];
            dispatch({
              type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
              payload: {
                maxAmount: latinFormat(
                  maxLocalCurrency * Number(originCountry[0].rate)
                ),
                minAmount: latinFormat(
                  minLocalCurrency * Number(originCountry[0].rate)
                ),
              },
            });
            dispatch({
              type: SIMULATOR_ACTIONS.SET_USER_LANDING_COUNTRY,
              payload: {
                amountToSendCountry: originCountry[0],
                amountToSendCurrency: originCountry[0].currency,
              },
            });
            dispatch(
              setTypeOfChange(
                originCountry[0].currency,
                rates[originCountry[0].currency]
                  ? rates[originCountry[0].currency]
                  : 1
              )
            );
          }
        }
      });
  };
};

//LOOK AND FEEL
export const showDetails = (value: boolean) => {
  return {
    type: SIMULATOR_ACTIONS.SHOW_DETAILS_BLOCK,
    payload: value,
  };
};

//AMOUNT TO SEND
export const onSendValueChange = (value: number | string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const {
      rates,
      amountToSendCurrency,
      amountToSendCountry,
    } = state.simulatorBox;
    if (value) {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: convertToNumber(value),
      });
      if (amountToSendCountry.exchange_rate_enable === "INACTIVE") {
        dispatch({
          type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
          payload: latinFormat(
            convertToNumber(value) * Number(amountToSendCountry.exchange_rate)
          ),
        });
      } else {
        dispatch({
          type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
          payload: latinFormat(
            convertToNumber(value) *
              rates[amountToSendCurrency] *
              Number(amountToSendCountry.rate)
          ),
        });
      }

      dispatch(setSaving(convertToNumber(value)));
      dispatch(setSavingCurrency(amountToSendCurrency));
    } else {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: "",
      });
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: "",
      });
      dispatch(setSaving(0));
      dispatch(setSavingCurrency(amountToSendCurrency));
    }
  };
};

export const setAmountToSendCurrency = (value: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { rates, amountToSend, availableCountries } = state.simulatorBox;
    const country = availableCountries.filter(
      (option: any) => option.currency === value
    );
    let maxVES;
    let minVES;

    let minLocalCurrency;
    let maxLocalCurrency;
    if (country[0].exchange_rate_enable === "INACTIVE") {
      maxVES = country[0].exchange_rate_usd_destiny * country[0].max_amount;
      minVES = country[0].exchange_rate_usd_destiny * country[0].min_amount;
      minLocalCurrency = minVES / Number(country[0].exchange_rate);
      maxLocalCurrency = maxVES / Number(country[0].exchange_rate);
      dispatch({
        type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
        payload: {
          maxAmount: latinFormat(maxLocalCurrency),
          minAmount: latinFormat(minLocalCurrency),
        },
      });
      dispatch({
        type: SIMULATOR_ACTIONS.SET_AMOUNT_TO_SEND_CURRENCY,
        payload: {
          amountToSendCurrency: value,
          amountToSendCountry: country[0],
        },
      });

      dispatch(setTypeOfChange(value, country[0].exchange_rate));
      if (amountToSend) {
        dispatch(setSaving(convertToNumber(amountToSend)));
        dispatch(setSavingCurrency(value));
        dispatch({
          type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
          payload: latinFormat(
            convertToNumber(amountToSend) * Number(country[0].exchange_rate)
          ),
        });
      } else {
        dispatch(setSaving(convertToNumber(0)));
        dispatch(setSavingCurrency(value));
      }
    } else {
      maxVES = rates["USD"] * country[0].max_amount;
      minVES = rates["USD"] * country[0].min_amount;
      minLocalCurrency = minVES / rates[country[0].currency];
      maxLocalCurrency = maxVES / rates[country[0].currency];
      dispatch({
        type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
        payload: {
          maxAmount: latinFormat(maxLocalCurrency * Number(country[0].rate)),
          minAmount: latinFormat(minLocalCurrency * Number(country[0].rate)),
        },
      });
      dispatch({
        type: SIMULATOR_ACTIONS.SET_AMOUNT_TO_SEND_CURRENCY,
        payload: {
          amountToSendCurrency: value,
          amountToSendCountry: country[0],
        },
      });

      dispatch(setTypeOfChange(value, rates[value]));
      if (amountToSend) {
        dispatch(setSaving(convertToNumber(amountToSend)));
        dispatch(setSavingCurrency(value));
        dispatch({
          type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
          payload: latinFormat(
            convertToNumber(amountToSend) *
              rates[value] *
              Number(country[0].rate)
          ),
        });
      } else {
        dispatch(setSaving(convertToNumber(0)));
        dispatch(setSavingCurrency(value));
      }
    }
  };
};

export const onSendValueBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { amountToSend } = state.simulatorBox;
    if (amountToSend) {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: latinFormat(convertToNumber(amountToSend)),
      });
    } else {
      dispatch({
        type: SIMULATOR_ACTIONS.DO_NOTHING,
      });
    }
  };
};

//AMOUNT TO RECEIVE
export const onReceiveValueChange = (value: number | string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const {
      rates,
      amountToSendCurrency,
      amountToSend,
      amountToSendCountry,
    } = state.simulatorBox;
    if (value) {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: convertToNumber(value),
      });
      if (amountToSendCountry.exchange_rate_enable === "INACTIVE") {
        dispatch({
          type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
          payload: latinFormat(
            convertToNumber(value) / amountToSendCountry.exchange_rate
          ),
        });
      } else {
        dispatch({
          type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
          payload: latinFormat(
            convertToNumber(value) /
              (rates[amountToSendCurrency] * Number(amountToSendCountry.rate))
          ),
        });
      }

      dispatch(setSaving(convertToNumber(amountToSend)));
      dispatch(setSavingCurrency(amountToSendCurrency));
    } else {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: "",
      });

      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: "",
      });
      dispatch(setSaving(0));
      dispatch(setSavingCurrency(amountToSendCurrency));
    }
  };
};

export const setAmountToReceiveCurrency = (value: string) => {
  return {
    type: SIMULATOR_ACTIONS.SET_AMOUNT_TO_RECEIVE_CURRENCY,
    payload: value,
  };
};

export const onReceiveValueBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { amountToReceive } = state.simulatorBox;
    if (amountToReceive) {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: latinFormat(convertToNumber(amountToReceive)),
      });
    } else {
      dispatch({
        type: SIMULATOR_ACTIONS.DO_NOTHING,
      });
    }
  };
};

//SET TYPE OF CHANGE

const setTypeOfChange = (currency: string, rate: number) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { amountToSendCountry } = state.simulatorBox;
    let ves;
    if (amountToSendCountry.exchange_rate_enable === "INACTIVE") {
      ves = latinFormat(rate);
    } else {
      ves = latinFormat(rate * Number(amountToSendCountry.rate));
    }

    dispatch({
      type: SIMULATOR_ACTIONS.SET_TYPE_OF_CHANGE,
      payload: `1 ${currency} = ${ves} VES`,
    });
  };
};

//SET SAVING

const setSavingCurrency = (currency: string) => {
  return {
    type: SIMULATOR_ACTIONS.SET_SAVING_CURRENCY,
    payload: currency,
  };
};

const setSaving = (value: number) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { amountToSendCountry } = state.simulatorBox;
    dispatch({
      type: SIMULATOR_ACTIONS.SET_SAVING,
      payload: latinFormat(value * Number(amountToSendCountry.saving)),
    });
  };
};

// GET YADIO RATES
export const getRates = () => {
  return (dispatch: any) => {
    axios
      .get(process.env.REACT_APP_YADIO_ENDPOINT + "/json")
      .then((response: any) => {
        dispatch({
          type: SIMULATOR_ACTIONS.GET_RATES,
          payload: {
            rates: { ...response.data.rates, USD: response.data.USD.rate },
          },
        });
        dispatch(setInitialCountry());
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({ type: SIMULATOR_ACTIONS.DO_NOTHING, payload: errorMessage });
      });
  };
};
