import RequestManager from "../../services/RequestManager";
import {
  getErrorMessage,
  NON_ALLOWED_USER_TRANSACTION_STATUS,
} from "../../utils/ConstHelper";
import { latinFormat } from "./SimulatorActions";
import { handleUnauthorizedError } from "./UserActions";

const base = "HOME_ACTIONS_";
const requestManager = new RequestManager();

export const HOME_ACTIONS = {
  GET_TRANSACTION_HISTORY_START: base + "GET_TRANSACTION_HISTORY_START",
  GET_TRANSACTION_HISTORY_FAIL: base + "GET_TRANSACTION_HISTORY_FAIL",
  GET_TRANSACTION_HISTORY_SUCCESS: base + "GET_TRANSACTION_HISTORY_SUCCESS",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",
};

export const getUserTransactions = (limit: number, status?: string) => {
  return (dispatch: any, getState: any) => {
    const accessToken = localStorage.getItem("accessToken");
    dispatch({ type: HOME_ACTIONS.GET_TRANSACTION_HISTORY_START });
    requestManager
      .get(`/bank?status=ACTIVE`, {})
      .then((response: any) => {
        const availableBanks = response.data;
        let url = `/transaction/user?limit=${limit}`;
        if (status) {
          url = url + `&status=${status}`;
        }
        requestManager
          .get(url, {
            Authorization: `Bearer ${accessToken}`,
          })
          .then((response: any) => {
            const transactionsArray = response.data;
            const transactions = transactionsArray.map(
              (transaction: any, index: number) => {
                const cashierBank = availableBanks.filter(
                  (bank: any) => bank.id === transaction.cashier_account.bankId
                );

                return {
                  originAmount: latinFormat(transaction.origin_amount),
                  originCurrency: transaction.origin_currency,
                  destinationAmount: latinFormat(
                    transaction.destination_amount
                  ),
                  destinationCurrency: transaction.destination_currency,
                  celupagoReference: transaction.celupago_reference,
                  bankReference: transaction.bank_reference,
                  emissionDate: transaction.emission_date,
                  confirmationDate: transaction.confirmation_date,
                  usedRate: transaction.used_rate,
                  usedSaving: transaction.used_saving,
                  cryptoCurrency: transaction.crypto_currency,
                  cryptoAmount: transaction.crypto_amount,
                  exchangeType: transaction.exchange_type,
                  status:
                    transaction.status.name.charAt(0).toUpperCase() +
                    transaction.status.name.slice(1).toLowerCase(),
                  statusCode: transaction.status.code,
                  cashierId: transaction.cashier.celupagos_id,
                  cashierName: `${transaction.cashier.name} ${transaction.cashier.last_name}`,
                  cashierBank,
                  beneficiaryName: transaction.client_account.account_holder,
                  key: index + 1,
                };
              }
            );
            const transactionsFiltered = status
              ? transactions
              : transactions.filter(
                  (transaction: any) =>
                    transaction.statusCode !==
                      NON_ALLOWED_USER_TRANSACTION_STATUS.CE ||
                    transaction.statusCode !==
                      NON_ALLOWED_USER_TRANSACTION_STATUS.CN ||
                    transaction.statusCode !==
                      NON_ALLOWED_USER_TRANSACTION_STATUS.CR
                );
            dispatch({
              type: HOME_ACTIONS.GET_TRANSACTION_HISTORY_SUCCESS,
              payload: transactionsFiltered,
            });
          })
          .catch((error: any) => {
            const errorMessage = getErrorMessage(error);
            if (errorMessage.error === "UNAUTHORIZED") {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("loginDate");
              localStorage.removeItem("sessionExpiresIn");
              localStorage.removeItem("sessionTime");

              setTimeout(() => {
                dispatch(handleUnauthorizedError());
              }, 500);
            } else {
              dispatch({
                type: HOME_ACTIONS.GET_TRANSACTION_HISTORY_FAIL,
                payload: { errorMessage: errorMessage.message },
              });
            }
          });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);

        dispatch({
          type: HOME_ACTIONS.GET_TRANSACTION_HISTORY_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      });
  };
};
