import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import Logo from "../../../assets/img/logo/logo.png";
import UserRegular from "../../../assets/icons/userRegular.png";
import Chevron from "../../../assets/icons/chevron-down.png";
import AuthMenu from "./AuthMenu";
import Modal from "../../common/Modal";
import ExclamationCircle from "./../../../assets/icons/exclamation-circle.png";
import "./AuthHeader.css";

const defaultProps = {};
const defaultState = { showModalMenu: false, logOut: false };

type Props = RouteComponentProps<any> & {
  isVisible: boolean;
  toggleHeaderMenu: () => void;
  regards?: string;
  history: any;
} & typeof defaultProps;
type State = typeof defaultState;

class AuthHeader extends Component<Props, State> {
  state = defaultState;
  showModal = () => {
    const { showModalMenu } = this.state;
    const visible = showModalMenu;
    this.setState({ showModalMenu: !visible });
  };

  handleClose = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginDate");
    localStorage.removeItem("sessionExpiresIn");
    localStorage.removeItem("sessionTime");

    this.setState({ showModalMenu: false, logOut: true });
  };

  render() {
    const { showModalMenu, logOut } = this.state;
    const { isVisible, toggleHeaderMenu, regards } = this.props;
    const redirect = logOut ? <Redirect to="/" /> : <React.Fragment />;

    return (
      <React.Fragment>
        {redirect}
        <div className="auth-header-container">
          <div className="auth-header-logo-container">
            <img
              src={Logo}
              alt="celupago"
              className="auth-header-logo"
              onClick={() => this.props.history.push("/")}
            />
          </div>
          <div className="auth-header-menu-container">
            <div
              className="auth-header-menu-icon-container"
              onClick={toggleHeaderMenu}
            >
              {regards && (
                <div className="auth-header-regards">¡Hola {regards}!</div>
              )}
              <img
                src={UserRegular}
                alt="userRegular"
                className="user-regular"
              />
              <img src={Chevron} alt="chevron" className="chevron-arrow " />
            </div>
            <div className="auth-menu">
              {isVisible ? <AuthMenu showModal={this.showModal} /> : null}
            </div>
          </div>
          <Modal
            show={showModalMenu}
            title={"¿Estás seguro que deseas cerrar sesión?"}
            img={ExclamationCircle}
            body={""}
            buttonTextGoBack={"NO"}
            handleGoBack={this.showModal}
            handleClose={this.handleClose}
            buttonTextClose={"SI"}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AuthHeader);
