import React, { ReactElement } from "react";
const arrow = require("../../../assets/icons/chevron-down-dark.png");
type Props = {
  onDetailPress?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const DetailButton = ({ onDetailPress }: Props): ReactElement => {
  return (
    <div className="detail-button-container" onClick={onDetailPress}>
      <p>
        <strong>Ver detalle</strong>
      </p>
      <div className="arrow-container">
        <img src={arrow} className="down-arrow" alt="" />
      </div>
    </div>
  );
};

export default DetailButton;
