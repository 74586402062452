import React, { ReactElement } from "react";
import Eye from "../../assets/icons/eye.png";
import EyeSlash from "../../assets/icons/eye-slash.png";
import "./Common.css";

type Props = {
  styleName?: string;
  visible?: boolean;
  type: string;
  maxLength: number;
  minLength: number;
  value?: string;
  label: string;
  disable?: boolean;
  onClick?: () => void;
  onChangeValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

const Input = ({
  styleName,
  visible,
  type,
  maxLength,
  minLength,
  label,
  onClick,
  onChangeValue,
  onBlur,
  onFocus,
  value,
  disable,
}: Props): ReactElement => {
  return (
    <div>
      {type === "password" ? (
        <React.Fragment>
          <input
            disabled={disable}
            className={`common-input ${styleName}`}
            type={visible ? "text" : "password"}
            placeholder={label}
            onChange={onChangeValue}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            minLength={minLength}
            maxLength={maxLength}
          ></input>
          <img
            src={visible ? EyeSlash : Eye}
            alt="eye"
            className="eye-icon"
            onClick={onClick}
          />
        </React.Fragment>
      ) : (
        <input
          disabled={disable}
          className={`common-input ${styleName}`}
          type={type}
          placeholder={label}
          onChange={onChangeValue}
          onBlur={onBlur}
          value={value}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete="new-password"
        ></input>
      )}
    </div>
  );
};

export default Input;
