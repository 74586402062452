import React, { ReactElement } from "react";
import "./Payment.css";
type Props = {
  label: string;
  value: string;
};

const SummaryCashierLabel = ({ label, value }: Props): ReactElement => {
  return (
    <div className="list-payment">
      <p className="text-payment">{label}</p>
      <div className="summary-value-payment">
        <p className="text-payment text-bold-payment">{value}</p>
      </div>
    </div>
  );
};

export default SummaryCashierLabel;
