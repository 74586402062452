import React from "react";
import "./TermsAndConditions.css";

const logo = require("../../../assets/img/logo/logo.png");

const LegalPermission = () => {
  return (
    <div className={"main-container-terms"}>
      <div className="reticula-terms">
        <div className="logo-container-terms">
          <a target="_blank" href="/">
            <img src={logo} alt="celupago" className="logo-terms" />
          </a>
        </div>
        <div className="text-container-terms">
          <div className="head-title-container-terms"></div>

          <div className="terms-container">
            <p className="sub-title-terms">POLÍTICA DE PRIVACIDAD</p>
            <p className="terms-text ">
              Las siguientes{" "}
              <span className="terms-text-bold">políticas de privacidad</span>{" "}
              (las "
              <span className="terms-text-bold">Políticas de Privacidad</span>")
              entrarán en vigor desde su aceptación para los nuevos{" "}
              <span className="terms-text-bold">Clientes</span> y/o{" "}
              <span className="terms-text-bold">Usuarios</span>. Este documento
              es parte integrante del Acuerdo de Servicio ({" "}
              <span className="terms-text-bold">Términos de uso</span>).
              Mediante la aceptación de los{" "}
              <span className="terms-text-bold">Términos de Uso</span>, en el
              momento de la inscripción el{" "}
              <span className="terms-text-bold">Cliente</span> y/o{" "}
              <span className="terms-text-bold">Usuario</span> acepta las
              políticas aquí contenidas.
            </p>

            <p className="sub-title-terms">LA INFORMACIÓN QUE RECABAMOS</p>
            <p className="terms-text ">
              Para disfrutar de los servicios que ofrece{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, el{" "}
              <span className="terms-text-bold">Cliente</span> y/o{" "}
              <span className="terms-text-bold">Usuario</span> debe suministrar
              ciertos datos personales ("
              <span className="terms-text-bold">Información Personal</span>")
              completos y exactos.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> podrá solicitar,
              recabar y almacenar la siguiente Información Personal: Nombre
              completo, imagen personal, número de documento o identificación
              válida, número de teléfono, dirección de correo electrónico,
              etc.). Cuando el <span className="terms-text-bold">Usuario</span>{" "}
              navega en nuestro sitio web, se recibe de manera automática la
              dirección de protocolo de internet de tu computadora (
              <span className="terms-text-bold">IP</span>) con el fin de
              proporcionarnos información que nos ayuda a conocer acerca de su
              navegador y sistema operativo.{" "}
              <span className="terms-text-bold">Email marketing</span> (
              <span className="terms-text-bold">if applicable</span>): Con el
              permiso del <span className="terms-text-bold">Usuario</span>,
              podremos enviarles correos electrónicos acerca de nuestros
              servicios, nuevos productos y otras actualizaciones.
            </p>

            <p className="sub-title-terms">
              CONSENTIMIENTO DEL{" "}
              <span className="terms-text-bold">USUARIO</span>
            </p>
            <p className="terms-text ">
              Los <span className="terms-text-bold">Usuarios</span> garantizan y
              responden en cualquier caso, de la veracidad, exactitud, vigencia
              y autenticidad de la Información Personal facilitada, y se
              comprometen a mantenerla debidamente actualizada. Cuando El{" "}
              <span className="terms-text-bold">Usuario</span> nos provee su
              información personal para completar una solicitud de venta o
              compra a través de la{" "}
              <span className="terms-text-bold">Aplicación Web/Móvil</span>,
              entendemos que aceptas la recolección y uso de tus datos
              personales. Si luego de que el{" "}
              <span className="terms-text-bold">Usuario</span> no desea
              suministrar más datos con relación a sus datos personales, podrá
              anular su consentimiento y será contactado por nosotros, por la
              recolección de sus datos personales, uso o divulgación de
              información. <span className="terms-text-bold">EKIIPAGO</span>{" "}
              validará con el <span className="terms-text-bold">Usuario</span>{" "}
              su deseo de culminar la relación con el servicio ofrecido por{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, en cualquier
              momento, contactando a{" "}
              <a href="mailto:soporte@ekiipago.com"> soporte@ekiipago.com</a>.
            </p>
            <p className="sub-title-terms">CONFIDENCIALIDAD</p>
            <p className="terms-text ">
              Una vez inscrito en nuestro sitio Web,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no venderá,
              alquilará o compartirá la Información Personal, excepto en las
              formas establecidas en estas políticas. Sin perjuicio de ello, el{" "}
              <span className="terms-text-bold">Usuario</span> consiente y en
              forma expresa que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> transfiera total
              o parcialmente la Información Personal a cualquiera de las
              sociedades controladas, controlantes y/o vinculadas con{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, a cualquier
              título y en el momento, forma y condiciones que estime
              pertinentes. Haremos todo lo que esté a nuestro alcance para
              proteger la privacidad de la información. Puede suceder que, en
              virtud de órdenes judiciales, o de regulaciones legales, nos
              veamos en la obligación de revelar información a las autoridades o
              terceras partes bajo ciertas circunstancias, o bien en casos que
              terceras partes puedan interceptar o acceder a cierta información
              o transmisiones de datos en cuyo caso{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no responderá
              por la información que sea revelada.
            </p>
            <p className="sub-title-terms">MENORES DE EDAD</p>
            <p className="terms-text ">
              <span className="terms-text-bold">EKIIPAGO</span> en las
              siguientes Políticas deja muy en claro que nuestros servicios solo
              están disponibles para aquellas personas que tengan capacidad
              legal para contratar. Por lo tanto, aquellos que no cumplan con
              esta condición deberán abstenerse de suministrar información
              personal para ser incluida en nuestras bases de datos. Sin
              embargo, pueden hacerlo a través de su Representante, Padres o
              Tutores.
            </p>
            <p className="sub-title-terms">SERVICIOS DE TERCEROS</p>
            <p className="terms-text ">
              En general, los proveedores de terceras partes utilizados por
              nosotros solo recopilarán, usarán y divulgarán su información en
              la medida que sea necesaria para que les permita desempeñar los
              servicios que nos proveen. Sin embargo, algunos proveedores de
              servicios de terceros, tales como pasarelas de pago y otros
              procesadores de transacciones de pago, tienen sus propias{" "}
              <span className="terms-text-bold">políticas de privacidad</span>{" "}
              con respecto a la información que estamos obligados a
              proporcionarles para las transacciones relacionadas con las
              solicitudes. Para estos proveedores, le recomendamos que lea las{" "}
              <span className="terms-text-bold">políticas de privacidad</span>{" "}
              para que pueda entender la manera en que su información personal
              será manejada. Al hacer clic en enlaces de nuestra{" "}
              <span className="terms-text-bold">Aplicación web/móvil</span>,
              puede que sea redirigido fuera de nuestro Sitio Web. No somos
              reponsables por las prácticas de privacidad de otros sitios y le
              recomendamos leer sus normas de privacidad.
            </p>
            <p className="sub-title-terms">SEGURIDAD</p>
            <p className="terms-text ">
              Para proteger su información personal, se tomarán precauciones
              razonables y seguiremos las mejores prácticas de la industria para
              asegurarnos de que no haya pérdida de manera inapropiada, mal uso,
              acceso, divulgación, alteración o destrucción de la misma. SI nos
              proporcionas la información personal, está es encriptada mediante
              la{" "}
              <span className="terms-text-bold">
                tecnología Secure Socket Layer (SSL)
              </span>{" "}
              y se almacena con un cifrado{" "}
              <span className="terms-text-bold">AES-256</span>. Aunque ningún
              método de transmisión a través de Internet o de almacenamiento
              electrónico es 100% seguro, seguimos todos los requisitos de{" "}
              <span className="terms-text-bold">PCI-DSS</span> e implementamos
              normas adicionales aceptadas por la industria.
            </p>
            <p className="sub-title-terms">COOKIES</p>
            <p className="terms-text ">
              El <span className="terms-text-bold">Usuario</span> del sitio Web
              conoce y acepta que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> podrá utilizar
              un sistema de seguimiento mediante la utilización de cookies (las
              " <span className="terms-text-bold">Cookies</span>"). Las{" "}
              <span className="terms-text-bold">Cookies</span> son pequeños
              archivos que se instalan en el disco rígido, con una duración
              limitada en el tiempo que ayudan a personalizar los servicios.
              También ofrecemos ciertas funcionalidades que sólo están
              disponibles mediante el empleo de{" "}
              <span className="terms-text-bold">Cookies</span>. Las{" "}
              <span className="terms-text-bold">Cookies</span> se utilizan con
              el fin de conocer los intereses, el comportamiento y la demografía
              de quienes visitan o son usuarios de nuestro Sitio Web y de esa
              forma, comprender mejor sus necesidades e intereses y darles un
              mejor servicio o proveerle información relacionada. También
              usaremos la información obtenida por intermedio de las{" "}
              <span className="terms-text-bold">Cookies</span> para analizar las
              páginas navegadas por el visitante o{" "}
              <span className="terms-text-bold">usuario</span>, las búsquedas
              realizadas, mejorar nuestras iniciativas comerciales y
              promocionales, mostrar publicidad o promociones, banners de
              interés, noticias sobre{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, perfeccionar
              nuestra oferta de contenido y artículos; también podremos utilizar{" "}
              <span className="terms-text-bold">Cookies</span> para promover y
              hacer cumplir las reglas y seguridad del Sitio Web. Utilizamos
              adicionalmente las{" "}
              <span className="terms-text-bold">Cookies</span> para que el{" "}
              <span className="terms-text-bold">Usuario</span> no tenga que
              introducir su clave tan frecuentemente durante una sesión de
              navegación, también para contabilizar y corroborar las
              inscripciones, la actividad del{" "}
              <span className="terms-text-bold">Usuario</span> y otros conceptos
              para acuerdos comerciales. Se aclara expresamente que estas
              políticas cubre la utilización de{" "}
              <span className="terms-text-bold">Cookies</span> por este sitio y
              no la utilización de{" "}
              <span className="terms-text-bold">Cookies</span> por parte de
              anunciantes. Nosotros no controlamos el uso de{" "}
              <span className="terms-text-bold">Cookies</span> por terceros.
            </p>
            <p className="sub-title-terms">SPAM</p>
            <p className="terms-text ">
              <span className="terms-text-bold">EKIIPAGO</span> no acepta
              conductas consideradas "
              <span className="terms-text-bold">spamming</span>", ya sea en
              opiniones, preguntas, respuestas y/o el envío no solicitado de
              correos electrónicos. Queda absolutamente prohibido el envío
              indiscriminado de mensajes de cualquier naturaleza entre los{" "}
              <span className="terms-text-bold">Usuarios</span> y{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> podrá suspender
              o inhabilitar definitivamente a aquellos{" "}
              <span className="terms-text-bold">Usuarios</span> que violen esta
              política.
            </p>
            <p className="sub-title-terms">
              CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">EKIIPAGO</span> tendrá potestad
              absoluta de modificar esta política de privacidad en cualquier
              momento, le recomendamos revisar estas políticas frecuentemente.
              Si hacemos cambios materiales a esta política, usted sera
              notificado de la actualización de estas políticas, por lo que
              usted estará al tanto. Si nuestra tienda es adquirida o fusionada
              con otra empresa, tu información puede ser transferida a los
              nuevos propietarios, para que podamos seguir vendiéndo nuestros
              productos.
            </p>
            <p className="sub-title-terms">
              PREGUNTAS E INFORMACIÓN DE CONTACTO
            </p>
            <p className="terms-text ">
              Si usted desea acceder, corregir, enmendar o borrar cualquier
              información personal que poseamos sobre usted, registrar una
              queja, o solicitar información, usted deberá contactar a nuestro
              Oficial de Cumplimiento de Privacidad a través de
              <a href="mailto:soporte@ekiipago.com"> soporte@ekiipago.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalPermission;
