import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Logo from "../../../../assets/img/logo/logoDark.png";
import SingOut from "../../../../assets/icons/sign-out.png";
import Modal from "../../../common/Modal";
import ExclamationCircle from "./../../../../assets/icons/exclamation-circle.png";
import { globalStateType } from "../../../../ducks/reducers";
import { cashierLogOut } from "../../../../ducks/actions/cashier/LoginCashierActions";
import "./AuthCashierHeader.css";

const defaultProps = {};
const defaultState = { showModal: false };

type Props = RouteComponentProps<any> & {
  cashierLogOut: () => void;
  logOut: boolean;
  history: any;
} & typeof defaultProps;
type State = typeof defaultState;

class AuthCashierHeader extends Component<Props, State> {
  state = defaultState;

  showModal = () => {
    const { showModal } = this.state;
    const visible = showModal;
    this.setState({ showModal: !visible });
  };

  handleClose = () => {
    const { cashierLogOut } = this.props;
    cashierLogOut();
    this.setState({ showModal: false });
  };

  render() {
    const { logOut } = this.props;
    const { showModal } = this.state;
    const redirect = logOut ? <Redirect to="/" /> : <React.Fragment />;
    return (
      <div className="header-cashier-container">
        <div className="header-cashier-left-container">{redirect}</div>
        <div className="header-cashier-logo-container">
          <img
            src={Logo}
            alt="celupago"
            className="header-cashier-logo"
            onClick={() => this.props.history.push("/")}
          />
        </div>
        <div className="header-cashier-exit-icon-container">
          <img
            src={SingOut}
            alt="singOut"
            className="exit-icon"
            onClick={this.showModal}
          />
        </div>
        <Modal
          show={showModal}
          title={"¿Estás seguro que deseas cerrar sesión?"}
          img={ExclamationCircle}
          body={""}
          buttonTextGoBack={"NO"}
          handleGoBack={this.showModal}
          handleClose={this.handleClose}
          buttonTextClose={"SI"}
        />
      </div>
    );
  }
}
const mapStateTopProps = (state: globalStateType) => {
  return { logOut: state.loginCashier.logOut };
};
export default withRouter(
  connect(mapStateTopProps, {
    cashierLogOut,
  })(AuthCashierHeader)
);
