import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ExclamationCircle from "./../../../assets/icons/exclamation-circle.png";
import Question from "./../../../assets/icons/question.png";
import CapsuleOne from "./../../../assets/icons/capsuleOne.png";
import Diary from "./../../../assets/icons/diary.png";
import Dropdown from "../../common/Dropdown";
import Input from "../../common/Input";
import Button from "../../common/Button";
import Loader from "../../common/Loader";
import Modal from "../../common/Modal";
import SummaryCashierLabel from "./SummaryCashierLabel";
import { globalStateType } from "../../../ducks/reducers";
import {
  onReferenceNumberBlur,
  onReferenceNumberChange,
  getBanks,
  getCashiersOnline,
  onBankChange,
  onCashierOnlineChange,
  generateTransaction,
  getLimitAvailable,
  onLimitAvailableChange,
} from "../../../ducks/actions/PaymentActions";
import { cleanBeneficiaryFormState } from "../../../ducks/actions/BeneficiaryFormActions";
import {
  latinFormat,
  convertToNumber,
} from "../../../ducks/actions/SimulatorActions";
import {
  Bank,
  ACCOUNT_TYPES,
  AccountCashier,
  randomNumber,
} from "../../../utils/ConstHelper";
import "./Payment.css";

const defaultState = { logOut: false, showBanner: false };

type Props = {
  selectedBank?: Bank;
  selectedCashierOnline?: AccountCashier;
  referenceNumber: string;
  referenceNumberValid?: boolean;
  referenceNumberError: string;
  onReferenceNumberChange: (text: string) => void;
  onReferenceNumberBlur: () => void;
  loading: boolean;
  availableCashiersOnline: AccountCashier[];
  stepsNavigation: () => void;
  navigationHome: () => void;
  amountToReceiveCurrency?: string;
  amountToSend?: number | string;
  amountToReceive?: number | string;
  amountToSendCurrency?: string;
  getBanks: () => void;
  getCashiersOnline: (value: Bank) => void;
  onBankChange: (value: Bank) => void;
  onCashierOnlineChange: (value: AccountCashier) => void;
  availableBanks: Bank[];
  cleanBeneficiaryFormState: () => void;
  generateTransaction: (
    navigate: () => void,
    rateCashier?: string,
    amountForCashier?: string
  ) => void;
  getLimitAvailable: (value: AccountCashier) => void;
  limitAvailable: boolean;
  onLimitAvailableChange: (value: Boolean) => void;
  showModal: boolean;
  recurrentBeneficiary: boolean;
  tail: boolean;
};

type State = typeof defaultState;

class Payment extends Component<Props, State> {
  state = defaultState;

  componentDidMount() {
    const { getBanks, recurrentBeneficiary } = this.props;
    this.setState({ showBanner: recurrentBeneficiary });
    getBanks();
    setTimeout(this.hideBanner, 5000);
  }

  hideBanner = () => {
    this.setState({ showBanner: false });
  };

  hideModal = () => {
    const { onLimitAvailableChange, limitAvailable } = this.props;
    onLimitAvailableChange(limitAvailable);
  };

  hideGoBack = () => {
    const { onLimitAvailableChange, limitAvailable } = this.props;
    onLimitAvailableChange(limitAvailable);
    this.props.navigationHome();
  };

  handleBankChange = (value: Bank) => {
    const { onBankChange, getCashiersOnline } = this.props;
    onBankChange(value);
    getCashiersOnline(value);
  };

  handleCashierOnlineChange = (value: AccountCashier) => {
    const {
      onCashierOnlineChange,
      getLimitAvailable,
      availableCashiersOnline,
    } = this.props;

    if (value.id === 0) {
      if (availableCashiersOnline.length !== 1) {
        const availableCashiers = availableCashiersOnline.length - 1;
        const selectedCashier = randomNumber(1, availableCashiers);
        getLimitAvailable(availableCashiersOnline[selectedCashier]);
        onCashierOnlineChange(availableCashiersOnline[selectedCashier]);
      }
    } else {
      getLimitAvailable(value);
      onCashierOnlineChange(value);
    }
  };

  handleTypeCashier = (value: string) => {
    const type = ACCOUNT_TYPES.filter((type) => type.value === value);
    if (type) return type[0].name;
    return "";
  };

  handleOnButtonClick = (rateCashier: string, amountForCashier: string) => {
    const { generateTransaction, stepsNavigation } = this.props;
    generateTransaction(stepsNavigation, rateCashier, amountForCashier);
  };
  handleOnReferenceNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onReferenceNumberChange, onReferenceNumberBlur } = this.props;
    onReferenceNumberChange(event.target.value);
    onReferenceNumberBlur();
  };

  hideModalExpired = () => {
    this.setState({ logOut: true });
  };

  render() {
    const { logOut, showBanner } = this.state;

    const {
      selectedBank,
      selectedCashierOnline,
      referenceNumber,
      referenceNumberValid,
      referenceNumberError,
      loading,
      amountToSendCurrency,
      amountToReceiveCurrency,
      amountToReceive,
      amountToSend,
      availableBanks,
      availableCashiersOnline,
      limitAvailable,
      showModal,
      tail,
    } = this.props;

    const redirect = logOut ? <Redirect to="/" /> : <React.Fragment />;

    let rateCashier = "";
    let amountForCashier = "";
    if (amountToSendCurrency && selectedCashierOnline && amountToSend) {
      if (selectedCashierOnline.cashierType === "MASTER") {
        rateCashier = `1 ${amountToSendCurrency} = ${latinFormat(
          selectedCashierOnline.rate
        )} VES`;

        amountForCashier = latinFormat(
          convertToNumber(amountToSend) * selectedCashierOnline.rate
        );
      }
    }

    return (
      <div className="payment-container">
        {redirect}
        {loading ? <Loader /> : <React.Fragment />}
        <Modal
          show={showModal}
          title={"Sesión expirada"}
          img={ExclamationCircle}
          body={"Su sesión ha expirado"}
          handleClose={this.hideModalExpired}
        />
        {showBanner ? (
          <div className="banner">
            <div className="diary-container">
              <img src={Diary} alt="diary" className="diary" />
            </div>
            <p className="text-banner">
              El <span className="text-bold-banner">beneficiario</span> fue
              agregado automáticamente a tu lista de{" "}
              <span className="text-bold-banner">frecuentes</span>
            </p>
          </div>
        ) : (
          <React.Fragment />
        )}
        <div>
          <h2 className="title-payment">Pago</h2>
        </div>
        <div className="inputs-container-payment">
          <p className="subTitle-payment">
            <img src={CapsuleOne} alt="capsule" className="capsule-payment" />
            Selecciona el banco donde realizarás tu pago
          </p>
          <div className="container-row-payment">
            <p className="text-payment">Banco país origen</p>
            <div className="dropdown-container-payment ">
              <Dropdown
                large
                placeholder={"Banco país origen"}
                options={availableBanks}
                onValueChange={this.handleBankChange}
                selectedOption={selectedBank}
                valid={selectedBank ? true : false}
              />
            </div>
          </div>
          <div className="summary-container-payment">
            <SummaryCashierLabel
              label={"Monto"}
              value={`${amountToSend} ${amountToSendCurrency}`}
            />

            <SummaryCashierLabel
              label={"Monto a recibir"}
              value={
                selectedCashierOnline && amountToSend
                  ? selectedCashierOnline.cashierType === "MASTER"
                    ? `${amountForCashier} ${amountToReceiveCurrency}`
                    : `${amountToReceive} ${amountToReceiveCurrency}`
                  : `${amountToReceive} ${amountToReceiveCurrency}`
              }
            />
          </div>
          {selectedCashierOnline ? (
            <div className="summary-container-payment ">
              <p className="text-payment text-blue-payment">
                Datos del cajero asignado
              </p>
              <div className="summary-payment ">
                <SummaryCashierLabel
                  label={"ID"}
                  value={`${selectedCashierOnline.celupagosId}`}
                />
                <SummaryCashierLabel
                  label={"Nombre del titular"}
                  value={`${selectedCashierOnline.accountHolder}`}
                />
                <SummaryCashierLabel
                  label={"Documento de identidad"}
                  value={`${selectedCashierOnline.docTypeCode}- ${selectedCashierOnline.document}`}
                />
                <SummaryCashierLabel
                  label={"Nº de cuenta"}
                  value={`${selectedCashierOnline.accountNumber}`}
                />
                <SummaryCashierLabel
                  label={"Tipo de cuenta"}
                  value={this.handleTypeCashier(
                    selectedCashierOnline.accountType
                  )}
                />
                {selectedCashierOnline.cashierType === "MASTER" && (
                  <SummaryCashierLabel
                    label={"Tasa de cambio"}
                    value={rateCashier}
                  />
                )}
                <Modal
                  show={!limitAvailable}
                  title={
                    tail
                      ? "El cajero seleccionado está procesando una orden"
                      : "El cajero seleccionado no puede procesar el monto"
                  }
                  img={ExclamationCircle}
                  body={
                    tail
                      ? "Si deseas continuar deberás esperar unos minutos o elegir otro cajero activo."
                      : "Si deseas continuar deberás enviar otro monto o elegir otro cajero activo."
                  }
                  handleClose={this.hideModal}
                  buttonTextGoBack={"REGRESAR"}
                  handleGoBack={this.hideGoBack}
                  buttonTextClose={"CONTINUAR"}
                />
              </div>
            </div>
          ) : (
            <div className="container-row-payment">
              <div className="question-container-payment">
                <p className="text-payment text-blue-payment">
                  Elige tu cajero
                </p>
                <div className="tooltip-payment">
                  <p className="tooltiptext-payment">
                    -Selecciona{" "}
                    <span className="tooltiptext-bold-payment">
                      Asignarme un cajero
                    </span>{" "}
                    para realizar tu transacción.
                    <br />-
                    <span className="tooltiptext-bold-payment">
                      Ingresa un ID
                    </span>{" "}
                    para utilizar un{" "}
                    <span className="tooltiptext-bold-payment">
                      cajero referido.
                    </span>
                  </p>
                  <img
                    src={Question}
                    alt="question"
                    className="question-payment"
                  />
                </div>
              </div>

              <Dropdown
                large
                placeholder={"Ingresa nombre o ID Cajero"}
                valid={selectedCashierOnline ? true : false}
                options={availableCashiersOnline}
                onValueChange={this.handleCashierOnlineChange}
                selectedOption={selectedCashierOnline}
                isSearchable={true}
              />
            </div>
          )}
        </div>
        <div className="inputs-container-payment">
          <p className="subTitle-payment">
            <img
              src={CapsuleOne}
              alt="capsule"
              className="capsule-payment capsule-rotate-payment"
            />
            Ingresa la referencia de tu pago
          </p>
          <div className="container-row-payment">
            <div className="question-container-payment">
              <p className="text-payment">Nº de referencia</p>
              <div className="tooltip-payment">
                <p className="tooltiptext-payment">
                  <span className="tooltiptext-bold-payment">
                    Número de referencia
                  </span>{" "}
                  de la transferencia realizada en el{" "}
                  <span className="tooltiptext-bold-payment">
                    Banco País de Origen.
                  </span>
                </p>
                <img
                  src={Question}
                  alt="question"
                  className="question-payment"
                />
              </div>
            </div>

            <div className="input-container-payment">
              <Input
                styleName={
                  referenceNumberValid !== undefined
                    ? referenceNumberValid
                      ? "common-input-valid"
                      : "common-input-invalid"
                    : ""
                }
                type="text"
                maxLength={9}
                minLength={3}
                label="Ej. 000000000"
                onChangeValue={this.handleOnReferenceNumberChange}
                value={referenceNumber}
              />
              <div className="error-beneficiaryForm-container">
                <p className="error-text-beneficiaryForm-container">
                  {referenceNumberError}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="button-register">
          <div className={"payment-button-container"}>
            <Button
              buttonText={"REALIZAR ENVÍO"}
              onClick={() =>
                this.handleOnButtonClick(rateCashier, amountForCashier)
              }
              disabled={
                referenceNumberValid && selectedCashierOnline ? false : true
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    limitAvailable: state.payment.limitAvailable,
    selectedBank: state.payment.selectedBank,
    selectedCashierOnline: state.payment.selectedCashierOnline,
    referenceNumber: state.payment.referenceNumber,
    referenceNumberValid: state.payment.referenceNumberValid,
    referenceNumberError: state.payment.referenceNumberError,
    showModal: state.payment.showModal,
    availableBanks: state.payment.availableBanks,
    availableCashiersOnline: state.payment.availableCashiersOnline,
    loading: state.payment.loading,
    amountToSendCurrency: state.simulatorBox.amountToSendCurrency,
    amountToReceiveCurrency: state.simulatorBox.amountToReceiveCurrency,
    amountToReceive: state.simulatorBox.amountToReceive,
    amountToSend: state.simulatorBox.amountToSend,
    recurrentBeneficiary: state.beneficiaryForm.recurrentBeneficiary,
    tail: state.payment.tail,
  };
};

export default connect(mapStateTopProps, {
  onReferenceNumberBlur,
  onReferenceNumberChange,
  getBanks,
  onBankChange,
  getCashiersOnline,
  onCashierOnlineChange,
  cleanBeneficiaryFormState,
  generateTransaction,
  getLimitAvailable,
  onLimitAvailableChange,
})(Payment);
