import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { globalStateType } from "../../../ducks/reducers";
import Sidebar from "./sidebar/Sidebar";
import AuthCashierHeader from "./auth-cashier-header/AuthCashierHeader";
import MainHomeCashier from "./MainHomeCashier";
import ExclamationCircle from "./../../../assets/icons/exclamation-circle.png";
import Modal from "../../common/Modal";
import { SESSION_TIME_TO_ALERT } from "../../../utils/ConstHelper";
import {
  getCashierProfile,
  cleanCashierState,
} from "../../../ducks/actions/cashier/CashierActions";
import "./HomeCashierContainer.css";

const defaultProps = {};
const defaultState = {
  option: "Operations",
  logOut: false,
  alertTimeModal: false,
};

type Props = {
  getCashierProfile: () => void;
  name: string;
  cashierType: string;
  showModal: boolean;
  cleanCashierState: () => void;
} & typeof defaultProps;
type State = typeof defaultState;

let timer = null as any;

class HomeCashierContainer extends Component<Props, State> {
  state = defaultState;

  componentDidMount() {
    const { getCashierProfile } = this.props;
    const sessionExpiresIn = localStorage.getItem("sessionExpiresIn");
    const now = moment();
    const expDate = moment(moment.unix(Number(sessionExpiresIn)).format());
    const duration = moment.duration(expDate.diff(now));
    const secondsLeft = duration.asSeconds();
    const delay = (Number(secondsLeft) - SESSION_TIME_TO_ALERT) * 1000;
    timer = setTimeout(() => {
      this.setState({ alertTimeModal: true });
    }, delay);
    getCashierProfile();
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  selectOption = (option: string) => {
    this.setState({ option });
  };

  hideModal = () => {
    const { cleanCashierState } = this.props;
    this.setState({ logOut: true });
    cleanCashierState();
  };

  hideAlertTimeModal = () => {
    this.setState({ alertTimeModal: false });
  };

  render() {
    const { option, logOut, alertTimeModal } = this.state;
    const { name, cashierType, showModal } = this.props;

    const redirect = logOut ? <Redirect to="/" /> : <React.Fragment />;

    return (
      <div className="home-cashier-container">
        {redirect}
        <Modal
          show={showModal}
          title={"Sesión expirada"}
          img={ExclamationCircle}
          body={"Su sesión ha expirado"}
          handleClose={this.hideModal}
        />
        <Modal
          show={alertTimeModal}
          title={"Su sesión expira en 10 minutos"}
          img={ExclamationCircle}
          handleClose={this.hideAlertTimeModal}
        />
        <Sidebar
          name={name}
          cashierType={cashierType}
          selectOption={this.selectOption}
        />
        <div className="home-cashier-content-container">
          <AuthCashierHeader />
          <MainHomeCashier
            selectOption={this.selectOption}
            selectedOptions={option}
          />
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    name: state.cashier.name,
    cashierType: state.cashier.cashierType,
    showModal: state.cashier.showModal,
  };
};
export default withRouter(
  connect(mapStateTopProps, {
    getCashierProfile,
    cleanCashierState,
  })(HomeCashierContainer)
);
