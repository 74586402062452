import RequestManager from "../../services/RequestManager";
import { validateNumber } from "../../utils/Validators";
import { Bank, getErrorMessage, AccountCashier } from "../../utils/ConstHelper";

const base = "PAYMENT_ACTIONS_";
const requestManager = new RequestManager();

export const PAYMENT_ACTIONS = {
  FETCH_BANKS_DATA: base + "FETCH_BANKS_DATA",
  FETCH_CASHIERS_DATA: base + "FETCH_CASHIERS_DATA",
  FETCH_CASHIERS_ONLINE_DATA: base + "FETCH_CASHIERS_ONLINE_DATA",

  FETCH_CASHIERS_LIMIT_AVAILABLE: base + "FETCH_CASHIERS_LIMIT_AVAILABLE",
  ON_LIMIT_AVAILABLE_CHANGE: base + "ON_LIMIT_AVAILABLE_CHANGE",

  ON_BANK_CHANGE: base + "ON_BANK_CHANGE",
  ON_CASHIER_CHANGE: base + "ON_CASHIER_CHANGE",
  ON_CASHIER_ONLINE_CHANGE: base + "ON_CASHIER_ONLINE_CHANGE",
  ON_REFERENCE_NUMBER_CHANGE: base + "ON_REFERENCE_NUMBER_CHANGE",

  ON_REFERENCE_NUMBER_BLUR: base + "ON_REFERENCE_NUMBER_BLUR",

  PAYMENT_START: base + "PAYMENT_START",
  PAYMENT_FAIL: base + "PAYMENT_FAIL",
  PAYMENT_SUCCESS: base + "PAYMENT_SUCCESS",

  SESSION_EXPIRED: base + "SESSION_EXPIRED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",
};

export const cleanPaymentState = () => {
  return {
    type: PAYMENT_ACTIONS.CLEAN_STATE,
  };
};
export const getBanks = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { amountToSendCountry } = state.simulatorBox;
    requestManager
      .get(`/bank/${amountToSendCountry.id}/byCountry`, {})
      .then((response: any) => {
        const availableBanks = response.data.map((bank: any) => {
          return {
            id: bank.id,
            bankStatus: bank.bank_status,
            code: bank.code,
            commercialName: bank.commercial_name,
            countryId: bank.countryId,
            entityName: bank.entity_name,
            createdAt: bank.created_at,
            value: bank.id,
            label: `${bank.commercial_name} ${bank.entity_name}`,
          };
        });

        dispatch({
          type: PAYMENT_ACTIONS.FETCH_BANKS_DATA,
          payload: availableBanks,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: PAYMENT_ACTIONS.FETCH_BANKS_DATA,
          payload: [],
        });
      });
  };
};

export const onReferenceNumberChange = (text: string) => {
  if (validateNumber(text)) {
    return {
      type: PAYMENT_ACTIONS.ON_REFERENCE_NUMBER_CHANGE,
      payload: text,
    };
  } else {
    if (text === "") {
      return {
        type: PAYMENT_ACTIONS.ON_REFERENCE_NUMBER_CHANGE,
        payload: text,
      };
    } else {
      return {
        type: PAYMENT_ACTIONS.DO_NOTHING,
      };
    }
  }
};

export const onReferenceNumberBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { referenceNumber } = state.payment;

    if (referenceNumber !== "") {
      dispatch({
        type: PAYMENT_ACTIONS.ON_REFERENCE_NUMBER_BLUR,
        payload: { valid: true, errorMessage: "" },
      });
    } else {
      dispatch({
        type: PAYMENT_ACTIONS.ON_REFERENCE_NUMBER_BLUR,
        payload: {
          valid: false,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onBankChange = (value: Bank) => {
  return {
    type: PAYMENT_ACTIONS.ON_BANK_CHANGE,
    payload: value,
  };
};

export const getCashiersOnline = (value: Bank) => {
  return (dispatch: any) => {
    requestManager
      .get(
        `/cashier-bank-account/${value.id}/ByCashierOnline?status=ACTIVE`,
        {}
      )
      .then((response: any) => {
        const availableCashiersOnline = response.data.map(
          (cashierOnline: any) => {
            return {
              id: cashierOnline.id,
              accountHolder: cashierOnline.account_holder,
              document: cashierOnline.document,
              docType: cashierOnline.doc_type,
              docTypeCode: cashierOnline.cashier.docType.code,
              email: cashierOnline.email,
              guarantee: cashierOnline.cashier.guarantee,
              dailyLimit: cashierOnline.cashier.daily_limit,
              accountType: cashierOnline.account_type,
              accountNumber: cashierOnline.account_number,
              bankId: cashierOnline.bankId,
              cashierId: cashierOnline.cashierId,
              label: `${cashierOnline.cashier.celupagos_id} - ${cashierOnline.account_holder}`,
              celupagosId: cashierOnline.cashier.celupagos_id,
              cashierType: cashierOnline.cashier.cashier_type,
              rate: cashierOnline.cashier.rate,
            };
          }
        );
        if (availableCashiersOnline.length === 0) {
          availableCashiersOnline.push({
            label: "En este momento no hay cajeros disponibles",
            value: 0,
            id: 0,
            custom: false,
            notAvailable: true,
          });
        } else {
          availableCashiersOnline.push({
            label: "Asignarme un cajero",
            value: 0,
            id: 0,
            custom: true,
          });
        }

        dispatch({
          type: PAYMENT_ACTIONS.FETCH_CASHIERS_ONLINE_DATA,
          payload: availableCashiersOnline.sort((a: any, b: any) => {
            if (a.value > b.id) {
              return 1;
            }
            if (a.value < b.id) {
              return -1;
            }
            // a must be equal to b
            return 0;
          }),
        });
      })
      .catch((error: any) => {
        dispatch({
          type: PAYMENT_ACTIONS.FETCH_CASHIERS_ONLINE_DATA,
          payload: [],
        });
      });
  };
};

export const onCashierOnlineChange = (value: AccountCashier) => {
  return {
    type: PAYMENT_ACTIONS.ON_CASHIER_ONLINE_CHANGE,
    payload: value,
  };
};

export const onLimitAvailableChange = (value: Boolean) => {
  return {
    type: PAYMENT_ACTIONS.ON_LIMIT_AVAILABLE_CHANGE,
    payload: !value,
  };
};

export const getLimitAvailable = (value: AccountCashier) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const {
      amountToSend,
      rates,
      amountToSendCurrency,
      amountToSendCountry,
    } = state.simulatorBox;
    requestManager
      .get(`/transaction/${value.cashierId}/invoicedAmount`, {})
      .then((response: any) => {
        let dollar;
        if (amountToSendCountry.exchange_rate_enable === "INACTIVE") {
          dollar =
            amountToSendCountry.exchange_rate_usd_destiny /
            amountToSendCountry.exchange_rate;
        } else {
          dollar = rates["USD"] / rates[amountToSendCurrency];
        }

        const invoicedAmount = parseFloat(response.data.amountTransaction);
        const completedOperation = parseFloat(
          response.data.amountTransactionComplete
        );
        const limitAvailable = Number(value.dailyLimit) - invoicedAmount;

        const limitGuarantee = value.guarantee;

        const amount = parseFloat(amountToSend) / dollar;

        const blockByTail =
          amount > limitAvailable &&
          completedOperation < Number(value.dailyLimit) &&
          invoicedAmount !== completedOperation
            ? true
            : false;

        if (amount <= limitAvailable && amount <= limitGuarantee) {
          dispatch({
            type: PAYMENT_ACTIONS.FETCH_CASHIERS_LIMIT_AVAILABLE,
            payload: { limitAvailable: true, tail: false },
          });
        } else {
          dispatch({
            type: PAYMENT_ACTIONS.FETCH_CASHIERS_LIMIT_AVAILABLE,
            payload: { limitAvailable: false, tail: blockByTail },
          });
        }
      })
      .catch((error: any) => {
        dispatch({
          type: PAYMENT_ACTIONS.FETCH_CASHIERS_LIMIT_AVAILABLE,
          payload: { limitAvailable: false, tail: false },
        });
      });
  };
};

export const convertToFloat = (value: string) => {
  const regex = /([^0-9])+/gi;
  const stringValue = value.toString().split(",");
  const valueToConvert = `${stringValue[0]
    .replace(regex, "")
    .replace(",", ".")}.${stringValue[1]}`;
  return Number(valueToConvert);
};

export const generateTransaction = (
  navigate: () => void,
  rateCashier?: string,
  amountForCashier?: string
) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: PAYMENT_ACTIONS.PAYMENT_START,
    });
    const state = getState();
    const { beneficiaryAccountId } = state.beneficiaryForm;
    const {
      amountToSendCurrency,
      amountToReceiveCurrency,
      amountToReceive,
      amountToSend,
      amountToSendCountry,
      typeOfChange,
      rates,
    } = state.simulatorBox;

    const { referenceNumber, selectedCashierOnline } = state.payment;

    let transaction: any = {
      origin_amount: convertToFloat(amountToSend),
      origin_currency: amountToSendCurrency,
      destination_amount: convertToFloat(
        amountForCashier ? amountForCashier : amountToReceive
      ),
      destination_currency: amountToReceiveCurrency,
      bank_reference: referenceNumber,
      used_rate: amountToSendCountry.rate,
      used_saving: amountToSendCountry.saving,
      beneficiaryAccount: beneficiaryAccountId,
      cashier: selectedCashierOnline.cashierId,
      cashierAccount: selectedCashierOnline.id,
      exchange_type: rateCashier ? rateCashier : typeOfChange,
    };

    if (amountToSendCountry.exchange_rate_enable === "INACTIVE") {
      transaction.used_usd_destiny_rate =
        amountToSendCountry.exchange_rate_usd_destiny;
      transaction.used_origin_destiny_rate = amountToSendCountry.exchange_rate;
    } else {
      transaction.used_usd_destiny_rate = rates["USD"];
      transaction.used_origin_destiny_rate = rates[amountToSendCurrency];
    }

    const accessToken = localStorage.getItem("accessToken");

    requestManager
      .post(`/transaction`, transaction, {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        dispatch({
          type: PAYMENT_ACTIONS.PAYMENT_SUCCESS,
          payload: response.data.idTransaction,
        });
        navigate();
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);

        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: PAYMENT_ACTIONS.SESSION_EXPIRED,
              payload: "UNAUTHORIZED",
            });
          }, 500);
        } else {
          dispatch({
            type: PAYMENT_ACTIONS.PAYMENT_FAIL,
            payload: errorMessage.error,
          });
        }
      });
  };
};
