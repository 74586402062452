import React from "react";
import "./Common.css";

type Props = {
  cashier?: boolean;
};

const NoTransactionComponent = ({ cashier }: Props) => (
  <div className="no-transaction-container">
    <div
      className={
        cashier ? "no-transaction-gray-line" : "no-transaction-white-line"
      }
    >
      <span className={cashier?"no-transaction-text no-transaction-cashier":"no-transaction-text"}>
        {cashier ? "No hay resultados" : "No hay transacciones a listar"}
      </span>
    </div>
  </div>
);

export default NoTransactionComponent;
