import React, { Component } from "react";
import { connect } from "react-redux";
import {
  onEmailChange,
  resendEmail,
} from "../../../ducks/actions/RegisterActions";
import { globalStateType } from "../../../ducks/reducers";
import Logo from "../../../assets/img/logo/logo.png";
import "./CheckEmail.css";

type Props = {
  email: string;
  onEmailChange: (text: string) => void;
  resendEmail: () => void;
};

type State = typeof initialState;

const initialState = {
  email: "",
};

class CheckEmail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { onEmailChange } = this.props;
    const emailStorage = localStorage.getItem("checkEmail");

    if (emailStorage) {
      this.setState({ email: emailStorage });
      onEmailChange(emailStorage);
    } else {
      this.setState({ email: "tu correo" });
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("checkEmail");
  }

  resendEmail = () => {
    const { resendEmail } = this.props;
    resendEmail();
  };
  render() {
    const { email } = this.state;
    return (
      <div className="checkEmail-container">
        <div className="checkEmail-header">
          <a href="/">
            <img src={Logo} alt="celupago" className="checkEmail-logo" />
          </a>
        </div>
        <div className="title-shadow-container-checkEmail">
          <div className="title-container-checkEmail">
            <span className="title-checkEmail">¡Revisa tu email!</span>
          </div>
        </div>
        <div className="checkEmail-content-container">
          <p>Te llegó un correo de ekiipago</p>
          <p>
            Te enviamos a <span className="text-blue-checkEmail">{email} </span>{" "}
            los pasos para continuar con tu registro.
          </p>
          <p>
            Si aún no has recibido nuestro correo en tu bandeja de entrada,
            chequea tu carpeta de SPAM.
          </p>
          <p>
            ¿No recibiste el correo? Haz clic{" "}
            <span
              onClick={this.resendEmail}
              className="text-blue-checkEmail pointer-checkEmail"
            >
              aquí{" "}
            </span>{" "}
            para reenviar.
          </p>
        </div>
      </div>
    );
  }
}
const mapStateTopProps = (state: globalStateType) => {
  return {
    email: state.register.email,
  };
};
export default connect(mapStateTopProps, { onEmailChange, resendEmail })(
  CheckEmail
);
