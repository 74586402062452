import { NEW_PASSWORD_ACTIONS } from "../actions/NewPasswordActions";

export type newPasswordStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  password: "",
  passwordValid: undefined as undefined | boolean,
  passwordConfirmation: "",
  passwordConfirmationValid: undefined as undefined | boolean,
  checkSpecialCharacter: false,
  checkAlphanumeric: false,
  checkLength: false,
  passwordError: "",
  passwordConfirmationError: "",
  error: "",
  loading: false,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case NEW_PASSWORD_ACTIONS.ON_PASSWORD_CHANGE:
      return {
        ...state,
        password: action.payload.password,
        passwordValid: action.payload.valid,
        checkAlphanumeric: action.payload.checkAlphanumeric,
        checkSpecialCharacter: action.payload.checkSpecialCharacter,
        checkLength: action.payload.checkLength,
      };

    case NEW_PASSWORD_ACTIONS.ON_PASSWORD_CONFIRM_CHANGE:
      return { ...state, passwordConfirmation: action.payload };

    case NEW_PASSWORD_ACTIONS.ON_PASSWORD_BLUR:
      return {
        ...state,
        passwordValid: action.payload.valid,
        passwordError: action.payload.errorMessage,
      };

    case NEW_PASSWORD_ACTIONS.ON_PASSWORD_CONFIRM_BLUR:
      return {
        ...state,
        passwordConfirmationValid: action.payload.valid,
        passwordConfirmationError: action.payload.errorMessage,
      };

    case NEW_PASSWORD_ACTIONS.NEW_PASSWORD_START:
      return { ...state, loading: true };

    case NEW_PASSWORD_ACTIONS.NEW_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload.errorMessage,
        loading: false,
      };

    case NEW_PASSWORD_ACTIONS.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload.errorMessage,
      };

    case NEW_PASSWORD_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
