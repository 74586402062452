import React, { ReactElement } from "react";
import "./TransactionSummary.css";

type Props = {
  label: string;
  value: string;
};
const SummaryLabel = ({ label, value }: Props): ReactElement => {
  return (
    <div className="summary-row">
      <div className="summary-row-label-container">
        <p className="summary-label-text">{label}</p>
      </div>

      <div className="summary-row-value-container">
        <p className="summary-text">{value}</p>
      </div>
    </div>
  );
};

export default SummaryLabel;
