import axios from "axios";
import { getErrorMessage, calculateAge } from "../../../utils/ConstHelper";
import RequestManager from "../../../services/RequestManager";
import {
  validateAlphanumeric,
  validateNumeric,
  validateCharacterSpecial,
  validateBlanks,
  validatePassword,
  validateFloat,
} from "../../../utils/Validators";
import { latinFormat } from "../SimulatorActions";
import moment from "moment";

const base = "CASHIER_ACTIONS_";
const requestManager = new RequestManager();

export const CASHIER_ACTIONS = {
  FETCH_CASHIER_PROFILE_START: base + "FETCH_CASHIER_PROFILE_START",
  FETCH_CASHIER_PROFILE_FAIL: base + "FETCH_CASHIER_PROFILE_FAIL",
  FETCH_CASHIER_PROFILE_SUCCESS: base + "FETCH_CASHIER_PROFILE_SUCCESS",
  GET_CASHIER_AMOUNT_COMPLETE: base + "GET_CASHIER_AMOUNT_COMPLETE",

  EDIT_PROFILE_SUCCESS: base + "EDIT_PROFILE_SUCCESS",
  EDIT_OPERATION_SUCCESS: base + "EDIT_OPERATION_SUCCESS",
  EDIT_OPERATION_FAIL: base + "EDIT_OPERATION_FAIL",

  FETCH_CASHIER_TRANSACTIONS_START: base + "FETCH_CASHIER_TRANSACTIONS_START",
  FETCH_CASHIER_TRANSACTIONS_FAIL: base + "FETCH_CASHIER_TRANSACTIONS_FAIL",
  FETCH_CASHIER_TRANSACTIONS_SUCCESS:
    base + "FETCH_CASHIER_TRANSACTIONS_SUCCESS",

  //ON CHANGES ACTIONS
  ON_PASSWORD_CHANGE: base + "ON_PASSWORD_CHANGE",
  ON_NEW_PASSWORD_CHANGE: base + "ON_NEW_PASSWORD_CHANGE",
  ON_PASSWORD_CONFIRM_CHANGE: base + "ON_PASSWORD_CONFIRM_CHANGE",
  ON_NEW_RATE_CHANGE: base + "ON_NEW_RATE_CHANGE",
  ON_BIRTHDAY_CHANGE: base + "ON_BIRTHDAY_CHANGE",

  //ON BLUR ACTIONS
  ON_PASSWORD_BLUR: base + "ON_PASSWORD_BLUR",
  ON_NEW_PASSWORD_BLUR: base + "ON_NEW_PASSWORD_BLUR",
  ON_PASSWORD_CONFIRM_BLUR: base + "ON_PASSWORD_CONFIRM_BLUR",
  ON_NEW_RATE_BLUR: base + "ON_NEW_RATE_BLUR",

  GET_RATES: base + "GET_RATES",

  GET_CRYPTO_ACTIVES: base + "GET_CRYPTO_ACTIVES",

  CLEAN_STATE: base + "CLEAN_STATE",

  DO_NOTHING: base + "DO_NOTHING",
};

export const cleanCashierState = () => {
  return {
    type: CASHIER_ACTIONS.CLEAN_STATE,
  };
};

export const onBirthdayChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: CASHIER_ACTIONS.DO_NOTHING,
    };
  } else {
    if (calculateAge(text) >= 18 && calculateAge(text) <= 70) {
      return {
        type: CASHIER_ACTIONS.ON_BIRTHDAY_CHANGE,
        payload: {
          birthday: text,
          valid: false,
        },
      };
    } else {
      return {
        type: CASHIER_ACTIONS.ON_BIRTHDAY_CHANGE,
        payload: {
          birthday: text,
          valid: true,
          errorMessage: "Fecha inválida",
        },
      };
    }
  }
};

export const onPasswordChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: CASHIER_ACTIONS.DO_NOTHING,
    };
  } else {
    if (validatePassword(text)) {
      return {
        type: CASHIER_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: false,
        },
      };
    } else {
      return {
        type: CASHIER_ACTIONS.ON_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: true,
        },
      };
    }
  }
};

export const onPasswordBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { password } = state.cashier;

    if (password !== "") {
      if (validatePassword(password)) {
        dispatch({
          type: CASHIER_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: false, errorMessage: "" },
        });
      } else {
        dispatch({
          type: CASHIER_ACTIONS.ON_PASSWORD_BLUR,
          payload: { valid: true, errorMessage: "Contraseña inválida" },
        });
      }
    } else {
      dispatch({
        type: CASHIER_ACTIONS.ON_PASSWORD_BLUR,
        payload: {
          valid: true,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onNewPasswordChange = (text: string) => {
  const validAlphanumeric = validateAlphanumeric(text);
  const validNumeric = validateNumeric(text);
  const validCharacterSpecial = validateCharacterSpecial(text);

  let validateLength = false;

  if (text.length === 8) {
    validateLength = true;
  }

  if (validateBlanks(text)) {
    return {
      type: CASHIER_ACTIONS.DO_NOTHING,
    };
  } else {
    if (validatePassword(text)) {
      return {
        type: CASHIER_ACTIONS.ON_NEW_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: false,
          checkAlphanumeric: true,
          checkSpecialCharacter: true,
          checkLength: true,
        },
      };
    } else {
      return {
        type: CASHIER_ACTIONS.ON_NEW_PASSWORD_CHANGE,
        payload: {
          password: text,
          valid: true,
          checkAlphanumeric: validAlphanumeric && validNumeric,
          checkSpecialCharacter: validCharacterSpecial,
          checkLength: validateLength,
        },
      };
    }
  }
};

export const onNewPasswordBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { newPassword } = state.cashier;

    if (newPassword !== "") {
      if (validatePassword(newPassword)) {
        dispatch({
          type: CASHIER_ACTIONS.ON_NEW_PASSWORD_BLUR,
          payload: { valid: false, errorMessage: "" },
        });
      } else {
        dispatch({
          type: CASHIER_ACTIONS.ON_NEW_PASSWORD_BLUR,
          payload: { valid: true, errorMessage: "Contraseña inválida" },
        });
      }
    } else {
      dispatch({
        type: CASHIER_ACTIONS.ON_NEW_PASSWORD_BLUR,
        payload: {
          valid: true,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onPasswordConfirmChange = (text: string) => {
  if (validateBlanks(text)) {
    return {
      type: CASHIER_ACTIONS.DO_NOTHING,
    };
  } else {
    return {
      type: CASHIER_ACTIONS.ON_PASSWORD_CONFIRM_CHANGE,
      payload: text,
    };
  }
};

export const onPasswordConfirmBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { passwordConfirmation, newPassword } = state.cashier;

    if (passwordConfirmation !== "") {
      if (passwordConfirmation === newPassword) {
        dispatch({
          type: CASHIER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
          payload: { valid: false, errorMessage: "" },
        });
      } else {
        dispatch({
          type: CASHIER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
          payload: {
            valid: true,
            errorMessage: "Confirmar contraseña no coincide",
          },
        });
      }
    } else {
      dispatch({
        type: CASHIER_ACTIONS.ON_PASSWORD_CONFIRM_BLUR,
        payload: {
          valid: true,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const onNewRateChange = (text: string) => {
  return {
    type: CASHIER_ACTIONS.ON_NEW_RATE_CHANGE,
    payload: {
      rate: text,
      valid: false,
    },
  };
};

export const onNewRateBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { newRate } = state.cashier;
    if (newRate !== "") {
      if (validateFloat(newRate)) {
        dispatch({
          type: CASHIER_ACTIONS.ON_NEW_RATE_BLUR,
          payload: {
            valid: false,
            errorMessage: "",
          },
        });
      } else {
        dispatch({
          type: CASHIER_ACTIONS.ON_NEW_RATE_BLUR,
          payload: {
            valid: true,
            errorMessage: "Formato inválido 0.00",
          },
        });
      }
    } else {
      dispatch({
        type: CASHIER_ACTIONS.ON_NEW_RATE_BLUR,
        payload: {
          valid: true,
          errorMessage: "El campo no puede estar vacío",
        },
      });
    }
  };
};

export const getCashierProfile = () => {
  return (dispatch: any) => {
    const accessToken = localStorage.getItem("accessTokenCashier");
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_START });
    requestManager
      .get("/cashier/profile", {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        const {
          id,
          cashier_type,
          celupagos_id,
          docType,
          document,
          email,
          last_name,
          name,
          phone_number,
          phone_prefix,
          rate,
          birthday,
          guarantee,
        } = response.data;

        const CASHIER = {
          id,
          celupagos_id,
          rate,
          name,
          lastName: last_name,
          email,
          document,
          phoneNumber: phone_number,
          phonePrefix: phone_prefix,
          cashierType: cashier_type,
          docType,
          birthday,
          guarantee,
        };
        dispatch({
          type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_SUCCESS,
          payload: CASHIER,
        });
        dispatch(getLimitAvailable());
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error, "CASHIER");
        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessTokenCashier");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
              payload: { error: errorMessage.error, showModal: true },
            });
          }, 500);
        } else {
          dispatch({
            type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
            payload: { error: errorMessage.error, showModal: false },
          });
        }
      });
  };
};

export const changePasswordCashier = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { password, newPassword } = state.cashier;
    const accessToken = localStorage.getItem("accessTokenCashier");

    const data = {
      password,
      newPassword,
    };
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_START });
    requestManager
      .post("/cashier/changepassword", data, {
        Authorization: `Bearer ${accessToken}`,
      })
      .then((response: any) => {
        dispatch({
          type: CASHIER_ACTIONS.EDIT_PROFILE_SUCCESS,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error, "CASHIER");
        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessTokenCashier");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
              payload: { error: errorMessage.error, showModal: true },
            });
          }, 500);
        } else {
          alert(errorMessage.message);
          dispatch({
            type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
            payload: { error: errorMessage.error },
          });
        }
      });
  };
};

export const updateRateCashier = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { id, newRate } = state.cashier;

    const data = { rate: newRate };
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_START });
    requestManager
      .patch(`/cashier/${id}/rate`, data, {})
      .then((response: any) => {
        dispatch({
          type: CASHIER_ACTIONS.EDIT_PROFILE_SUCCESS,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error, "CASHIER");
        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessTokenCashier");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
              payload: { error: errorMessage.error, showModal: true },
            });
          }, 500);
        } else {
          alert(errorMessage.message);
          dispatch({
            type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
            payload: { error: errorMessage.error },
          });
        }
      });
  };
};

export const updateBirthdayCashier = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { id, newBirthday } = state.cashier;

    const data = { birthday: newBirthday };
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_START });
    requestManager
      .patch(`/cashier/${id}/birthday`, data, {})
      .then((response: any) => {
        dispatch({
          type: CASHIER_ACTIONS.EDIT_PROFILE_SUCCESS,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error, "CASHIER");
        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessTokenCashier");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
              payload: { error: errorMessage.error, showModal: true },
            });
          }, 500);
        } else {
          alert(errorMessage.message);
          dispatch({
            type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
            payload: { error: errorMessage.error },
          });
        }
      });
  };
};

export const getCashierTransactions = (
  status?: string,
  from?: string,
  to?: string
) => {
  return (dispatch: any, getState: any) => {
    const accessTokenCashier = localStorage.getItem("accessTokenCashier");
    dispatch({ type: CASHIER_ACTIONS.FETCH_CASHIER_TRANSACTIONS_START });
    requestManager
      .get(`/bank?status=ACTIVE`, {})
      .then((response: any) => {
        const availableBanks = response.data;
        let url = `/transaction/cashier?limit=40`;

        if (status) {
          url = url + `&status=${status}`;
        }

        if (from && !to) {
          const maxDate = moment().format().split("T")[0];
          url = url + `&from=${from}&to=${maxDate}`;
        } else {
          if (from) {
            url = url + `&from=${from}`;
          }
          if (to) {
            url = url + `&to=${to}`;
          }
        }

        requestManager
          .get(url, {
            Authorization: `Bearer ${accessTokenCashier}`,
          })
          .then((response: any) => {
            const transactionsArray = response.data;
            const transactions = transactionsArray.map(
              (transaction: any, index: number) => {
                const cashierBank = availableBanks.filter(
                  (bank: any) => bank.id === transaction.cashier_account.bankId
                );
                return {
                  originAmount: latinFormat(transaction.origin_amount),
                  originCurrency: transaction.origin_currency,
                  destinationAmount: latinFormat(
                    transaction.destination_amount
                  ),
                  destinationCurrency: transaction.destination_currency,
                  celupagoReference: transaction.celupago_reference,
                  bankReference: transaction.bank_reference,
                  emissionDate: transaction.emission_date,
                  confirmationDate: transaction.confirmation_date,
                  usedRate: transaction.used_rate,
                  usedSaving: transaction.used_saving,
                  cryptoCurrency: transaction.crypto
                    ? transaction.crypto.code
                    : "",
                  cryptoAmount: transaction.crypto_amount,
                  exchangeType: transaction.exchange_type,
                  status:
                    transaction.status.name.charAt(0).toUpperCase() +
                    transaction.status.name.slice(1).toLowerCase(),
                  statusCode: transaction.status.code,
                  originBeneficiaryName:
                    transaction.user.name + " " + transaction.user.last_name,
                  destinyBeneficiaryName:
                    transaction.client_account.account_holder,
                  key: index + 1,
                  user: transaction.user,
                  cashierAccount: transaction.cashier_account,
                  clientAccount: transaction.client_account,
                  cashierBank: cashierBank[0],
                  id: transaction.id,
                };
              }
            );

            dispatch({
              type: CASHIER_ACTIONS.FETCH_CASHIER_TRANSACTIONS_SUCCESS,
              payload: transactions,
            });
          })
          .catch((error: any) => {
            const errorMessage = getErrorMessage(error);
            if (errorMessage.error === "UNAUTHORIZED") {
              localStorage.removeItem("accessTokenCashier");
              localStorage.removeItem("loginDate");
              localStorage.removeItem("sessionExpiresIn");
              localStorage.removeItem("sessionTime");

              setTimeout(() => {
                dispatch({
                  type: CASHIER_ACTIONS.FETCH_CASHIER_PROFILE_FAIL,
                  payload: { error: errorMessage.error, showModal: true },
                });
              }, 500);
            } else {
              dispatch({
                type: CASHIER_ACTIONS.FETCH_CASHIER_TRANSACTIONS_FAIL,
                payload: { errorMessage: errorMessage.message },
              });
            }
          });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);

        dispatch({
          type: CASHIER_ACTIONS.FETCH_CASHIER_TRANSACTIONS_FAIL,
          payload: { errorMessage: errorMessage.message },
        });
      });
  };
};

export const updateStatusOperation = (status: string, id: number) => {
  return (dispatch: any) => {
    const accessTokenCashier = localStorage.getItem("accessTokenCashier");
    const data = { status };
    requestManager
      .patch(`/transaction/${id}/status`, data, {
        Authorization: `Bearer ${accessTokenCashier}`,
      })
      .then((response: any) => {
        dispatch({
          type: CASHIER_ACTIONS.EDIT_OPERATION_SUCCESS,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error, "CASHIER");
        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessTokenCashier");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: CASHIER_ACTIONS.EDIT_OPERATION_FAIL,
              payload: { error: errorMessage.error, showModal: true },
            });
          }, 500);
        } else {
          alert(errorMessage.message);
          dispatch({
            type: CASHIER_ACTIONS.EDIT_OPERATION_FAIL,
            payload: { error: errorMessage.error },
          });
        }
      });
  };
};

export const updateCryptoOperation = (
  crypto: string,
  cryptoAmount: number,
  id: number,
  status: string
) => {
  return (dispatch: any) => {
    const accessTokenCashier = localStorage.getItem("accessTokenCashier");
    const data = {
      cryptoCurrency: Number(crypto),
      crypto_amount: cryptoAmount,
      status,
    };
    requestManager
      .patch(`/transaction/${id}/crypto`, data, {
        Authorization: `Bearer ${accessTokenCashier}`,
      })
      .then((response: any) => {
        dispatch({
          type: CASHIER_ACTIONS.EDIT_OPERATION_SUCCESS,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error, "CASHIER");
        if (errorMessage.error === "UNAUTHORIZED") {
          localStorage.removeItem("accessTokenCashier");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");

          setTimeout(() => {
            dispatch({
              type: CASHIER_ACTIONS.EDIT_OPERATION_FAIL,
              payload: { error: errorMessage.error, showModal: true },
            });
          }, 500);
        } else {
          alert(errorMessage.message);
          dispatch({
            type: CASHIER_ACTIONS.EDIT_OPERATION_FAIL,
            payload: { error: errorMessage.error },
          });
        }
      });
  };
};

// GET YADIO RATES
export const getRates = () => {
  return (dispatch: any) => {
    axios
      .get(process.env.REACT_APP_YADIO_ENDPOINT + "/json")
      .then((response: any) => {
        dispatch({
          type: CASHIER_ACTIONS.GET_RATES,
          payload: {
            btcToUsd: latinFormat(response.data.BTC.price),
            btcToVes: latinFormat(response.data.VES.price),
            rates: { ...response.data.rates, USD: response.data.USD.rate },
          },
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({ type: CASHIER_ACTIONS.DO_NOTHING, payload: errorMessage });
      });
  };
};

export const getLimitAvailable = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { id } = state.cashier;

    requestManager
      .get(`/transaction/${id}/invoicedAmount`, {})
      .then((response: any) => {
        const invoicedAmount = response.data.amountTransactionComplete;

        dispatch({
          type: CASHIER_ACTIONS.GET_CASHIER_AMOUNT_COMPLETE,
          payload: invoicedAmount,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({ type: CASHIER_ACTIONS.DO_NOTHING, payload: errorMessage });
      });
  };
};

export const getCryptoActives = () => {
  return (dispatch: any) => {
    requestManager
      .get(`/crypto-active?status=ACTIVE`, {})
      .then((response: any) => {
        const cryptos = response.data.map((crypto: any) => {
          return {
            id: crypto.id,
            name: crypto.name,
            code: crypto.code,
            value: crypto.id,
            label: crypto.code,
          };
        });
        dispatch({
          type: CASHIER_ACTIONS.GET_CRYPTO_ACTIVES,
          payload: cryptos,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({ type: CASHIER_ACTIONS.DO_NOTHING, payload: errorMessage });
      });
  };
};
