export const EMAIL_REGEX: string =
  '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const USER_NAME_REGEX: string = "([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*";
export const PASSWORD_REGEX: string =
  "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[.,/*#-])[A-Za-z-0-9$*$/$.,/*#-]{8,15}$";
export const ONLY_NUMBER_REGEX: string = "^[0-9]+$";

export const ONLY_LETTER_REGEX: string = "^[a-zA-Z]+$";

export const ONLY_LETTER_ACCENT_REGEX: string = "^[a-zA-ZñáéíóúÁÉÍÓÚ ]*$";

export const BLANKS_REGEX: string = "\\s";

export const FLOAT_RATE_REGEX: string = "^[0-9]{1,11}([.][0-9]{1,2})$";

export const FLOAT_CRYPTO_REGEX: string = "^[0-9]{1,5}([.][0-9]{1,9})$";

export type CountryOption = {
  flag: string;
  countryCurrency: string;
  iso: string;
};

export type DocType = {
  id: number;
  name: string;
  code: string;
  doc_type_status: string;
};

export type Transaction = {
  celupagosId: string;
  amountToSend: string;
  originCurrency: string;
  amountToReceive: string;
  destinationCurrency: string;
  exchangeType: string;
  accountHolder: string;
  country: string;
  bankName: string;
  accountNumber: string;
  phoneNumber: string;
};

export type TransactionHistory = {
  originAmount: string;
  originCurrency: string;
  destinationAmount: string;
  destinationCurrency: string;
  celupagoReference: string;
  bankReference: string;
  emissionDate: string;
  confirmationDate: string;
  usedRate: string;
  usedSaving: string;
  cryptoCurrency: any;
  cryptoAmount: string;
  exchangeType: string;
  status: string;
  statusCode: string;
  cashierId: string;
  cashierName: string;
  cashierBank: any;
  beneficiaryName: string;
  key: number;
};

export type CashierTransactions = {
  originAmount: string;
  originCurrency: string;
  destinationAmount: string;
  destinationCurrency: string;
  celupagoReference: string;
  bankReference: string;
  emissionDate: string;
  confirmationDate: string;
  usedRate: string;
  usedSaving: string;
  cryptoCurrency: string;
  cryptoAmount: string;
  exchangeType: string;
  status: string;
  statusCode: string;
  originBeneficiaryName: string;
  destinyBeneficiaryName: string;
  key: number;
  user: any;
  cashierAccount: any;
  clientAccount: any;
  cashierBank: any;
  id: number;
};

export type User = {
  id: number;
  name: string;
  lastName: string;
  email: string;
  document: string;
  phoneNumber: string;
  phonePrefix: string;
  birthday: string | null;
  gender: null | string;
  clientType: string;
  country: {
    id: number;
    name: string;
    iso: string;
    calling_code: string;
    currency: string;
  };
  docType: {
    id: number;
    name: string;
    code: string;
  };
  accounts: any;
};

export type Bank = {
  id: number;
  bankStatus: string;
  code: string;
  commercialName: string;
  countryId: number;
  entityName: string;
  createdAt: string;
  label: string;
  value: number;
  digits: number;
};

export type AccountType = {
  id: number;
  value: string;
  label: string;
  name: string;
};

export type GenderType = {
  id: number;
  value: string;
  label: string;
  name: string;
};

export type CashierType = {
  id: number;
  value: string;
  name: string;
};

export type CashierStatusType = {
  id: number;
  value: string;
  name: string;
};

export type AccountCashier = {
  id: number;
  accountHolder: string;
  document: string;
  docType: string;
  docTypeCode: string;
  email: string;
  accountType: string;
  accountNumber: string;
  bankId: number;
  cashierId: number;
  label: string;
  celupagosId: number;
  cashierType: string;
  guarantee: number;
  dailyLimit: number;
  rate: number;
};
export type Country = {
  id: number;
  name: string;
  iso: string;
  calling_code: string;
  currency: string;
  country_status: string;
  min_amount: number | null | string;
  max_amount: number | null | string;
  flag_url: null | string;
  rate: number | string;
  saving: number | string;
  flag: string;
  value: string;
  label: string;
  docTypes: DocType[];
};

export type CryptoActive = {
  id: number;
  name: string;
  code: string;
  value: number;
  label: string;
};

export type CallingCodeVeType = {
  id: number;
  value: string;
  label: string;
  name: string;
};

export const FLAGS = {
  venFlag: require("../assets/country-flags/ven.png"),
  peruFlag: require("../assets/country-flags/peru.png"),
  argFlag: require("../assets/country-flags/arg.png"),
  bolFlag: require("../assets/country-flags/bol.png"),
  clpFlag: require("../assets/country-flags/clp.png"),
  colFlag: require("../assets/country-flags/col.png"),
  ecuFlag: require("../assets/country-flags/ecu.png"),
  spaFlag: require("../assets/country-flags/spa.png"),
  porFlag: require("../assets/country-flags/por.png"),
  itaFlag: require("../assets/country-flags/ita.png"),
  holFlag: require("../assets/country-flags/hol.png"),
  gerFlag: require("../assets/country-flags/ger.png"),
};

export const callingCodeVe: CallingCodeVeType[] = [
  { id: 1, value: "0412", label: "0412", name: "" },
  { id: 2, value: "0414", label: "0414", name: "" },
  { id: 3, value: "0424", label: "0424", name: "" },
  { id: 4, value: "0416", label: "0416", name: "" },
  { id: 5, value: "0426", label: "0426", name: "" },
];

/*   { id: 1, value: "0412", label: "0412" },
  { id: 2, value: "0414", label: "0414" },
  { id: 3, value: "0424", label: "0424" },
  { id: 4, value: "0416", label: "0416" },
  { id: 5, value: "0426", label: "0426" }, */

export const COUNTRY_OPTIONS: CountryOption[] = [
  {
    flag: FLAGS.venFlag,
    countryCurrency: "VES",
    iso: "VE",
  },
  {
    flag: FLAGS.peruFlag,
    countryCurrency: "PEN",
    iso: "PE",
  },
  {
    flag: FLAGS.argFlag,
    countryCurrency: "ARS",
    iso: "AR",
  },
  {
    flag: FLAGS.clpFlag,
    countryCurrency: "CLP",
    iso: "CL",
  },
  {
    flag: FLAGS.colFlag,
    countryCurrency: "COP",
    iso: "CO",
  },
  {
    flag: FLAGS.ecuFlag,
    countryCurrency: "USD",
    iso: "EC",
  },
  {
    flag: FLAGS.spaFlag,
    iso: "ES",
    countryCurrency: "EUR",
  },
  {
    flag: FLAGS.porFlag,
    iso: "PT",
    countryCurrency: "EUR",
  },
  {
    flag: FLAGS.itaFlag,
    iso: "IT",
    countryCurrency: "EUR",
  },
  {
    flag: FLAGS.holFlag,
    iso: "NL",
    countryCurrency: "EUR",
  },
  {
    flag: FLAGS.gerFlag,
    iso: "DE",
    countryCurrency: "EUR",
  },
];

export type CashierFilter = {
  label: string;
  value: string;
  id: number;
};

export const CASHIER_FILTERS: CashierFilter[] = [
  {
    label: "En proceso",
    value: "EP",
    id: 0,
  },
  {
    label: "Completada",
    value: "CT",
    id: 1,
  },
  {
    label: "Rechazado",
    value: "RZ",
    id: 2,
  },
  {
    label: "Devuelto",
    value: "DT",
    id: 3,
  },
  {
    label: "Compra exitosa",
    value: "CE",
    id: 4,
  },
  {
    label: "Compra no exitosa",
    value: "CNE",
    id: 5,
  },

  {
    label: "Validada",
    value: "VD",
    id: 6,
  },
];

export const NON_ALLOWED_USER_TRANSACTION_STATUS = {
  CE: "CE",
  CN: "CN",
  CR: "CR",
};

export const NON_ALLOWED_TO_EDIT_CASHIER_TRANSACTION_STATUS = {
  CE: "CE",
  DT: "DT",
  CT: "CT",
  VR: "VR",
  CNE: "CNE",
  RZ: "RZ",
  CR: "CR",
  CRZ: "CRZ",
};
export const ACCOUNT_TYPES: AccountType[] = [
  { id: 1, value: "SAVING", label: "CA", name: "Ahorro" },
  { id: 2, value: "CHECKING", label: "CC", name: "Corriente" },
];

export const GENDER_TYPES: GenderType[] = [
  { id: 1, value: "MALE", label: "Masculino", name: "" },
  { id: 2, value: "FEMALE", label: "Femenino", name: "" },
];

export const CASHIER_TYPES: CashierType[] = [
  { id: 1, value: "MASTER", name: "Senior" },
  { id: 2, value: "STANDARD", name: "Junior" },
];

export const COMING_FROM = {
  private: "PRIVATE_SECTION",
  public: "PUBLIC_SECTION",
};

export const CASHIER_STATUS: CashierStatusType[] = [
  { id: 1, value: "RZ", name: "RECHAZADO" },
  { id: 2, value: "DT", name: "DEVUELTO" },
  { id: 3, value: "VD", name: "VALIDADA" },
  { id: 4, value: "CE", name: "COMPRA EXITOSA" },
  { id: 5, value: "CNE", name: "COMPRA NO EXITOSA" },
];

export const SESSION_TIME_TO_ALERT: number = 600;

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const calculateAge = (date: string) => {
  const today = new Date();
  const birthday = new Date(date);
  let age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
};

export const getErrorMessage = (error: any, user?: any) => {
  if (error.response) {
    if (error.response.data) {
      if (
        error.response.data.error &&
        error.response.data.statusCode &&
        error.response.data.statusCode === 401 &&
        error.response.data.error === "Unauthorized"
      ) {
        error.response.data.error = "UNAUTHORIZED";

        if (user === "CASHIER") {
          localStorage.removeItem("accessTokenCashier");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");
        } else {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("loginDate");
          localStorage.removeItem("sessionExpiresIn");
          localStorage.removeItem("sessionTime");
        }

        return error.response.data;
      }
      return error.response.data;
    }
  } else if (error.request) {
    error = { message: "Comprueba tu conexión e inténtalo nuevamente" };
    return error;
  } else {
    error = { message: "Error Inesperado" };
    return error;
  }
};
