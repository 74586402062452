import React, { ReactElement } from "react";
import ReactLoading from "react-loading";
import "./Common.css";

const Loader = (): ReactElement => {
  return (
    <div className="loader">
      <section className="loader-main">
        <div className="body-loader">
          <ReactLoading type={"spin"} color={"#7234e9"} width={"40px"} />
        </div>
      </section>
    </div>
  );
};

export default Loader;
