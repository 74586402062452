import RequestManager from "../../services/RequestManager";
import { getErrorMessage } from "../../utils/ConstHelper";

const base = "TRANSACTION_SUMMARY_ACTIONS_";
const requestManager = new RequestManager();

export const TRANSACTION_SUMMARY_ACTIONS = {
  FETCH_TRANSACTION_DATA: base + "FETCH_TRANSACTION_DATA",

  TRANSACTION_SUMMARY_START: base + "TRANSACTION_SUMMARY_START",
  TRANSACTION_SUMMARY_FAIL: base + "TRANSACTION_SUMMARY_FAIL",

  SESSION_EXPIRED: base + "SESSION_EXPIRED",
  CLEAN_STATE: base + "CLEAN_STATE",
  DO_NOTHING: base + "DO_NOTHING",
};

export const cleanTransactionSummaryState = () => {
  return {
    type: TRANSACTION_SUMMARY_ACTIONS.CLEAN_STATE,
  };
};

export const getTransactionSummary = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: TRANSACTION_SUMMARY_ACTIONS.TRANSACTION_SUMMARY_START,
    });
    const state = getState();
    const { idTransaction } = state.payment;
    requestManager
      .get(`/transaction/${idTransaction}`)
      .then((response: any) => {
        const transaction = response.data;
        const transactionSummary = {
          celupagosId: transaction.cashier.celupagos_id,
          amountToSend: transaction.origin_amount,
          originCurrency: transaction.origin_currency,
          amountToReceive: transaction.destination_amount,
          destinationCurrency: transaction.destination_currency,
          exchangeType: transaction.exchange_type,
          accountHolder: transaction.client_account.account_holder,
          country: transaction.client_account.bank.country.name,
          bankName: transaction.client_account.bank.commercial_name,
          accountNumber: transaction.client_account.account_number,
          phoneNumber: transaction.client_account.phone_number,
        };

        dispatch({
          type: TRANSACTION_SUMMARY_ACTIONS.FETCH_TRANSACTION_DATA,
          payload: transactionSummary,
        });
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessage(error);
        dispatch({
          type: TRANSACTION_SUMMARY_ACTIONS.TRANSACTION_SUMMARY_FAIL,
          payload: errorMessage.message,
        });
      });
  };
};
