import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  onEmailChange,
  onEmailBlur,
  forgotPassword,
  cleanForgotPasswordState,
} from "../../../ducks/actions/ForgotPasswordActions";
import { globalStateType } from "../../../ducks/reducers";
import Logo from "../../../assets/img/logo/logo.png";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Loader from "../../common/Loader";

import "./Login.css";

type Props = {
  email: string;
  emailValid?: boolean;
  emailError: string;
  onEmailChange: (text: string) => void;
  onEmailBlur: () => void;
  forgotPassword: () => void;
  error: string;
  forgotPasswordSuccess: boolean;
  loading: boolean;
  cleanForgotPasswordState: () => void;
};

class ForgotPassword extends Component<Props> {
  componentWillUnmount() {
    const { cleanForgotPasswordState } = this.props;
    cleanForgotPasswordState();
  }
  componentDidMount() {
    const { onEmailChange } = this.props;

    const emailStorage = localStorage.getItem("email");

    if (emailStorage) {
      onEmailChange(emailStorage);
    }
  }
  handleOnEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onEmailChange, onEmailBlur } = this.props;
    onEmailChange(event.target.value);
    onEmailBlur();
  };

  handleOnButtonClick = () => {
    const { forgotPassword } = this.props;
    forgotPassword();
  };
  render() {
    const {
      email,
      emailValid,
      emailError,
      onEmailBlur,
      error,
      forgotPasswordSuccess,
      loading,
    } = this.props;

    const redirect =
      forgotPasswordSuccess === false ? (
        <React.Fragment />
      ) : (
        <Redirect to="/" />
      );

    return (
      <React.Fragment>
        {redirect}
        <div className="login-container">
          {loading ? <Loader /> : null}
          <div className="login-header">
            <a href="/">
              <img src={Logo} alt="celupago" className="login-logo" />
            </a>
            <h2 className="subTitle-login">Recuperar contraseña</h2>
          </div>

          <div className="inputs-login-container">
            <Input
              styleName={
                emailValid !== undefined
                  ? emailValid
                    ? "common-input-valid"
                    : "common-input-invalid"
                  : ""
              }
              type="text"
              maxLength={40}
              minLength={6}
              label="Correo electrónico"
              onChangeValue={this.handleOnEmailChange}
              onBlur={onEmailBlur}
              value={email}
            />
            <div className="error-login-container">
              <p className="error-text-login">{emailError}</p>
            </div>

            <div className="error-register">
              <p className="error-text-register">{error}</p>
            </div>

            <div className="button-login">
              <Button
                buttonText={"ENVIAR"}
                onClick={this.handleOnButtonClick}
                disabled={emailValid ? false : true}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    email: state.forgotPassword.email,
    emailValid: state.forgotPassword.emailValid,
    emailError: state.forgotPassword.emailError,
    error: state.forgotPassword.error,
    forgotPasswordSuccess: state.forgotPassword.forgotPasswordSuccess,
    loading: state.forgotPassword.loading,
  };
};

export default connect(mapStateTopProps, {
  onEmailChange,
  onEmailBlur,
  forgotPassword,
  cleanForgotPasswordState,
})(ForgotPassword);
