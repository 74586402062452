import React from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../../common/Button";
import { CashierTransactions } from "../../../../utils/ConstHelper";
import "./Operations.css";

type Props = {
  open: boolean;
  handleClose: () => void;
  transaction?: CashierTransactions;
};

const TransactionCard = ({ open, handleClose, transaction }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className="dialog-title-container">
          <span className="dialog-title">Resumen de transacción</span>
        </div>
      </DialogTitle>
      <DialogContent>
        {transaction && (
          <React.Fragment>
            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label">
                Nº de Transacción
              </span>
              <span className="transaction-dialog-bold">
                {transaction.celupagoReference}
              </span>
            </div>
            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label">
                Banco país origen
              </span>
              <span className="transaction-dialog-value">
                {transaction.cashierBank.commercial_name}
              </span>
            </div>
            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label">
                Referencia Bancaria
              </span>
              <span className="transaction-dialog-value">
                {transaction.bankReference}
              </span>
            </div>
            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label">
                Beneficiario orígen
              </span>
              <span className="transaction-dialog-value">
                {transaction.originBeneficiaryName}
              </span>
            </div>
            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label">
                Beneficiario destino
              </span>
              <span className="transaction-dialog-value">
                {transaction.destinyBeneficiaryName}
              </span>
            </div>
            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label">Fecha de emisión</span>
              <span className="transaction-dialog-value">
                {moment(transaction.emissionDate).format("DD/MM/YYYY hh:mm A")}
              </span>
            </div>
            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label">Monto orígen</span>
              <span className="transaction-dialog-value">
                {transaction.originAmount} {transaction.originCurrency}
              </span>
            </div>
            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label">Monto destino</span>
              <span className="transaction-dialog-value">
                {transaction.destinationAmount}{" "}
                {transaction.destinationCurrency}
              </span>
            </div>

            <div className="transaction-dialog-row-item">
              <span className="transaction-dialog-label status-different-color">
                Estatus
              </span>
              <span className="transaction-dialog-value status-different-color">
                {transaction.status.charAt(0).toUpperCase() +
                  transaction.status.slice(1).toLowerCase()}
              </span>
            </div>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button buttonText={"OK"} onClick={() => handleClose()} />
      </DialogActions>
    </Dialog>
  );
};

export default TransactionCard;
