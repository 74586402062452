import React, { ReactElement } from "react";
import "./Buttons.css";

type Props = {
  buttonText: string;
  onClick?: () => void;
};

const SecondaryButton = ({ buttonText, onClick }: Props): ReactElement => {
  return (
    <div className="home-button-container">
      <div className={"secondary-button"} onClick={onClick}>
        {buttonText}
      </div>
    </div>
  );
};

export default SecondaryButton;
