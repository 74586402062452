import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import AuthHeader from "../../common/auth-header/AuthHeader";
import Loader from "../../common/Loader";
import {
  getUserProfile,
  cleanUserState,
} from "../../../ducks/actions/UserActions";
import { getUserTransactions } from "../../../ducks/actions/HomeActions";
import { globalStateType } from "../../../ducks/reducers";
import MainButton from "./MainButton";
import SecondaryButton from "./SecondaryButton";
import FilterOptions from "./transaction-section/FilterOptions";
import HistoryList from "./transaction-section/HistoryList";
import { TransactionHistory } from "../../../utils/ConstHelper";
import HistoryTable from "./transaction-section/HistoryTable";
import Modal from "../../common/Modal";
import NoTransactionComponent from "../../common/NoTransaction";
import ExclamationCircle from "./../../../assets/icons/exclamation-circle.png";
import "./HomeContainer.css";

const defaultProps = {};
const defaultState = {
  isHeaderMenuVisible: false,
  logOut: false,
  currentTransaction: 0,
};

type Props = {
  //props | state
  getUserProfile: () => void;
  cleanUserState: () => void;
  getUserTransactions: (limit: number, status?: string) => void;
  name: string;
  loading: boolean;
  showModal: boolean;
  userError: any;
  history: any;
  transactions: TransactionHistory[];
  transactionLoad: boolean;
} & typeof defaultProps;

type State = typeof defaultState;

class HomeContainer extends Component<Props, State> {
  state = defaultState;

  componentDidMount() {
    const { getUserProfile, getUserTransactions } = this.props;
    getUserProfile();
    getUserTransactions(window.innerWidth > 480 ? 40 : 10);
  }

  toggleHeaderMenu = () => {
    const { isHeaderMenuVisible } = this.state;
    const visible = isHeaderMenuVisible;
    this.setState({ isHeaderMenuVisible: !visible });
  };

  hideHeaderMenu = () => {
    const { isHeaderMenuVisible } = this.state;
    if (isHeaderMenuVisible) {
      this.setState({ isHeaderMenuVisible: false });
    }
  };

  hideModal = () => {
    const { cleanUserState } = this.props;
    this.setState({ logOut: true });
    cleanUserState();
  };

  handleShowTransactionDetails = (key: number) => {
    const transaction = this.state.currentTransaction;
    this.setState({ currentTransaction: key === transaction ? 0 : key });
  };

  onClickRefresh = () => {
    const { getUserTransactions } = this.props;
    getUserTransactions(window.innerWidth > 480 ? 40 : 10);
  };

  renderTransactionHistory = () => {
    const { currentTransaction } = this.state;
    const { transactions } = this.props;
    return window.innerWidth > 480 ? (
      <HistoryTable transactions={transactions} refresh={this.onClickRefresh} />
    ) : (
      <HistoryList
        transactions={transactions}
        currentTransaction={currentTransaction}
        onTransactionPress={this.handleShowTransactionDetails}
      />
    );
  };

  render() {
    const { isHeaderMenuVisible, logOut } = this.state;
    const {
      name,
      loading,
      history,
      showModal,
      getUserTransactions,
      transactions,
      transactionLoad,
    } = this.props;

    const redirect = logOut ? <Redirect to="/" /> : <React.Fragment />;

    return (
      <div className="home-container" onClick={this.hideHeaderMenu}>
        {loading || (transactionLoad && <Loader />)}
        <Modal
          show={showModal}
          title={"Sesión expirada"}
          img={ExclamationCircle}
          body={"Su sesión ha expirado"}
          handleClose={this.hideModal}
        />
        {redirect}
        <AuthHeader
          isVisible={isHeaderMenuVisible}
          toggleHeaderMenu={this.toggleHeaderMenu}
          regards={name}
        />
        <div className="home-main-container">
          <div className="home-content-container">
            <p className="home-title">Rápido, simple y confiable</p>
            <MainButton
              buttonText="Iniciar nuevo envío"
              onClick={() => history.push("/remittance")}
            />
            <SecondaryButton buttonText="Historial de Transacciones" />
            <FilterOptions
              handleUserFinishedTransactions={() =>
                getUserTransactions(window.innerWidth > 480 ? 40 : 10, "CT")
              }
              handleUserRejectedTransactions={() =>
                getUserTransactions(window.innerWidth > 480 ? 40 : 10, "RZ")
              }
              handleUserInProcessTransactions={() =>
                getUserTransactions(window.innerWidth > 480 ? 40 : 10, "EP")
              }
              handleUserTransactions={() =>
                getUserTransactions(window.innerWidth > 480 ? 40 : 10)
              }
            />
            {transactions.length > 0 ? (
              this.renderTransactionHistory()
            ) : (
              <NoTransactionComponent />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    loading: state.user.loading,
    userError: state.user.error,
    showModal: state.user.showModal,
    transactions: state.home.transactions,
    transactionLoad: state.home.loading,
    name: state.user.name,
  };
};
export default withRouter(
  connect(mapStateTopProps, {
    getUserProfile,
    cleanUserState,
    getUserTransactions,
  })(HomeContainer)
);
