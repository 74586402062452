import React, { Component } from "react";
import CurrentBreadcrumb from "../../../assets/icons/blueBorderCircle.png";
import NextBreadcrumb from "../../../assets/icons/grayBorderCircle.png";
import CheckedBreadcrumb from "../../../assets/icons/circleSolidBlue.png";
import BlueChevronLeft from "../../../assets/icons/blueChevronLeft.png";

import "./Breadcrumbs.css";

const defaultProps = {};
const defaultState = {};

const getBreadcrumb = (currentIndex: number, breadcrumbIndex: number) => {
  if (currentIndex === breadcrumbIndex) {
    return (
      <img
        src={CurrentBreadcrumb}
        alt="currentBreadcrumb"
        className="breadcrumbs"
      />
    );
  }

  if (currentIndex < breadcrumbIndex) {
    return (
      <img src={NextBreadcrumb} alt="nextBreadcrumb" className="breadcrumbs" />
    );
  }

  if (currentIndex > breadcrumbIndex) {
    return (
      <img
        src={CheckedBreadcrumb}
        alt="checkedBreadcrumb"
        className="breadcrumbs"
      />
    );
  }
};

type Props = {
  //props | state
  currentStep: number;
  // navigateToStep1: () => void;
  // navigateToStep2: () => void;
  // navigateToStep3: () => void;
  // navigateToStep4: () => void;
  handleBackPress: () => void;
  //hideHeaderMenu: () => void;
} & typeof defaultProps;

type State = typeof defaultState;

class Breadcrumbs extends Component<Props> {
  render() {
    const { currentStep } = this.props;
    return (
      <div
        className="breadcrumbs-main-container"
        //onClick={this.props.hideHeaderMenu}
      >
        <div className="center-container">
          {currentStep === 1 || currentStep === 2 ? (
            <div
              className="back-arrow-container"
              onClick={this.props.handleBackPress}
            >
              <img
                src={BlueChevronLeft}
                alt="chevronLeftBlue"
                className="back-arrow"
              />
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div
          className="breadcrumbs-container"
          //     onClick={currentStep === 3 ? () => {} : this.props.navigateToStep1}
        >
          <div className="breadcrumb-asset-container">
            <div className="breadcrumb-pusher" />
            {getBreadcrumb(currentStep, 0)}
            <div
              className={`breadcrumb-separator ${
                currentStep > 0 ? "blue-separator" : ""
              }`}
            />
          </div>

          <p className="guide-text">Cálculo del envío</p>
        </div>

        <div
          className="breadcrumbs-container"
          //     onClick={currentStep === 3 ? () => {} : this.props.navigateToStep2}
        >
          <div className="breadcrumb-asset-container middle-breadcrumb">
            <div
              className={`breadcrumb-separator ${
                currentStep > 0 ? "blue-separator" : ""
              } `}
            />
            {getBreadcrumb(currentStep, 1)}
            <div
              className={`breadcrumb-separator ${
                currentStep > 1 ? "blue-separator" : ""
              }`}
            />
          </div>
          <p className="guide-text">Datos del beneficiario</p>
        </div>
        <div
          className="breadcrumbs-container"
          //    onClick={currentStep === 3 ? () => {} : this.props.navigateToStep3}
        >
          <div className="breadcrumb-asset-container middle-breadcrumb">
            <div
              className={`breadcrumb-separator ${
                currentStep > 1 ? "blue-separator" : ""
              }`}
            />
            {getBreadcrumb(currentStep, 2)}
            <div
              className={`breadcrumb-separator ${
                currentStep > 2 ? "blue-separator" : ""
              }`}
            />
          </div>
          <p className="guide-text">Pago</p>
        </div>
        <div
          className="breadcrumbs-container"
          //    onClick={this.props.navigateToStep4}
        >
          <div className="breadcrumb-asset-container last-breadcrumb">
            <div
              className={`breadcrumb-separator ${
                currentStep > 2 ? "blue-separator" : ""
              }`}
            />
            {getBreadcrumb(currentStep, 3)}
            <div className="breadcrumb-pusher" />
          </div>
          <p className="guide-text">Resumen de transacción</p>
        </div>
        <div className="center-container"></div>
      </div>
    );
  }
}

export default Breadcrumbs;
