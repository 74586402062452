import React from "react";
import "./TermsAndConditions.css";

const logo = require("../../../assets/img/logo/logo.png");

const TermsAndConditions = () => {
  return (
    <div className={"main-container-terms"}>
      <div className="reticula-terms">
        <div className="logo-container-terms">
          <a target="_blank" href="/">
            <img src={logo} alt="celupago" className="logo-terms" />
          </a>
        </div>
        <div className="text-container-terms">
          <div className="head-title-container-terms">
            <p className="head-title-terms">TÉRMINOS Y CONDICIONES </p>
          </div>
          <div className="terms-container">
            <p className="sub-title-terms">1. ACUERDO DE SERVICIO</p>
            <p className="terms-text ">
              1.1. Los términos y condiciones contenidos en el presente contrato
              de servicio (en lo sucesivo, este contrato, los “
              <span className="terms-text-bold"> Términos de Uso</span>”)
              constituyen un contrato de servicios entre{" "}
              <span className="terms-text-bold">CELUPAGO, C.A.</span>, compañía
              anónima venezolana, inscrita en el Registro Mercantil V de la
              circunscripción Judicial del Distrito Capital y Estado Miranda,
              bajo el Nro. 46, Tomo 136_A y titular del Registro Único de
              Información Fiscal (RIF) bajo el N°{" "}
              <span className="terms-text-bold">J-31727952-2</span>, (en lo
              sucesivo "<span className="terms-text-bold">EKIIPAGO</span>") y
              Usted y constituye un acuerdo de voluntades, por lo que al acceder
              o hacer uso de los servicios de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, acceder a
              nuestro sitio web o usar cualquier servicio prestado por{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, usted acepta
              estos Términos de Uso. El uso continuado del sitio Web{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_FRONT_HOST}
              >
                {" "}
                {process.env.REACT_APP_FRONT_HOST}{" "}
              </a>
              implica la aceptación tácita que usted está de acuerdo con los
              Términos de Uso que se prevén en estos Términos de Uso,
              independientemente de que exista algún medio de aceptación (
              <span className="terms-text-bold">incluyendo cualquier clic</span>
              ). Si estos Términos de Uso no le resultan aceptables, usted debe
              suspender el uso de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, dejar de
              acceder a nuestro sitio web y de usar cualquier servicio prestado
              por <span className="terms-text-bold">EKIIPAGO</span> o cualquiera
              de sus subsidiarias y afiliadas; si no suspende dicho uso, usted
              seguirá obligado conforme a estos Términos de Uso.
            </p>
            <p className="terms-text ">
              1.2. A lo largo de los siguientes Términos de Uso,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> y el sitio Web{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_FRONT_HOST}
              >
                {" "}
                {process.env.REACT_APP_FRONT_HOST}{" "}
              </a>
              pueden ser referidos como "
              <span className="terms-text-bold">nosotros</span>", "
              <span className="terms-text-bold">nuestro</span>", "
              <span className="terms-text-bold">sitio Web</span>", "
              <span className="terms-text-bold">plataforma tecnológica</span>",
              "<span className="terms-text-bold">Aplicación Web/Móvil</span>", y
              "<span className="terms-text-bold">EKIIPAGO</span>” o términos
              equivalentes. Los{" "}
              <span className="terms-text-bold">usuarios</span> de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> incluyen, pero
              no se limitan a los{" "}
              <span className="terms-text-bold">usuarios</span> registrados, así
              como cualquier visitante que utilice el sitio web de alguna forma
              (en lo sucesivo, un “USUARIO”, definición que se entenderá incluye
              el término definido{" "}
              <span className="terms-text-bold">Usuario</span> referido a
              continuación en estos Términos de Uso).
            </p>
            <p className="terms-text ">
              1.3. En ningún caso el{" "}
              <span className="terms-text-bold">USUARIO</span> podrá hacer uso
              de los servicios que presta{" "}
              <span className="terms-text-bold">EKIIPAGO</span> a menos que haya
              aceptado, o que se considere que ha aceptado (mediante su acceso a
              la Aplicación Web/Móvil y el uso de los servicios) estos Términos
              de Uso. Lo más probable es que haya hecho clic o va a hacer clic
              en la sección que contiene las palabras "
              <span className="terms-text-bold">
                Acepto Términos y Condiciones
              </span>
              " o una sintaxis similar. Usted debe entender que esto tiene el
              mismo efecto jurídico que si usted pone su firma manuscrita en
              cualquier contrato o acuerdo de voluntades. Mediante el uso de
              cualquier parte de nuestra Aplicación Web/Móvil o de los
              Servicios, Usted entiende y acepta que dicho uso constituye su
              consentimiento voluntario, libre, completo e incondicional de
              todos los términos de estos Términos de Uso.
            </p>
            <p className="sub-title-terms">
              2. TÉRMINOS GENERALES DEL SERVICIO
            </p>
            <p className="terms-text ">
              2.1. Para poder hacer uso de las funciones de nuestra Aplicación
              Web/Móvil, el <span className="terms-text-bold">USUARIO</span>{" "}
              deberá estar registrado conforme a las políticas aplicables al
              tipo de cuenta que corresponda. Para poder registrarse como{" "}
              <span className="terms-text-bold">USUARIO</span> se debe tener al
              menos 18 años de edad y contar con la capacidad legal (es decir,
              capacidad de goce y de ejercicio) para aceptar estos Términos de
              Uso.
            </p>
            <p className="terms-text ">
              2.2. El <span className="terms-text-bold">USUARIO</span> acepta y
              reconoce que <span className="terms-text-bold">EKIIPAGO</span>{" "}
              tendrá la Potestad de cambiar estos Términos de Uso en cualquier
              momento. Respecto de cualquier modificación, Usted podría, sin que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> esté obligado a
              hacerlo, recibir una notificación de cualquier cambio sustancial
              al mismo y siempre puede encontrar la última versión de estos
              Términos de Uso en la Aplicación Web/Móvil. Cada vez que se
              realice cualquier actualización a estos Términos de Uso y Usted
              continúe el uso de esta plataforma, se considerará que el uso
              continuado implica la aceptación de dichos cambios. Se recomienda,
              por lo tanto, que Usted lea estos Términos de Uso con frecuencia.
              Si Usted no estuviere de acuerdo con los Términos de Uso o
              cualquier modificación a los mismos, Usted debe dejar de hacer uso
              de nuestro sitio Web o nuestros servicios.
            </p>
            <p className="terms-text ">
              2.3. Como <span className="terms-text-bold">USUARIO</span>, Usted
              reconoce y conviene que, si no entiende todos los términos de
              estos Términos de Uso, debe consultar con un abogado antes de
              utilizar la Aplicación Web/Móvil o cualquiera de los Servicios que
              proporciona <span className="terms-text-bold">EKIIPAGO</span>. Si
              Usted no puede revisar periódicamente estos Términos de Uso para
              determinar si alguno de los términos ha cambiado, Usted asume toda
              la responsabilidad por el hecho de no hacerlo y Usted acepta que
              dicho incumplimiento equivale a una renuncia expresa de su derecho
              a que se modifiquen los términos o a iniciar cualquier acción que
              tenga como propósito aducir que los términos no han sido
              modificados, otorga a{" "}
              <span className="terms-text-bold">EKIIPAGO</span> el finiquito más
              amplio y libera a{" "}
              <span className="terms-text-bold">EKIIPAGO</span> de cualquier
              responsabilidad. <span className="terms-text-bold">EKIIPAGO</span>{" "}
              tendrá el derecho, pero no la obligación, de hacer de su
              conocimiento cualquier modificación, por lo cual, antes de usar
              los servicios de <span className="terms-text-bold">EKIIPAGO</span>{" "}
              o la Aplicación Web/Móvil, Usted se obliga a revisar estos
              Términos de Uso y su aplicabilidad de forma periódica. Cualquier
              versión modificada se entenderá como que suple a todas las
              versiones precedentes.
            </p>
            <p className="terms-text ">
              2.4. El uso y acceso a nuestra Aplicación Web/Móvil o nuestros
              servicios es nulo siempre que éste se realice desde cualquier
              lugar, donde esté prohibido por las leyes locales aplicables.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no se hace
              responsable de las acciones que tomen los{" "}
              <span className="terms-text-bold">usuarios</span> en desacato de
              esta previsión y nos reservamos el derecho de terminar nuestras
              operaciones con dichos{" "}
              <span className="terms-text-bold">usuarios</span>.
            </p>
            <p className="terms-text ">
              2.5.{" "}
              <span className="terms-text-bold">
                POR EL USO DE NUESTRO SITIO WEB Y LA ACEPTACIÓN DE ESTOS
                TÉRMINOS DE USO, USTED RECONOCE Y CONVIENE QUE (I){" "}
                <span className="terms-text-bold">EKIIPAGO</span> NO SE PROPONE
                CAPTAR RECURSOS DEL PÚBLICO, NI PRESTARLOS A CUALQUIER TERCERO,
                SINO QUE SU APLICACIÓN WEB/MÓVIL ES UN UNA PLATAFORMA
                TECNOLÓGICA QUE IDENTIFICA E INTEGRA COORDINADAMENTE A UN
                CONJUNTO DE CAJEROS INDEPENDIENTES (NO BANCARIOS) DISPUESTOS A
                RECIBIR DINERO FIDUCIARIO DEL PAÍS DE ORIGEN DE LA TRANSACCIÓN,
                EN SUS CUENTAS BANCARIAS EN ESE PAÍS, PARA LA ADQUISICIÓN -EN
                NOMBRE Y POR CUENTA PROPIA- DE DIVISAS DIGITALES QUE SERÁN LUEGO
                TRANSFERIDAS A OTROS CAJEROS INDEPENDIENTES (NO BANCARIOS) EN EL
                PAÍS DE DESTINO DE LA TRANSACCIÓN, DISPUESTOS A PAGAR POR ELLAS
                -TAMBIÉN EN NOMBRE Y POR CUENTA PROPIA- UN PRECIO ESTABLECIDO EN
                DINERO FIDUCIARIO DEL PAÍS DE DESTINO DE LA TRANSACCIÓN,
                MEDIANTE ABONO DIRECTO EN LA CUENTA BANCARIA LOCAL DEL
                DESTINATARIO SEÑALADO POR EL{" "}
                <span className="terms-text-bold">USUARIO</span> AL INICIO DE LA
                MISMA; (II) <span className="terms-text-bold">EKIIPAGO</span>{" "}
                IDENTIFICA E INTEGRA A UN CONJUNTO CAJEROS INDEPENDIENTES (NO
                BANCARIOS), UBICADOS TANTO EN EL PAÍS DE ORIGEN DE LA
                TRANSACCIÓN COMO EN EL PAÍS DE DESTINO DE LA TRANSACCIÓN,
                OFRECIÉNDOLES LA POSIBILIDAD DE ACCEDER A UN POTENCIAL UNIVERSO
                DE <span className="terms-text-bold">USUARIOS</span> A TRAVÉS DE
                SU APLICACIÓN WEB/MÓVIL; (III){" "}
                <span className="terms-text-bold">EKIIPAGO</span> ACTÚA COMO
                "GARANTE" ABSOLUTO Y A PRIMER REQUERIMIENTO DEL RESULTADO FINAL
                DE LA TRANSACCIÓN; (IV) RESPECTO DE CUALESQUIERA RECURSOS O
                CANTIDADES RECIBIDAS POR LOS CAJEROS INDEPENDIENTES (NO
                BANCARIOS) QUE SE INTEGREN A LA APLICACIÓN WEB/MÓVIL,{" "}
                <span className="terms-text-bold">EKIIPAGO</span> NO PAGARÁ
                INTERÉS, PREMIO O PRIMA ALGUNA, DE CUALQUIER NATURALEZA, LO QUE
                EL <span className="terms-text-bold">USUARIO</span> RECONOCE Y
                CONVIENE, RENUNCIANDO A CUALQUIER DERECHO QUE EXISTIERE O
                PUDIERE ADUCIRSE QUE EXISTE DE RECLAMARLOS, DE{" "}
                <span className="terms-text-bold">EKIIPAGO</span> O DE CUALQUIER
                PERSONA, Y LIBERANDO A{" "}
                <span className="terms-text-bold">EKIIPAGO</span> DE CUALQUIER
                RESPONSABILIDAD; (V){" "}
                <span className="terms-text-bold">EKIIPAGO</span> NO TIENE
                INTENCIÓN NI USARÁ LOS RECURSOS DE LOS{" "}
                <span className="terms-text-bold">USUARIOS</span> QUE RECIBAN
                LOS CAJEROS INDEPENDIENTES (NO BANCARIOS) PARA NINGÚN FIN, POR
                LO QUE <span className="terms-text-bold">EKIIPAGO</span> NO
                ADQUIRIRÁ EL CARÁCTER DE DEPOSITARIO NI TENDRÁ RESPONSABILIDAD
                POR LOS ACTOS DE CUALQUIER TERCERO QUE MANTENGA LOS RECURSOS DE
                LOS <span className="terms-text-bold">USUARIOS</span> QUE SE LES
                HAGA LLEGAR A TRAVÉS DE LA APLICACIÓN WEB/MÓVIL; Y (VI) LAS
                OBLIGACIONES DE{" "}
                <span className="terms-text-bold">EKIIPAGO</span> QUE RESULTAN
                DE ESTOS TÉRMINOS DE USO, NO ESTÁN GARANTIZADAS POR CUALQUIER
                SUBSIDIARIA, AFILIADA O TERCERO, INCLUYENDO A CUALQUIER
                AUTORIDAD.
              </span>
            </p>
            <p className="sub-title-terms">3. DEFINICIONES</p>
            <p className="terms-text ">
              3.1. Para efectos de estos Términos de Uso, los siguientes
              términos, tendrán los significados que se señalan a continuación:
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">Cuenta</span>: Las cuentas{" "}
              <span className="terms-text-bold">EKIIPAGO</span> se establecen
              para almacenar la información de transacciones.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no realiza
              operaciones de captación, por lo que las cuentas de cualesquiera{" "}
              <span className="terms-text-bold">USUARIOS</span> en la Aplicación
              Web/Móvil sirven para identificar a quien hace uso de los
              Servicios, con sus respectivas funcionalidades (
              <span className="terms-text-bold">
                v.g. Historial de Transacciones, Iniciar Nuevo Envío, Cerrar
                Sesión, etc
              </span>
              .)
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">Divisas Digitales</span>:
              Significa una representación de valor digital que no es emitida
              por un banco central, autoridad pública o Autoridad, no
              necesariamente ligada a una moneda, pero que es aceptada por
              personas naturales y/o jurídicas como un método de pago y puede
              ser transferida, guardada, o intercambiada electrónicamente, sin
              que tenga curso legal o poder liberatorio, ni se considere
              garantizada por cualquier Autoridad.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no proporciona
              ni emite a sus <span className="terms-text-bold">USUARIOS</span>{" "}
              Divisas Digitales. Todas las Divisas Digitales intercambiadas o
              negociadas por y entre los Cajeros Independientes (no bancarios)
              son propiedad de los mismos, por lo que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> únicamente
              proporciona la Aplicación Web/Móvil para facilitar la operación.
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">Servicios</span>: Los servicios
              que presta <span className="terms-text-bold">EKIIPAGO</span>,
              consisten en proporcionar una Aplicación Web/Móvil a través de la
              cual los <span className="terms-text-bold">USUARIOS</span> pueden
              acceder a un mercado libre de intercambio de Dinero Fiduciario
              Divisas Digitales y viceversa, como mecanismo para realizar pagos
              y transferencias nacionales e internacionales.
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">Dinero Fiduciario</span>: Es una
              moneda emitida por el gobierno que no está respaldada por una
              mercancía física, como el oro o la plata, sino por el gobierno que
              la emitió. El valor del dinero fiduciario se deriva de la relación
              entre la oferta y la demanda y la estabilidad del gobierno emisor,
              en lugar del valor de un producto básico que lo respalda, como es
              el caso del dinero común. La mayoría de las monedas de papel
              modernas son monedas fiduciarias, incluidos el dólar
              estadounidense, el euro y otras monedas mundiales importantes.
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">Visitante</span>: Para los
              efectos de estos Términos de Uso, todos los{" "}
              <span className="terms-text-bold">USUARIOS</span> son Visitantes,
              pero no todos los Visitantes son{" "}
              <span className="terms-text-bold">USUARIOS</span>. El presente
              Convenio se aplica a todos los Visitantes, ya sean{" "}
              <span className="terms-text-bold">USUARIOS</span> o no. Usted se
              convierte en un Visitante al acceder a este sitio Web, o Servicio
              de cualquier manera. Usted no tiene que convertirse en un{" "}
              <span className="terms-text-bold">USUARIO</span> del sitio para
              que estos Términos de Uso le apliquen. Usted se convierte en un{" "}
              <span className="terms-text-bold">USUARIO</span> al momento de
              registrarse en el sitio Web, como se explica en la definición
              siguiente. Sólo los{" "}
              <span className="terms-text-bold">USUARIOS</span> pueden utilizar
              los Servicios.
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">Usuario</span>: Son las personas
              que han completado el procedimiento de registro e identificación
              de acuerdo con los requisitos establecidos para los niveles de
              cuenta de <span className="terms-text-bold">EKIIPAGO</span>, según
              corresponda, de acuerdo con su perfil y hacen uso de los servicios
              promocionados a través de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>. Todos los{" "}
              <span className="terms-text-bold">USUARIOS</span> de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> deben tener al
              menos 18 años de edad, en el entendido que, de no tener dicha
              edad, la persona de que se trate conviene que los Servicios y el
              acceso a nuestro sitio Web les sean suspendidos de manera
              indefinida y que <span className="terms-text-bold">EKIIPAGO</span>{" "}
              tendrá el derecho de iniciar cualquier acción en contra del{" "}
              <span className="terms-text-bold">USUARIO</span> a la que tuviere
              derecho conforme a las disposiciones aplicables. En ese sentido,
              los <span className="terms-text-bold">USUARIOS</span> autorizan
              que se verifique su identidad, a partir de los datos que Usted
              proporcione, ya sea mediante terceros o en bases de datos
              oficiales o mediante cualquier medio que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> considere
              mejores para el cumplimiento de sus obligaciones en materia de
              Prevención de Lavado de Dinero o Financiamiento al Terrorismo (
              <span className="terms-text-bold">PLD/FT</span>), políticas de
              Prevención de Fraude, y todos aquellos requisitos que impongan las
              leyes aplicables, de cualquier naturaleza.
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">
                Cajeros Independientes (no bancarios)
              </span>
              : Personas previamente registradas y con acceso a la Aplicación
              Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span>,
              dispuestas a recibir dinero fiduciario del país de origen de una
              transacción, en sus cuentas bancarias en ese país, para la
              adquisición -en nombre y por cuenta propia- de divisas digitales
              con el compromiso de luego transferidas a otros cajeros
              independientes (no bancarios) en el país de destino de la
              transacción, dispuestos a pagar por ellas -también en nombre y por
              cuenta propia- un precio establecido en dinero fiduciario del país
              de destino de la transacción, mediante abono directo en la cuenta
              bancaria local del beneficiario señalado por el{" "}
              <span className="terms-text-bold">USUARIO</span> al inicio de la
              misma.
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">
                Aplicación Web/Móvil de EKIIPAGO
              </span>
              : Se refiere al sitio Web{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_FRONT_HOST}
              >
                {" "}
                {process.env.REACT_APP_FRONT_HOST}{" "}
              </a>{" "}
              propiedad de <span className="terms-text-bold">EKIIPAGO</span>,
              que permite ejecutar la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> compatible para
              dispositivos web y móviles.
            </p>
            <p className="terms-text ">
              <span className="terms-text-bold">Aplicación 2FA</span>: Es una
              aplicación de autenticación de dos factores que ofrece seguridad
              adicional en el proceso de inicio de sesión.
            </p>
            <p className="terms-text ">
              3.2. Al momento de ser considerado un{" "}
              <span className="terms-text-bold">USUARIO</span> y por el sólo
              hecho de serlo, Usted declara que está actuando por cuenta propia
              y <span className="terms-text-bold">NO</span> por cuenta, orden,
              ni en representación de un tercero, en ningún caso y bajo ninguna
              circunstancia, y reconoce que, conforme a los presentes Términos
              de Uso, le está prohibido permitir que otra persona use la cuenta,
              que un tercero se ostente con credenciales y/o datos de acceso
              propios del <span className="terms-text-bold">USUARIO</span>{" "}
              verificado o actuar por cuenta de cualquier tercero. Asimismo,
              reconoce que, en caso de que se detecte que un{" "}
              <span className="terms-text-bold">USUARIO</span> está violando lo
              dispuesto en el presente párrafo, se le suspenderá, será sujeto a
              un proceso de revisión y reporte de sus operaciones, y{" "}
              <span className="terms-text-bold">EKIIPAGO</span> tendrá el
              derecho de iniciar en su contra cualquier acción que corresponda,
              conforme a lo previsto por cualquiera de las disposiciones
              aplicables.
            </p>
            <p className="terms-text ">
              3.3. A lo largo de los siguientes términos de uso, Usted puede ser
              referido como “<span className="terms-text-bold">Usted</span>”, “
              <span className="terms-text-bold">USUARIO</span>”, “
              <span className="terms-text-bold">Suyo</span>”, etc.
            </p>
            <p className="sub-title-terms">4. CUMPLIMIENTO (COMPLIANCE)</p>
            <p className="terms-text ">
              4.1. La política de{" "}
              <span className="terms-text-bold">PLD/FT</span> de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> se guía por las
              disposiciones aplicables de la legislación venezolana de
              Legitimación de Capitales y voluntariamente por los criterios
              internacionales que ha emitido el Grupo de Acción Financiera
              Internacional sobre el Blanqueo de Capitales.
            </p>
            <p className="terms-text ">
              4.2. Nuestro programa de{" "}
              <span className="terms-text-bold">PLD/FT</span> está diseñado para
              evitar el uso los servicios promocionados a través de la
              Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> para el Lavado
              de Dinero o Financiamiento del Terrorismo. Es nuestra política el
              tomar todas las medidas necesarias para prevenir y reportar las
              actividades sospechosas; participamos activamente en la prevención
              de cualquier acto que facilite el lavado de dinero, la
              financiación del terrorismo u otros delitos financieros.
            </p>
            <p className="terms-text ">
              4.3. Nuestra política de{" "}
              <span className="terms-text-bold">PLD/FT</span> también se centra
              en la detección y prevención de actividades fraudulentas desde la
              identificación y evaluación de las áreas de riesgo relacionados
              con el fraude, así como la implementación de procedimientos para
              prevenir dichos riesgos mediante mecanismos de control y
              vigilancia.
            </p>
            <p className="terms-text ">
              4.4. En el caso de que se sospeche que su cuenta de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> está
              incumpliendo con cualquier disposición de{" "}
              <span className="terms-text-bold">PLD/FT</span>, Usted acepta que
              tenemos el derecho llevar a cabo cualquier actividad de revisión y
              auditoría que consideremos necesaria o conveniente, de solicitar y
              recibir cualquier información o documentación necesaria o
              conveniente, de reportar sus actividades a cualquier Autoridad y
              de tomar cualquier otra medida o llevar a cabo cualquier acto
              contemplado implícita o explícitamente en estos Términos de Uso,
              de suspender su cuenta{" "}
              <span className="terms-text-bold">EKIIPAGO</span> e, inclusive, el
              acceso a la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>. En caso de que
              su cuenta de <span className="terms-text-bold">USUARIO</span> o de
              cualquier otra cuenta registrada o asociada a la misma se
              encuentre incumpliendo la política de{" "}
              <span className="terms-text-bold">PLD/FT</span> de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, Usted acepta
              que tenemos el derecho de cancelar sus derechos como{" "}
              <span className="terms-text-bold">USUARIO</span> y suspenderle
              todos los Servicios, en cualquier momento, mediante aviso o aún en
              ausencia de cualquier aviso.
            </p>
            <p className="terms-text ">
              4.5. En caso que se demuestre legal o judicialmente que, Usted o
              las operaciones que realiza violan nuestra política de{" "}
              <span className="terms-text-bold">PLD/FT</span> y/o de la ley, el{" "}
              <span className="terms-text-bold">USUARIO</span> se compromete a
              renunciar a todos los fondos en la misma, a favor de lo que
              determinen las autoridades competentes, sin responsabilidad alguna
              para <span className="terms-text-bold">EKIIPAGO</span>, de la que
              en este acto le libera.
            </p>
            <p className="terms-text ">
              4.6. Como parte de la implementación de nuestra política de{" "}
              <span className="terms-text-bold">PLD/FT</span>,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> se reserva el
              derecho de requerir cualquier información adicional, con la
              intención de determinar el perfil transaccional de los{" "}
              <span className="terms-text-bold">USUARIOS</span>, así como el
              origen y el destino de los fondos involucrados en las operaciones
              que el <span className="terms-text-bold">USUARIO</span> realice en
              la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> o para cualquier
              otro fin relacionado o conveniente y, de no recibir la información
              necesaria, <span className="terms-text-bold">EKIIPAGO</span> podrá
              suspender o dar por terminados los Servicios o el acceso por el{" "}
              <span className="terms-text-bold">USUARIO</span> a nuestro sitio
              Web.
            </p>
            <p className="terms-text ">
              4.7. Si Usted está buscando información para o pretende llevar a
              cabo cualquier actividad financiera ilegal o fraudulenta, por
              favor deje este sitio inmediatamente y absténgase de utilizar los
              Servicios que ofrece la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>. Usted reconoce
              y acepta que es consciente de la legalidad de la utilización de
              nuestros servicios en su jurisdicción y que no utilizará los
              Servicios que proporciona{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, si tal uso está
              prohibido o si viola las leyes de su estado, localidad, país u
              otra jurisdicción en la que se encuentre.
            </p>
            <p className="sub-title-terms">5. SEGURIDAD</p>
            <p className="terms-text ">
              5.1. Para la seguridad del{" "}
              <span className="terms-text-bold">USUARIO</span>, se requiere que
              el <span className="terms-text-bold">USUARIO</span> utilice algún
              método de autenticación de múltiples factores. Siempre se debe
              utilizar por lo menos dos (2) formas de autenticación para acceder
              a su cuenta de <span className="terms-text-bold">USUARIO</span> y
              realizar ciertas operaciones de la cuenta.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> en ningún caso
              será responsable por el uso de las formas de autenticación por el{" "}
              <span className="terms-text-bold">USUARIO</span> o por cualquier
              tercero. <span className="terms-text-bold">EKIIPAGO</span> no se
              hace responsable de que el{" "}
              <span className="terms-text-bold">USUARIO</span> sea víctima de{" "}
              <span className="terms-text-bold">Phishing</span>, descuide sus
              datos de acceso, los proporcione a terceros o cualquier otro tipo
              de negligencia por parte del{" "}
              <span className="terms-text-bold">USUARIO</span>.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> tampoco será
              responsable, en caso alguno, por actos de terceros que afecten
              internet, la plataforma informática de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> o los actos o
              forma de autenticación del{" "}
              <span className="terms-text-bold">USUARIO</span>, o de
              cualesquiera actos o hechos similares, de los cuales el{" "}
              <span className="terms-text-bold">USUARIO</span> libera a{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, renunciando a
              cualquier derecho que pudiere corresponderle por este concepto.
            </p>
            <p className="terms-text ">
              5.2. Entre las formas de autenticación de múltiples factores,
              además de sus credenciales o contraseñas de acceso puede contener
              códigos entregados a través del servicio de mensajes cortos ("
              <span className="terms-text-bold">SMS</span>") o una autenticación
              de dos factores especificada y con el apoyo de alguna aplicación
              ("<span className="terms-text-bold">2FA</span>").
            </p>
            <p className="terms-text ">
              5.3. En caso de que el{" "}
              <span className="terms-text-bold">USUARIO</span> decida instalar
              una aplicación <span className="terms-text-bold">2FA</span> en un
              dispositivo (por ejemplo, teléfono o tableta), lo hace bajo su
              propio riesgo. <span className="terms-text-bold">EKIIPAGO</span>{" "}
              no podrá ser responsable de la fuga o pérdida ya sea de
              información o de fondos, derivado de la utilización de una
              aplicación <span className="terms-text-bold">2FA</span> en un
              dispositivo cuyo sistema operativo haya sido alterado, por
              ejemplo, sin limitación, Jailbreak (
              <span className="terms-text-bold">iOS</span>) o Root (
              <span className="terms-text-bold">Android</span>).
            </p>
            <p className="terms-text ">
              5.4. <span className="terms-text-bold">EKIIPAGO</span> tendrá
              potestad absoluta, a nuestra entera discreción, de prohibir el
              acceso desde o mediante cualquier dispositivo cuyo sistema
              operativo haya sido o se sospeche que haya sido alterado de alguna
              manera.
            </p>
            <p className="terms-text ">
              5.5. Igualmente, <span className="terms-text-bold">EKIIPAGO</span>{" "}
              tendrá potestad absoluta, en cualquier caso y sin que medie
              comunicación o explicación alguna, de prohibir el acceso a su
              sitio Web a cualquier persona.
            </p>
            <p className="terms-text ">
              5.6. Es responsabilidad del{" "}
              <span className="terms-text-bold">USUARIO</span> no compartir ni
              proporcionar sus datos de identificación a terceros, así como
              asegurarse de que solamente ingresa sus datos o credenciales en la
              dirección segura{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_FRONT_HOST}
              >
                {" "}
                {process.env.REACT_APP_FRONT_HOST}.
              </a>{" "}
              El sitio oficial de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> cuenta con un
              certificado “<span className="terms-text-bold">SSL</span>”, el
              cual permite asegurar al{" "}
              <span className="terms-text-bold">USUARIO</span> que se encuentra
              en el sitio adecuado, el certificado se puede apreciar a través de
              un icono de su navegador. Sin perjuicio de lo anterior, el acceso
              al sitio oficial de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> es
              responsabilidad del{" "}
              <span className="terms-text-bold">USUARIO</span>, sin que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> sea responsable
              de, o pueda asegurar, que el sitio es seguro. Se recomienda que el{" "}
              <span className="terms-text-bold">USUARIO</span> mantenga
              confidenciales y en sitios separados las contraseñas de los
              métodos de autenticación de múltiples factores. Asimismo, el{" "}
              <span className="terms-text-bold">USUARIO</span> también acepta
              que es su responsabilidad mantener la confidencialidad de sus
              credenciales, contraseñas y factores de autenticación.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no podrá ser
              responsable de la fuga o pérdida ya sea de información o de fondos
              derivados de un error en la confidencialidad por parte del{" "}
              <span className="terms-text-bold">USUARIO</span> o de cualquier
              otro acto o hecho, de cualquier naturaleza, atribuible al{" "}
              <span className="terms-text-bold">USUARIO</span>.
            </p>
            <p className="terms-text ">
              5.7. El proceso de recuperación de contraseña del{" "}
              <span className="terms-text-bold">USUARIO</span> solamente se
              realizará mediante los pasos que para ello ha designado{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, tomando en
              cuenta los más altos estándares de seguridad. Dicho proceso
              únicamente podrá ser iniciado por el{" "}
              <span className="terms-text-bold">USUARIO</span> y nunca provendrá
              por parte de <span className="terms-text-bold">EKIIPAGO</span>.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> nunca le pedirá,
              bajo ninguna circunstancia, por correo, correo electrónico ni por
              vía telefónica que revele las credenciales que utiliza para
              acceder a su cuenta. Por favor, nunca proporcione sus datos de
              acceso a otra persona.{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, sin embargo,
              podrá tener acceso a su Cuenta en los casos y circunstancias
              previstas en estos Términos de Uso.
            </p>
            <p className="terms-text ">
              5.8. <span className="terms-text-bold">EKIIPAGO</span> entiende
              como válidas todas las operaciones que realice el{" "}
              <span className="terms-text-bold">USUARIO</span> después de haber
              iniciado sesión en su Cuenta, como -por ejemplo- de manera
              enunciativa, más no limitativa, tales como ofertas de compra o
              venta o cualquier uso de los Servicios. Mediante estos Términos de
              Uso, Usted acepta que es responsable, y está obligado, sin
              responsabilidad alguna para{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, de las
              obligaciones de pago que puedan surgir como consecuencia de, o en
              relación con, una oferta que se cierre, convenga o lleve a cabo a
              través de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, mediante el uso
              de las credenciales de inicio de sesión, así como los procesos de
              autenticación, asociados a la propia cuenta del{" "}
              <span className="terms-text-bold">USUARIO</span>. Por el solo
              hecho de acceder a la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> y solicitar o
              celebrar cualquier operación en términos de la misma (aún en el
              caso que un tercero hiciere uso inapropiado de la Cuenta por
              cuenta del <span className="terms-text-bold">USUARIO</span>), el{" "}
              <span className="terms-text-bold">USUARIO</span> se obliga a
              responder de las obligaciones que le sean atribuibles o en las que
              haya incurrido, en términos de estos Términos de Uso. El{" "}
              <span className="terms-text-bold">USUARIO</span> se obliga a
              responder, con todo su patrimonio, de cualesquiera de sus
              obligaciones en términos de estos Términos de Uso.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> entiende como
              válidas todas las operaciones que realice el{" "}
              <span className="terms-text-bold">USUARIO</span> después de haber
              iniciado sesión en su Cuenta, como -por ejemplo- de manera
              enunciativa, mas no limitativa, tales como: ofertas de compra o
              venta o cualquier uso de los Servicios. Mediante estos Términos de
              Uso, Usted acepta que es responsable de los cargos o pérdidas que
              puedan surgir como consecuencia, o en relación con una oferta que
              se realicen en la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, mediante el uso
              de las credenciales de inicio de sesión, así como los procesos de
              autenticación, asociados a la propia Cuenta del{" "}
              <span className="terms-text-bold">USUARIO</span>.
            </p>
            <p className="terms-text ">
              5.9. Derivado de lo anterior, es responsabilidad del{" "}
              <span className="terms-text-bold">USUARIO</span> notificar a{" "}
              <span className="terms-text-bold">EKIIPAGO</span> si cree o
              sospecha que su cuenta o sus credenciales pueden haber sido
              comprometidas, o si un tercero está actuando por su cuenta, para
              lo cual deberá exponer su caso a través de los canales de atención
              dispuestos por <span className="terms-text-bold">EKIIPAGO</span>.
              Además, el <span className="terms-text-bold">USUARIO</span> se
              obliga a tomar todas las medidas necesarias o convenientes que le
              solicite <span className="terms-text-bold">EKIIPAGO</span> en
              relación con cualquiera de las conductas citadas.
            </p>

            <p className="sub-title-terms">
              6. CONTACTO CON EL{" "}
              <span className="terms-text-bold">USUARIO</span>
            </p>

            <p className="terms-text ">
              6.1. El <span className="terms-text-bold">USUARIO</span> acepta
              que <span className="terms-text-bold">EKIIPAGO</span> tomará como
              dirección primaria de contacto, la dirección de correo electrónico
              con la que se ha dado de alta en Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>. Su dirección de
              correo será la vía a través de la cual recibirá avisos
              relacionados con su acceso a los Servicios que ofrece la
              Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>. También podemos
              utilizar esta dirección de correo electrónico para otras
              comunicaciones, incluidos los avisos con respecto a estos Términos
              de Uso y sus operaciones. Para asegurar que Usted reciba todas
              nuestras comunicaciones, Usted se compromete a mantener su
              dirección de correo electrónico actualizada y notificarnos
              inmediatamente si hay algún cambio. El{" "}
              <span className="terms-text-bold">USUARIO</span> podrá actualizar
              la dirección de correo electrónica mediante el canal de atención
              de <span className="terms-text-bold">EKIIPAGO</span>{" "}
              <a href="mailto:soporte@ekiipago.com">soporte@ekiipago.com</a> en
              la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              6.2. <span className="terms-text-bold">EKIIPAGO</span> no será
              responsable, en ningún caso, si envía cualquier notificación a la
              dirección de correo electrónico registrada, y Usted no la recibe.
              La entrega de cualquier notificación a la dirección de correo
              electrónico registrada será considerada válida y vinculante; no
              obstante, no la haya recibido. Si cualquier correo electrónico se
              devuelve como imposible de entregar, nos reservamos el derecho de
              bloquear el acceso a nuestro sitio Web hasta que proporcione y
              confirme una nueva dirección de correo electrónico.
            </p>
            <p className="sub-title-terms">
              7. FUNCIONAMIENTO DE LA APLICACIÓN WEB/MÓVIL DE{" "}
              <span className="terms-text-bold">EKIIPAGO</span>
            </p>
            <p className="terms-text ">
              7.1. Todos los Visitantes pueden acceder a las áreas públicas del
              sitio Web; sin embargo, sólo los{" "}
              <span className="terms-text-bold">USUARIOS</span> pueden activar
              cualquier Servicio ofrecido a través de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>. Usted entiende
              que, mediante esta plataforma,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> solamente ofrece
              acceso a los Servicios o aplicaciones conexas.
            </p>
            <p className="terms-text ">
              7.2. Usted reconoce que los servicios promocionados en la
              Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no han sido
              aprobados por ninguna autoridad regulatoria o de supervisión de
              ningún país específico. Asimismo, Usted reconoce que no se
              requiere que <span className="terms-text-bold">EKIIPAGO</span>{" "}
              mantenga niveles mínimos de capitalización y no resulta en
              responsabilidad para{" "}
              <span className="terms-text-bold">EKIIPAGO</span> de no existir{" "}
              <span className="terms-text-bold">USUARIOS</span> que tengan la
              intención de adquirir Dinero Fiduciario de algún país o Divisas
              Digitales de algún tipo, por lo cual{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no será
              responsable, por lo que el{" "}
              <span className="terms-text-bold">USUARIO</span> lo libera,
              renunciando a cualquier acción en contra de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> por este
              concepto.
            </p>
            <p className="terms-text ">
              7.3. Salvo por la garantía total y de primer requerimiento que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> asume con
              respecto al resultado final de cualquier transacción que hubiese
              sido solicitada y confirmada a través de la Aplicación Web/Móvil
              de <span className="terms-text-bold">EKIIPAGO</span>,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no tendrá
              obligación ni responsabilidad alguna, de ninguna naturaleza,
              respecto de los servicios que allí se ofrecen o conforme a estos
              Términos de Uso, excepto por aquellas obligaciones expresamente
              contempladas en estos Términos de Uso.
            </p>
            <p className="terms-text ">
              7.4. Solo los <span className="terms-text-bold">USUARIOS</span>{" "}
              que hayan creado una cuenta, de acuerdo con su perfil, podrán
              colocar solicitudes de venta o compra a través de la Aplicación
              Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span>.
              Una vez que a través de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> se ha logrado
              empatar una solicitud de venta iniciada por un{" "}
              <span className="terms-text-bold">USUARIO</span> con otra postura
              de compra iniciada por otro{" "}
              <span className="terms-text-bold">USUARIO</span>, de lo que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no será
              responsable, <span className="terms-text-bold">EKIIPAGO</span> se
              encargará de identificar e integrar coordinadamente a un conjunto
              de Cajeros Independientes (no bancarios) dispuestos a recibir
              Dinero Fiduciario del lugar de origen del inicio de la
              transacción, en sus cuentas bancarias en ese lugar, para la
              adquisición -en nombre y por cuenta propia- de Divisas Digitales
              que serán luego transferidas a otros Cajeros Independientes (no
              bancarios) en el país de destino de la transacción, dispuestos a
              pagar por ellas -también en nombre y por cuenta propia- un precio
              establecido en Dinero Fiduciario del lugar de destino de la
              transacción, mediante abono directo en la cuenta bancaria local
              del destinatario señalado por el{" "}
              <span className="terms-text-bold">USUARIO</span> al inicio de la
              misma; garantizando el resultado de la operación y la asignación
              de los fondos a los{" "}
              <span className="terms-text-bold">USUARIOS</span>{" "}
              correspondientes, mediante el cobro de una comisión. El{" "}
              <span className="terms-text-bold">USUARIO</span> se obliga, de
              manera incondicional e irrevocable, a pagar dicha comisión a{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              7.6. La Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> únicamente
              auxiliará en la conclusión de una operación en caso que los fondos
              necesarios estén disponibles en las cuentas de los Cajeros
              Independientes (no bancarios) involucrados en ella. Los fondos que
              se ofrecen para la venta o compra no estarán disponibles para ser
              utilizados para ningún propósito distinto a los servicios que
              promueve la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> o sus
              aplicaciones asociadas.
            </p>
            <p className="terms-text ">
              7.7. En algunos casos, es posible que las solitudes de venta o de
              compra no se concreten en una sola operación, sino que se
              completen como consecuencia de varias solicitudes. El{" "}
              <span className="terms-text-bold">USUARIO</span> reconoce que es
              su obligación retirar aquellas solicitudes que no desee que se
              cumplan, en el entendido que, si la solicitud correspondiente se
              hubiere aceptado, el{" "}
              <span className="terms-text-bold">USUARIO</span> estará obligado a
              cumplirla.
            </p>
            <p className="terms-text ">
              7.8. Usted es responsable de proteger sus datos de ingreso al
              sitio Web; cualquier acceso no autorizado es su responsabilidad.
              Usted utiliza esta tecnología bajo su propio riesgo.
            </p>
            <p className="sub-title-terms">8. LÍMITES Y NIVELES DE CUENTAS</p>
            <p className="terms-text ">
              8.1 <span className="terms-text-bold">EKIIPAGO</span> tendrá la
              potestad absoluta de establecer distintos límites y tipos de
              cuenta, según sus propios criterios o según se requiera para
              alinearlos a la regulación que resulte aplicable en el tiempo.
            </p>
            <p className="sub-title-terms">9. REGLAS OPERACIONALES</p>
            <p className="terms-text ">
              9.1. Las solicitudes de compra o venta se ejecutan en un lapso
              aproximado de una (01) hora cuando se verifique una asignación
              directa a la mejor postura de venta disponible o una asignación
              directa a la mejor postura de compra disponible; las demás
              solicitudes de compra o venta se ejecutan cuando se logra ejecutar
              una oferta complementaria. No garantizamos que su solicitud se
              llenará incluso si el precio de mercado es igual al monto ofrecido
              en ella, lo que Usted reconoce y acepta.
            </p>
            <p className="terms-text ">
              9.2. Las solicitudes pueden ser objeto de retrasos, contratiempos
              y/o condiciones que pueden llegar a afectar la transmisión o
              ejecución de las mismas.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no tiene
              control, sobre fallas del hardware, software, electrónica o la
              congestión del mercado, por lo que, en estos casos, no somos
              responsables de los retrasos, dificultades o condiciones que
              afectan negativamente a la transmisión o la ejecución de las
              solicitudes, lo que Usted reconoce y acepta.
            </p>
            <p className="terms-text ">
              9.3. <span className="terms-text-bold">EKIIPAGO</span> tendrá
              potestad absoluta, sin estar obligados, a implementar mecanismos
              para que todas las solicitudes de compra o venta pasen mediante
              sistemas automatizados para facilitar las labores de cumplimiento.
              Si su solicitud se encuentra en alguno de los supuestos a partir
              de los cuales se generan alertas, comprobaciones o si cumple
              ciertos criterios definidos, puede requerir revisión manual.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> atenderá las
              revisiones manuales lo antes posible, pero no somos responsables
              de los retrasos que pudiesen ocurrir, ni de cualesquiera pérdidas
              o daños patrimoniales resultantes.
            </p>
            <p className="terms-text ">
              9.4. Se le podrá requerir que Usted confirme su solicitud por
              correo electrónico, por teléfono o por otras formas de
              autenticación antes de la ejecución si se considera sospechosa o
              si por cualquier otra razón{" "}
              <span className="terms-text-bold">EKIIPAGO</span> lo considera
              necesario o conveniente.
            </p>
            <p className="sub-title-terms">
              10. HISTORIAL DE CUENTA Y COMPROBANTES FISCALES
            </p>
            <p className="terms-text ">
              10.1. Usted puede encontrar un historial de todas sus
              transacciones de su Cuenta en la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> después de haber
              iniciado sesión. <span className="terms-text-bold">EKIIPAGO</span>{" "}
              tendrá la potestad absoluta de ajustar, corregir o reconciliar sus
              registros cuando así sea necesario, lo que Usted reconoce y
              acepta.
            </p>
            <p className="terms-text ">
              10.2. En <span className="terms-text-bold">EKIIPAGO</span> nos
              esforzamos por proporcionarle información precisa y verídica; sin
              embargo, es posible que exista algún error en su historial. En
              caso de que exista un error, usted cuenta con 20 días hábiles para
              hacerlo de nuestro conocimiento; pasado este periodo se entenderá
              que Usted está de acuerdo con el estado de su transacción, sin
              poder iniciar una reclamación en fecha subsecuente, lo que Usted
              reconoce y acepta.
            </p>
            <p className="terms-text ">
              10.3. Le recordamos que los fondos son propiedad de los{" "}
              <span className="terms-text-bold">USUARIOS</span> que publican
              solicitudes de compra o venta y los fondos intercambiados producto
              de una operación de compra o venta fluyen directamente desde los{" "}
              <span className="terms-text-bold">USUARIOS</span> hacia los
              Cajeros Independientes (no bancarios) o desde los Cajeros
              Independientes (no bancarios) hacia los{" "}
              <span className="terms-text-bold">USUARIOS</span>. En la
              actualidad, <span className="terms-text-bold">EKIIPAGO</span> no
              obtiene recursos derivados del cobro de comisiones por la
              intermediación en la realización de cada solicitud de compra o
              venta exitosa. Por consiguiente,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no puede
              proporcionarle ningún comprobante fiscal de sus operaciones.
            </p>
            <p className="terms-text ">
              10.4. Se informa que por el momento no tenemos conocimiento de que
              exista regulación fiscal específica que sea aplicable a las
              Divisas Digitales. Es su responsabilidad consultar con un
              profesional calificado cómo debe hacer el tratamiento fiscal de
              cualquier operación que involucre Divisas Digitales, las
              utilidades o pérdidas relacionadas con las mismas o cualquier otro
              aspecto vinculado.
            </p>
            <p className="sub-title-terms">11. COMISIONES</p>
            <p className="terms-text ">
              11.1. <span className="terms-text-bold">EKIIPAGO</span> podrá
              establecer en el futuro que cualquier solicitud de compra o venta
              colocada a través de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> esté sujeta a
              una tarifa ("<span className="terms-text-bold">Comisión</span>")
              que podría ser ajustada conforme crece la transaccionalidad del{" "}
              <span className="terms-text-bold">USUARIO</span>.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> tendrá la
              potestad absoluta de poder cambiar, modificar o aumentar esa
              Comisión en cualquier momento, pudiendo o no notificar previamente
              al <span className="terms-text-bold">USUARIO</span> respecto de
              cualquier modificación.
            </p>
            <p className="terms-text ">
              11.2. Todas las solicitudes de compra o venta que se realicen a
              través de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> están sujetas a
              todas las leyes, reglamentos y normas aplicables que puedan
              regular el uso, transferencia, compra, venta, intercambio o
              remisión de Dinero Fiduciario y Divisas Digitales. El{" "}
              <span className="terms-text-bold">USUARIO</span> acepta y reconoce
              que debe conocer las disposiciones aplicables y, en caso que
              requiera cualquier aclaración, a consultar a sus asesores
              fiscales.
            </p>
            <p className="terms-text ">
              11.3. En caso de que la Comisión a que tenga derecho{" "}
              <span className="terms-text-bold">EKIIPAGO</span> sea objeto de la
              Ley del Impuesto al Valor Agregado, o bien, de cualquier otra
              contribución nacional o local que se llegase a causar, se
              considerará que la Comisión que se cause tendrá incluido el
              impuesto que corresponda.
            </p>
            <p className="sub-title-terms">12. LÍMITE DE RESPONSABILIDAD</p>
            <p className="terms-text ">
              12.1. Su Cuenta con nosotros no es una cuenta bancaria. Nuestros
              servicios no pagarán intereses por los fondos que utilice para la
              llevar a cabo las transacciones en la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              12.2. Los fondeos de Dinero Fiduciario no están protegidos por
              ningún tipo de seguro de depósito.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no almacena los
              fondos de los <span className="terms-text-bold">USUARIOS</span>.
              Todas sus operaciones o depósitos están sujetas a todas las leyes,
              reglamentos y normas aplicables, que puedan regular el uso,
              transferencia, compra, venta, intercambio o remisión de Dinero
              Fiduciario y Divisas Digitales y su transacción a través de la
              Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              12.3. <span className="terms-text-bold">EKIIPAGO</span> considera
              transmisor de dinero a cualquier persona natural que acepte y
              transmita divisas o fondos, o el valor de la divisa o de los
              fondos, sea cual sea el medio.
            </p>
            <p className="terms-text ">
              12.4. <span className="terms-text-bold">EKIIPAGO</span> tendrá el
              derecho de rechazar cualquier operación en la cual el{" "}
              <span className="terms-text-bold">USUARIO</span> tenga la
              intención de utilizar la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> como transmisor
              de dinero.
            </p>
            <p className="terms-text ">
              12.5. <span className="terms-text-bold">EKIIPAGO</span> no realiza
              operaciones de captación. Los recursos de los{" "}
              <span className="terms-text-bold">USUARIOS</span> se mantienen en
              cuentas bancarias independientes a la operación comercial de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>; y{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no los utiliza
              en ninguna operación de intermediación financiera; los{" "}
              <span className="terms-text-bold">USUARIOS</span> reconocen y
              convienen que <span className="terms-text-bold">EKIIPAGO</span> no
              es responsable por cualquier evento o circunstancia que afecte a
              cualquiera de las instituciones en las que mantenga los recursos
              de los <span className="terms-text-bold">USUARIOS</span>.
            </p>
            <p className="sub-title-terms">13. ASESORÍA DE INVERSIÓN</p>
            <p className="terms-text ">
              13.1. El <span className="terms-text-bold">USUARIO</span> reconoce
              que todas las decisiones de compra se realizan únicamente por
              Usted y, en consecuencia, cada{" "}
              <span className="terms-text-bold">USUARIO</span> está obligado a
              llevar a cabo una evaluación independiente respecto de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> y las
              operaciones que puede llevar a cabo a través de Aplicación
              Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span> y
              cualquier otra circunstancia relacionada, sin que reciba ninguna
              asesoría o guía por parte de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no adquiere
              responsabilidad alguna y no será en ningún caso responsable con el{" "}
              <span className="terms-text-bold">USUARIO</span> en relación con
              este tipo de decisiones. En ningún caso se considerará que la
              operación de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> crea una
              relación de asesoría de inversión. Todos los funcionarios de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> tienen prohibido
              dar consejos de inversión, remesas de Dinero Fiduciario,
              operaciones de cambio de divisas o compra de Divisas Digitales;
              cualquier operación que el{" "}
              <span className="terms-text-bold">USUARIO</span> realice en la
              plataforma es responsabilidad exclusiva del mismo.
            </p>
            <p className="terms-text ">
              13.2. El <span className="terms-text-bold">USUARIO</span> reconoce
              que:
            </p>
            <ul>
              <li className="terms-text ">
                Existe volatilidad en las tasas de cambio del Dinero Fiduciario
                de los países, por lo que el{" "}
                <span className="terms-text-bold">Usuario</span> asume el riesgo
                que, mientras realiza una transacción a través de la Aplicación
                Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span>,
                ocurran grandes fluctuaciones en la tasa de cambio aplicada a la
                misma, pudiendo incurrir en pérdidas sustanciales y en corto
                tiempo.
              </li>
              <li className="terms-text ">
                Las cuentas bancarias de los Cajeros Independientes (no
                bancarios) integrados a la Aplicación Web/Móvil no cuentan con
                ningún tipo de seguro de depósito. El Gobierno y las Entidades
                Paraestatales no son responsables ni garantizan los recursos de
                los <span className="terms-text-bold">USUARIOS</span>.
              </li>
              <li className="terms-text ">
                Existen riesgos tecnológicos, cibernéticos y de fraude
                inherentes al uso, transferencia, compra, venta, intercambio o
                remisión de Dinero Fiduciario y Divisas Digitales.
              </li>
              <li className="terms-text ">
                Es posible que el Dinero Fiduciario de algún país no sea líquido
                en un momento o período determinado, por lo que podría no
                existir comprador alguno o medio para comprarlo o venderlo.
              </li>
              <li className="terms-text ">
                La Legislación puede cambiar en cualquier momento, lo cual puede
                tener repercusiones en el uso, transferencia, compra, venta,
                intercambio o remisión de Dinero Fiduciario y Divisas Digitales,
                e inclusive, se podría prohibir.
              </li>
              <li className="terms-text ">
                La operación de{" "}
                <span className="terms-text-bold">EKIIPAGO</span> desde su
                Aplicación Web/Móvil podría prohibirse en cualquier momento, lo
                que podrá resultar en que no puedan concluirse operaciones
                pendientes, de lo que no sería responsable{" "}
                <span className="terms-text-bold">EKIIPAGO</span>, pudiendo
                resultar en pérdidas para el{" "}
                <span className="terms-text-bold">USUARIO</span>.
              </li>
              <li className="terms-text ">
                El valor del Dinero Fiduciario de los países y Divisas Digitales
                se determina mediante la oferta y la demanda, por lo que{" "}
                <span className="terms-text-bold">EKIIPAGO</span> no tiene
                injerencia alguna en su fijación.
              </li>
              <li className="terms-text ">
                Las transacciones se dan por realizadas cuando han sido
                confirmadas en la Aplicación Web/Móvil de{" "}
                <span className="terms-text-bold">EKIIPAGO</span>.
              </li>
              <li className="terms-text ">
                Las transacciones en Divisas Digitales son irreversibles una vez
                que éstas han sido ejecutadas.
              </li>
              <li className="terms-text ">
                Las pérdidas por transacciones fraudulentas o accidentales
                pueden no ser recuperables y, adicionalmente, una vez
                confirmadas, el <span className="terms-text-bold">USUARIO</span>{" "}
                está obligado a cumplirlas.
              </li>
              <li className="terms-text ">
                El precio y la liquidez del Dinero Fiduciario y Divisas
                Digitales ha sido objeto de grandes fluctuaciones en el pasado y
                pueden estar sujetos a grandes fluctuaciones en el futuro.
              </li>
              <li className="terms-text ">
                Las Divisas Digitales, salvo el Petro, no son moneda de curso
                legal y no están respaldados por el Gobierno de la República
                Bolivariana de Venezuela ni por el Banco Central de Venezuela.
              </li>
              <li className="terms-text ">
                Las Divisas Digitales, salvo el Petro, no tienen curso legal en
                jurisdicción alguna ni cuentan con el respaldo de ningún
                gobierno, por lo que no hay ninguna garantía de que quien
                actualmente acepte pagos en Divisas Digitales, lo seguirán
                haciendo.
              </li>
            </ul>
            <p className="terms-text ">
              13.4. Derivado de lo anterior, a través de estos Términos de Uso
              se reconoce que Usted es el único responsable de determinar la
              naturaleza, el valor potencial, idoneidad y adecuación de los
              riesgos para Usted como{" "}
              <span className="terms-text-bold">USUARIO</span>, y que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no da consejos o
              recomendaciones con respecto a inversiones, remesas de Dinero
              Fiduciario, operaciones de cambio de divisas o compra de Divisas
              Digitales. Usted reconoce y acepta que deberá acceder y utilizar
              los Servicios y el sitio Web bajo su propio riesgo.
            </p>
            <p className="sub-title-terms">
              14. FONDEOS DE RECURSOS POR LOS{" "}
              <span className="terms-text-bold">USUARIOS</span>:
            </p>
            <p className="terms-text ">
              14.1. Ni los Cajeros Independientes (No Bancarios) ni{" "}
              <span className="terms-text-bold">EKIIPAGO</span> son una
              institución de crédito, y por lo tanto los fondeos de recursos no
              devengan intereses, ni están protegidos por ningún seguro de
              depósito.
            </p>
            <p className="terms-text ">
              14.2. <span className="terms-text-bold">EKIIPAGO</span> no
              solicita o requiere que nuestros{" "}
              <span className="terms-text-bold">USUARIOS</span> mantengan
              recursos en sus cuentas bancarias. El fondeo de las cuentas de los
              Cajeros Independientes (no bancarios) es necesario exclusivamente
              para cubrir la postura de compra o venta que desee efectuar el{" "}
              <span className="terms-text-bold">USUARIO</span> a través de la
              Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              14.3. Al momento de abrir una cuenta de{" "}
              <span className="terms-text-bold">USUARIO</span> en la Aplicación
              Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span>, el{" "}
              <span className="terms-text-bold">USUARIO</span> debe proporcionar
              ciertos datos de registro e información con el fin de verificar su
              identidad. Parte de esta información puede ser datos personales,
              privados o sensibles. Al momento de completar el formulario de
              registro en línea, Usted afirma que proporciona información
              verdadera, precisa, actualizada y completa sobre Usted, según lo
              requiere el formulario de registro; y además Usted acepta mantener
              y actualizar los Datos de Registro para conservar la información
              como verdadera, precisa, actualizada y completa durante todo el
              tiempo mientras que Usted es un{" "}
              <span className="terms-text-bold">USUARIO</span> de la Aplicación
              Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              14.4. El <span className="terms-text-bold">USUARIO</span> reconoce
              que, al momento de registrarse con{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, es su
              responsabilidad hacer de nuestro conocimiento cualquier cambio o
              actualización de la información proporcionada inicialmente para la
              apertura de la cuenta, incluyendo, pero no limitado a, los cambios
              en su dirección, teléfono u otros cambios asociados con la
              Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> y los Servicios
              que allí se promueven. Si Usted proporciona alguna información que
              sea falsa, inexacta, desactualizada o incompleta, o si nosotros o
              cualquiera de nuestros agentes o representantes autorizados
              tenemos motivos razonables para sospechar que dicha información es
              falsa, inexacta, desactualizada o incompleta,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> tiene el derecho
              de suspender o cancelar su Cuenta y negarle el uso presente o
              futuro de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> y los Servicios,
              exigir los daños y perjuicios que ocasione dicha omisión,
              remitirlo a las autoridades correspondientes para que se determine
              su responsabilidad penal, cuando la hubiere, o iniciar cualquier
              otra acción conforme a la legislación aplicable.
            </p>
            <p className="terms-text ">
              14.5. Los Cajeros Independientes (no bancarios) únicamente aceptan
              fondeos por medio de transferencia electrónica bancaria nacional.
              Para conocer una lista actualizada de los métodos de fondeo, Usted
              puede consultar nuestro sitio Web. Los Cajeros Independientes (no
              bancarios) pueden negarse a aceptar cualquier otra transferencia
              por cualquier otro medio.
            </p>
            <p className="terms-text ">
              14.6. Los fondeos desde terceros, es decir aquellos que se
              originen desde o provengan de una cuenta en una institución
              financiera a nombre distinto al registrado por el{" "}
              <span className="terms-text-bold">USUARIO ESTÁN PROHIBIDOS</span>.
              Las transferencias siempre deberán provenir de una cuenta a nombre
              del <span className="terms-text-bold">USUARIO</span>.{" "}
              <span className="terms-text-bold">EKIIPAGO</span> tendrá potestad
              absoluta de hacer que los Cajeros Independientes (no bancarios)
              devuelvan cualquier transferencia efectuada por cualquier tercero
              en contravención de lo previsto en esta sección y de iniciar
              cualquier acción relacionada conforme a la legislación aplicable.
            </p>
            <p className="terms-text ">
              14.7. <span className="terms-text-bold">EKIIPAGO</span> utilizará
              los datos personales recabados de los{" "}
              <span className="terms-text-bold">USUARIOS</span> para llevar a
              cabo las actividades enfocadas al cumplimiento de las obligaciones
              derivadas de cualquier relación jurídica y comercial que resulta
              de estos Términos de Uso, así como para proporcionar la mejor
              experiencia de <span className="terms-text-bold">USUARIO</span>{" "}
              posible. Para más información acerca del tratamiento y de los
              derechos que puede hacer valer, Usted puede acceder al aviso de
              privacidad integral en nuestro sitio Web.
            </p>
            <p className="terms-text ">
              14.8. Finalmente, el{" "}
              <span className="terms-text-bold">USUARIO</span> reconoce que es
              responsable de cualesquiera y todas las actividades realizadas a
              través de su Cuenta. Usted se compromete a notificar
              inmediatamente de cualquier uso no autorizado de su contraseña o
              identificación de <span className="terms-text-bold">USUARIO</span>
              , así como de cualquier otra violación de seguridad. No nos
              hacemos responsables de cualquier uso no autorizado de su cuenta,
              y Usted acepta que Usted es responsable de dicho uso no
              autorizado, aún en el caso que lo hubiere reportado.
            </p>
            <p className="sub-title-terms">15. CIERRE DE CUENTAS</p>
            <p className="terms-text ">
              15.1. El <span className="terms-text-bold">USUARIO</span> puede
              cerrar, en cualquier momento, su Cuenta, para lo cual deberá
              seleccionar la opción que más se adecúe a su solicitud en la
              Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>. La cuenta se
              cerrará una vez que el{" "}
              <span className="terms-text-bold">USUARIO</span> haya cancelado
              todas las solicitudes pendientes y que haya cumplido todas las
              operaciones que hubiere concertado y que no pudieren cancelarse.
              Asimismo, el <span className="terms-text-bold">USUARIO</span>{" "}
              deberá completar un protocolo de seguridad para la protección de
              la cuenta del <span className="terms-text-bold">USUARIO</span>,
              según lo requiera periódicamente{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              15.2. <span className="terms-text-bold">EKIIPAGO</span> tendrá
              potestad absoluta de suspender, cancelar o modificar cualquier
              aspecto de su Cuenta, o su disponibilidad, en cualquier momento
              sin previo aviso, sin tener responsabilidad alguna. Además de
              suspender, modificar, desactivar o cancelar su acceso a todo o
              parte de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> de inmediato y
              sin previo aviso, en los casos en que{" "}
              <span className="terms-text-bold">(i)</span> Usted viole cualquier
              disposición de estos Términos de Uso;{" "}
              <span className="terms-text-bold">(ii)</span> Usted fondea su
              cuenta bancaria desde otra cuenta sobre la cual no tiene la
              propiedad, real o aparente;{" "}
              <span className="terms-text-bold">(iii)</span> se emita una orden
              judicial para el congelamiento de los fondos;{" "}
              <span className="terms-text-bold">(iv)</span> Usted o sus cuentas
              están sujeto a algún litigio pendiente, investigación o
              procedimiento gubernamental;{" "}
              <span className="terms-text-bold">(v)</span> se tiene una sospecha
              razonable de que ha infringido nuestra política de{" "}
              <span className="terms-text-bold">PLD/FT</span> o ha incumplido
              con cualesquiera de las disposiciones aplicables;{" "}
              <span className="terms-text-bold">(vi)</span> si su Cuenta
              permanece inactiva por un año o más;{" "}
              <span className="terms-text-bold">(vii)</span> Usted fondea sus
              transacciones mediante el uso de técnicas o procesos fraudulentos
              o de manera ilegal;{" "}
              <span className="terms-text-bold">(viii)</span> si se hace de
              nuestro conocimiento que sus fondos son producto de un delito;{" "}
              <span className="terms-text-bold">(ix)</span> si, en la opinión
              razonable de <span className="terms-text-bold">EKIIPAGO</span>, su
              cuenta bancaria interactúa con servicios de inversión fraudulentos
              o de rendimientos insostenibles;{" "}
              <span className="terms-text-bold">(x)</span> por cualquier otra
              razón por la cual{" "}
              <span className="terms-text-bold">EKIIPAGO</span> considere que
              sus transacciones representan un riesgo para nuestras operaciones,
              y <span className="terms-text-bold">(xi)</span> por cualquier otra
              causa, a discreción de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              15.3. Si su Cuenta se ha cerrado, se le enviará un aviso a la
              dirección de correo electrónico registrada asociada con su cuenta
              de <span className="terms-text-bold">USUARIO</span>, cuando así lo
              permita la legislación aplicable, según sea el caso y se iniciará
              un procedimiento de acuerdo con la Cláusula 14.6. de estos
              términos, en el entendido que{" "}
              <span className="terms-text-bold">EKIIPAGO</span> no tendrá
              responsabilidad alguna, de cualquier naturaleza, derivada de que
              hubiere cerrado la cuenta del{" "}
              <span className="terms-text-bold">USUARIO</span>.
            </p>
            <p className="sub-title-terms">16. ERRORES DE SISTEMA</p>
            <p className="terms-text ">
              16.1. Es posible que, debido a factores tecnológicos,
              mantenimientos programados u otros factores, fuera o dentro de
              nuestro control, la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> u otros
              servicios sean interrumpidos temporalmente. Usted acepta que no
              somos responsables por los daños y perjuicios derivados de dicha
              interrupción y renuncia a cualquier derecho que pudiere tener en
              contra de <span className="terms-text-bold">EKIIPAGO</span> por
              este concepto.
            </p>
            <p className="terms-text ">
              16.2. El <span className="terms-text-bold">USUARIO</span> acepta
              que <span className="terms-text-bold">EKIIPAGO</span> tendrá
              potestad absoluta de revertir cualquier operación que haya sido
              efectuada como consecuencia de una interrupción en el servicio o
              fallo en el funcionamiento de las comunicaciones electrónicas,
              instalaciones comerciales, instalaciones de almacenamiento,
              mecanismos de grabación u otros componentes de la Aplicación
              Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span>. En
              estas circunstancias las operaciones se revisarán caso por caso, y{" "}
              <span className="terms-text-bold">EKIIPAGO</span> le informará
              mediante la dirección de correo electrónico proporcionada por el{" "}
              <span className="terms-text-bold">USUARIO</span>, si alguna de sus
              operaciones está en esta situación.
            </p>
            <p className="terms-text ">
              16.3. En ningún caso seremos responsables por cualquier daño o
              perjuicio, directo o indirecto, incluyendo, sin limitación, todas
              las pérdidas, costos, gastos, lucro cesante, pérdida de
              beneficios, pérdida de ingresos o expectativas de ganancias
              esperadas, como consecuencia de la falla en el servicio de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> o de nuestro
              sitio Web, sin importar la causa, período de tiempo o afectación
              patrimonial. No somos responsables por cualquier daño o perjuicio
              que cualquier persona sufra como consecuencia del acceso a nuestro
              sitio Web.
            </p>
            <p className="terms-text ">
              16.4. <span className="terms-text-bold">EKIIPAGO</span> realiza
              constantemente esfuerzos para prevenir vulneraciones a la
              seguridad de nuestra Aplicación Web/Móvil; sin embargo, podríamos
              llegar a ser víctimas de una vulneración en la cual se vean
              afectadas las cuentas de los{" "}
              <span className="terms-text-bold">USUARIOS</span>. En caso de que
              suceda un evento como el descrito en este párrafo,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> publicará un
              comunicado indicando los pasos a seguir.
            </p>
            <p className="sub-title-terms">17. MARKET MAKERS</p>
            <p className="terms-text ">
              17.1. <span className="terms-text-bold">EKIIPAGO</span> tendrá
              potestad absoluta de involucrar uno o más creadores de mercado ("
              <span className="terms-text-bold">Market Makers</span>"), que
              también pueden estar afiliados con nosotros como proveedores de
              liquidez en la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>. Usted entiende
              y acepta que podemos proporcionar a nuestros Market Makers
              información pública relativa a las solicitudes y otras ofertas
              proporcionadas por Usted y otros participantes en la Aplicación
              Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span>.
              Los Market Makers tendrán la oportunidad, pero no la obligación,
              de aceptar y cubrir dichas solicitudes u ofertas de manera
              preferente y en algunos casos eso implica que podrían llegar a
              tener un mejor precio que si de otro modo colocaran las
              solicitudes directamente a través de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="sub-title-terms">
              18. DERECHOS DE PROPIEDAD INDUSTRIAL E INTELECTUAL
            </p>
            <p className="terms-text ">
              18.1. La plataforma es propiedad de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, por lo cual
              tendrá potestad absoluta sobre la misma y cualquier tecnología
              asociada a la misma. Cualquier creación, idea, invención, mejora,
              know-how, concepto, algoritmo, protocolo, datos, procesos,
              procedimientos, métodos, técnicas, protocolos, fórmulas, sistemas,
              herramientas, composiciones, códigos, software, prototipos,
              documentos, componentes o cualquier otra información que sea de la
              autoría de <span className="terms-text-bold">EKIIPAGO</span> o de
              cualquier <span className="terms-text-bold">USUARIO</span>, pero
              exclusivamente como consecuencia del uso del sitio Web de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, sea o no
              patentable o susceptible de estar bajo la protección de derechos
              de autor, o cualquier tipo de derechos de propiedad intelectual o
              industrial, son propiedad de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, teniendo el
              derecho <span className="terms-text-bold">EKIIPAGO</span> de
              iniciar cualquier acción, o tomar cualquier medida, de cualquier
              naturaleza, para obtener su registro ante cualquier Autoridad
              (incluyendo cualquier Autoridad en materia de propiedad
              intelectual).
            </p>
            <p className="terms-text ">
              18.2. Del mismo modo, cualquier obra derivada, tales como, de
              manera descriptiva mas no limitativa, tales como una traducción,
              modificación, mejora, personalización, adaptación, compilación o
              cualquier tipo de producto que use como origen cualquier elemento
              de nuestra plataforma será propiedad de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, por lo que no
              concedemos ningún tipo de licencia, ni permiso para la producción
              de alguna obra derivada o permiso o licencia para su uso por
              cualquier tercero. En su caso, cualquier producción derivada será
              propiedad única y exclusiva de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              18.3. Todos los materiales del sitio Web y cualquier otro sitio
              Web propiedad, operado, licenciado o controlado por nosotros
              constituyen información privada y propiedad intelectual de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> o de sus
              empresas asociadas o subsidiarias, por lo que tendremos plena
              potestad sobre los mismos. Todos los materiales incluidos en el
              sitio Web, incluido, pero no limitado a, compilaciones de datos y
              software son propiedad de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> y/o empresas
              asociadas o subsidiarias y están protegidos por las leyes de
              derechos de autor.
            </p>
            <p className="sub-title-terms">19. DISPOSICIONES ADICIONALES</p>
            <p className="terms-text ">
              19.1. Por el hecho de usar el sitio Web de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> o de solicitar o
              recibir los servicios promocionados a través de la Aplicación
              Web/Móvil de <span className="terms-text-bold">EKIIPAGO</span>,
              cada uno de los <span className="terms-text-bold">USUARIOS</span>{" "}
              declara que <span className="terms-text-bold">(i)</span> está
              facultado para aceptar y cumplir estos Términos de Uso,{" "}
              <span className="terms-text-bold">(ii)</span> el cumplimiento de
              estos Términos de Uso no contraviene cualquier disposición que le
              sea aplicable o cualquier contrato o convenio del que sea parte,{" "}
              <span className="terms-text-bold">(iii)</span> ha obtenido y está
              en vigor cualquier autorización necesaria para cumplir con estos
              Términos de Uso, <span className="terms-text-bold">(iv)</span> no
              es parte de cualquier litigio o controversia que pudiere tener
              efectos adversos respecto de sus obligaciones conforme a estos
              Términos de Uso, <span className="terms-text-bold">(v)</span>{" "}
              estos Términos de Uso constituyen obligaciones válidas y exigibles
              en su contra, conforme a sus términos, y{" "}
              <span className="terms-text-bold">(vi)</span> No existe una ley o
              regulación contra el uso de nuestros servicios en la Jurisdicción,
              País o Estado donde Usted vive o se encuentra ubicado.
            </p>
            <p className="terms-text ">
              19.2. Cada <span className="terms-text-bold">USUARIO</span> que
              acceda al sitio Web de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> o que reciba
              servicios promocionados a través de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, se obliga a
              indemnizar y liberar a{" "}
              <span className="terms-text-bold">EKIIPAGO</span> respecto de
              cualquier daño o perjuicio que afecte a{" "}
              <span className="terms-text-bold">EKIIPAGO</span>, como
              consecuencia de cualquier hecho, acto u omisión del{" "}
              <span className="terms-text-bold">USUARIO</span> relacionado con
              el acceso al sitio Web de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> o con los
              servicios que allí se promocionan y conviene que, para resarcirse
              de dichos daños o perjuicios,{" "}
              <span className="terms-text-bold">EKIIPAGO</span> podrá ejercer
              sus derechos en contra del{" "}
              <span className="terms-text-bold">USUARIO</span> conforme a la
              legislación aplicable.
            </p>
            <p className="terms-text ">
              19.3. Los términos establecidos en estos Términos de Uso rigen el
              uso de la Aplicación Web/Móvil de{" "}
              <span className="terms-text-bold">EKIIPAGO</span> y los servicios
              que proporciona. Al hacer clic en "
              <span className="terms-text-bold">
                Acepto Términos y Condiciones
              </span>
              ", Usted acepta que quedará vinculado por las disposiciones de
              estos Términos de Uso y reconoce que ha recibido y entendido las
              disposiciones contenidas en ellos. Si Usted no está de acuerdo con
              estos Términos de Uso, no haga clic en "
              <span className="terms-text-bold">
                cepto Términos y Condiciones
              </span>
              " y por favor no visite, acceda o utilice la Aplicación Web/Móvil
              de <span className="terms-text-bold">EKIIPAGO</span>, ni los
              servicios que proporcionamos.
            </p>
            <p className="terms-text ">
              19.4. Si alguna disposición de estos Términos de Uso es inválida o
              no exigible conforme a la ley aplicable, las disposiciones
              restantes continuarán en pleno vigor y efecto.
            </p>
            <p className="terms-text ">
              19.5. Estos Términos de Uso constituyen el acuerdo completo entre
              Usted y <span className="terms-text-bold">EKIIPAGO</span> en
              relación con el uso o la participación en nuestra Aplicación
              Web/Móvil o cualquiera de los servicios que se proveen en este
              sitio. Estos Términos sustituyen cualquier otro acuerdo, oral o
              por escrito, con respecto a lo mismo entre Usted y{" "}
              <span className="terms-text-bold">EKIIPAGO</span>.
            </p>
            <p className="terms-text ">
              19.6. Si <span className="terms-text-bold">EKIIPAGO</span> no
              insiste en el estricto cumplimiento de cualquier término o
              disposición del presente acuerdo, ello no se interpretará como una
              renuncia respecto de exigir su cumplimiento posterior.
            </p>
            <p className="terms-text ">
              19.7. Estos Términos de Uso, o sus derechos y obligaciones
              derivados en virtud de los mismos, no podrán ser transferidos por
              Usted; pero tendremos potestad absoluta de transferir o asignar la
              información que hemos recopilado de Usted como parte de una
              fusión, adquisición, venta o cualquier otro cambio de control, o
              como consecuencia de cualquier otra operación.
            </p>
            <p className="terms-text ">
              19.8. Las disposiciones de estos Términos de Uso sobrevivirán a la
              terminación o expiración de la relación, por el plazo de
              prescripción aplicable.
            </p>
            <p className="terms-text ">
              19.9. Para la interpretación, cumplimiento y ejecución de estos
              Términos de Uso, las partes convienen que serán aplicables las
              leyes de la República Bolivariana de Venezuela y se someten a la
              jurisdicción y competencia de los tribunales de la ciudad de
              Caracas, renunciando en este acto a aquellos que por ministerio de
              ley, fuero o residencia le pudieran corresponder, por razón de su
              domicilio o por cualquier otra causa. El{" "}
              <span className="terms-text-bold">USUARIO</span> acepta que todas
              las solicitudes de compra y de venta se consideran realizados en
              territorio de la República Bolivariana de Venezuela y más
              específicamente, dentro de la ciudad de Caracas.
            </p>
            <p className="terms-text ">
              19.10. El <span className="terms-text-bold">USUARIO</span>{" "}
              reconoce que una versión impresa de este Términos de Uso será
              admisible como instrumento probatorio en procedimientos judiciales
              o administrativos y que los títulos de las Secciones en estos
              Términos de Uso son sólo por referencia, y no regulan el
              significado o interpretación de cualquier disposición de estos
              Términos de Uso.
            </p>
            <p className="terms-text ">
              19.11. Aún si el presente acuerdo está disponible en otros
              idiomas, los significados de los términos, condiciones y
              declaraciones en este documento están sujetos a sus definiciones e
              interpretaciones en el idioma español.
            </p>
            <p className="terms-text ">
              19.12. Para la conveniencia de las partes, los presentes Términos
              de Uso podrán ser firmados de manera electrónica o por facsímil;
              el ejemplar que posea cada una de las partes, ya sea en formato
              electrónico, escaneado o por cualquier medio digital o facsímil se
              considerará un original, y los dos constituirán un acuerdo
              vinculante para ambas partes.
            </p>
            <p className="terms-text ">
              19.13. El <span className="terms-text-bold">USUARIO</span>{" "}
              manifiesta que ha leído las disposiciones de estos Términos de
              Uso, y que firma y que entiende sus obligaciones y derechos
              derivados de los mismos, asumiendo así las obligaciones que
              derivan de su firma.
            </p>
            <p className="sub-title-terms">20. CONTACTO</p>
            <p className="terms-text ">
              El <span className="terms-text-bold">USUARIO</span> nos puede
              contactar a través de la dirección de correo electrónico:{" "}
              <a href="mailto:soporte@ekiipago.com">soporte@ekiipago.com</a>,
              exponiendo el motivo del contacto, con sus datos y evidencias, si
              fuere el caso.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
