import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import RegisterIcon from "../../../assets/icons/navigateToRegister.png";
import SimulatorInput from "./SimulatorInput";
import Button from "../Button";
import DetailButton from "./DetailButton";
import {
  onSendValueChange,
  onReceiveValueChange,
  showDetails,
  setAmountToSendCurrency,
  onReceiveValueBlur,
  onSendValueBlur,
  setInitialCountry,
  convertToNumber,
  getAvailableCountries,
} from "../../../ducks/actions/SimulatorActions";
import { globalStateType } from "../../../ducks/reducers";
import { COMING_FROM } from "../../../utils/ConstHelper";
import "./Simulator.css";

const defaultProps = {};
const defaultState = {};

type Props = {
  //props | state
  saving?: string;
  savingCurrency: string;
  total?: number | string;
  totalCurrency: string;
  typeOfChange: string;
  amountToSend?: number | string;
  amountToReceive?: number | string;
  displayDetails: boolean;
  rates: any;
  availableCountries: any;
  amountToSendCurrency: string;
  amountToSendCountry?: any;
  amountToReceiveCountry?: any;
  maxAmount?: number | string;
  minAmount?: number | string;
  //functions
  onSendValueChange: (value: number | string) => void;
  onReceiveValueChange: (value: number | string) => void;
  showDetails: (value: boolean) => void;
  setAmountToSendCurrency: (text: string) => void;
  onReceiveValueBlur: () => void;
  onSendValueBlur: () => void;
  setInitialCountry: () => void;
  getAvailableCountries: () => void;
  comingFrom?: string;
  stepsNavigation?: () => void;
  history?: any;
} & typeof defaultProps;

type State = typeof defaultState;

class SimulatorContainer extends Component<Props> {
  componentDidMount() {
    const { getAvailableCountries } = this.props;
    getAvailableCountries();
  }

  handleOnButtonClick = () => {
    const { history } = this.props;
    ReactGA.event({
      category: "Button",
      action: "Click enviar dinero from landing page",
    });
    if (history) {
      history.push("/login");
    }
  };

  handleGoToRegisterClick = () => {
    const { history } = this.props;
    ReactGA.event({
      category: "Button",
      action: "Click registrate from landing page",
    });
    if (history) {
      history.push("/register");
    }
  };

  onCheckMarkChange = () => {
    const { showDetails, displayDetails } = this.props;
    showDetails(!displayDetails);
  };
  handleAmountToSendCurrencyChange = (value: any) => {
    const { setAmountToSendCurrency } = this.props;
    setAmountToSendCurrency(value.currency);
  };
  handleAmountToSendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onSendValueChange } = this.props;
    onSendValueChange(event.target.value);
  };

  handleAmountToReceiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onReceiveValueChange } = this.props;
    onReceiveValueChange(event.target.value);
  };

  getErrorMessage = () => {
    const {
      maxAmount,
      minAmount,
      amountToSend,
      amountToSendCurrency,
    } = this.props;

    if (
      maxAmount &&
      amountToSend &&
      minAmount &&
      convertToNumber(amountToSend) < convertToNumber(maxAmount) &&
      convertToNumber(amountToSend) > convertToNumber(minAmount)
    ) {
      return <React.Fragment />;
    }

    if (
      minAmount &&
      amountToSend &&
      convertToNumber(amountToSend) < convertToNumber(minAmount)
    ) {
      return (
        <p className="simulator-alert-text">
          Puedes enviar desde {minAmount} {amountToSendCurrency}
        </p>
      );
    }
    if (
      maxAmount &&
      amountToSend &&
      convertToNumber(amountToSend) > convertToNumber(maxAmount)
    ) {
      return (
        <p className="simulator-alert-text">
          Puedes enviar hasta {maxAmount} {amountToSendCurrency}
        </p>
      );
    }
  };

  render() {
    const {
      saving,
      savingCurrency,
      typeOfChange,
      amountToSend,
      amountToReceive,
      displayDetails,
      amountToSendCurrency,
      onReceiveValueBlur,
      onSendValueBlur,
      amountToSendCountry,
      maxAmount,
      minAmount,
      availableCountries,
      comingFrom,
    } = this.props;
    return (
      <div className="simulator-container">
        <p className="simulator-text">
          Tú <span className="simulator-text-bold">envías</span>
        </p>
        <SimulatorInput
          value={amountToSend}
          options={availableCountries}
          onChangeValue={this.handleAmountToSendChange}
          onCurrencyChange={this.handleAmountToSendCurrencyChange}
          disabled={amountToSendCurrency ? false : true}
          onBlur={onSendValueBlur}
          selectedCountry={amountToSendCountry}
          placeholder={"Ingresa monto a enviar"}
        />
        {this.getErrorMessage()}
        <p className="simulator-text">
          Tu beneficiario <span className="simulator-text-bold">recibe</span>
        </p>
        <SimulatorInput
          value={amountToReceive}
          options={availableCountries}
          onChangeValue={this.handleAmountToReceiveChange}
          beneficiary
          disabled={amountToSendCurrency ? false : true}
          onBlur={onReceiveValueBlur}
          placeholder={"Monto a recibir"}
        />
        <div className="savings-container">
          <div className="savings-text-container">
            {amountToSendCountry &&
            amountToSendCountry.exchange_rate_enable === "INACTIVE" ? null : (
              <p className="savings-text">
                <strong>Te ahorras</strong> {saving} {savingCurrency}
              </p>
            )}
          </div>

          <DetailButton onDetailPress={this.onCheckMarkChange} />
        </div>
        {displayDetails ? (
          <div className="resume-container">
            <p className="savings-text margin-none">
              Tasa de cambio: <strong>{typeOfChange}</strong>
            </p>
            <p className="savings-text margin-none">
              Llega en menos de <strong>60 minutos</strong>
            </p>
          </div>
        ) : (
          <React.Fragment />
        )}

        <Button
          buttonText={
            comingFrom && comingFrom === COMING_FROM.private
              ? "CONTINUAR"
              : "ENVIAR DINERO"
          }
          onClick={
            comingFrom && comingFrom === COMING_FROM.private
              ? this.props.stepsNavigation
              : this.handleOnButtonClick
          }
          disabled={
            maxAmount &&
            amountToSend &&
            minAmount &&
            convertToNumber(amountToSend) < convertToNumber(maxAmount) &&
            convertToNumber(amountToSend) > convertToNumber(minAmount)
              ? false
              : true
          }
        />
        <div className="register-icon-simulator-container">
          {comingFrom && comingFrom === COMING_FROM.private ? (
            <React.Fragment />
          ) : (
            <div
              className="register-icon-simulator-gray-container"
              onClick={this.handleGoToRegisterClick}
            >
              <div className="register-icon-simulator-circle">
                <img
                  src={RegisterIcon}
                  alt="RegisterIcon"
                  className="register-icon-simulator"
                />
              </div>
              <span className="register-icon-simulator-text">REGÍSTRATE</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    saving: state.simulatorBox.saving,
    savingCurrency: state.simulatorBox.savingCurrency,
    total: state.simulatorBox.total,
    totalCurrency: state.simulatorBox.totalCurrency,
    typeOfChange: state.simulatorBox.typeOfChange,
    amountToSend: state.simulatorBox.amountToSend,
    amountToReceive: state.simulatorBox.amountToReceive,
    rates: state.simulatorBox.rates,
    displayDetails: state.simulatorBox.displayDetails,
    amountToSendCurrency: state.simulatorBox.amountToSendCurrency,
    amountToSendCountry: state.simulatorBox.amountToSendCountry,
    amountToReceiveCountry: state.simulatorBox.amountToReceiveCountry,
    maxAmount: state.simulatorBox.maxAmount,
    minAmount: state.simulatorBox.minAmount,
    availableCountries: state.simulatorBox.availableCountries,
  };
};

export default connect(mapStateTopProps, {
  onSendValueChange,
  onReceiveValueChange,
  showDetails,
  setAmountToSendCurrency,
  onReceiveValueBlur,
  onSendValueBlur,
  setInitialCountry,
  getAvailableCountries,
})(SimulatorContainer);
