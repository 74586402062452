import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import Eye from "../../../../assets/icons/eye-regular-black.png";
import Refresh from "../../../../assets/icons/refresh.png";
import Button from "../../../common/Button";
import { TransactionHistory } from "../../../../utils/ConstHelper";
import "./HistoryTable.css";

type Props = {
  transactions: TransactionHistory[];
  refresh?: () => void;
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    marginTop: 20,
    backgroundColor: "#b2aeae",
    color: "#000000",
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
  },
  body: {
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    width: "100%",
  },
});

const renderModal = (
  open: boolean,
  handleClose: () => void,
  transaction?: TransactionHistory
) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className="dialog-title-container">
          <span className="dialog-title">Resumen de transacción</span>
        </div>
      </DialogTitle>
      <DialogContent>
        {transaction && (
          <React.Fragment>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label">Nº de Transacción</span>
              <span className="transaction-table-bold">
                {transaction.celupagoReference}
              </span>
            </div>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label">Banco país origen</span>
              <span className="transaction-table-bold">
                {transaction.cashierBank[0].commercial_name}
              </span>
            </div>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label">
                Referencia Bancaria
              </span>
              <span className="transaction-table-bold">
                {transaction.bankReference}
              </span>
            </div>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label">ID cajero</span>
              <span className="transaction-table-bold">
                {transaction.cashierId}
              </span>
            </div>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label">Nombre cajero</span>
              <span className="transaction-table-value">
                {transaction.cashierName}
              </span>
            </div>

            <div className="transaction-table-row-item">
              <span className="transaction-table-label">Beneficiario</span>
              <span className="transaction-table-value">
                {transaction.beneficiaryName}
              </span>
            </div>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label">Fecha de emisión</span>
              <span className="transaction-table-value">
                {moment(transaction.emissionDate).format("DD/MM/YYYY hh:mm A")}
              </span>
            </div>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label">Monto orígen</span>
              <span className="transaction-table-value">
                {transaction.originAmount} {transaction.originCurrency}
              </span>
            </div>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label">Monto destino</span>
              <span className="transaction-table-value">
                {transaction.destinationAmount}{" "}
                {transaction.destinationCurrency}
              </span>
            </div>
            <div className="transaction-table-row-item">
              <span className="transaction-table-label status-different-color">
                Estatus
              </span>
              <span className="transaction-table-value status-different-color">
                {transaction.status.charAt(0).toUpperCase() +
                  transaction.status.slice(1).toLowerCase()}
              </span>
            </div>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button buttonText={"OK"} onClick={() => handleClose()} />
      </DialogActions>
    </Dialog>
  );
};

const HistoryTable = ({ transactions, refresh }: Props) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const [currentTransaction, setCurrentTransaction] = useState(
    undefined as undefined | TransactionHistory
  );
  const classes = useStyles();

  const handleClickOpen = (transaction: TransactionHistory) => {
    setCurrentTransaction(transaction);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <React.Fragment>
      <div className="table-main-container">
        {renderModal(open, handleClose, currentTransaction)}
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ID cajero</StyledTableCell>
              <StyledTableCell align="center">
                Nº de Transacción
              </StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Monto orígen</StyledTableCell>
              <StyledTableCell align="center">Monto destino</StyledTableCell>
              <StyledTableCell align="center">Estatus</StyledTableCell>
              {refresh ? (
                <StyledTableCell align="center">
                  <div onClick={refresh}>
                    <img
                      src={Refresh}
                      alt="refresh"
                      className="refresh-icon-style"
                    />
                  </div>
                </StyledTableCell>
              ) : (
                <StyledTableCell align="center"> </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions
              .slice(page * 10, page * 10 + 10)
              .map((transaction) => (
                <StyledTableRow key={transaction.key}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {transaction.cashierId}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {transaction.celupagoReference}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {moment(transaction.emissionDate).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {transaction.originAmount} {transaction.originCurrency}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {transaction.destinationAmount}{" "}
                    {transaction.destinationCurrency}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {transaction.status}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => {
                      handleClickOpen(transaction);
                    }}
                  >
                    <img src={Eye} alt="radio" className="eye-icon-style" />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={transactions.length}
          rowsPerPage={10}
          page={page}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : "más de" + to}`
          }
          onChangePage={handleChangePage}
        />
      </TableContainer>
    </React.Fragment>
  );
};

export default HistoryTable;
