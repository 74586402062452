import React from "react";
import Lock from "../../../assets/icons/lock.png";
import File from "../../../assets/icons/file-dark.png";
import Link from "../../../assets/icons/link-dark.png";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="aboutUs" id="aboutUs">
      <div className="title-aboutUs">
        ¿POR QUÉ SOMOS LA MEJOR OPCIÓN EN EL ENVÍO DE DINERO A VENEZUELA?
      </div>

      <div className="file-aboutUs">
        <div className="wrap-aboutUs">
          <img src={Lock} alt="lock" className="lock" />
        </div>

        <div className="subTitle-wrap-aboutUs">
          <div className="file-subTitle-shadow-aboutUs"> </div>
          <p className="subTitle-aboutUs file-subTitle-aboutUs">
            Seguridad en el envío de dinero
          </p>
          <p className="text-aboutUs">
            Garantizando el abono de tu dinero en Venezuela, con altos
            estándares de seguridad y respaldado por los mejores Asesores
            Financieros en el intercambio de activos digitales (
            <span className="text-purple">AFX</span>).
          </p>
        </div>
      </div>

      <div className="link-aboutUs">
        <div className="wrap-aboutUs">
          <img src={Link} alt="link" className="link" />
        </div>

        <div className="subTitle-wrap-aboutUs">
          <div className="lock-subTitle-shadow-aboutUs"> </div>
          <p className="subTitle-aboutUs lock-subTitle-aboutUs">
            Atención inmediata durante todo el envío
          </p>

          <p className="text-aboutUs">
            A través de nuestro canal de atención:{" "}
            <a
              rel="noopener noreferrer"
              href="https://wa.me/5804126298180"
              target="_blank"
              className="link-ref"
            >
              WhatsApp{" "}
            </a>
            en todo momento, al tener dudas en el envío de tu dinero con
            nosotros, y también podrás
            <a
              rel="noopener noreferrer"
              href="https://goo.gl/maps/KA3xkwRNZeozLMfp8"
              target="_blank"
              className="link-ref"
            >
              {" "}
              ubicarnos aquí
            </a>
            .
          </p>
        </div>
      </div>
      <div className="lock-aboutUs">
        <div className="wrap-aboutUs">
          <img src={File} alt="file" className="file" />
        </div>

        <div className="subTitle-wrap-aboutUs">
          <div className="link-subTitle-shadow-aboutUs"> </div>
          <p className="subTitle-aboutUs link-subTitle-aboutUs">
            Nuestra experiencia en el mercado
          </p>

          <p className="text-aboutUs">
            Con más de 180 mil clientes que usan el servicio de recargas móviles
            de <span className="text-purple">operadoras reconocidas</span> a
            través de nuestras{" "}
            <span className="text-purple">entidades financieras aliadas</span>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
