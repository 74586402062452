import React, { ReactElement } from "react";
import Schedule from "../../assets/img/workingSchedule.png";
import ScheduleMobile from "../../assets/img/workingScheduleMobile.png";
import "./Modal.css";

type Props = {
  handleClose?: () => void;
  show: boolean;
};

const ScheduleModal = ({ handleClose, show }: Props): ReactElement => {
  const showHideClassName = show
    ? "modal display-block-modal"
    : "modal display-none-modal";

  return (
    <div className={showHideClassName}>
      <section className="schedule-modal-main">
        <div className="all-schedule-modal-content-container">
          <div className="schedule-image-container">
            <img
              src={window.innerWidth < 580 ? ScheduleMobile : Schedule}
              className="schedule-image"
              alt="schedule"
            />
          </div>
          <div className="close-schedule-button-container">
            <div className="close-schedule-button-body" onClick={handleClose}>
              <span className="close-schedule-button-text">X</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ScheduleModal;
