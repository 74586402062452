import { TRANSACTION_SUMMARY_ACTIONS } from "../actions/TransactionSummaryActions";
import { Transaction } from "../../utils/ConstHelper";

export type transactionSummaryStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  transactionSummary: undefined as any | Transaction,
  error: false,
  loading: false,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TRANSACTION_SUMMARY_ACTIONS.CLEAN_STATE:
      return { ...state, ...INITIAL_STATE };

    case TRANSACTION_SUMMARY_ACTIONS.FETCH_TRANSACTION_DATA:
      return {
        ...state,
        loading: false,
        transactionSummary: action.payload,
      };

    case TRANSACTION_SUMMARY_ACTIONS.TRANSACTION_SUMMARY_START:
      return { ...state, loading: true };

    case TRANSACTION_SUMMARY_ACTIONS.TRANSACTION_SUMMARY_FAIL:
      return {
        ...state,
        transactionSummary: [],
        error: true,
        loading: false,
      };

    case TRANSACTION_SUMMARY_ACTIONS.SESSION_EXPIRED:
      return {
        ...state,
        orderSuccess: action.payload,
        loading: false,
      };

    case TRANSACTION_SUMMARY_ACTIONS.DO_NOTHING:
      return { ...state };

    default:
      return { ...state };
  }
};
