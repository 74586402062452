import React, { Component } from "react";
import UserCircle from "../../../../assets/icons/user-circle.png";
import UserCircleLight from "../../../../assets/icons/user-circle-light.png";
import UserConfig from "../../../../assets/icons/user-config.png";
import ChevronLight from "../../../../assets/icons/chevron-light.png";
import Radiobutton from "../../../../assets/icons/Radiobutton-light.png";
import "./Sidebar.css";
import { CASHIER_TYPES } from "../../../../utils/ConstHelper";

const defaultProps = {};
const defaultState = { showOption: false };

type Props = {
  name: string;
  cashierType: string;
  selectOption: (option: string) => void;
} & typeof defaultProps;
type State = typeof defaultState;

class Sidebar extends Component<Props, State> {
  state = defaultState;

  showModal = () => {
    const { showOption } = this.state;
    const visible = showOption;
    this.setState({ showOption: !visible });
  };

  handleTypeCashier = (value: string) => {
    if (value) {
      const type = CASHIER_TYPES.filter((type) => type.value === value);
      if (type) return type[0].name;
      return "";
    }
  };

  render() {
    const { showOption } = this.state;
    const { cashierType, name, selectOption } = this.props;
    return (
      <div className="sidebar-container">
        <div className="sidebar-user-container">
          <div className="sidebar-user">
            <img
              src={UserCircle}
              alt="userCircle"
              className="sidebar-user-icon"
            />
          </div>
          <div className="sidebar-user-info">
            <div>
              <div className="sidebar-text">{name}</div>
              <div className="sidebar-text-light">
                Cajero {this.handleTypeCashier(cashierType)}
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-option-container" onClick={this.showModal}>
          <img
            src={UserConfig}
            alt="userConfig"
            className="sidebar-user-config-icon"
          />
          <div className="sidebar-option">
            <span className="sidebar-text">Configurar</span>
          </div>
          <img
            src={ChevronLight}
            alt="chevronLight"
            className="sidebar-arrow-config-icon"
          />
        </div>
        {showOption ? (
          <div
            className="sidebar-option-container sidebar-sub-option"
            onClick={() => selectOption("Operations")}
          >
            <img
              src={Radiobutton}
              alt="sidebar-radio-icon"
              className="sidebar-radio-icon"
            />
            <div className="sidebar-option">
              <span className="sidebar-text">Operaciones </span>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
        <div
          className="sidebar-option-container profile-container-margin"
          onClick={() => selectOption("ProfileCashier")}
        >
          <img
            src={UserCircleLight}
            alt="userCircleLight"
            className="sidebar-user-light-icon"
          />
          <div className="sidebar-option">
            <span className="sidebar-text">Mi Perfil </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
