import React, { Component } from "react";
import moment from "moment-timezone";
import ScheduleModal from "../../common/ScheduleModal";
import HeaderContainer from "../../common/header/HeaderContainer";
import AboutUs from "../../common/body/AboutUs";
import FooterContainer from "../../common/footer/FooterContainer";
import Main from "../../common/body/Main";
import "./Landing.css";

const defaultProps = {};
const defaultState = {
  showScheduleAlert: false,
};

type Props = {} & typeof defaultProps;

type State = typeof defaultState;
class Landing extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    moment.tz.setDefault("America/Caracas");
    const currentTime = moment().format();
    const startTime = moment().set("hour", 8).set("minutes", 0).format();
    const closeTime = moment().set("hour", 17).set("minutes", 0).format();
    if (currentTime < startTime || currentTime > closeTime)
      this.setState({ showScheduleAlert: true });
  }
  handleClose = () => {
    this.setState({ showScheduleAlert: false });
  };

  componentWillUnmount() {
    moment.tz.setDefault();
  }

  render() {
    const { showScheduleAlert } = this.state;

    return (
      <div className="landing-container">
        <ScheduleModal
          show={showScheduleAlert}
          handleClose={this.handleClose}
        />
        <main className="body-content">
          <div className="landing-header-container">
            <HeaderContainer />
          </div>
          <div className="landing-main-container">
            <Main />
          </div>
          <div className="landing-aboutUs-container">
            <AboutUs />
          </div>
          <div className="landing-footer-container">
            <FooterContainer />
          </div>
        </main>
        <a
          rel="noopener noreferrer"
          href="https://wa.me/5804126298180"
          target="_blank"
          className="chat-button"
        >
          <div className="chat-button-1">
            <div className="chat-button-2">
              <div className="chat-button-3">Chat</div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default Landing;
