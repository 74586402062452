import React, { Component } from "react";
import Button from "../../common/Button";
import SummaryLabel from "./SummaryLabel";
import "./TransactionSummary.css";
import Modal from "../../common/Modal";
import CheckCircle from "./../../../assets/icons/check-circle.png";
import ExclamationCircle from "./../../../assets/icons/exclamation-circle.png";
import { connect } from "react-redux";
import { globalStateType } from "../../../ducks/reducers";
import { getTransactionSummary } from "../../../ducks/actions/TransactionSummaryActions";
import { Transaction } from "../../../utils/ConstHelper";
import Loader from "../../common/Loader";
import { cleanBeneficiaryFormState } from "../../../ducks/actions/BeneficiaryFormActions";
import { cleanPaymentState } from "../../../ducks/actions/PaymentActions";
import {
  cleanSimulatorState,
  latinFormat,
} from "../../../ducks/actions/SimulatorActions";
import { cleanTransactionSummaryState } from "../../../ducks/actions/TransactionSummaryActions";
import { Redirect } from "react-router-dom";

const defaultProps = {};

type Props = {
  getTransactionSummary: () => void;
  transactionSummary: Transaction;
  stepsNavigation?: () => void;
  cleanBeneficiaryFormState: () => void;
  cleanPaymentState: () => void;
  cleanSimulatorState: () => void;
  cleanTransactionSummaryState: () => void;
  loading: boolean;
  error: boolean;
} & typeof defaultProps;

type State = typeof initialState;

const initialState = {
  show: false,
  orderSuccess: false,
};

class TransactionSummary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    const { getTransactionSummary } = this.props;
    getTransactionSummary();
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    const {
      cleanBeneficiaryFormState,
      cleanPaymentState,
      cleanSimulatorState,
      cleanTransactionSummaryState,
    } = this.props;
    this.setState({ show: false });
    if (this.props.stepsNavigation) {
      cleanBeneficiaryFormState();
      cleanPaymentState();
      cleanSimulatorState();
      cleanTransactionSummaryState();
      //   this.props.stepsNavigation();
      this.setState({ orderSuccess: true });
    }
  };
  render() {
    const { transactionSummary, loading, error } = this.props;

    const redirect = this.state.orderSuccess ? (
      <Redirect to="/home" />
    ) : (
      <React.Fragment />
    );
    return (
      <div className={"summary-body-container"}>
        {redirect}
        {loading ? <Loader /> : <React.Fragment />}
        <div className={"summary-text-container"}>
          <p className={"summary-title-text"}>Resumen de transacción</p>
        </div>
        <div className={"transaction-summary-container"}>
          {transactionSummary ? (
            <React.Fragment>
              <div className="summary-transaction-info-container">
                <div className="transaction-summary-title-container">
                  <p className="transaction-summary-title">
                    Información de transacción
                  </p>
                </div>
                <SummaryLabel
                  label={"ID de cajero"}
                  value={transactionSummary.celupagosId}
                />
                <SummaryLabel
                  label={"Monto enviado"}
                  value={`${latinFormat(
                    parseFloat(transactionSummary.amountToSend)
                  )} ${transactionSummary.originCurrency}`}
                />
                <SummaryLabel
                  label={"Monto recibido"}
                  value={`${latinFormat(
                    parseFloat(transactionSummary.amountToReceive)
                  )} ${transactionSummary.destinationCurrency}`}
                />
                <SummaryLabel
                  label={"Tasa de cambio"}
                  value={transactionSummary.exchangeType}
                />
              </div>
              <div className="summary-beneficiary-info-container">
                <div className="transaction-summary-title-container">
                  <p className="transaction-summary-title">
                    Información del beneficiario
                  </p>
                </div>
                <SummaryLabel
                  label={"Nombres y apellidos"}
                  value={transactionSummary.accountHolder}
                />
                <SummaryLabel
                  label={"País"}
                  value={transactionSummary.country}
                />
                <SummaryLabel
                  label={"Banco "}
                  value={transactionSummary.bankName}
                />
                <SummaryLabel
                  label={"Nº de teléfono"}
                  value={transactionSummary.phoneNumber}
                />
                <SummaryLabel
                  label={"Nº de cuenta"}
                  value={transactionSummary.accountNumber}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div className={"summary-button-container"}>
          <Button
            buttonText={"FINALIZAR"}
            disabled={false}
            onClick={(this.props.stepsNavigation, this.showModal)}
          />
        </div>
        <Modal
          show={this.state.show}
          title={"¡Envío exitoso!"}
          img={CheckCircle}
          body={"El dinero llegará a su destino en menos de 60 minutos"}
          handleClose={this.hideModal}
        />
        {error ? (
          <Modal
            show={true}
            title={"Aviso de error"}
            img={ExclamationCircle}
            body={"Ha ocurrido un problema. Inténtalo nuevamente"}
            handleClose={this.hideModal}
          />
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    transactionSummary: state.transactionSummary.transactionSummary,
    loading: state.transactionSummary.loading,
    error: state.transactionSummary.error,
  };
};

export default connect(mapStateTopProps, {
  getTransactionSummary,
  cleanBeneficiaryFormState,
  cleanPaymentState,
  cleanSimulatorState,
  cleanTransactionSummaryState,
})(TransactionSummary);
